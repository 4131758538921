import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Container,
} from "@material-ui/core";
import Styles from "../components/common/style";
import { Link, useParams } from "react-router-dom";
import { useTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import { setWithExpiry, getWithExpiry } from "../asset/utility";
import OutlinedButton from "../components/common/outlinedButton/index";
import OutlinedButton2 from "../components/common/outlinedButton2/index";
import Res from "../asset/e-res.svg";
import Meta from "../asset/metaverse.svg";
import Education from "../asset/education.svg";
import Personal from "../asset/personal.svg";
import investment from "../asset/investments.svg";
import Currency from "../asset/currency.svg";
import Business from "../asset/business.svg";
import Tick from "../asset/tick.svg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "2rem 4rem 2rem 4rem",
    justifyContent: "space-around",
    width: "80vw",
    [theme.breakpoints.down(650)]: {
      flexDirection: "column",
      width: "60vw",
    },
  },
}));

const HomeScreen = () => {
  const classes = Styles();
  const theme = useTheme();
  const clas = useStyles();
  const paramRef = useParams().ref || null;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const handleRegister = () => {
    const storedRef = getWithExpiry("ref") || null;
    if (storedRef) {
      let signUpLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signUpLink, "_blank");
    } else {
      let signUpLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signUpLink, "_blank");
    }
  };
  const redirectToAbout = () => {
    window.location.pathname = "/about";
  };

  return (
    <div className="classes.root">
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.home}>
              {matches ? (
                // mobile view
                <Grid item xs={12} sm={12}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                      >
                        <Box
                          style={{
                            margin: "10rem auto auto auto",
                            width: "95%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box style={{ textAlign: "left" }}>
                            <Typography
                              style={{
                                fontSize: "3rem",
                                fontFamily: "ARIAL",
                                fontWeight: "bold",
                                color: "white",
                                letterSpacing: 2,
                              }}
                            >
                              WELCOME TO THE
                            </Typography>

                            <Typography
                              style={{
                                fontSize: "1.8rem",
                                fontFamily: "ARIAL",
                                fontWeight: "bold",
                                color: "#FBC89F",
                                letterSpacing: 2,
                              }}
                            >
                              PRINCIPALITY OF COGITO
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "1.8rem",
                                fontFamily: "Inter",
                                fontWeight: "400",
                                color: "#fff",
                                letterSpacing: 1.2,
                              }}
                            >
                              Home of the
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "1.8rem",
                                fontFamily: "Inter",
                                fontWeight: "400",
                                color: "#fff",
                                letterSpacing: 1.2,
                              }}
                            >
                              Cogito Metaverse
                            </Typography>
                            <Link
                              to="/cogito-metaverse"
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "2rem",
                                }}
                              >
                                <OutlinedButton2
                                  title="Learn More"
                                  size={12}
                                  height={35}
                                  border={"1.5px solid  #FF7300"}
                                  width={130}
                                  margin={"2rem auto 1.5rem 0"}
                                />
                              </Box>
                            </Link>
                          </Box>
                          <Box>
                            <img
                              className={"coinImg"}
                              src="https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/poc_coins.png"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                // web view
                <Grid item xs={12} sm={12}>
                  <Grid item className={classes.regssmargin}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                    >
                      <Grid item>
                        {/* left content */}
                        <Box
                          style={{
                            margin: "14rem auto 5rem auto",
                            width: "80vw",
                            textAlign: "left",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography
                              variant="h2"
                              style={{
                                fontFamily: "ARIAL",
                                fontWeight: "600",
                                color: "white",
                                letterSpacing: 6,
                                fontSize: "4rem",
                                marginBottom: "1rem",
                              }}
                            >
                              WELCOME TO THE
                            </Typography>

                            <Typography
                              variant="h3"
                              style={{
                                fontFamily: "ARIAL",
                                fontWeight: "600",
                                color: "#FBC89F",
                                letterSpacing: 4,
                                fontSize: "3rem",
                                marginBottom: "1rem",
                              }}
                            >
                              PRINCIPALITY OF COGITO
                            </Typography>
                            <Typography
                              variant="h3"
                              style={{
                                fontFamily: "Inter",
                                fontSize: "1.8rem",
                                fontWeight: "300",
                                color: "#fff",
                                letterSpacing: 3,
                                marginBottom: "1rem",
                              }}
                            >
                              Home of the
                            </Typography>
                            <Typography
                              variant="h3"
                              style={{
                                fontFamily: "Inter",
                                fontSize: "1.8rem",
                                fontWeight: "300",
                                color: "#fff",
                                letterSpacing: 3,
                                marginBottom: "1rem",
                              }}
                            >
                              Cogito Metaverse
                            </Typography>
                            <Link
                              to="/cogito-metaverse"
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              <OutlinedButton2
                                title="Learn More"
                                size={16}
                                width={280}
                                border={2}
                                margin={"2rem auto 4rem 0"}
                              />
                            </Link>
                          </Box>

                          {/* <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "50%",
                              height: "100%",
                              minHeight: "1000px",
                              margin: "auto",
                              background: "red",
                            }}
                          >
                            <h1>hii</h1> */}
                          <img
                            className={"coinImg"}
                            src="https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/poc_coins.png"
                          />
                          {/* </Box> */}
                        </Box>

                        {/* right content */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "3%", marginBottom: "0%" }}>
          <Grid item xs={12} sm={12}>
            {matches ? (
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box padding={matches ? "0rem 2rem " : null}>
                    <Typography
                      style={{
                        fontSize: matches ? "1rem" : "2.1rem",
                        fontFamily: "Arial",
                        fontWeight: 700,
                        fontStyle: "normal",
                        textAlign: "center",
                        color: "#359db6",
                        paddingBottom: "4%",
                      }}
                    >
                      What is the Principality of Cogito?
                    </Typography>
                  </Box>
                  <Box>
                    <Container>
                      <Typography className={classes.sub_Content}>
                        It is a global principality in the digital space which
                        brings together smart minds and is a haven for ordinary
                        people, investors, businesses and non-profit
                        organisations. The concept is revolutionary. A
                        principality that breaks traditional borders and is open
                        to citizens from every country in the world.
                      </Typography>
                    </Container>
                  </Box>

                  <Box className={clas.boxContainer}>
                    <a href="#e-risdency" style={{ textDecoration: "none" }}>
                      <Box className={classes.outerBox}>
                        <Box className={classes.boxDialogeLinks}>
                          <img
                            src={Res}
                            alt="e-residency"
                            height={28}
                            style={{
                              paddingRight: "0.8rem",
                            }}
                          />
                          E-Residency
                        </Box>
                      </Box>
                    </a>
                    <a
                      href="#cogito-metaverse"
                      style={{ textDecoration: "none" }}
                    >
                      <Box className={classes.outerBox}>
                        <Box className={classes.boxDialogeLinks}>
                          <img
                            src={Meta}
                            alt="metaverse"
                            height={28}
                            style={{
                              paddingRight: "0.8rem",
                            }}
                          />
                          The Cogito
                          <br />
                          Metaverse
                        </Box>
                      </Box>
                    </a>
                    <a href="#education" style={{ textDecoration: "none" }}>
                      <Box className={classes.outerBox}>
                        <Box className={classes.boxDialogeLinks}>
                          <img
                            src={Education}
                            alt="education"
                            height={28}
                            style={{
                              paddingRight: "0.8rem",
                            }}
                          />
                          Education
                        </Box>
                      </Box>
                    </a>
                  </Box>

                  <Box>
                    <Container id="e-residency">
                      <Typography
                        style={{
                          fontSize: "1.6rem",
                          textAlign: "center",
                          fontFamily: "arial",
                          fontWeight: "bold",
                          color: "#FF7300",
                        }}
                      >
                        E-Residency
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        The Principality of Cogito gives you the ability to
                        register and run your company as a fully location
                        independent company. This means that you can run your
                        business from anywhere in the world, connect with a
                        network of individuals and businesses in the Cogito
                        Metaverse and enjoy the benefits of simple taxes, low
                        bureaucracy, and simple international banking.
                      </Typography>
                      <Box
                        id="cogito-metaverse"
                        style={{ padding: "2rem 0 6rem 0" }}
                      >
                        <OutlinedButton
                          title="APPLY NOW"
                          size={10}
                          height={35}
                          onClick={handleRegister}
                          width={150}
                          border={2}
                        />
                      </Box>
                    </Container>
                  </Box>
                  <Box>
                    <Container>
                      <Typography
                        style={{
                          fontSize: "1.6rem",
                          textAlign: "center",
                          fontFamily: "arial",
                          fontWeight: "bold",
                          color: "#FF7300",
                        }}
                      >
                        The Cogito Metaverse
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        The Cogito Metaverse is a digital space where the future
                        of finance is growing.Right now we are building the
                        foundation of the financial system and taking
                        registrations.It is possible to register a business or
                        trust and to use Cog to buy and sell goods and
                        services.In the second quarter of 2022, we plan to
                        launch the stock market and there are plans in
                        development for education platforms, communications,
                        networking and fractional investments.
                      </Typography>
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_COGITO_LINK}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Box style={{ padding: "1rem 0 2rem 0" }}>
                          <OutlinedButton
                            title="VISIT COGITO METAVERSE"
                            size={10}
                            height={35}
                            width={180}
                            border={2}
                          />
                        </Box>
                      </a>
                    </Container>
                  </Box>
                  <Box>
                    <Container
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className={classes.sub_Content}>
                        The Principality of Cogito’s vision for the Cogito
                        Metaverse, is to bring the global market together in one
                        place, break down boundaries, and eliminate government
                        control of people’s finances.Within the Cogito
                        Metaverse, Cogito e-residents will support each other;
                        provide freedom of trade, finance with no interest,
                        simple, fair, and low tax rates, education, and ability
                        to start investing.As a Cogito e-resident you will build
                        wealth in a way traditional banks and investment
                        companies can not provide you.You will have the ability
                        to establish companies and asset protection trusts in
                        the Cogito Metaverse to protect your hard earned
                        wealth.This will give you the ability to build wealth in
                        a new way.
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        The Cogito Metaverse currently has the following
                        functions:
                      </Typography>

                      <Box direction="column" className={classes.pointtext}>
                        <Box
                          direction="column"
                          className={classes.boxpointtext}
                        >
                          <img
                            src={Tick}
                            height={28}
                            alt="tick"
                            style={{
                              paddingRight: "2rem",
                            }}
                          />
                          <Typography className={classes.mobileBox}>
                            E-Residency - the ability to have a digital
                            citizenship <br />
                            in the Principality of Cogito
                          </Typography>
                        </Box>
                        <Box
                          direction="column"
                          className={classes.boxpointtext}
                        >
                          <img
                            src={Tick}
                            height={28}
                            alt="tick"
                            style={{ paddingRight: "2rem" }}
                          />
                          <Typography className={classes.mobileBox}>
                            Business and Trust registration
                          </Typography>
                        </Box>
                        <Box
                          direction="column"
                          className={classes.boxpointtext}
                        >
                          <img
                            src={Tick}
                            height={28}
                            alt="tick"
                            style={{ paddingRight: "2rem" }}
                          />
                          <Typography className={classes.mobileBox}>
                            Business Directory
                          </Typography>
                        </Box>
                        <Box
                          direction="column"
                          className={classes.boxpointtext}
                        >
                          <img
                            src={Tick}
                            height={28}
                            alt="tick"
                            style={{ paddingRight: "2rem" }}
                          />
                          <Typography className={classes.mobileBox}>
                            Affiliate Program
                          </Typography>
                        </Box>
                        <Box
                          id="education"
                          direction="column"
                          className={classes.boxpointtext}
                        >
                          <img
                            src={Tick}
                            height={28}
                            alt="tick"
                            style={{ paddingRight: "2rem" }}
                          />
                          <Typography className={classes.mobileBox}>
                            Digital Currency - The Cog, a unique asset and cash
                            <br /> backed currency secured in a single central
                            bank model.
                          </Typography>
                        </Box>
                      </Box>
                    </Container>
                  </Box>
                  <Box>
                    <Container style={{ padding: 0 }}>
                      <Typography
                        style={{
                          fontSize: "1.6rem",
                          textAlign: "center",
                          fontFamily: "arial",
                          fontWeight: "bold",
                          color: "#FF7300",
                        }}
                      >
                        Education
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        It should be possible for everyone, whatever their
                        background, ethnicity or location to access the
                        resources to manage their finances.Learning how to save,
                        budget and invest is a skill that enables us to be able
                        to provide not only for today but also the future.In
                        September 2022, an education portal will be added to the
                        Cogito Metaverse, giving members the ability to access
                        content across multiple platforms including articles,
                        video content and podcasts.
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "1.5rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_COGITO_LINK}/cog`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={Currency}
                                alt="digital-currency"
                                height={28}
                                style={{
                                  paddingRight: "0.8rem",
                                }}
                              />
                              Digital
                              <br />
                              Currency
                            </Box>
                          </Box>
                        </a>
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_COGITO_LINK}/personal-banking`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={Personal}
                                alt="personal"
                                height={28}
                                style={{
                                  paddingRight: "0.8rem",
                                }}
                              />
                              Personal
                            </Box>
                          </Box>
                        </a>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "2.5rem",
                          // padding: " 1rem",
                          // width: '80%'
                        }}
                      >
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_COGITO_LINK}/business`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={Business}
                                alt="business"
                                height={28}
                                style={{
                                  paddingRight: "0.8rem",
                                }}
                              />
                              Business
                            </Box>
                          </Box>
                        </a>
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_COGITO_LINK}/finance`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={investment}
                                alt="investment"
                                height={28}
                                style={{
                                  paddingRight: "0.8rem",
                                }}
                              />
                              Investment
                            </Box>
                          </Box>
                        </a>
                      </Box>
                    </Container>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid container direction="column">
                <Box className={classes.boxmid}>
                  <Box>
                    <Typography
                      style={{
                        fontSize: "2.1rem",
                        fontFamily: "Arial",
                        fontWeight: 700,
                        fontStyle: "normal",
                        textAlign: "center",
                        color: "#359db6",
                        // paddingBottom: "2%",
                      }}
                    >
                      What is the Principality of Cogito?
                    </Typography>
                  </Box>
                  <Box>
                    <Container maxWidth={false}>
                      <Typography className={classes.sub_Content}>
                        It is a global principality in the digital space which
                        brings together smart minds and is a haven for ordinary
                        people, investors, businesses and non-profit
                        organisations. The concept is revolutionary. A
                        principality that breaks traditional borders and is open
                        to citizens from every country in the world.
                      </Typography>
                    </Container>
                  </Box>

                  <Box style={{ marginTop: "3rem" }}>
                    <Container maxWidth={false}>
                      <Typography
                        id="e-residency"
                        style={{
                          fontSize: "1.6rem",
                          textAlign: "center",
                          fontFamily: "arial",
                          fontWeight: "bold",
                          color: "#FF7300",
                        }}
                      >
                        E-Residency
                      </Typography>

                      <Typography className={classes.sub_Content}>
                        The Principality of Cogito gives you the ability to
                        register and run your company as a fully location
                        independent company. This means that you can run your
                        business from anywhere in the world, connect with a
                        network of individuals and businesses in the Cogito
                        Metaverse and enjoy the benefits of simple taxes, low
                        bureaucracy, and simple international banking.
                      </Typography>

                      <Box
                        id="cogito-metaverse"
                        style={{ padding: "1rem 0 7rem 0" }}
                      >
                        <OutlinedButton
                          title="APPLY NOW"
                          size={16}
                          onClick={handleRegister}
                          width={250}
                          border={2}
                        />
                      </Box>
                    </Container>
                  </Box>
                  <Box>
                    <Container maxWidth={false}>
                      <Typography
                        style={{
                          fontSize: "1.6rem",
                          textAlign: "center",
                          fontFamily: "arial",
                          fontWeight: "bold",
                          color: "#FF7300",
                        }}
                      >
                        The Cogito Metaverse
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        The Cogito Metaverse is a digital space where the future
                        of finance is growing.Right now we are building the
                        foundation of the financial system and taking
                        registrations.It is possible to register a business or
                        trust and to use Cog to buy and sell goods and
                        services.In the second quarter of 2022, we plan to
                        launch the stock market and there are plans in
                        development for education platforms, communications,
                        networking and fractional investments.
                      </Typography>
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_COGITO_LINK}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Box style={{ padding: "1rem 0 2rem 0" }}>
                          <OutlinedButton
                            title="VISIT COGITO METAVERSE"
                            size={16}
                            // onClick={() => {
                            // 	window.open( "/cogito-metaverse");
                            // }}
                            width={matches ? 280 : 300}
                            border={2}
                          />
                        </Box>
                      </a>
                    </Container>
                  </Box>
                  <Box>
                    <Container
                      maxWidth={false}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className={classes.sub_Content}>
                        The Principality of Cogito’s vision for the Cogito
                        Metaverse, is to bring the global market together in one
                        place, break down boundaries, and eliminate government
                        control of people’s finances.Within the Cogito
                        Metaverse, Cogito e-residents will support each other;
                        provide freedom of trade, finance with no interest,
                        simple, fair, and low tax rates, education, and ability
                        to start investing.As a Cogito e-resident you will build
                        wealth in a way traditional banks and investment
                        companies can not provide you.You will have the ability
                        to establish companies and asset protection trusts in
                        the Cogito Metaverse to protect your hard earned
                        wealth.This will give you the ability to build wealth in
                        a new way.
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        The Cogito Metaverse currently has the following
                        functions:
                      </Typography>
                      {matches ? null : (
                        <Box direction="column" className={classes.pointtext}>
                          <Box
                            direction="column"
                            className={classes.boxpointtext}
                          >
                            <img
                              src={Tick}
                              height={35}
                              alt="tick"
                              style={{
                                paddingRight: "2rem",
                              }}
                            />
                            <Typography className={classes.mobileBox}>
                              E-Residency - the ability to have a digital
                              citizenship <br />
                              in the Principality of Cogito
                            </Typography>
                          </Box>
                          <Box
                            direction="column"
                            className={classes.boxpointtext}
                          >
                            <img
                              src={Tick}
                              height={35}
                              alt="tick"
                              style={{ paddingRight: "2rem" }}
                            />
                            <Typography className={classes.mobileBox}>
                              Business and Trust registration
                            </Typography>
                          </Box>
                          <Box
                            direction="column"
                            className={classes.boxpointtext}
                          >
                            <img
                              src={Tick}
                              height={35}
                              alt="tick"
                              style={{ paddingRight: "2rem" }}
                            />
                            <Typography className={classes.mobileBox}>
                              Business Directory
                            </Typography>
                          </Box>
                          <Box
                            direction="column"
                            className={classes.boxpointtext}
                          >
                            <img
                              src={Tick}
                              height={35}
                              alt="tick"
                              style={{ paddingRight: "2rem" }}
                            />
                            <Typography className={classes.mobileBox}>
                              Affiliate Program
                            </Typography>
                          </Box>
                          <Box
                            id="education"
                            direction="column"
                            className={classes.boxpointtext}
                          >
                            <img
                              src={Tick}
                              height={35}
                              alt="tick"
                              style={{ paddingRight: "2rem" }}
                            />
                            <Typography className={classes.mobileBox}>
                              Digital Currency - The Cog, a unique asset and
                              cash
                              <br /> backed currency secured in a single central
                              bank model.
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Container>
                  </Box>
                  <Box>
                    <Container maxWidth={false}>
                      <Typography
                        style={{
                          fontSize: "1.6rem",
                          textAlign: "center",
                          fontFamily: "arial",
                          fontWeight: "bold",
                          color: "#FF7300",
                        }}
                      >
                        Education
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        It should be possible for everyone, whatever their
                        background, ethnicity or location to access the
                        resources to manage their finances.Learning how to save,
                        budget and invest is a skill that enables us to be able
                        to provide not only for today but also the future.In
                        September 2022, an education portal will be added to the
                        Cogito Metaverse, giving members the ability to access
                        content across multiple platforms including articles,
                        video content and podcasts.
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // background: "red",
                          // marginTop: "1.5rem",
                          marginBottom: "3.5rem",
                          // width: '80%'
                        }}
                      >
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_COGITO_LINK}/cog`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={Currency}
                                alt="digital-currency"
                                height={32}
                                style={{
                                  paddingRight: "0.8rem",
                                  textDecoration: "none",
                                }}
                              />
                              Digital
                              <br />
                              Currency
                            </Box>
                          </Box>
                        </a>
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_COGITO_LINK}/personal-banking`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={Personal}
                                alt="personal"
                                height={32}
                                style={{
                                  paddingRight: "0.8rem",
                                }}
                              />
                              Personal
                            </Box>
                          </Box>
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${process.env.REACT_APP_COGITO_LINK}/business`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={Business}
                                alt="business"
                                height={32}
                                style={{
                                  paddingRight: "0.8rem",
                                }}
                              />
                              Business
                            </Box>
                          </Box>
                        </a>
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_COGITO_LINK}/finance`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box className={classes.outerBox}>
                            <Box className={classes.boxDialogeLinks}>
                              <img
                                src={investment}
                                alt="investment"
                                height={32}
                                style={{
                                  paddingRight: "0.8rem",
                                }}
                              />
                              Investment
                            </Box>
                          </Box>
                        </a>
                      </Box>
                    </Container>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default HomeScreen;
