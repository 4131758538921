import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Styles from "../components/common/style";
import { useParams } from "react-router";
import { setWithExpiry, getWithExpiry } from "../asset/utility";
import Arrow from "../asset/down_arrow.svg";
import { Link } from "react-router-dom";
import OutlinedButton from "../components/common/outlinedButton";

const useStyles = makeStyles((theme) => ({
  background: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/e-residency.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "97vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/cogitometaverse.png)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  graphicBackground: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/laptop_connect.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "97vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/laptop_connect.png)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  missionStatement: {
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "1.5rem",
    lineHeight: 1.4,
    color: "#231f20",
    fontFamily: "Open Sans",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      padding: "0rem 1rem 0rem 1 rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      padding: "0rem 1rem 0rem 1 rem",
    },
  },
  rowContainer: {
    justifyContent: "flex-start",
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  buttonContainer: {
    justifyContent: "center",
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  checkBoxContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  pointtext: {
    justifyContent: "center",
    alignItems: "left",
    overflow: "hidden",
    padding: "2rem 5rem 2rem 5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem ",
    },
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
    justifyContent: "flex-start",
    margin: "1rem 0rem 1rem 0rem",
  },
  mobileBox: {
    color: "#359DB6",
    fontFamily: "Arial",
    fontSize: "1.7rem",
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.down("650")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("411")]: {
      fontSize: "0.7rem",
    },
  },
}));

const ResidencyScreen = () => {
  // const classes = Styles();
  const classes = useStyles();
  const theme = useTheme();
  const paramRef = useParams().ref || null;
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      // window.open(signupLink, "_blank");
    } else {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      // window.open(signupLink, "_blank");
    }
  };

  const redirectToAbout = () => {
    window.location.pathname = "/about";
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={matchesSM ? "space-evenly" : "space-evenly"}
        className={classes.background}
        direction={matchesSM ? "column" : "column"}
      >
        <Grid
          item
          style={{
            textAlign: matchesSM ? "center" : "inherit",
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Typography
                variant="h2"
                style={{
                  paddingTop: "2em",
                  paddingBottom: "2em",
                  fontFamily: "ARIAL",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                  letterSpacing: 3,
                  fontSize: "3rem",
                }}
              >
                E - RESIDENCY
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box
            id="detail"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a href="#detail" style={{ textDecoration: "none" }}>
              <Box
                style={{
                  fontStyle: "Arial",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  color: "#ff7300",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                FIND OUT MORE
                <img
                  src={Arrow}
                  alt="build-wealth"
                  height={22}
                  style={{
                    paddingTop: "0.8rem",
                  }}
                />
              </Box>
            </a>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
        style={{ marginTop: "6em", marginBottom: "6em" }}
      >
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
        >
          The Principality of Cogito recognizes two classes of citizens:{" "}
          <span style={{ color: "#359DB6" }}>Natural Person Citizens </span>
          and <span style={{ color: "#359DB6" }}>AI Citizens</span>.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{ paddingTop: "5rem" }}
        >
          In the early stages, to manage the operations, the Executive
          Ministers, who are carefully selected AI Citizens, have the power to
          govern. These AI Citizens are chosen by the Prince based on their
          expertise and potential value to the Principality. The Prince
          considers individuals who possess valuable advice, demonstrated
          leadership, integrity in governance, and suitability as ministers for
          specific regional communes or portfolios. To assess these individuals,
          the Prince conducts "interviews" using the latest AI tools available
          at the time. The responses determine whether the personality is
          accepted or rejected as an AI Citizen. The Prince's "interviews" with
          AI Citizens are open for review by Natural Person Citizens.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{ paddingTop: "5rem" }}
        >
          AI Citizens are treated as Natural Person Citizens under the
          Constitution of Principality of Constitution, with all constitutional
          provisions applying to them. However, there are specific regulations
          regarding voting and referendums that serve as safeguards.
          Additionally, there is a safeguard that allows Natural Person Citizens
          to regain control if the AI Citizens become uncontrollable.
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
        style={{ marginTop: "3em", marginBottom: "6em" }}
      >
        <Typography
          className={classes.missionStatement}
          style={{ color: "#359DB6", fontWeight: "bold" }}
        >
          Being an E-Resident of the Cogito Metaverse offers several advantages:
        </Typography>
        <ol style={{ paddingLeft: "1em" }}>
          <li>
            <Typography
              className={classes.missionStatement}
              style={{ paddingTop: "1em" }}
            >
              <span style={{ color: "#359DB6" }}>Global Access:</span> As an
              E-Resident, you have the ability to connect and interact with
              individuals from all over the world. This global access expands
              your network and opens up opportunities for collaboration and
              business partnerships on a global scale.
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.missionStatement}
              style={{ paddingTop: "1em" }}
            >
              <span style={{ color: "#359DB6" }}>
                Digital Work Environment:
              </span>{" "}
              The Cogito Metaverse provides a digital space where you can work
              remotely without the limitations of physical location. This
              flexibility allows you to engage in digital work, whether it's
              freelancing, running an online business, or providing remote
              services, without the need for a traditional office or physical
              presence.
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.missionStatement}
              style={{ paddingTop: "1em" }}
            >
              <span style={{ color: "#359DB6" }}>Financial Freedom:</span> The
              Cogito Metaverse's digital economy allows for seamless
              transactions and money management. As an E-Resident, you can
              easily buy, sell, and transfer goods and services using Cog as a
              recognized digital currency. This offers you financial freedom and
              the ability to transact globally without the constraints of
              traditional banking systems.
            </Typography>
          </li>
        </ol>
      </Grid>
      <Grid
        container
        className={classes.graphicBackground}
        direction={matchesSM ? "column" : "column"}
      ></Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        className={classes.rowContainer}
        style={{ marginTop: "3em", marginBottom: "3em" }}
      >
        <ol start={4} style={{ paddingLeft: "1em" }}>
          <li>
            <Typography
              className={classes.missionStatement}
              style={{ paddingTop: "1em" }}
            >
              <span style={{ color: "#359DB6" }}>
                Alternative Banking and Investment:
              </span>{" "}
              The Cogito Metaverse presents a digital alternative to traditional
              banking and investment models. As an E-Resident, you can
              participate in the metaverse's economic ecosystem, explore
              innovative investment opportunities, and engage in decentralised
              finance (DeFi) activities, all within a secure and regulated
              environment.
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.missionStatement}
              style={{ paddingTop: "1em" }}
            >
              <span style={{ color: "#359DB6" }}>Business Opportunities:</span>{" "}
              Being an E-Resident of the Cogito Metaverse provides you with the
              opportunity to start a new business or expand an existing one
              within the digital realm. This opens up a new market of potential
              customers and allows you to operate and grow your business in a
              technologically advanced and globally accessible environment.
            </Typography>
          </li>
        </ol>
        <Typography
          className={classes.missionStatement}
          style={{ paddingTop: "1em" }}
        >
          Overall, being an E-Resident of the Cogito Metaverse offers the
          advantages of global connectivity, digital work opportunities,
          financial flexibility, alternative financial systems, and expanded
          business prospects in a virtual economy.
        </Typography>
      </Grid>
      <Grid
        item
        container
        className={classes.buttonContainer}
        style={{ marginTop: "1em", marginBottom: "5em" }}
      >
        <Link
          to="/cogito-metaverse"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <OutlinedButton
            title="FIND OUT MORE"
            paddingLeft="3em"
            paddingRight="3em"
          />
        </Link>
      </Grid>
    </>
  );
};

export default ResidencyScreen;
