import {
  makeStyles,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import logodark from "../asset/logoDark.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  img: {
    width: "105%",

    [theme.breakpoints.down(1280)]: {
      width: "90%",
    },
    [theme.breakpoints.down(960)]: {
      width: "85%",
    },
  },
}));

const MaintainencePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const MaintainenceImage =
    "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/maintenance_page.png";

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item container justify="flex-start">
            <Box>
              <img
                className={classes.img}
                src={logodark}
                height={70}
                width={70}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="space-around" alignItems="center">
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={3}
            justify={matches ? "center" : null}
          >
            <Box py={{ xs: 2, sm: 2, md: 16, lg: 8 }}>
              <img className={classes.img} src={MaintainenceImage} />
            </Box>
          </Grid>

          <Grid item container xs={12} sm={12} md={6} lg={6} direction="column">
            <Box
              py={{ xs: 2, sm: 4, md: 16, lg: 8 }}
              px={{ xs: 5, sm: 8 }}
              pl={{ lg: 6 }}
            >
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                Website is under MAINTENANCE
              </Typography>

              <Typography
                variant="h6"
                style={{ marginTop: "1.5rem", textAlign: "left" }}
              >
                Our website is currently undergoing scheduled maintenance. This
                should be the best time for you to turn off the computer and
                spend time with your family. We should be back shortly. Thank
                you for your patience.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default MaintainencePage;
