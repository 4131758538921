import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import "./Navbar.css";
import LOGO from "../../../asset/logo.svg";
import ThemeButton from "../../common/themebutton/index";
import { getWithExpiry } from "../../../asset/utility";
import { useMediaQuery } from "@material-ui/core";
import Banner from "../../../Pages/Banner";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    // width: "67%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    // [theme.breakpoints.down(1050)]: {
    //   justifyContent: "space-around"
    // },
    // [theme.breakpoints.down(850)]: {
    //   width: "80%",
    // },
    // [theme.breakpoints.down(600)]: {
    //   width: "76%",
    // },
    // [theme.breakpoints.down(570)]: {
    //   width: "72%",
    // },
    // [theme.breakpoints.down(450)]: {
    //   width: "68%",
    // },
    // [theme.breakpoints.down(400)]: {
    //   width: "62%",
    // },
  },
  submenuItem1: {
    padding: "10px",
    borderBottom: "1px solid #fff",
    listStyle: "none",
    [theme.breakpoints.down("xs")]: {
      padding: "8% 0%",
      display: "block",
      // marginTop: "8%",
      borderBottom: "none",
    },
    
  },
  submenuItem2: {
    padding: "10px",
    listStyle: "none",
    [theme.breakpoints.down("xs")]: {
      padding: "2% 0",
      display: "block",
    },
  },
}));

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [Members, setMembers] = useState(false);
  const clas = useStyles();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(1050));
  const showButton = () => {
    if (window.innerWidth <= 1050) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const handleLogin = () => {
    // if (isMobile) {
    // 	setOpen(false);
    // }
    const storedRef = getWithExpiry("ref") || null;
    if (storedRef) {
      let LoginLink = `${process.env.REACT_APP_URL_LOGIN}/${storedRef}`;
      window.open(LoginLink, "_blank");
    } else {
      let LoginLink = `${process.env.REACT_APP_URL_LOGIN}`;
      window.open(LoginLink, "_blank");
    }
  };

  const handleRegister = () => {
    // if (isMobile) {
    // 	setOpen(false);
    // }
    const storedRef = getWithExpiry("ref") || null;
    if (storedRef) {
      let signUpLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      window.open(signUpLink, "_blank");
    } else {
      let signUpLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      window.open(signUpLink, "_blank");
    }
  };
  const handleMouseEnter = () => {
    setSubMenu(!subMenu);
  };
  const handleMouseLeave = () => {
    setSubMenu(!subMenu);
  };
  const parliamentMembers = () => {
    setMembers(true);
    closeMobileMenu();
  };
  return (
    <>
      {/* <Banner /> */}
      <nav className={navbar ? "navbar active" : "navbar"}>
        <div className="navbar-container">
          <div className="logo-box">
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img
                className="navLogo"
                src={LOGO}
                alt="logo"
                style={{ width: "70px" }}
              />
            </Link>
          </div>

          {matches && (
            <div className={clas.buttonContainer}>
              <ThemeButton
                disabled={false}
                background="#359DB6"
                title="LOGIN"
                size={10}
                height={28}
                width={15}
                radius={8}
                onClick={handleLogin}
                margin="0 0 0 10px"
              />
              <ThemeButton
                background="#FF7300"
                title="REGISTER"
                size={10}
                height={28}
                width={15}
                radius={8}
                onClick={handleRegister}
                margin="0 0 0 10px"
              />
              {/* <div className="menu-icon" > */}
              <i
                onClick={handleClick}
                className={
                  click ? "menu-icon fas fa-times" : "menu-icon fas fa-bars"
                }
              />
              {/* </div> */}
            </div>
          )}

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                HOME
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                ABOUT
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/cogito-metaverse"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                COGITO METAVERSE
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/e-residency"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                E-RESIDENCY
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/education"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                EDUCATION
              </Link>
            </li>
            <li
              className="nav-item"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                setSubMenu(true);
              }}
            >
              <Link className="nav-links">GOVERNMENT</Link>
              {subMenu ? (
                matches ? (
                  <div>
                    <li className={clas.submenuItem1}>
                      <Link
                        className="nav-links"
                        onClick={parliamentMembers}
                        to="/government"
                      >
                        Government Page
                      </Link>
                    </li>
                    <li className={clas.submenuItem2}>
                      <Link
                        className="nav-links"
                        onClick={parliamentMembers}
                        to="/parliamentMembers"
                      >
                        Parliament Members
                      </Link>
                    </li>
                  </div>
                ) : (
                  <ul
                    // className="sub-menu"
                    style={{
                      position: "absolute",
                      background: "#000",
                      boxSizing: "borderBox",
                      // textAlign: "left",
                      padding: "8px 4px",
                      fontSize: "1rem",
                      top: "60px",
                      borderRadius: "21px",
                    }}
                  >
                    <li className={clas.submenuItem1}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          // padding: "2% 4%",
                          fontSize: "1rem",
                        }}
                        onClick={parliamentMembers}
                        to="/government"
                      >
                        Government Page
                      </Link>
                    </li>
                    <li className={clas.submenuItem2}>
                      <Link
                        className="sub-menu"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          // padding: matches ? "0" : "2% 1%",

                          fontSize: "1rem",
                        }}
                        onClick={parliamentMembers}
                        to="/parliamentMembers"
                      >
                        Parliament Members
                      </Link>
                    </li>
                  </ul>
                )
              ) : null}
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                CONTACT
              </Link>
            </li>
            {/* <li>
							<Link
								to="/sign-up"
								className="nav-links-mobile"
								onClick={closeMobileMenu}
							>
								Sign Up
							</Link>
						</li> */}
          </ul>

          {!matches && (
            <>
              <ThemeButton
                disabled={false}
                background="#359DB6"
                title="LOGIN"
                size={14}
                height={38}
                width={100}
                radius={11}
                onClick={handleLogin}
                margin={"0rem 0.8rem 0rem 0rem"}
                paddingLeft={0}
              />
              <ThemeButton
                disabled={false}
                background="#FF7300"
                title="REGISTER"
                size={14}
                height={38}
                width={100}
                radius={11}
                onClick={handleRegister}
                margin="0rem 0.8rem 0 0"
              />
              <i
                onClick={handleClick}
                style={{ marginTop: "0.5rem" }}
                className={
                  click ? "menu-icon fas fa-times" : "menu-icon fas fa-bars"
                }
              />
            </>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
