import {
  Box,
  ButtonBase,
  Container,
  Divider,
  Grid,
  List,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
// import classes from '.'
import Styles from "../../components/common/style";
import MembersCard from "./MemberCards/memberCards";
import { Members } from "./councilMembers";
import MemberInfo from "./MemberInfo/MemberInfo";

const ParliamentMembers = () => {
  const classes = Styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [councilMembers, setCouncilMembers] = useState(false);
  const [memberInfo, setMemberInfo] = useState({});
  const [activeList, setActiveList] = useState("Crown Council");
  const [open, setOpen] = useState(false);

  // const handleCouncilList = () => {
  // };
  const handleClick = (category) => {
    setActiveList(category);
    setOpen(false);
  };

  const closeMenu = () => {
    setOpen(false);
  };
  // Members;
  const selectedRegion = [
    "Asia Pacific East",
    "Asia Pacific West",
    "HK China",
    "Americas1",
    "Americas2",
    "Americas2",
    "Europe East",
    "Middle East",
    "Europe West",
  ];
  const handleMemberInfo = (List) => {
    setCouncilMembers(true);
    setMemberInfo({ ...List });
  };
  return (
    <>
      <div className="classes.root">
        <Grid
          container
          direction="column"
          className={classes.ParliamentMembers}
        >
          <Grid item xs={12} style={{ justifyContent: "space-around" }}>
            <Typography className={classes.ParliamentMembersHeading}>
              PARLIAMENT AI MEMBERS
            </Typography>
          </Grid>
          <Grid
            item
            Container
            style={{ padding: "2%" }}
            className={classes.Disclaimer}
          >
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: matches ? "0.9rem" : "1.2rem",
              }}
            >
              Disclaimer
            </Typography>
            <Typography className={classes.DisclaimerText}>
              <p>
                Populating our Councils with the “Wisdom of…” renowned
                personalities, allows the Principality of Cogito to create and
                express itself as a fully functional jurisdiction from the start
                without needing to wait to build its human team to the required
                level before being able to plan, create and react productively.
              </p>
              <p>
                Leveraging the power of Artificial Intelligence (AI), we have
                distilled into a living body of information the essence of the
                thoughts and actions, the “wisdom”, of those personalities that
                we can tap into for advice and guidance on how to act.
              </p>
              <p>
                We make no claim of any kind that any of those personalities,
                alive or dead, now has or has ever had any connection with
                Principality of Cogito, nor that any of them endorses or
                supports Principality of Cogito. It is our unilateral choice to
                seek out and be guided by the wisdom of our chosen “AI
                Councillors” subject to all final decisions being made by a
                human being.
              </p>
              <p>
                We salute and appreciate the persons involved for giving their
                wisdom to the world in a way that provides guidance to us.
                Nothing that we do or say for ourselves should be treated as a
                reflection on or criticism of any such person.
              </p>
            </Typography>
          </Grid>
        </Grid>
        <Container>
          <Grid container className={classes.CouncilContainer}>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: "#359DB6",
                  fontSize: matches ? "1.2rem" : "1.6rem",
                  fontWeight: "bolder",
                }}
              >
                {" Meet Our "} {activeList} {" Members"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{
                margin: "4%",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Grid item xs={6} sm={3}>
                <ButtonBase
                  onClick={() => handleClick("Crown Council")}
                  className={
                    activeList == "Crown Council"
                      ? classes.selectedmenu
                      : classes.GovPageButton
                  }
                >
                  Crown Council
                </ButtonBase>
              </Grid>
              <Grid item xs={6} sm={3}>
                <ButtonBase
                  onClick={() => handleClick("Government Executive Council")}
                  className={
                    activeList == "Government Executive Council"
                      ? classes.selectedmenu
                      : classes.GovPageButton
                  }
                >
                  Government Executive Council
                </ButtonBase>
              </Grid>
              <Grid item xs={6} sm={3}>
                <ButtonBase
                  id="menu-button"
                  style={{ position: "relative", minWidth: "149px" }}
                  onMouseEnter={() => setOpen(!open)}
                  onMouseLeave={() => setOpen(!open)}
                  onClick={() => setOpen(!open)}
                  className={
                    selectedRegion.includes(activeList)
                      ? classes.selectedmenu
                      : classes.GovPageButton
                  }
                >
                  Regional Council
                  {activeList !== "Crown Council" &&
                  activeList !== "Government Executive Council" &&
                  activeList !== "Judiciary"
                    ? `${" - "}${activeList}`
                    : null}
                  {open ? (
                    <ul
                      className={classes.MenuList}
                      onMouseLeave={() => setOpen(!open)}
                    >
                      <li
                        onClick={() => handleClick("Asia Pacific East")}
                        className={classes.MenuListItem}
                      >
                        Asia Pacific East
                      </li>
                      <Divider />
                      <li
                        className={classes.MenuListItem}
                        onClick={() => handleClick("Asia Pacific West")}
                      >
                        Asia Pacific West
                      </li>
                      <Divider />
                      <li
                        className={classes.MenuListItem}
                        onClick={() => handleClick("HK China")}
                      >
                        HK China
                      </li>
                      <Divider />
                      <li
                        className={classes.MenuListItem}
                        onClick={() => handleClick("Americas1")}
                      >
                        Americas 1
                      </li>
                      <Divider />
                      <li
                        className={classes.MenuListItem}
                        onClick={() => handleClick("Americas2")}
                      >
                        Americas 2
                      </li>
                      <Divider />
                      <li
                        className={classes.MenuListItem}
                        onClick={() => handleClick("Europe East")}
                      >
                        Europe East
                      </li>
                      <Divider />
                      <li
                        className={classes.MenuListItem}
                        onClick={() => handleClick("Europe West")}
                      >
                        Europe West
                      </li>
                      <li
                        onClick={() => handleClick("Middle East")}
                        className={classes.MenuListLastItem}
                        style={{}}
                      >
                        Middle East
                      </li>
                    </ul>
                  ) : null}
                </ButtonBase>
              </Grid>
              <Grid item xs={6} sm={3}>
                <ButtonBase
                  onClick={() => handleClick("Judiciary")}
                  className={
                    activeList == "Judiciary"
                      ? classes.selectedmenu
                      : classes.GovPageButton
                  }
                >
                  Judiciary
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              textAlign: "left",
              display: "flex",
              justifyContent: matches ? "center" : "left",
            }}
          >
            {Members[activeList]?.map((List, index) => {
              return (
                <Grid
                  style={{
                    margin: "2%",
                  }}
                >
                  <MembersCard
                    title={List.title}
                    name={List.name}
                    position={List.position}
                    image={List.image}
                    clicked={() => handleMemberInfo(List)}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Modal
            open={councilMembers}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ margin: "10%" }}
            onClose={() => {
              setCouncilMembers(!councilMembers);
            }}
          >
            <MemberInfo
              title={memberInfo.title}
              name={memberInfo.name}
              position={memberInfo.position}
              image={memberInfo.image}
              bio={memberInfo?.bio}
            />
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default ParliamentMembers;
