import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

function FaqPage() {
  return (
    <div>
      <Grid container>
        <Grid item>
          <img src="/images/faqbanner.jpg" className="registrybanner" alt="" />
        </Grid>
      </Grid>
      <Divider variant="middle" />

      <Grid
        container
        className="registrySection2"
        justifyContent="space-around"
      >
        <Grid item className="colorGrey" xs={12} md={5}>
          <Typography variant="h5" className="section2-txt1">
            We have all the information you need to make informed decision on
            your future.
          </Typography>
        </Grid>
        <Grid item className="colorGrey " xs={5} md={2}>
          <Typography variant="subtitle1">
            <a href="#general" className="plainlink">
              <img src="/images/arrowOrange.png" alt="" />
              General
            </a>
          </Typography>
          <br />
          <Typography variant="subtitle1">
            <a href="#cogito" className="plainlink">
              <img src="/images/arrowOrange.png" alt="" />
              Cogito
            </a>
          </Typography>
          <br />
          <Typography variant="subtitle1">
            <a href="#cog" className="plainlink">
              <img src="/images/arrowOrange.png" alt="" />
              Cog
            </a>
          </Typography>
          <br />
          <Typography variant="subtitle1">
            <a href="#legal" className="plainlink">
              <img src="/images/arrowOrange.png" alt="" />
              Legal
            </a>
          </Typography>
        </Grid>

        <Grid item className="colorGrey  " xs={5} md={2}>
          <Typography variant="subtitle1">
            <a href="#economy" className="plainlink">
              <img src="/images/arrowOrange.png" alt="" />
              Economy
            </a>
          </Typography>
          <br />
          <Typography variant="subtitle1">
            <a href="#business" className="plainlink">
              <img src="/images/arrowOrange.png" alt="" />
              Business
            </a>
          </Typography>
          <br />
          <Typography variant="subtitle1">
            <a href="#security" className="plainlink">
              <img src="/images/arrowOrange.png" alt="" />
              Security
            </a>
          </Typography>
        </Grid>
      </Grid>
      <Divider variant="middle" />

      <Grid container className="insightSections">
        <Grid container>
          <Grid item>
            <h2 className="aboutsection3-h1" id="general">
              General
            </h2>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              Who created Cogito?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Principality of Cogito was first developed in the early 1990s by a
              reclusive dyslexic entrepreneur. The mastermind documentation
              around the technology and concept was locked away for many years
              until it was stumbled upon in early 2017.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Who controls
              Cogito?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito is managed by a highly skilled and competent board who are
              based in different countries around the world. Their backgrounds
              are in law, accounting, IT, governance, risk management and
              customer services systems.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; How does Cogito
              work?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              By late 2020, Cogito will function to a user as a mobile app or
              computer program that provides a personal Cog account and allows a
              user to send and receive Cogito Cog, no different to current
              banking transactions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Behind the scenes, the Cogito network is sharing different
              ledgers, some components use “block chain” and others use Cog ©
              ALDS, (agreed ledger distribution side chain). These ledgers
              contain every transaction, allowing a user’s computer to verify
              the validity of each transaction. The authenticity of each
              transaction is protected by digital signatures corresponding to
              the sending addresses, allowing all users to have full control
              over sending Cogito Cog from their own Cog addresses. In addition,
              anyone can process related transactions using the computing power
              of their device and earn a tax rebate in either the Cog or fiat
              for this service.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; How do I acquire
              Cog?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cog payments will be easier to make than debit or credit card
              purchases, and can be received without a merchant account.
              Payments are made from an account application, either on your
              computer or smartphone, by entering the recipient’s address, the
              payment amount, and pressing send. To make it easier to enter a
              recipient’s address, many accounts can obtain the address by
              scanning a QR code or touching two phones together with NFC
              technology.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; How difficult is
              it to make a Cog payment?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito is managed by a highly skilled and competent board who are
              based in different countries around the world. Their backgrounds
              are in law, accounting, IT, governance, risk management and
              customer services systems.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; What are the
              advantages of Cog?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              <strong>Payment freedom:</strong> It is possible to send and
              receive the Cog anywhere in the world at any time. No bank
              holidays. No borders. No bureaucracy.No delays. Cogito allows its
              users to be in full control of their money.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              <strong>Low costs:</strong> There is a 3% transaction tax to
              receive Cog, and a 3% transaction tax to send Cog. Taxes are in
              fiat from your Cogito euro account. Businesses with high turnover
              in Cog are able to negotiate a lower tax rate.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              <strong>Fewer risks for merchants:</strong> Cog transactions are
              secure, and do not contain customers’ sensitive or personal
              information. This protects merchants from losses caused by fraud
              or fraudulent chargebacks, and there is no need for PCI
              compliance. Cogito carries out comprehensive KYC and AML checks on
              all paid up members. Merchants can easily expand to new markets
              where either credit cards are not available or fraud rates are
              unacceptably high. The net results are lower costs, larger
              markets, and fewer administrative costs.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              <strong>Security and control:</strong> Cogito users are in full
              control of their transactions; it is impossible for merchants to
              force unwanted or unnoticed charges as can happen with other
              payment methods. Cog payments can be made without personal
              information tied to the transaction. This offers strong protection
              against identity theft..
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cog can only be transacted on the Cogito banking platform,
              effectively a single global bank which means all transactions can
              be audited and checked. This is the highest level of security as
              unlike traditional currency, Cog can not be transferred to a
              foreign bank.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              <strong>Value tied to euro:</strong> Cog is tied in value to the
              euro, so in reality it is more like a barter system than a digital
              currency. There is no real speculative opportunity in the trading
              of Cog. This provides security for users who will know the value
              of their Cog will be stable today and tomorrow.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; What are the
              disadvantages of Cog?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Ongoing development – The Cogito app is in active development. By
              2021 new tools, features, and services will be released to make
              the Cogito Cog available for everyday transactions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Why do people
              trust Cog?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito is open about how the Cogito economy is structured, the
              management will be reporting regularly on the status of the debt
              reserve fund and the true value balance of the Cog. This is
              completely different to most banks for example who leverage off
              capital or use other complex leverage methods where the risk to
              clients is near impossible to understand.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Can I make money
              with Cog?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              You should never expect to get rich with Cog or any emerging
              technology. It is always important to be wary of anything that
              sounds too good to be true or disobeys basic economic rules.
              Cogito does offer the ability to earn income. This is through
              developing the Cogito user network. You can be rewarded for life
              from the activity of those people you introduce and the further
              developing network beyond them.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito also encourages JV or referral to our investment modules,
              you can make contact through the website if you have a business,
              contacts or an idea that you believe would suit our Cogito family.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito is a growing space of innovation and there are business
              opportunities that also include risks. There is no guarantee that
              Cogito will continue to grow even though it has developed at a
              very fast rate so far. Investing time and resources on anything
              related to Cogito requires entrepreneurship. There are various
              ways to make money with Cogito such as networking, investments or
              running new businesses. All of these methods are competitive and
              there is no guarantee of profit. It is up to each individual to
              make a proper evaluation of the costs and the risks involved in
              any such project.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Is the Cog fully
              virtual and immaterial?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cog is as virtual as the credit cards and online banking networks
              people use every day. Cog can be used to pay online and in
              physical stores just like any other form of money.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Is Cogito
              anonymous?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito is designed to allow its users to send and receive payments
              with an acceptable level of privacy as well as any other form of
              money. However, Cog is not as anonymous and cannot offer the same
              level of privacy as cash. The use of Cog leaves digital records.
              Various mechanisms exist to protect users’ privacy, and more are
              in development. Cogito offers various corporate structures such as
              foreign trusts and companies to those people that want levels of
              security and asset protection. Having said that, Cogito still
              requires the beneficiaries of such entities to complete the same
              KYC and AML procedures as all our members.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Some concerns have been raised that private transactions could be
              used for illegal purposes with Cog. However, it is worth noting
              that Cogito will undoubtedly be subjected to similar regulations
              that are already in place inside existing financial systems. Cog
              cannot be more anonymous than cash and it is not likely to prevent
              criminal investigations from being conducted. Additionally, Cog is
              also designed to prevent a large range of financial crimes. Cogito
              does not support the use of Cog being used for illegal activity
              and reserves its right to close any Cog account used for such
              activities.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Can Cogito scale
              to become a major payment network?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Cogito network is designed and being built to handle global
              large scale users and transactions right from the start. An
              important part of that is our customer service division which is
              built to handle four million clients from the start.
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ backgroundColor: "#f0efed" }}>
        <Grid container className="insightSections">
          <Grid container>
            <Grid item>
              <h2 className="aboutsection3-h1" id="cogito">
                Cogito
              </h2>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What is
                Cogito?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                The Principality of Cogito was established as a decentralized
                libertarian state. It now stands as one of the last of the safe
                havens that has not been controlled via large government
                sanctions.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; How does it
                work?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                The Principality of Cogito is decentralized which provides the
                ultimate security for people who wish to register and operate
                their business and investment affairs without the highly
                regulated controls placed by governments desperate to hang on to
                their diminishing taxable base .
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What is a
                haven?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito is a tax free zone with common-sense laws and minimal
                fees for establishing and conducting your business. It has no
                reporting agreements with any other jurisdiction.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Who control
                Cogito?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                The Constitution of the Principality of Cogito details the
                legalities around the structure of power and control for Cogito.
                In simple terms the Principality of Cogito is divided into eight
                regions which each have an elected board in a democratic process
                by the Digital Citizens.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What is
                digital citizenship?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Digital citizenship is a commitment to be involved in the
                society which is Cogito. Becoming a digital citizen gives you
                the right and ability to register a trust and company and thus
                access the safe Haven of the Principality of Cogito.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Why do I need
                to register?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                The initial registration gives you the ability to keep abreast
                of developments in the Principality of Cogito and start earning
                Cogito Cog from the referral incentive.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Why do I need
                to register a trust?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                By establishing a discretionary trust, you will be settlor and
                protector of the trust, (settlor means you supply the initial
                assets and protector means you have veto rights over important
                decisions of the trustee). Cogito Corporate Trustee Ltd will act
                as trustee. You can select the beneficiaries of the trust. Do
                not include yourself. The reason you are not included as a
                beneficiary is that it compromises the independence of the
                structure from you. This helps protect you against a claim of
                tax liability at home.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; I already have
                a business, why do I need to register for a trading company in
                Cogito?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                There is no obligation to set up a company in Cogito. The reason
                that you would register a business in Cogito is that without a
                Cogito registered company, earnings in Cogito would most likely
                be taxable in your country of residence.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What is the
                purpose of an investment company in Cogito?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Citizens that register an investment company will have access to
                the Cogito stock exchange which will list all of the investments
                available through Cogito. Contact us to keep informed about the
                date of stock exchange opening.
                <br />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid container className="insightSections">
        <Grid container>
          <Grid item>
            <h2 className="aboutsection3-h1" id="cog">
              The Cog
            </h2>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; What is the Cog?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Cog is the currency of the Principality of Cogito. The Cog is
              asset and cash backed and linked in value to the Euro. It will
              have the ability to be used in everyday life. The Cog bridges the
              gap between the traditional bitcoin model and the global financial
              markets..
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; How can I get
              Cogs?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              1. As an initial offer you will be able to purchase Cog at a
              discounted rate for capital of your Cogito companies. You can earn
              the Cog through sponsoring others and as a result of the business
              they generate
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              2. You can also apply for MyDCs credit facility.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              By 2020 you will be able to use them as payment for goods or
              services.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Can I use Cogs
              in everyday live?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Cog is a fiat currency and is legal tender between Cogito
              digital citizens. In 2020/21 we will launch the Cog app which will
              enable the Cogito Cog to be used in everyday transactions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Can I trade
              Cogs?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              No. The Cog is not intended to be used in trading with other
              currencies because it is a centralised currency and cannot be
              transferred to another banking system. This protects the Cog from
              the possibility of money laundering.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp;What about Cogito
              and consumer protection?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito is freeing people to transact on their own terms. Each user
              can send and receive payments in a similar way to cash. Cogito
              provides a dispute mediation services for any issue that arise
              from transaction problems. Such services could allow a third party
              to approve or reject a transaction in case of disagreement between
              the other parties without having control on their money. As
              opposed to cash and other payment methods, Cog always leaves a
              public proof that a transaction did take place, which can
              potentially be used in a recourse against businesses with
              fraudulent practices.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              It is also worth noting that while merchants usually depend on
              their public reputation to remain in business and pay their
              employees, they don’t have access to the same level of information
              when dealing with new consumers. The way Cog works allows both
              individuals and businesses to be protected against fraudulent
              chargebacks while giving the choice to the consumer to ask for
              more protection when they are not willing to trust a particular
              merchant.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito’s goal is to attract a family of members with a higher
              level of integrity that suit our corporate values.
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ backgroundColor: "#f0efed" }}>
        <Grid container className="insightSections">
          <Grid container>
            <Grid item>
              <h2 className="aboutsection3-h1" id="legal">
                Legal
              </h2>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Is Cogito
                legal?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito principals have spent several hundred thousand euro on
                legal advice. However, legal advice cannot cover all countries
                regulations. Cogito has made every effort to ensure compliance
                with global KYC, AML regulations and most internationally used
                common law.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Regulators from various jurisdictions are taking steps to
                provide individuals and businesses with rules on how to
                integrate this new technology with the formal, regulated
                financial system. For example, the Financial Crimes Enforcement
                Network (FinCEN), a bureau in the United States Treasury
                Department, issued non-binding guidance on how it characterizes
                certain activities involving virtual currencies.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                It is important that you obtain satisfactory legal advice on how
                you use or promote Cogito as it is most likely the user that
                runs the risk of a regulatory breach than Cogito where we are
                not domiciled in your country.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Is Cogito
                useful for illegal purposes?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cog is money, and money has always been used both for legal and
                illegal purposes. Cash, credit cards and current banking systems
                widely surpass Cog in terms of their use to finance crime. Cog
                can bring significant innovation in payment systems and the
                benefits of such innovation are often considered to be far
                beyond their potential drawbacks.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cog is designed to be a huge step forward in making money more
                secure and could also act as a significant protection against
                many forms of financial crime. For instance, Cogs are completely
                impossible to counterfeit. Users are in full control of their
                payments and cannot receive unapproved charges such as with
                credit card fraud. Cogito allows money to be secured against
                theft and loss using very strong and useful mechanisms such as
                backups, encryption, and multiple signatures.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Some concerns have been raised that Cog could be more attractive
                to criminals because it can be used to make private and
                irreversible payments. However, these features already exist
                with cash and wire transfer, which are widely used and
                well-established. The use of Cog will undoubtedly be subjected
                to similar regulations that are already in place inside existing
                financial systems, and Cog is not likely to prevent criminal
                investigations from being conducted. In general, it is common
                for important breakthroughs to be perceived as being
                controversial before their benefits are well understood. The
                Internet is a good example among many others to illustrate this.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Can the Cog be
                regulated?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                It is possible to regulate the use of Cog in a similar way to
                any other instruments. Just like the dollar, Cog can be used for
                a wide variety of purposes, some of which can be considered
                legitimate or not as per each jurisdiction’s laws. In this
                regard, Cog is no different than any other tool or resource and
                can be subjected to different regulations in each country. Cog
                use could also be made difficult by restrictive regulations, in
                which case it is hard to determine what percentage of users
                would keep using the technology. A government that chooses to
                ban Cog could prevent domestic businesses and markets from
                developing, shifting innovation to other countries. The
                challenge for regulators, as always, is to develop efficient
                solutions while not impairing the growth of new emerging markets
                and businesses..
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito supports a productive level of regulation if it truly
                protects consumers and the businesses using Cog. Cogito doesn’t
                support regulation designed to control the market in order to
                protect companies that are threatened by emerging technology. We
                see this as anti-competitive behaviour.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What about
                Cogito and taxes?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                <strong>Tax Liability:</strong> Each country has its own tax
                laws and so this is not intended to be tax advice. What is
                proposed in this document is very general and should work for
                most people in most jurisdictions.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                You should make up your own mind or seek independent tax advice
                to make sure, before committing, that the jurisdiction of your
                citizenship and/or nationality will not succeed in a claim that
                your Cogito structure re constitutes “tax evasion”.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                <strong>Tax in general:</strong> If you are a trader of
                cryptocurrencies, depending on your nationality and where you
                live, there is a very high chance you are liable for tax on the
                profit you make.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                But profit does not necessarily mean cash in your bank in the
                currency in which you pay your tax. It more often than not means
                unrealized increase in paper value.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Let’s say you started investing $1,000 to purchase, say bitcoin,
                and you have then used the bitcoin to buy other coins. You have
                been buying and selling for the whole year and the value of all
                your coins on paper is now $50,000. You may be liable to pay tax
                on that entire increase in value.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                From our experience, the timing on the need to pay tax is always
                poor. This may force you to sell at a time when the market is
                down or when a strategic position you have taken has not
                matured.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                <strong>Cogito Legal Structure:</strong> Better not to make that
                profit for yourself! The Principality of Cogito legal structure
                is designed to let you make the decisions when and if you need
                to pay tax at home. The recommended structure for crypto traders
                includes a discretionary trust and a Cogito trading company.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp;Why do I need
                to register?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                The initial registration gives you the ability to keep abreast
                of developments in the Principality of Cogito and start earning
                Cogito Cog from the referral incentive.
                <br />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid container className="insightSections">
        <Grid container>
          <Grid item>
            <h2 className="aboutsection3-h1" id="economy">
              Economy
            </h2>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; How are Cogito
              Cogs created?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito Cog are generated by Cogito on an as needs basis. Initially
              through a two year basic Cog payment of C150 per week to all new
              members, also through sale of company capital for new company
              registrations. Prior to going fully live for trading businesses
              that have registered will have a special offer of credit
              facilities made to them. After becoming fully live it is expected
              the only way to obtain Cogito Cog will be through buying and
              selling goods and services and the basic universal income.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Who determines
              the Cog price?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The price of a Cogito Cog is determined by the value of the euro.
              Cogito systems run a real-time API adjusting all local currency
              transactions to the Cog/Euro value.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Can the Cog
              become worthless?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              No, Cogito Cog is currently tied to the euro and the debt reserve
              systems in place are there to ensure that value backing is stable.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp;What protection
              is there from failed business?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cogito takes a percentage from every transaction and accumulates
              it in a debt reserve fund. This debt reserve fund is a cash
              deposit fund to offset any potential losses if Cogito is unable to
              get full repayment of a Cog debt. Disclosure of the debt reserve
              fund will be made to all members on the website once launched. In
              addition Cogito holds a percentage of every investment and gets a
              percentage return from each investment. A share of all of this
              accumulates in the debt reserve fund to provide the asset and cash
              backing for Cog.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Does Cogito
              unfairly benefit early adopters?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Some early adopters will have some benefits because they took
              risks and invested time and resources in a still to be proven
              technology that was hardly used by anyone. Other early adopters
              will work hard to develop the network of businesses and the
              rewards for that may be highly lucrative. This is very similar to
              investing in an early startup that can either gain value through
              its usefulness and popularity, or just never break through. Cogito
              is still in its infancy, and it has been designed with a very
              long-term view; it is hard to imagine how it could be less biased
              towards early adopters, and today’s users may or may not be the
              early adopters of tomorrow.
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ backgroundColor: "#f0efed" }}>
        <Grid container className="insightSections">
          <Grid container>
            <Grid item>
              <h2 className="aboutsection3-h1" id="business">
                Business
              </h2>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; How does
                Cogito work for my everyday business?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito is great for business in a variety of ways.
                <br />
                <ul>
                  <li>
                    It can encourage new clients to select your goods and
                    services over one where they normally pay with cash.
                  </li>
                  <li>
                    It can provide you with a method to obtain payment from slow
                    paying clients.
                  </li>
                  <li>Interest free funding</li>
                  <li>
                    It provides you with potential to tender competitively
                  </li>
                  <li>
                    It opens new supply lines globally from the trusted network
                    of members.
                  </li>
                  <li>
                    It provides additional income opportunities from the network
                    of people and businesses you introduce.
                  </li>
                  <li>
                    It provides an alternative incentive option for staff who
                    also are members.
                  </li>
                </ul>
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Can I have
                separate business and personal Cog accounts?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Yes, when you register your business with Cogito we set up a
                separate set of Cog accounts for your business. You will be able
                to view personal and business accounts when you log in. You will
                also be able to manage each account, select your preferred
                account for sales, purchases and receiving commissions.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; How do I
                convert Cog into fiat?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito does not offer the option to withdraw cash from your
                accounts. That’s not to say we wont in the future, but as it
                stands this creates a lot of regulatory issues and we believe
                the risks versus rewards to our members do not warrant offering
                cash withdrawal.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Can I limit
                the amount l sell on Cog?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Yes, it is important for your business to manage your cash flow.
                We do encourage setting a Cogito Cog sales budget for the month
                / year and steadily build the Cog into you normal business
                strategy.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; Can I charge
                more for product sold using Cog?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Different countries have different regulations on this. We
                advise you to check your local regulations. It is not a practice
                we condone.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp;What tools are
                available to communicate to other members that I accept Cog?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito is putting together a toolbox for businesses. We plan
                that this will include point of sale materials, branding for
                advertising, trade nights, account managers for businesses and
                an online market place. We would welcome other suggestions.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; How do I apply
                for a Cog overdraft?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Once registered and you have been through the KYC and AML
                process you will have a link available to you to apply for a
                credit facility.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What fees and
                interest rates are there for a Cog overdraft?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Fees are determined by the amount borrowed and other factors in
                the loan. Interest rate is zero.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What security
                do I need to provdie?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                This depends on the facility so it can range from no asset
                security through to acceptable assets.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; How do I
                account for Cog in my company accounts?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito provides you with the ability to export all your
                transactions using a variety of formats, which can then be
                imported into your accounting software. We are working with some
                of the leading software providers to provide real-time
                synchronization for transactions also.
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="h6" className="colorGrey">
                <img src="/images/arrowHead.png" alt="" /> &nbsp; What taxes do
                I pay?
                <br />
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="spacing">
            <Grid item>
              <Typography variant="subtitle1" className="colorGrey">
                Cogito will not give tax advice for your local jurisdiction. You
                need to check with your accountant. Each country has different
                ways of charging taxes and so they are best positioned to advise
                you. In Cogito you only pay a maximum 3% purchase or 3% sales
                tax on each transaction.
                <br />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid container className="insightSections">
        <Grid container>
          <Grid item>
            <h2 className="aboutsection3-h1" id="security">
              Security
            </h2>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Is Cog secure?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Cogito technology – the protocol and the cryptography – has a
              strong security track record. Cogitos most common vulnerability is
              in user error. Cog account files that store the necessary security
              cannot be accidentally deleted, lost or stolen.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; What is KYC and
              AML?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              KYC stands for ‘Know Your Customer’ and AML stands for ‘Anti-Money
              Laundering’. It is the process of a business identifying and
              verifying the identity of its clients. This regulatory process is
              a legislative requirement in many jurisdictions to ensure the
              security and integrity of the system.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Could users
              collude against Cogito?
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              No. Any Cogito client that doesn’t comply with the same rules
              cannot enforce their own rules on other users. As per the current
              specification, double spending is not possible on the Cogito
              systems, and neither is spending Cog without a valid signature.
              Therefore, it is not possible to generate uncontrolled amounts of
              Cog out of thin air, spend other users’ funds, corrupt the
              network, or anything similar.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp; Is Cogito
              vulnerable to quantum computing?
              <br />
            </Typography>
          </Grid>
        </Grid>

        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Yes, most systems relying on cryptography in general are,
              including traditional banking systems. However, quantum computers
              don’t yet exist and probably won’t for a while. In the event that
              quantum computing could be an imminent threat to Cogito, the
              protocol could be upgraded to use post-quantum algorithms. Given
              the importance that this update would have, it can be safely
              expected that it would be highly reviewed by developers and
              adopted by all Cog users.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing" id="privacyTerms">
          <Grid item>
            <Typography variant="h6" className="colorGrey">
              <img src="/images/arrowHead.png" alt="" /> &nbsp;Cogito Privacy
              Statement
              <br />
            </Typography>
          </Grid>
        </Grid>

        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The personal information on this site may be used by Principality
              of Cogito for direct marketing or other products developed by it
              and for no other purpose. All information will be held by Cogito
              where it can be inspected and corrected by you. Cogito shall take
              all practical steps to achieve privacy protection. <br />
              We may place cookies on your computer. These provide us your
              preferences, to allow us to user customization of the website, to
              assist These are only accessible by us and will be only used to
              track the pages of the website visited by you, in order for us to
              build a better site more tailored to your needs and interests. Our
              cookies do not record personal information about you, merely your
              computer.
              <br />
              Whilst we make every effort to provide you with links to other
              reputable sites, they are not under our day to day control. When
              you click through to these sites you leave the area controlled by
              Cogito. We cannot therefore accept responsibility for any issues
              arising in connection with either their use of your data, the site
              content or the services offered by these sites.
              <br /> Secure Server Information - We are constantly striving to
              make the transaction as worry free as possible. To this end, we
              use secure socket layer (SSL) protocol, the industry standard for
              ensuring the security of your electronic commerce transactions.
              SSL uses 256-bit encryption for all credit card information in
              transit over the internet. In addition, the Cogito website is
              hosted behind a commercial firewall to prevent hackers from
              obtaining access to your data. Even if this unlikely event does
              occur, we do not keep a record of your full credit card number.
              All reasonable steps to ensure that this information is secure
              have been taken but we cannot warrant all information will be
              secure at all times.
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Divider variant="middle" />

      {/* <Grid conatiner className="insightsection3">
        <Grid item>
          <img src="/images/chaticon.png" alt="" />
        </Grid>
      </Grid>
      <Grid conatiner className="insightsection3">
        <Grid item className="  colorGrey">
          <Typography variant="subtitle1">
            Still have more questions? We can help you. once registered for
            free, Cogito has 24/7 helpdesk. Ask any questions you have with our
            assistant in the Cogito App.
          </Typography>
        </Grid>
      </Grid> */}
    </div>
  );
}

export default FaqPage;
