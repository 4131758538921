import React from "react";
import "./App.css";
import Navbar from "./components/common/Navbar/Navbar";
import InsightsPage from "./components/insightsPage";
import RegistryPage from "./components/registry";
import Footer from "./components/common/footer";
import FaqPage from "./components/faq";
import LoginPage from "./components/login";
import RegistrationPage from "./components/registration";
import TermsConditions from "./components/termsCondition";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HomeScreen from "./Pages/home";
import AboutScreen from "./Pages/about";
import CogitoScreen from "./Pages/cogito";
import ResidencyScreen from "./Pages/e-residency";
import EducationScreen from "./Pages/education";
import GovScreen from "./Pages/gov";
import ContactScreen from "./Pages/contact";
import PageNotFound from "./Pages/404";
import ParliamentMembers from "./Pages/ParliamentMembers/parliamentMembers";
import CryptopiaForm from "./Pages/cryptopiaForm";

const theme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#1A1A1A",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Navbar />
          <Switch>
            <Route path="/" exact component={HomeScreen} />
            <Route path="/ref/:ref" component={HomeScreen} />
            <Route path="/about" component={AboutScreen} />
            <Route path="/e-residency" component={ResidencyScreen} />
            <Route path="/education" component={EducationScreen} />
            <Route path="/government" component={GovScreen} />
            <Route path="/contact" component={ContactScreen} />
            <Route path="/cogito-metaverse" component={CogitoScreen} />
            <Route path="/insights" component={InsightsPage} />
            <Route path="/registry" component={RegistryPage} />
            <Route path="/faq" component={FaqPage} />
            <Route path="/registration" component={RegistrationPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/terms" component={TermsConditions} />
            <Route path="/parliamentMembers" component={ParliamentMembers} />
            <Route path="/pageNotFound" component={PageNotFound} />
            <Route path="/cryptopiaform" component={CryptopiaForm} />
            <Redirect to="/pageNotFound" />
          </Switch>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
