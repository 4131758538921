import React from "react";

//material

import { Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { withTheme } from "styled-components";

const useStyles = makeStyles({
  button: {
    color: "#359DB6",
    transition: "0.4s ease-in-out",
    background: "#fff",
    "&:hover": {
      background: "#359DB6",
      color: "#fff",
    },
  },
  label: {
    textTransform: "capitalize",
  },
  // disabled: {
  //     background: "#8ab0b9",
  // },
});

const OutlinedButton = (props) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="outlined"
      onClick={props.onClick}
      style={{
        fontFamily: "Arial",
        // color: "#ffff",
        height: props.height ? props.height : 50,
        border: "none",
        fontSize: props.size ? props.size : "",
        fontWeight: "bold",
        margin: props.margin ? props.margin : "",
        borderRadius: props.radius ? props.radius : 26,
        width: props.width ? props.width : "",

        paddingRight: props.paddingRight ? props.paddingRight : "",
        paddingLeft: props.paddingLeft ? props.paddingLeft : "",
      }}
    >
      {props.title}
    </Button>
  );
};

export default OutlinedButton;
