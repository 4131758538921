import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import { setWithExpiry } from "../asset/utility";
import Arrow from "../asset/down_arrow.svg";
import Tick from "../asset/tick.svg";

const useStyles = makeStyles((theme) => ({
  background: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/POC/cogitometaverse.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "97vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/POC/cogitometaverse.png)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  graphicBackground: {
    background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/CM_page_background.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "97vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/CM_page_background.png)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
  },
  missionStatement: {
    textAlign: "left",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: 1.4,
    color: "#231f20",
    fontFamily: "Open Sans",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordWrap: "break-word",
    fontSize: "1.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      padding: "0rem 1rem 0rem 1 rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      padding: "0rem 1rem 0rem 1 rem",
    },
  },
  rowContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  checkBoxContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  pointtext: {
    justifyContent: "center",
    alignItems: "left",
    overflow: "hidden",
    padding: "2rem 5rem 2rem 5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem ",
    },
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
    justifyContent: "flex-start",
    margin: "1rem 0rem 1rem 0rem",
  },
  mobileBox: {
    color: "#359DB6",
    fontFamily: "Arial",
    fontSize: "1.7rem",
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.down("650")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("411")]: {
      fontSize: "0.7rem",
    },
  },
  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-start",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  boximgtext: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  principlesBox: {
    // width: matchesXS ? "100%" : matchesSM ? "75%" : matchesMD ? "75%" : "25%",

    height: "100%",

    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    alignItems: "center",
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.down("md")]: {
      width: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  principlesText: {
    // width: matchesXS
    // ? "85%"
    // : matchesSM
    // ? "80%"
    // : matchesMD
    // ? "75%"
    // : "55%",
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      width: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
    marginBottom: "2rem",
    textAlign: "center",

    fontSize: "1rem",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
    fontWeight: "400",
    color: "#231F20",
    fontFamily: "Open Sans",
  },
}));

const CogitoScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const paramRef = useParams().ref || null;
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={matchesSM ? "space-evenly" : "space-evenly"}
        className={classes.background}
        direction={matchesSM ? "column" : "column"}
      >
        <Grid
          item
          style={{
            textAlign: matchesSM ? "center" : "inherit",
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Typography
                variant="h2"
                style={{
                  paddingTop: "2em",
                  paddingBottom: "2em",
                  fontFamily: "ARIAL",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                  letterSpacing: 3,
                  fontSize: "3rem",
                }}
              >
                Cogito Metaverse
              </Typography>

              <Grid
                container
                justifyContent={matchesSM ? "center" : "center"}
                item
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_COGITO_LINK}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: "#FF7300",
                      borderWidth: "4px",
                      borderRadius: "46px",
                      paddingRight: 50,
                      paddingLeft: 50,
                      fontSize: "1.1rem",
                      fontStyle: "Open Sans",
                      color: "#FF7300",
                    }}
                  >
                    Visit Us
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box
            id="detail"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a href="#detail" style={{ textDecoration: "none" }}>
              <Box
                style={{
                  fontStyle: "Arial",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  color: "#ff7300",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                FIND OUT MORE
                <img
                  src={Arrow}
                  alt="build-wealth"
                  height={22}
                  style={{
                    paddingTop: "0.8rem",
                  }}
                />
              </Box>
            </a>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
        style={{ marginTop: "6em", marginBottom: "6em" }}
      >
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
        >
          The Principality of Cogito has created the Cogito Metaverse which is a
          revolutionary digital realm. The metaverse is often associated with
          the future of the Internet, known as Web 3.0, where ownership and
          control of information are distributed among the people who use it,
          rather than being centralised in the hands of a few platforms like
          Google or Facebook.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{ paddingTop: "5rem" }}
        >
          The vision for the Cogito Metaverse is to create a virtual space where
          people can connect with each other socially, engage in business
          activities, communicate, and learn. It aims to break traditional
          borders and be accessible to citizens from all around the world,
          fostering a global community.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{ paddingTop: "5rem" }}
        >
          One of the key aspects of the Cogito Metaverse is its unique digital
          currency called Cog. This digital currency allows members of the
          metaverse to buy, sell, and earn everyday goods within the virtual
          environment. The goal is for Cog to become widely accepted, enabling
          users to use it for transactions in regular shops worldwide. As more
          businesses join the metaverse and accept Cog as a form of currency,
          the financial ecosystem within the metaverse expands.
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent={matchesSM ? "space-evenly" : "space-evenly"}
        className={classes.graphicBackground}
        direction={matchesSM ? "column" : "column"}
        style={{ marginTop: "3em" }}
      ></Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
        style={{ marginTop: "6em", marginBottom: "3em" }}
      >
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
        >
          The Cogito Metaverse offers a range of features and functionalities to
          enhance the user experience. These include education, finance,
          connections, resources, entertainment, learning, communication,
          virtual reality, a stock market, qualifications, opportunities, and
          more. It aims to provide a comprehensive and immersive digital
          environment where users can learn, interact, and explore new
          possibilities.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{ paddingTop: "5rem" }}
        >
          In terms of financial flexibility, Cogito seeks to create a global
          digital currency system that treats everyone equally. It is designed
          to protect individuals from the financial limitations imposed by
          governments, as it is not influenced by exchange rates and provides
          fair taxation. Additionally, the Cogito Metaverse offers an open and
          transparent global marketplace, enabling users to engage in secure and
          efficient financial transactions.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{ paddingTop: "5rem" }}
        >
          To join the Cogito Metaverse, individuals can register through the
          registration portal on the Cogito Metaverse website. Once registered,
          users become Cogito digital citizens and gain access to corporate
          company structures. This provides a safe and secure repository for
          both personal and commercial operations.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{ paddingTop: "5rem" }}
        >
          For more detailed information on joining the Cogito Metaverse,
          individuals can visit the Registration Page. Additionally, they can
          explore the Insights and FAQ sections to learn more about Cogito and
          its various aspects.
        </Typography>
      </Grid>
      <Grid
        direction="row"
        item
        container
        justifyContent="space-evenly"
        className={classes.checkBoxContainer}
        style={{ marginTop: "3em" }}
      >
        <Box style={{ padding: "2rem 0 6rem 0" }}>
          <Container style={{ padding: 0 }}>
            {matchesMD ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  boxSizing: "border-box",
                }}
              >
                <Box
                  direction="column"
                  className={classes.pointtext}
                  //style={{ paddingRight: "0.5rem" }}
                >
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{
                        paddingRight: "1.2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Education
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Finance
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Connections
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Resources
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Entertainment
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Learning
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Communication
                    </Typography>
                  </Box>
                </Box>
                <Box
                  direction="column"
                  className={classes.pointtext}
                  style={{ paddingRight: "0.5rem" }}
                >
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{
                        paddingRight: "1.2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Digital Currency
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Library
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Virtual Reality
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Stock Market
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Qualifications
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Opportunities
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    {/* <img
                                                            src={Tick}
                                                            height={35}
                                                            alt="tick"
                                                            style={{ paddingRight: "2rem" }}
                                                        />
                                                        <Typography className={classes.mobileBox}>
                                                            Opportunities
                                                        </Typography> */}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  boxSizing: "border-box",
                }}
              >
                <Box direction="column" className={classes.pointtext}>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{
                        paddingRight: "1.2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Education
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Finance
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Connections
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Resources
                    </Typography>
                  </Box>
                </Box>
                <Box
                  direction="column"
                  className={classes.pointtext}
                  style={{ paddingRight: "0.5rem" }}
                >
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Entertainment
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Learning
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Communication
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{
                        paddingRight: "1.2rem",
                      }}
                    />
                    <Typography className={classes.mobileBox}>
                      Digital Currency
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Library
                    </Typography>
                  </Box>

                  <Box
                    direction="column"
                    className={classes.boxpointtext}
                  ></Box>
                </Box>
                <Box
                  direction="column"
                  className={classes.pointtext}
                  style={{ paddingRight: "0.5rem" }}
                >
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Virtual Reality
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Stock Market
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Qualifications
                    </Typography>
                  </Box>
                  <Box direction="column" className={classes.boxpointtext}>
                    <img
                      src={Tick}
                      height={matchesSM ? 22 : 32}
                      alt="tick"
                      style={{ paddingRight: "1.2rem" }}
                    />
                    <Typography className={classes.mobileBox}>
                      Opportunities
                    </Typography>
                  </Box>
                  <Box
                    direction="column"
                    className={classes.boxpointtext}
                  ></Box>
                </Box>
              </Box>
            )}
          </Container>
        </Box>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
      >
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
        >
          Overall, the Cogito Metaverse represents a groundbreaking digital
          landscape that combines social connections, economic opportunities,
          educational resources, and financial flexibility. It strives to shape
          the future of the Internet by creating a user-centric and inclusive
          environment where individuals can thrive and collaborate on a global
          scale.
        </Typography>
        <Typography
          variant="h4"
          align="center"
          className={classes.missionStatement}
          style={{
            paddingTop: "5rem",
            fontWeight: "bold",
            color: "#359DB6",
            textAlign: "center",
            fontSize: "2.75rem",
            fontFamily: "Open Sans",
          }}
        >
          OUR PRINCIPLES
        </Typography>
      </Grid>

      <img
        src={`${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/registration_page/shadow.jpg`}
        alt="shadow"
        width={"100%"}
      />

      <Grid container direction="column">
        <Grid
          item
          style={{
            padding: "2em",
            display: "flex",
            justifyContent: "center",
            alignItems: matchesMD ? "center" : "flex-start",
          }}
          direction={matchesMD ? "column" : "row"}
        >
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Humanism.png`}
              alt="humanism"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Humanism
                <br />
              </span>
              <br />
              We believe that prioritising the needs and experiences of every
              individual is essential in order to create a virtual environment
              that is empathetic, inclusive and accessible.
              <br />
              <br />
            </Typography>
          </Box>
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Partnership.png`}
              alt="partnership"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Partnership
                <br />
              </span>
              <br />
              The Cogito Metaverse aims to build for its citizens a community
              that fosters opportunities for connections and collaborations,
              where people work together to help each other grow and develop
              mutually.
              <br />
              <br />
            </Typography>
          </Box>
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Wellbeing.png`}
              alt="wellbeing"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Wellbeing
                <br />
              </span>
              <br />
              At Cogito Metaverse, we understand the importance of mental and
              physical health. We provide our members an environment that
              positively fosters and supports their overall well-being.
              <br />
              <br />
            </Typography>
          </Box>
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Learning.png`}
              alt="learning"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Learning
                <br />
              </span>
              <br />
              We know that education is important for all and that is why the
              Cogito Metaverse aims to provide learning that diminishes
              socio-economic barriers, and is hands-on, immersive as well as
              engaging.
              <br />
              <br />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="column" style={{ marginBottom: "2em" }}>
        <Grid
          item
          style={{
            padding: "2em",
            display: "flex",
            justifyContent: "center",
            alignItems: matchesMD ? "center" : "flex-start",
          }}
          direction={matchesMD ? "column" : "row"}
        >
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Security.png`}
              alt="security"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Security
                <br />
              </span>
              <br />
              The strong policies and protection measures of the Cogito
              Metaverse make it an extremely safe and trustworthy virtual world.
              <br />
              <br />
            </Typography>
          </Box>
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Equitability.png`}
              alt="equitabilty"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Equitability
                <br />
              </span>
              <br />
              The Cogito Metaverse is committed to providing equal opportunities
              that create a just and fair virtual environment.
              <br />
              <br />
            </Typography>
          </Box>
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Reachability.png`}
              alt="equitabilty"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Reachability
                <br />
              </span>
              <br />
              The benefits of Cogito Metaverse are equally accessible by all its
              members worldwide.
              <br />
              <br />
            </Typography>
          </Box>
          <Box className={classes.principlesBox}>
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/Integrity.png`}
              alt="equitabilty"
              height={100}
              width={100}
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            />
            <Typography className={classes.principlesText}>
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "700",
                }}
              >
                Integrity
                <br />
              </span>
              <br />
              We have carefully designed standards that set strong ethical
              standards which uphold Cogito Metaverse’s promise to protect and
              respect its citizens.
              <br />
              <br />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CogitoScreen;
