import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

function InsightsPage() {
  return (
    <div className="insightsContainer">
      <Grid container>
        <Grid item xs={12}>
          <img
            src="/images/insightsbanner-1.jpg"
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container className="insightSections" justifyContent="center">
        <Grid item>
          <Typography variant="h5" className="insightSection2-txt colorGrey">
            The Principality of Cogito economy is a custom designed approach to
            personal and business financial services. Learn how to equip
            yourself through our Cogito Insights.
          </Typography>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid container className="insightSections">
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">History</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Economies are usually determined by geography and local currency.
              Transacting was traditionally through barter where someone growing
              apples would trade a few apples with someone else for eggs and
              another person for bread and so on.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item className="colorGrey">
            <Typography variant="subtitle1">
              As we have evolved, currency has developed. The preferred of these
              early on was gold. Then coins and notes, cheques, promissory notes
              and IOUs. Finally digital currency by way of Eftpos, Visa,
              Mastercard, AMEX, bank transfers, paypal and digital currencies.
              When considering the more global market we live in now though all
              of these involve currency exchange and government controls.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid conatiner className="spacing">
          <Grid item className="colorGrey">
            <Typography variant="subtitle1">
              Communications developed and consumer demand to trade between
              countries grew. Transportation evolved and the ability to sell our
              goods further afield became appealing for a variety of reasons.
              USA positioned itself to be the powerhouse of currency convincing
              global governments and businesses that the US dollar should be the
              preferred currency for global transactions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid conatiner className="spacing">
          <Grid item className="colorGrey">
            <Typography variant="subtitle1">
              The impact of the move by many to transact in USD has meant local
              economies are impacted and to a large degree controlled by the
              USA. The state of the US economy and politics now has an unhealthy
              impact on peoples freedom to move and transact. Global governments
              have been happy to follow the US lead, but now a lot of people
              want to take back control of their financial freedom.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid conatiner className="spacing">
          <Grid item className="colorGrey">
            <Typography variant="subtitle1">
              Because of this, Cogito is inviting you to join the global
              evolution of change. Start using the Cog (the currency of Cogito)
              as part of your day to day earnings, spending and investing.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">Digital Currency</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Cog is a digital currency to be used for day to day buying and
              selling of goods and services and ultimately in investments. The
              Cog is tied in value to the euro so you know the value and
              stability of your digital currency all the time.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The main reasons we elected to tie to the euro are:
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              1.As a currency used by several countries its economic risk is
              limited in comparison to any other currency which as a single
              political controller.
              <br />
              2.The euro is becoming a preferred alternative for many businesses
              and governments as they attempt to remove themselves from the
              onerous controls of USA.
              <br />
              3.It is accepted and understood globally and best positioned to be
              a true global currency.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">Transact</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Cog is easy to use. Once you have registered you will also be
              able to invite friends and family to register. Businesses can also
              register and the benefits to them are the same as for you as an
              individual.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Transactions can be set up on your Cog bank app using your smart
              phone or your net account. So payments can happen phone to phone,
              phone Cog bank or Cog bank to Cog bank.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Transactions authenticate instantly by use of PIN and our ALDS
              technology.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Your transaction is conducted in your local value and Cog bank
              automatically converts to the Cog value in your account based on
              exchange rate for the day.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">The System</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Unlike virtual coins (crypto currency) which many people have
              become aware of, Cog is not a limited offer. Bank of Cogito issues
              the currency as required and based on Cogito government very
              strict set of rules and procedures.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              These strict rules mean that Cogito has the ability to help you
              and your business with a freer economy and smarter financing
              options.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Interest free credit facilities, settling debt using cog, opening
              up a new network of customers by offering goods and services for
              cog and restructuring your finances are examples of how Cogito can
              help you.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Cogito economy works simply off a zero base account system. If
              someone in the economy is in credit by Cog 100, then others in the
              network will be in debit by a total of Cog 100. All Cog is backed
              by either debt assets or euro funds on deposit.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">Risks</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The main risk in the Cogito economy is that someone owes Cog and
              their business fails. In order for Cogito to ensure we maintain
              the integrity of the global economy Cogito has several key systems
              in place.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Firstly before credit is offered Cogito carries out a set of
              checks, KYC and AML procedures, so we know who we are dealing
              with.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Secondly from every transaction conducted we take a percentage of
              the turnover tax charged and accumulate that in a debt reserve
              fund. This fund is audited and reports you are made available to
              citizens annually.
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      {/* <Grid conatiner className="insightsection3">
        <Grid item>
          <img src="/images/chaticon.png" alt="" />
        </Grid>
      </Grid>
      <Grid conatiner className="insightsection3">
        <Grid item className="  colorGrey">
          <Typography variant="subtitle1">
            Still have more questions? We can help you. once registered for
            free, Cogito has 24/7 helpdesk. Ask any questions you have with our
            assistant in the Cogito App.
          </Typography>
        </Grid>
      </Grid> */}
      <Grid conatiner className="insightsection3">
        <Grid item className=" colorGrey">
          <Typography variant="subtitle1">
            You can also learn more through our{" "}
            <Link to="/faq" className="blueLink">
              FAQ{" "}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default InsightsPage;
