import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Grid,
  Container,
  withStyles,
  Typography,
  TextField,
  makeStyles,
  Checkbox,
  useMediaQuery,
  InputBase,
} from "@material-ui/core";
import logo from "../asset/logo.svg";
import ThemeButton from "../components/common/themebutton";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Spinner from "../components/common/smallSpinner/smallSpinner";
import Lottie from "react-lottie";
import animationData from "../asset/lotties/success-lottie.json";
import Send from "@material-ui/icons/Send";
import { isValidNumber } from "../asset/utility";

const errorNotify = (message) => toast.error(message);
const successNotify = (message) => toast.success(message);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "#359DB6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#359DB6",
      },
    },
  },
})(TextField);

const BlackInput = withStyles({
  root: {
    "& .MuiInputBase-input": {
      color: "black",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "black",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
})(InputBase);

const BlueCheckbox = withStyles({
  root: {
    color: "#359DB6",
    "&$checked": {
      color: "#359DB6",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CryptopiaForm() {
  const classes = styles();
  const isMobile = useMediaQuery("(max-width:420px)");
  const isDesktop = useMediaQuery("(min-width:830px");
  const isIpad = !isMobile && !isDesktop;
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [mobile, setMobile] = useState(null);
  // const [email, setEmail] = useState(null);
  const [cogitoUsername, setCogitoUsername] = useState(null);
  const [cryptopiaMail, setCryptopiaMail] = useState(null);
  const [below500Plan, setBelow500Plan] = useState(null);
  const [estimatedCoinTypes, setEstimatedCoinTypes] = useState(null);
  const [estimatedCoinValue, setEstimatedCoinValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [disabledButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (formSubmitted) {
      window.scrollTo(0, 0);
    }
  }, [formSubmitted]);

  const onSubmit = () => {
    setDisableButton(true);
    const data = {
      username: cogitoUsername?.toLowerCase(),
      name: name,
      address: address,
      mobile: mobile,
      // email: email,
      cryptopiaEmail: cryptopiaMail,
      coinsType: Number(estimatedCoinTypes),
      coinsValue: Number(estimatedCoinValue),
      saleOption: below500Plan == true ? 0 : below500Plan == false ? 1 : null,
    };
    let emptyError = false;
    const entries = Object.entries(data);

    const errorMessages = {
      username: "Please Fill the username",
      name: "Please fill the name field",
      address: "Please fill the address field",
      mobile: "Please fill the Mobile number",
      cryptopiaEmail: "Please fill the cryptopia  email",
      coinsType: "Please fill the estimated number of coin types",
      coinsValue: "Please fill the estimated value of coins",
      saleOption: `Please select a plan from "Select your sale option"`,
    };

    for (const [key, value] of entries) {
      if (key === "coinsType") {
        if (!isValidNumber(value)) {
          errorNotify("Please enter a valid estimated number of coin types");
          emptyError = true;
          setDisableButton(false);
          break;
        }
      } else if (key === "coinsValue") {
        if (!isValidNumber(value)) {
          errorNotify("Please enter a valid estimated value of coins");
          emptyError = true;
          setDisableButton(false);
          break;
        }
      }

      if (
        value === null ||
        value === "" ||
        (value === 0 && key !== "saleOption")
      ) {
        errorNotify(errorMessages[key]);
        emptyError = true;
        setDisableButton(false);
        break;
      }
    }

    if (!emptyError) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_LINK}/user/submitCryptopiaAggrementForm`,
          data,
        )
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            successNotify("Form submission succesfull");
            setFormSubmitted(true);
          } else {
            errorNotify(res.data.errorMessage);
            setDisableButton(false);
          }
        })
        .catch(function (error) {
          setDisableButton(false);
          setLoading(false);
          errorNotify(error.response.data.errorMessage);
        });
    }
  };

  return (
    <div>
      <div
        style={{
          height: isIpad ? "10vh" : isMobile ? "15vh" : "20vh",
          background: "rgb(21, 99, 118)",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {formSubmitted ? (
          <Grid container>
            <Grid item xs={12}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={400}
                width={400}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  color: "#08c427",
                  fontWeight: "bold",
                  marginBottom: "20vh",
                }}
              >
                Form submission successful! . A copy of the same will be sent to
                you through mail, Thankyou!
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            style={{
              width: isMobile ? "90vw" : "80vw",
              border: "2px solid grey",
              margin: "20px ",
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{ background: "rgb(21, 99, 118)" }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2}>
                <img src={logo} style={{ width: isMobile ? "70%" : "35%" }} />
              </Grid>
              <Grid item xs={10} style={{ background: "rgb(21, 99, 118)" }}>
                <Typography
                  variant={isMobile ? "h6" : isIpad ? "h5" : "h4"}
                  style={{ color: "white" }}
                >
                  Sale & Purchase of Crypto at Cryptopia
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ margin: "20px 0", textDecoration: "underline" }}
              >
                FIRST SCHEDULE
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6">Date :</Typography>
            </Grid>
            <Grid item xs={9} style={{ textAlign: "left" }}>
              <Typography variant="subtitle1">
                {moment().format("DD-MM-YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ margin: "10px 0", textDecoration: "underline" }}
              >
                Seller
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography
                variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
              >
                Name :
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{ className: classes.textField }}
                variant="outlined"
                fullWidth
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography
                variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
              >
                Address :
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{ className: classes.textField }}
                variant="outlined"
                fullWidth
                onChange={(e) => setAddress(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography
                variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
              >
                Mobile No :
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{ className: classes.textField }}
                variant="outlined"
                fullWidth
                type="number"
                onChange={(e) => setMobile(e.currentTarget.value)}
                placeholder={"Please include your country code"}
              />
            </Grid>

            {/* <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography
                variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
              >
                Email :
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{
                  className: classes.textField,
                }}
                variant="outlined"
                fullWidth
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </Grid> */}

            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography
                variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
              >
                Cogito Username :
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{
                  className: classes.textField,
                }}
                variant="outlined"
                fullWidth
                onChange={(e) => setCogitoUsername(e.currentTarget.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: "3vh" }}>
                Note : If you dont have Cogito account yet, please click here to{" "}
                <a
                  href={process.env.REACT_APP_URL_SIGNUP}
                  style={{
                    color: "#359DB6",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                  target="_blank"
                >
                  Register
                </a>
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography
                variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
              >
                Cryptopia Email :
              </Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{
                  className: classes.textField,
                }}
                variant="outlined"
                fullWidth
                onChange={(e) => setCryptopiaMail(e.currentTarget.value)}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "5px" }}>
              <Typography
                variant="h6"
                style={{ margin: "10px 0", textDecoration: "underline" }}
              >
                Buyer
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{ className: classes.textField }}
                variant="outlined"
                fullWidth
                value="Epic Trust Limited"
              />
            </Grid>
            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography variant="h6">Address</Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{ className: classes.textField }}
                variant="outlined"
                fullWidth
                value="Business Suite 2001, Principality of Cogito"
              />
            </Grid>
            <Grid item xs={3} style={{ marginBottom: "5px" }}>
              <Typography variant="h6">Email</Typography>
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginBottom: "5px", paddingRight: "5px" }}
            >
              <CssTextField
                InputProps={{ className: classes.textField }}
                variant="outlined"
                fullWidth
                value="epictrust@principalityofcogito.com"
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ textAlign: "left", padding: "0 8vw" }}
            >
              <Grid item xs={12}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  <span style={{ fontWeight: "bolder" }}>THIS AGREEMENT </span>{" "}
                  is made on the date and between the parties specified in the
                  First Schedule above
                </Typography>
              </Grid>

              {data1.map((item) => {
                if (Object.keys(item) == "header") {
                  return (
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ margin: "20px 0", fontWeight: "bolder" }}
                      >
                        {Object.values(item)[0]}
                      </Typography>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12}>
                      <Typography variant="h6" style={{ margin: "20px 0" }}>
                        {Object.values(item)[0]}
                      </Typography>
                    </Grid>
                  );
                }
              })}

              <Grid item xs={3}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  CBC
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  Cash Backed COG, the currency of the Principality of Cogito
                  that is convertible to EURO;
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  Cog
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  The base currency of the Principality of Cogito calculated to
                  8 decimal places and tethered in value to the euro;
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  Coin(s)
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  Any coin or token belonging to the Seller and presently held
                  in a wallet at Cryptopia;
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  Cryptopia
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  Cryptopia Limited, a company incorporated in New Zealand and
                  presently in liquidation;
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  Liquidator
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  David Ruscoe of Grant Thornton or other person for the time
                  being conducting the liquidation of Cryptopia;
                </Typography>
              </Grid>

              {data2.map((item) => {
                if (Object.keys(item) == "header") {
                  return (
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ margin: "20px 0", fontWeight: "bolder" }}
                      >
                        {Object.values(item)[0]}
                      </Typography>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={12} style={item.style}>
                      <Typography variant="h6" style={{ margin: "20px 0" }}>
                        {Object.values(item)[0]}
                      </Typography>
                    </Grid>
                  );
                }
              })}
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    margin: "20px 0",
                    textDecoration: "underline",
                    fontWeight: "bolder",
                  }}
                >
                  Select your sale option.
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <BlueCheckbox
                  size={2}
                  checked={below500Plan == true}
                  onChange={() =>
                    below500Plan == null
                      ? setBelow500Plan(true)
                      : setBelow500Plan(!below500Plan)
                  }
                />
              </Grid>
              <Grid item xs={10} style={{ marginBottom: "5vh" }}>
                <Typography variant="h6">
                  My coins have a value of US$500 or less and I wish to sell to
                  Cogito for C500 (five hundred Cog)
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <BlueCheckbox
                  checked={below500Plan == false}
                  onChange={() =>
                    below500Plan == null
                      ? setBelow500Plan(false)
                      : setBelow500Plan(!below500Plan)
                  }
                />
              </Grid>
              <Grid item xs={10} style={{ marginBottom: "5vh" }}>
                <Typography variant="h6">
                  My coins have a value of over US$500 and I wish to sell to
                  Cogito in exchange for Cog based on the current market value
                  in euro.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "2vh" }}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  AGREED BY THE PARTIES on the date set at the head of this
                  Agreement.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "2vh" }}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  By the Seller or its authorised representative completing and
                  submitting the above- written Agreement and Assignment, the
                  Seller adopts and agrees to be bound by the terms thereof
                  whereupon this Assignment shall be deemed to be a valid and
                  complete transaction for all the purposes of the New Zealand
                  Electronic Transactions Act of 2002.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "1vh" }}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  Epic Trust shall be deemed to have adopted and be bound by the
                  terms of the said Agreement and Assignment upon the first to
                  happen of the following events:
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "1vh" }}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  1. Registration of the Seller as a Citizen of the Principality
                  of Cogito; and
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                  2. Payment of the purchase price
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ margin: "20px 0", textDecoration: "underline" }}
              >
                SECOND SCHEDULE
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ textAlign: "left", padding: "0 8vw" }}
            >
              <Grid item xs={12}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  The Seller’s Coins and Purchase Price
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ margin: "20px 0" }}>
                  List coins, estimate quantity and estimated value as best you
                  can,or provide a copy of your account holdings to
                  epictrust@principalityofcogito.com.
                </Typography>
              </Grid>

              <Grid item xs={3} style={{ marginBottom: "5px" }}>
                <Typography
                  variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
                >
                  Estimated number of types of coins
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                style={{ marginBottom: "5px", paddingRight: "5px" }}
              >
                <CssTextField
                  InputProps={{
                    className: classes.textField,
                  }}
                  variant="outlined"
                  fullWidth
                  type="number"
                  onChange={(e) =>
                    setEstimatedCoinTypes(Math.ceil(e.currentTarget.value))
                  }
                />
              </Grid>
              <Grid item xs={3} style={{ marginBottom: "5px" }}>
                <Typography
                  variant={isMobile ? "subtitle2" : isIpad ? "subtitle1" : "h6"}
                >
                  Estimated coin value in Euro
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                style={{ marginBottom: "5px", paddingRight: "5px" }}
              >
                <CssTextField
                  InputProps={{ className: classes.textField }}
                  variant="outlined"
                  fullWidth
                  type="Number"
                  onChange={(e) => setEstimatedCoinValue(e.currentTarget.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ThemeButton
                title={loading ? <Spinner /> : "Submit"}
                onClick={onSubmit}
                disabled={disabledButton}
                margin="5vh 0"
                width="150px"
              />
            </Grid>
            <Toaster
              toastOptions={{
                error: {
                  style: {
                    border: "1px solid red",
                    padding: "16px",
                    color: "red",
                  },
                },
                success: {
                  style: {
                    border: "1px solid green",
                    padding: "16px",
                    color: "green",
                  },
                },
              }}
            />
          </Grid>
        )}
      </div>
    </div>
  );
}

export default CryptopiaForm;

const styles = makeStyles((theme) => ({
  textField: {
    color: "#359DB6",
  },
}));

const data1 = [
  { header: "RECITALS" },
  {
    text: "(1) Cryptopia Limited is a company incorporated in New Zealand and currently in liquidation.",
  },
  {
    text: "(2) The High Court of New Zealand, on 8th. April 2020 in case No. CIV-2019-409-000544 [2020] NZHC 728 ruled that the cryptocurrency coins or tokens deposited by account holders with Cryptopia are beneficially owned by the account holders and are not assets of the company.",
  },
  {
    text: "(3) The Seller is an account holder at Cryptopia and beneficial owner of cryptocurrency stored in wallets at Cryptopia under the User Name specified in the First Schedule.",
  },
  {
    text: "(4) Despite the Court ruling, the Liquidator has refused or failed to transfer the Seller’s Coins to a wallet address under the control of the Seller.",
  },
  {
    text: "(5) The Purchaser has agreed to purchase the Seller’s Coins on the terms set out in this Agreement.",
  },
  { header: "THE TERMS OF THIS AGREEMENT" },
  { header: "1. DEFINITIONS" },
  {
    text: "In this Agreement the following expressions shall, except where the context otherwise requires, have the meanings respectively set opposite them:",
  },
];

const data2 = [
  { header: "2. AGREEMENT FOR SALE & PURCHASE AND ASSIGNMENT" },
  { header: "(a) Agreement" },
  {
    text: "Subject to the provisions of this Agreement, the Seller agrees to sell and the Buyer agrees to purchase the Seller’s Coins listed in the Second Schedule at the price(s) there specified.",
  },
  { header: "(b) Assignment of Coins" },
  {
    text: "(i) Subject to payment of the purchase price, the Seller, as beneficial owner, hereby assigns to the Buyer or its nominee all of the Seller’s rights title and interest in the Coins.",
  },
  {
    text: "(ii) Completion shall take place automatically upon payment of the purchase price by the Buyer.",
  },
  {
    text: "(iii) To become entitled to receive the purchase price of the Coins, the Seller shall, immediately before the submission of this Agreement, register free of charge as a citizen of the Principality of Cogito at the URL www.cogitometaverse.com.",
  },
  { header: "3. FURTHER ASSURANCE" },
  { header: "(a) Assignment of Other rights" },
  {
    text: "In further assurance of, and without prejudice to the generality of, the aforesaid assignment, the Seller hereby expressly:",
  },
  {
    text: "(i) assigns and transfers all of his rights and claims of whatsoever nature against Cryptopia and the Liquidator;",
  },
  {
    text: "(ii) undertakes, at the request and cost of the Buyer, to sign, execute and deliver all such documents and do all such other lawful acts and things as the Buyer may reasonably require in the exercise of any of the rights hereby assigned.",
  },

  { header: "(b) Power of Attorney" },
  {
    text: "(i) The Seller hereby appoints the Buyer and its directors, officers and lawfully appointed agents as agent and Attorney-in-Fact for the Seller with power to make any and all claims and do any and all other acts and things which the Seller has, at the date of the Assignment has in relation to the ownership and control of the Coins and/or any claim against Cryptopia and the Liquidator arising thereout.",
  },
  {
    text: "(ii) The Seller hereby ratifies and undertakes to ratify all documents signed and all acts lawfully done and performed by any such Attorney.",
  },
  { header: "(c) Cryptopia Login" },
  {
    text: "The Seller undertakes to provide the working login user name and password for the Seller’s Cryptopia account once settlement of this agreement is completed.",
  },
  { header: "4. WARRANTIES AND COVENANTS" },
  { header: "(a) Of Seller" },
  {
    text: "(i) The Seller warrants that:",
  },
  {
    text: "(A) She/he is the absolute, ultimate beneficial owner of the Coins and that no other person has any claim against or lien over any thereof;",
  },
  {
    text: "(B) the original funds used to purchase the Coins were from a legal source and not of any criminal origin;",
  },
  {
    text: "(C) The User name and Email address shown in the First Schedule are User Name and email address register in the Seller’s Profile at Cryptopia",
  },
  {
    text: "(ii) The Seller undertakes to the Buyer to take all necessary lawful steps and deliver all necessary documents required for AML/CTF/KYC purposes and/or otherwise reasonably required by the Buyer.",
  },
  {
    header: "(b) Warranties",
  },
  { text: "Each party hereto warrants to the other:" },
  { text: "(i) that it has the capacity to enter into this Agreement;" },
  {
    text: "(ii) that, where the party is not a natural person:",
  },
  {
    text: "(A) it is duly incorporated and in good standing in the place of its incorporation;",
  },
  {
    text: "(B) all necessary resolutions and authorities have been passed, given and made to   make   the   execution   and   performance   of   this   Agreement   the   proper corporate act of that party and to bind that party by its terms;",
  },
  {
    text: "(iii) that   the   execution   and   performance   of   this   Agreement   does   not   constitute   a breach or an event of default under any agreement between that party and any third party;",
  },

  {
    text: "(iv) that   the   person   signing   this   Agreement   as   or   on   behalf   of   that   party   is   fully authorised   to   sign   it   and   that   such   signature   commits   that   party   to   the   terms hereof.",
  },
  { header: "(c) Survival" },
  {
    text: "The representations  and  warranties set out in  this clause  shall survive completion of this Agreement for so long as may be necessary to carry them into full force and effect.",
  },
  { header: "5. CONFIDENTIALITY " },
  { header: "(a) Mutual Covenant" },
  {
    text: "The parties each covenant with each of the others that they will (and will procure that their respective   employees   agents   and   nominees   shall)   keep   confidential   any   confidential information which they may acquire in relation to the affairs of the other party or of the other party’s business associations or contacts and shall not use or disclose such information except with the written consent of that other party or in accordance with the order of a court of competent jurisdiction or in the course of execution of any step required in connection with the performance of this Agreement.",
  },
  { header: "(b) Time Limit" },
  {
    text: "The obligations of the parties contained in this clause shall continue without limit in point of time but shall cease to apply to any information coming into the public domain otherwise than by breach of the obligations contained in this clause by that party or by any person for whom that party is responsible.",
  },
  { header: "(c) Legal Proceedings" },
  {
    text: "Nothing in this clause shall prevent either party from disclosing information which would,apart from this sub-clause, be deemed to be within the provisions of this clause to the extent required in or in connection with legal proceedings arising out of this Agreement. ",
  },
  { header: "(d) Survival" },
  {
    text: "The provisions of this clause shall survive termination of this Agreement.",
  },
  { header: "6. JURISDICTION" },
  { header: "(a) Governing Law" },
  {
    text: "This Agreement shall be governed by the laws of the Principality of Cogito, without regard to any conflicts of laws provisions and to the exclusion of all other jurisdictions.   The parties submit to the jurisdiction of the courts of that jurisdiction.",
  },
];
