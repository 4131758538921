import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  home: {
    // background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/static/assets/homepages/hero.png)`,
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/poc_home_bg.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  about: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/About.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  cogito: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/CogitoMetaverse.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "97.5vh",
    width: "100%",
    alignItems: "flex-end",
  },
  residency: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/E-Residency.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  education: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/Education.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "97.5vh",
    width: "100%",
  },
  education_dw: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/Education.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "80vh",
    [theme.breakpoints.down("md")]: {
      height: "40vh",
    },
  },
  gov: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/Govt.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "97.5vh",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      height: "64vh",
      backgroundPosition: "left",
    },
  },
  contact: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/Contact.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  header: {
    backgroundColor: "#231F20",
    height: 600,
    borderBottom: "5px solid #359DB6",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  headerName: {
    color: "#FF7300",
    fontFamily: "Arial",
    fontWeight: "bolder",
    letterSpacing: 15,
    fontSize: 65,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      letterSpacing: 7,
    },
  },
  orangeHead: {
    fontSize: "1.6rem",
    textAlign: "center",
    fontFamily: "arial",
    fontWeight: "bold",
    color: "#FF7300",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2em",
    },
  },
  regssmargin: {
    margin: "2rem 0 2rem 0",
  },

  typo1: {
    [theme.breakpoints.down(350)]: {
      fontSize: "0.8em",
    },
  },

  typo2: {
    fontSize: "1.3em",
    color: "#ff7300",
    [theme.breakpoints.down(440)]: {
      fontSize: "1em",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "0.8em",
    },
  },

  vector: {
    marginTop: "1%",
  },

  img1: {
    height: "120px",
    [theme.breakpoints.down(440)]: {
      height: "60px",
    },
    [theme.breakpoints.down(350)]: {
      height: "40px",
    },
  },

  vector1: {
    margin: "0 0 0.4rem 0.4rem",
  },
  vector2: {
    margin: "3rem 0 0 0",
    justifyContent: "space-evenly",
    display: "flex",
    width: "100%",
  },

  img2: {
    height: "45px",
    [theme.breakpoints.down(440)]: {
      height: "20px",
    },
    [theme.breakpoints.down(350)]: {
      height: "15px",
    },
  },
  img3: {
    height: "30px",
    [theme.breakpoints.down(440)]: {
      height: "25px",
    },
    [theme.breakpoints.down(350)]: {
      height: "20px",
    },
    [theme.breakpoints.down(290)]: {
      height: "15px",
    },
  },

  mobile: {
    marginTop: "8rem",
    position: "absolute",
    height: "630px",
    [theme.breakpoints.down(440)]: {
      height: "400px",
    },
    [theme.breakpoints.down(350)]: {
      height: "300px",
    },
    [theme.breakpoints.down(290)]: {
      height: "200px",
    },
  },

  grid_second: {
    height: "18rem",
    width: "100%",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    marginTop: "9rem",
  },

  mobile1: {
    height: "450px",
    marginTop: "5rem",
    [theme.breakpoints.down(440)]: {
      height: "350px",
    },
    [theme.breakpoints.down(350)]: {
      height: "250px",
    },
    [theme.breakpoints.down(290)]: {
      height: "150px",
    },
  },

  grid_second1: {
    position: "absolute",
    right: "0rem",
    top: "63rem",
    height: "15rem",
    width: "100vw",
    backgroundColor: "#231f20",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#359db6",
    [theme.breakpoints.down(440)]: {
      top: "54rem",
    },
    [theme.breakpoints.down(350)]: {
      top: "45.5rem",
    },
    [theme.breakpoints.down(290)]: {
      top: "39rem",
    },
  },

  grid_second1_typo: {
    fontSize: "1.8rem",
    fontWeight: 500,
    fontFamily: "Open Sans",

    [theme.breakpoints.down(440)]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down(290)]: {
      fontSize: "1rem",
    },
  },

  mainBx: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10rem",
  },

  boxmid: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Open Sans",
    fontWeight: "lighter",
    alignItems: "center",
    paddingBottom: "5rem",
    [theme.breakpoints.down(960)]: {
      marginTop: "3rem",
    },
  },

  imgbox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    padding: "0 9rem 0 9rem",
    [theme.breakpoints.down(856)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "100%",
    },
  },

  sub_Content: {
    color: "#231F20",
    fontSize: "1.4rem",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "300",
    padding: "2rem 7rem 2rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
      fontSize: "1em",
    },
  },

  sub_Content_Eresidency: {
    lineHeight: "2rem",
    color: "#231F20",
    fontSize: "1.4rem",
    textAlign: "left",
    fontFamily: "Open Sans",
    fontWeight: "300",
    padding: "0rem 7.5rem 0rem 7.5rem",
    [theme.breakpoints.down(856)]: {
      padding: "5rem 2rem 2rem 2rem",
      fontSize: "1em",
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    wordWrap: "break-word",
    fontSize: "1.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      padding: "0.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
      padding: "0.4rem",
    },
  },

  sub_Content_Gov: {
    lineHeight: "2rem",
    color: "#231F20",
    fontSize: "1.4rem",
    textAlign: "left",
    fontFamily: "Open Sans",
    fontWeight: "300",
    padding: "0rem 7.5rem 0rem 7.5rem",
    [theme.breakpoints.down(856)]: {
      padding: "5rem 2rem 2rem 2rem",
      fontSize: "1em",
    },
  },

  sub_Content_Gov_Mid: {
    lineHeight: "2rem",
    color: "#231F20",
    fontSize: "1.4rem",
    textAlign: "left",
    fontFamily: "Open Sans",
    fontWeight: "300",
    padding: "2rem 0rem 2rem 0rem",
    [theme.breakpoints.down(856)]: {
      padding: "1rem 0rem",
      fontSize: "1rem",
      textAlign: "center",
    },
  },

  sub_Content_Bank_Mid: {
    color: "#231F20",
    fontSize: "1.4rem",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: "300",
    padding: "0rem 3rem 0rem 3rem",
    [theme.breakpoints.down(856)]: {
      padding: "3rem 2rem 3rem 2rem",
      fontSize: "1em",
    },
  },

  outerBox: {
    width: "16rem",
    height: "7rem",
    [theme.breakpoints.down("md")]: {
      width: "11rem",
      height: "5rem",
    },
  },
  midBoximg: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem  0rem 1rem 7rem",
  },

  midBoxtypohead: {
    textAlign: "left",
    fontSize: "1.6rem",
    fontWeight: 800,
    color: "#ff7300",
    fontFamily: "arial",
    padding: " 1rem 0 1rem 0rem",
    [theme.breakpoints.down(856)]: {
      padding: "0.8rem 0 0.8rem 0",
      fontSize: "1.2em",
    },
  },
  midBoxtyposub: {
    textAlign: "left",
    fontSize: "1.4rem",
    fontFamily: "Inter",
    fontWeight: "300",
    padding: " 0rem 0 3rem 0rem",
    [theme.breakpoints.down(856)]: {
      // padding: "0.8rem 0 1rem 0",
      fontSize: "1em",
    },
  },
  boxDialogeLinks: {
    height: "4.5rem",
    width: "12.5rem",
    borderRadius: 16,
    fontFamily: "Arial",
    fontWeight: "bold",
    color: "#359db6",
    fontSize: "1.3rem",
    background: "#231f20",
    boxShadow: "1px 2px 2px #9E9E9E",
    display: "flex",
    flexDirection: "row",
    // padding: "0 5% 0 8% ",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "Left",
    margin: "auto auto auto auto",
    transitionProperty: "background",
    transitionDuration: "0.5s",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        background: "rgb(68, 68, 68)",
      },
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
      width: "9.5rem",
      height: "4rem",
    },
  },
  pointtext: {
    // display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    overflow: "hidden",
    padding: "0rem 5rem 0rem 5rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem ",
    },
  },
  boxpointtext: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    textAlign: "left",
  },
  mobileBox: {
    color: "#359DB6",
    fontFamily: "Arial",
    fontSize: "1.5rem",
    fontWeight: "bold",
    // fontStyle: "normal",
    marginBottom: "2rem",
    textAlign: "left",
    [theme.breakpoints.down(850)]: {
      fontSize: "1rem",
    },
  },

  boxend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem",
    backgroundColor: "#231f20",
  },

  boxend1typo: {
    color: "white",
    fontSize: "1.2rem",
    fontWeight: "lighter",
    fontFamily: "Open Sans",
    textAlign: "center",
    padding: "4rem 7rem 3rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },

  boxend2typo: {
    fontSize: "1.2rem",
    fontWeight: "lighter",
    textAlign: "center",
    padding: "0 7rem 4rem 7rem",
    color: "#359db6",
    [theme.breakpoints.down(856)]: {
      padding: "0 2rem 2rem 2rem",
    },
  },

  currencyBox: {
    padding: "0 3rem 4.5rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0 3rem 2rem 3rem",
    },
    [theme.breakpoints.down(1260)]: {
      padding: "0 3rem 6rem 3rem",
    },
    [theme.breakpoints.between(1137, 1169)]: {
      padding: "0 3rem 4.5rem 3rem",
    },
  },
  HeadingText: {
    fontSize: "2rem",
    fontFamily: "Arial",
    fontWeight: 700,
    fontStyle: "normal",
    textAlign: "center",
    color: "#359db6",
    padding: "4% 6%",
    textDecoration: "underline",
    textUnderlinePosition: "under",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      padding: "15% 6% 0%",
    },
  },
  GovContentMid2: {
    color: "#231F20",
    fontSize: "1.4rem",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "300",
    padding: "2rem 1.5rem 6rem 7rem",
    [theme.breakpoints.down(856)]: {
      padding: "2rem 2rem 2rem 2rem",
      fontSize: "1em",
    },
  },
  ParliamentMembers: {
    background: `url(https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/POC/ParliamentMembers.jpg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    height: "80%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
    },
  },
  GovPageButton: {
    fontSize: "0.8rem",
    borderRadius: "15px",
    padding: "5px 15px",
    color: "#000",
    "&:hover": {
      border: "2px solid #FF7300",
      borderRadius: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
      marginTop: "6%",
    },
  },
  ParliamentMembersHeading: {
    fontWeight: "500",
    textAlign: "center",
    color: "white",
    letterSpacing: 3,
    fontSize: "60px",
    lineHeight: "70.21px",
    padding: "20.7rem",

    [theme.breakpoints.between(600, 1200)]: {
      fontSize: "35px",
      padding: "18rem 0",
      lineHeight: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "29px",
      padding: "6rem 0",
      lineHeight: "40px",
    },
  },
  GovPageMenuButton: {
    fontSize: "0.8rem",
    padding: "5px 15px",
    position: "relative",
    color: "#000",
    zIndex: 1,
  },
  selectedmenu: {
    fontSize: "0.8rem",
    border: "2px solid #FF7300",
    borderRadius: "15px",
    padding: "5px 15px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
      fontWeight: 600,
      fontSize: "0.7rem",
      marginTop: "6%",
    },
  },
  MenuList: {
    position: "absolute",
    width: "100%",
    top: "15px",
    borderRadius: "15px",
    listStyle: "none",
    background: "#C5F4FF",
    padding: "4%",
  },
  MenuListItem: {
    // padding: "5px 0",
    textAlign: "center",
    lineHeight: "32.68px",
    fontWeight: 500,

    borderBottom: "1px solid #7D7D7D",
  },
  MembersTitle: {
    fontSize: "1rem",
    fontWeight: "bolder",
  },
  MenuListLastItem: {
    // padding: "5px 0",
    textAlign: "center",
    lineHeight: "32.68px",
    fontWeight: 500,
  },
  Disclaimer: {
    width: "68%",
    maxWidth: "90vw",
    background: "#FFF3D9",
    position: "absolute",
    right: 0,
    bottom: -90,
    textAlign: "left",
    borderRadius: "15px",
    // margin: "8% 0 0",
    [theme.breakpoints.between(600, 1200)]: {
      bottom: -115,
      width: "100%",
      // margin: "10% 0",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: -14,
      width: "100%",
    },
  },
  DisclaimerText: {
    fontSize: "0.9rem",
    color: "#1A1A1A",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.4rem",
    },
  },
  CouncilContainer: {
    marginTop: "10%",
    [theme.breakpoints.between(600, 1200)]: {
      marginTop: "15%",
    },
    [theme.breakpoints.down(600)]: {
      // marginTop: "12%",
    },
  },
}));
