import React from "react";

//material
import { Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const CustomButton = withStyles({
	root: {
		border: 0,
		color: "white",
		fontFamily: "Arial",
		fontWeight: "bolder",
		// background: "#359DB6",
		"&:hover": {
			background: "#62c3db",
		},
	},
	label: {
		textTransform: "capitalize",
	},
	disabled: {
		background: "#8ab0b9",
	},
})((props) => <Button {...props} />);

const ThemeButton = (props) => {
	return (
		<CustomButton
			variant="contained"
			onClick={props.onClick}
			disabled={props?.disabled}
			style={{
				height: props.height ? props.height : 40,
				background: props.background ? props.background : "#FF7300",
				border: props.border ? props.border : "",
				fontSize: props.size ? props.size : "",
				margin: props.margin ? props.margin : "",
				borderRadius: props.radius ? props.radius : 20,
				width: props.width ? props.width : "",
			}}
		>
			{props.title}
		</CustomButton>
	);
};

export default ThemeButton;
