import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  makeStyles,
} from "@material-ui/core";
import Styles from "../components/common/style";
import { useParams } from "react-router";
import { setWithExpiry, getWithExpiry } from "../asset/utility";
import OutlinedButton from "../components/common/outlinedButton";
import AskCogito from "../asset/AskCogito.svg";
import Talkus from "../asset/TalkUs.svg";
import WorkUs from "../asset/WorkUs.svg";
import Join from "../asset/join.svg";
import Arrow from "../asset/down_arrow.svg";
import Tick from "../asset/tick.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "70vw",
    [theme.breakpoints.down(950)]: {
      width: "80vw",
    },
    [theme.breakpoints.down(700)]: {
      width: "100vw",
    },
  },
  image: {
    paddingTop: "0.8rem",
    height: "10rem",
    opacity: "1",
    [theme.breakpoints.down(900)]: {
      height: "7rem",
    },
    [theme.breakpoints.down(550)]: {
      height: "5rem",
    },
    "&:hover": {
      opacity: "0.9",
    },
  },
}));
const ContactScreen = () => {
  const useClasses = Styles();
  const themed = useTheme();
  const classes = useStyles();
  const clas = useStyles();
  const paramRef = useParams().ref || null;
  const matches = useMediaQuery(themed.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      // window.open(signupLink, "_blank");
    } else {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      // window.open(signupLink, "_blank");
    }
  };

  const redirectToAbout = () => {
    window.location.pathname = "/about";
  };

  return (
    <div className="useClasses.root">
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={useClasses.contact}>
              {matches ? (
                <Grid item xs={12} sm={12}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={useClasses.regssmargin}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                      >
                        <Grid item>
                          <Box
                            style={{
                              marginTop: "14.22rem ",
                              marginBottom: "7.1rem",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "1.4rem",
                                fontFamily: "ARIAL",
                                fontWeight: "bold",
                                textAlign: "center",
                                color: "white",
                                letterSpacing: 2,
                                paddingBottom: "2rem",
                              }}
                            >
                              CONTACT
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Grid item className={useClasses.regssmargin}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                    >
                      <Grid item>
                        <Box
                          style={{
                            marginTop: "20rem",
                            marginBottom: "15rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Typography
                            variant="h2"
                            style={{
                              fontFamily: "ARIAL",
                              fontWeight: "bold",
                              textAlign: "center",
                              color: "white",
                              letterSpacing: 3,
                            }}
                          >
                            CONTACT
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: matches ? null : "4%", marginBottom: "4%" }}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="column">
              <Box className={useClasses.boxmid}>
                <Box id="detail">
                  <Container maxWidth={false} className={classes.container}>
                    <Box classses>
                      <img
                        src={AskCogito}
                        alt="askCogito"
                        className={classes.image}
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_COGITO_LINK}/faq`,
                            "_blank",
                          );
                        }}
                        style={{
                          paddingTop: "0.8rem",
                        }}
                      />
                    </Box>
                    <Box>
                      <img
                        src={Talkus}
                        alt="talkUs"
                        className={classes.image}
                        style={{
                          paddingTop: "0.8rem",
                        }}
                      />
                    </Box>
                    {/* <Box>
                                            <img
                                                src={WorkUs}
                                                alt="workus"
                                                height={matches ? "100rem" : "75%"}
                                                style={{
                                                    paddingTop: "0.8rem"
                                                }}
                                            />
                                        </Box> */}
                  </Container>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default ContactScreen;
