import React, { useState } from "react";
import "./Banner.css";

const Banner = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleBannerClose = () => {
    setIsVisible(false);
    // onClose();
  };

  return isVisible ? (
    <div className="banner">
      <p className="banner-message">
        If you are wanting to claim your cryptopia settlement offer,{" "}
        <a
          href="/cryptopiaform"
          target="_blank"
          style={{ color: "#ff7300", fontWeight: "bold" }}
        >
          Click here
        </a>
      </p>

      {/* <button className="banner-close-button" onClick={() => setIsVisible(false)}>
    Close
   </button> */}
    </div>
  ) : null;
};

export default Banner;
