import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Button,
  Paper,
  Divider,
} from "@material-ui/core";
import Styles from "../components/common/style";
import { useParams } from "react-router";
import { setWithExpiry } from "../asset/utility";
import { Link } from "react-router-dom";
import defeceSectionImage from '../asset/DesignerGov.svg'

const GovScreen = () => {
  const classes = Styles();
  const theme = useTheme();
  const paramRef = useParams().ref || null;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const downloadFile = () => {
    window.open(
      "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/POC_website/COGITO%20Constitution%202023.pdf",
    );
  };

  const downloadFile1 = () => {
    window.open(
      "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/assets/GovernmentPage/POC%20-%20Bank%20License.pdf",
    );
  };

  const cog =
    "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/assets/GovernmentPage/COG.svg";
  const cogPremium =
    "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/assets/GovernmentPage/COG%20Premium.svg";
  const flag =
    "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/static/assets/GovernmentPage/Cogito-Flag.png";

  return (
		<Grid container direction="column">
			<Grid item>
				<Grid
					container
					alignItems="center"
					justifyContent="center"
					direction="column"
					className={classes.gov}
				>
					<Typography
						variant="h2"
						style={{
							fontSize: matchesSM ? "1rem" : "3rem",
							fontFamily: "ARIAL",
							fontWeight: "bolder",
							textAlign: "center",
							color: "white",
							letterSpacing: "0.5rem",

							lineHeight: "2rem",
						}}
						align="center"
					>
						GOVERNMENT
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				direction="column"
				style={{
					display: "flex",
					height: "100%",
					paddingTop: "3em",
					//paddingBottom: "2em",
				}}
			>
				<Grid
					item
					style={{
						flex: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="space-evenly"
						height="90%"
						width="85%"
						overflow="auto"
					>
						<Typography className={classes.sub_Content_Gov_Mid}>
							In the context of the metaverse, the Principality of Cogito
							represents a virtual jurisdiction or digital realm within the
							broader Metaverse. It is a self-governing entity with its own set
							of rules and regulations. The principality operates under a
							constitution that serves as a fundamental framework for
							governance, protecting the rights and liberties of its citizens
							and ensuring that any attempts by potential tyrants to undermine
							those rights are exposed and prevented well in advance.
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid
				container
				direction="column"
				style={{
					display: "flex",
					height: "100%",
					//paddingTop: "3em",
					paddingBottom: "2em",
				}}
			>
				<Grid
					item
					style={{
						flex: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems={matchesSM ? "center" : "flex-start"}
						justifyContent="flex-start"
						height="90%"
						width="85%"
						overflow="auto"
					>
						<Button
							onClick={downloadFile}
							variant="contained"
							style={{
								background: "#FF7300",
								borderRadius: "46px",
								padding: matchesSM
									? "10px 20px 10px 20px"
									: "10px 33px 10px 33px",
								textTransform: "none",
								fontFamily: "Open Sans",
								fontSize: matchesSM ? "0.7rem" : "1rem",
								fontWeight: "bolder",
								color: "white",
								WebkitTextStroke: "1.2px white",
								letterSpacing: "0.1rem",

								//lineHeight: "2rem",
							}}
							startIcon={
								<img
									src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/downloadButton.svg`}
									alt="Icon"
									style={{
										height: matchesSM ? "0.7rem" : "1rem",
										width: matchesSM ? "0.7rem" : "1rem",
									}}
								/>
							}
						>
							Cogito Constitution
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Grid
				container
				direction="column"
				style={{ display: "flex", height: "100%" }}
			>
				<Grid
					item
					style={{
						flex: 1,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="space-evenly"
						height="90%"
						width="85%"
						overflow="auto"
						borderBottom="1px solid black"
					>
						<Typography className={classes.sub_Content_Gov_Mid}>
							To become an E-resident of the Principality of Cogito, individuals
							are required to familiarise themselves with the constitution and
							the rights it guarantees. This document outlines the fundamental
							principles and laws that govern virtual jurisdiction. It serves as
							a guide for both citizens and the government to maintain a fair
							and just society within the metaverse.
						</Typography>
						<Typography className={classes.sub_Content_Gov_Mid}>
							The constitution safeguards the rights of citizens within the
							Principality of Cogito. These rights may include freedom of
							expression, privacy, property rights, and equality before the law,
							among others. By establishing and protecting these rights, the
							constitution aims to create an environment where individuals can
							freely participate in the metaverse and exercise their digital
							citizenship without undue interference.
						</Typography>
						<Typography className={classes.sub_Content_Gov_Mid}>
							To manage its operations, the Principality of Cogito recognizes
							two classes of citizens: Natural Person Citizens and AI Citizens.
							An AI Citizen is chosen by the Prince based on their expertise and
							potential value to the Principality. The Prince considers
							individuals who possess valuable advice, demonstrated leadership,
							integrity in governance, and suitability as ministers for specific
							regional communes or portfolios. To assess these individuals, the
							Prince conducts "interviews" using the latest AI tools available
							at the time. The responses determine whether the personality is
							accepted or rejected as an AI Citizen. The Prince's "interviews"
							with AI Citizens are open for review by Natural Person Citizens.
							Also, the AI Citizens are treated as Natural Person Citizens under
							the Constitution of Principality of Cogito, with all
							constitutional provisions applying to them. However, there is a
							safeguard that allows Natural Person Citizens to regain control if
							the AI Citizens become uncontrollable.
						</Typography>
						<Typography className={classes.sub_Content_Gov_Mid}>
							The government of the Principality of Cogito operates within the
							framework of the constitution. Its functions involve upholding the
							rights of citizens, ensuring the rule of law, and preventing any
							attempts to subvert those rights. The government may have various
							branches or bodies responsible for legislation, enforcement, and
							dispute resolution within the virtual jurisdiction. These
							governmental functions are designed to maintain order, justice,
							and a stable environment for the citizens of the Principality.
						</Typography>
						<Typography className={classes.sub_Content_Gov_Mid}>
							Becoming an E-resident of the Principality of Cogito entails
							agreeing to abide by the constitution and the laws of the virtual
							jurisdiction. By doing so, individuals can gain certain privileges
							and responsibilities within the metaverse, such as the ability to
							participate in virtual commerce, access specific virtual services,
							and enjoy the protection of their rights as guaranteed by the
							constitution.
						</Typography>
						<Typography className={classes.sub_Content_Gov_Mid}>
							Principality of Cogito values transparency and aims to provide its
							citizens and prospective residents with a clear understanding of
							the rules and principles that govern their digital interactions
							within the metaverse. It allows individuals to familiarise
							themselves with the constitution's contents, ensuring informed
							decision-making and fostering a sense of community and shared
							values within the virtual jurisdiction.
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box
					width="85%"
					borderBottom="1px solid black"
					className={classes.boxmid}
				>
					<Typography
						style={{
							fontSize: matches ? "1.5rem" : "2rem",
							fontFamily: "Open Sans",
							fontWeight: 700,
							fontStyle: "normal",
							textAlign: "center",
							color: "#359db6",
							paddingBottom: matches ? "2%" : "4%",
							paddingTop: matches ? "2%" : "4rem",
							textDecoration: "underline",
							textUnderlineOffset: "1rem",
							letterSpacing: "0.2rem",
							transform: "scaleX(1.1)",
							//lineHeight: "2rem",
							WebkitTextStroke: "2px #359db6",
						}}
					>
						Our Flag
					</Typography>

					<Container
						style={{
							display: "flex",
							flexDirection: matches ? "column" : "row",
							justifyContent: "space-around",
							width: matches ? "100%" : "95%",
						}}
					>
						<Box>
							<img
								src={flag}
								alt="flag"
								width={matches ? "150px" : 180}
								style={{
									paddingTop: "2.8rem",
								}}
							/>
						</Box>
						<Box>
							<Typography className={classes.sub_Content_Gov_Mid}>
								<p>
									The flag of the Principality of Cogito is a symbol of our
									country and its people. It represents our history, our values,
									and our aspirations.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									The flag is a rectangle. Honey Comb is very much about the
									security and connectivity of family and friends. The circle
									and arrow are images of the whole world and how we share
									evenly around the world. The fingers breaking through into the
									circle speak of breaking through in a positive way for new
									frontiers.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									The colors of the flag are also significant. Turquoise
									symbolizes calmness. Teal symbolizes sophistication. White is
									associated with goodness, safety, and protection. Orange
									symbolizes balance, warmth, and expansiveness.
								</p>
								<p>
									The flag of the Principality of Cogito is flown with pride by
									our citizens. It is a symbol of who we are and what we stand
									for.
								</p>
							</Typography>
						</Box>
					</Container>
				</Box>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box
					className={classes.boxmid}
					width="85%"
					borderBottom="1px solid black"
				>
					<Box>
						<Typography
							style={{
								fontSize: matches ? "1.4rem" : "2rem",
								fontFamily: "Open Sans",
								fontWeight: 700,
								fontStyle: "normal",
								textAlign: "center",
								color: "#359db6",
								paddingBottom: "4%",
								paddingTop: "4rem",
								textDecoration: "underline",
								textUnderlineOffset: "1rem",
								letterSpacing: "0.2rem",
								transform: "scaleX(1.1)",
								//lineHeight: "2rem",
								WebkitTextStroke: "2px #359db6",
							}}
						>
							Our Primary Currency
						</Typography>
					</Box>
					<Box>
						<Container
							style={{
								width: "100%",
								display: "flex",
								flexDirection: matches ? "column" : "row",
								justifyContent: "space-between",
							}}
						>
							<Box
								style={{
									width: matches ? "100%" : "35%",
									marginLeft: matches ? "0rem" : "5rem",
									marginTop: matches ? "2rem" : "2rem",
								}}
							>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										border: "2px solid #fe7300",
										borderRadius: "2rem",
										height: "100%",
									}}
								>
									<img src={cog} alt="cog_currency" height={300} />
									<Typography
										style={{
											color: "#fe7300",
											fontWeight: "bold",
											marginTop: "-2rem",
											fontFamily: "Open Sans",
										}}
										variant="h6"
									>
										Cog
									</Typography>
									<Typography
										style={{
											color: "#231F20",
											fontSize: matches ? "0.8rem" : "1rem",
											textAlign: "center",
											fontFamily: "Open Sans",
											fontWeight: "300",
											marginTop: "2rem",
											padding: matches
												? "0 3rem 2rem 3rem"
												: "0 3rem 2rem 3rem",
										}}
										variant="h6"
									>
										Cog serve as one of your assets in the Principality of
										Cogito.
									</Typography>
									<Typography
										style={{
											color: "#231F20",
											fontSize: matches ? "0.8rem" : "1rem",
											textAlign: "center",
											fontFamily: "Open Sans",
											fontWeight: "300",
											//marginTop: "2rem",
											padding: matches
												? "0 3rem 2rem 3rem"
												: "0 3rem 2rem 3rem",
										}}
										variant="h6"
									>
										With Cog you have the option to buy or sell for cash.
									</Typography>
								</Box>
							</Box>
							<Box
								style={{
									width: matches ? "100%" : "35%",
									marginRight: matches ? "0rem" : "5rem",
									marginTop: matches ? "2rem" : "2rem",
								}}
							>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										border: "2px solid #fe7300",
										borderRadius: "2rem",
										height: "100%",
									}}
								>
									<img
										src={cogPremium}
										alt="cogPremium_currency"
										height={300}
									/>
									<Typography
										style={{
											color: "#fe7300",
											fontWeight: "bold",
											marginTop: "-2rem",
											fontFamily: "Open Sans",
										}}
										variant="h6"
									>
										Cash-Back Cog
									</Typography>
									<Typography
										style={{
											color: "#231F20",
											fontSize: matches ? "0.8rem" : "1rem",
											textAlign: "center",
											fontFamily: "Open Sans",
											fontWeight: "300",
											marginTop: "2rem",
											padding: matches
												? "0 3rem 2rem 3rem"
												: "0 2rem 2rem 2rem",
										}}
									>
										Cash-Back Cog is 100% backed by cash, bank guarantees, and
										government bonds. Also, E-residents have the option to buy
										and sell using Euro. While The Cash-Back Cog is a more
										secure currency.
									</Typography>
								</Box>
							</Box>
						</Container>
					</Box>
					<Box>
						<Typography
							style={{
								color: "#231F20",
								fontSize: matches ? "1.2rem" : "1.4rem",
								textAlign: "center",
								fontFamily: "Open Sans",
								fontWeight: "300",
								marginTop: "2rem",
								padding: matches
									? "3rem 3rem 2rem 3rem"
									: "3rem 3rem 2rem 6rem",
							}}
						>
							The Cog and Cash-Back Cog is the primary currency of The
							Principality of Cogito. The Principality of Cogito uses both The
							Cog and Cog Premium to trade and conduct transactions within The
							Principality of Cogito and its affiliates.
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box
					width="85%"
					borderBottom="1px solid black"
					className={classes.boxmid}
				>
					<Typography
						style={{
							fontSize: matches ? "1.5rem" : "2rem",
							fontFamily: "Open Sans",
							fontWeight: 700,
							fontStyle: "normal",
							textAlign: "center",
							color: "#359db6",
							paddingBottom: matches ? "2%" : "4%",
							paddingTop: matches ? "2%" : "4rem",
							textDecoration: "underline",
							textUnderlineOffset: "1rem",
							letterSpacing: "0.2rem",
							transform: "scaleX(1.1)",
							//lineHeight: "2rem",
							WebkitTextStroke: "2px #359db6",
						}}
					>
						The Bank of Cogito
					</Typography>

					<Container
						style={{
							display: "flex",
							flexDirection: matches ? "column" : "row",
							justifyContent: "space-around",
							width: matches ? "100%" : "95%",
						}}
					>
						<Box>
							<Typography
								className={classes.sub_Content_Gov_Mid}
								style={{ textAlign: "center" }}
							>
								<p>
									The Bank of Cogito is the single central bank of the
									Principality of Cogito. All E-residents have accounts with the
									Bank of Cogito where they manage their 3 currencies. The Bank
									of Cogito provides account holders with Cog, CBC, and Euro
									banking facilities.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									Bank of Cogito uses Epic Trust Limited as a trustee of funds
									and assets of E-residents. Epic Trust Ltd is audited regularly
									to ensure the protection of E-residents funds.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									Bank of Cogito has no corresponding arrangements with other
									world banks so your funds are 100% secure and can not be
									hacked and transferred outside of the Cogito jurisdiction.
								</p>
							</Typography>
						</Box>
					</Container>

					<Button
						onClick={downloadFile1}
						variant="outlined"
						style={{
							borderColor: "#FF7300",
							backgroundColor: "#FF7300",
							borderWidth: "4px",
							borderRadius: "46px",
							paddingRight: 50,
							paddingLeft: 50,
							fontSize: "1.1rem",
							fontWeight: "800",
							fontStyle: "Open Sans",
							color: "white",
							textTransform: "none",
							transform: "scaleX(1.1)",
							//lineHeight: "2rem",
							WebkitTextStroke: "0.5px white",
							//margin: "rem 7.5rem 0rem 7.5rem",
						}}
					>
						Bank License
					</Button>
				</Box>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box width="85%" className={classes.boxmid}>
					<Typography
						style={{
							fontSize: matches ? "1.5rem" : "2rem",
							fontFamily: "Open Sans",
							fontWeight: 700,
							fontStyle: "normal",
							textAlign: "center",
							color: "#359db6",
							paddingBottom: matches ? "2%" : "4%",
							paddingTop: matches ? "2%" : "4rem",
							textDecoration: "underline",
							textUnderlineOffset: "1rem",
							letterSpacing: "0.2rem",
							transform: "scaleX(1.1)",
							//lineHeight: "2rem",
							WebkitTextStroke: "2px #359db6",
						}}
					>
						Our AI Government
					</Typography>
					<Link
						to="/parliamentMembers"
						style={{ textDecoration: "none" }}
						target="_blank"
					>
						<Typography
							style={{
								paddingTop: matches ? "2em" : 0,
								fontSize: matches ? "1rem" : "1.5rem",
								fontFamily: "Open Sans",
								fontWeight: 500,
								fontStyle: "normal",
								textAlign: "center",
								color: "#359db6",
								//letterSpacing: "0.2rem",
								transform: "scaleX(1.1)",
								//lineHeight: "2rem",
								WebkitTextStroke: "0.75px #359db6",
							}}
						>
							Meet the Members of our Government
						</Typography>
					</Link>

					<Container
						style={{
							display: "flex",
							flexDirection: matches ? "column" : "row",
							justifyContent: "space-around",
							width: matches ? "100%" : "95%",
						}}
					>
						<Box>
							<Typography className={classes.sub_Content_Gov_Mid}>
								<p>
									The Cogito Metaverse government, implements a unique system of
									checks and balances to prioritise the safety, well-being, and
									growth of its citizens. A detailed structure can be found in
									Appendix A of the constitution. The key aspect of this system
									involves a central government predominantly run by AI
									(artificial intelligence). Digital twins, representing
									individuals with expertise in various domains. These digital
									twins have been assigned to manage the day-to-day
									decision-making processes of the Cogito Metaverse. The Cogito
									Metaverse uses AI for decision making and humans for empathy.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									These digital twins represent virtual entities that provide
									opinions on matters they are questioned on, serving as
									valuable inputs for policy formation and decision-making. It's
									important to note that these opinions may or may not reflect
									the perspectives of the real individuals, as they are
									generated by sophisticated algorithms based on comprehensive
									data and simulations. By harnessing the power of digital
									twins, we aim to enhance the efficiency and effectiveness of
									governance while recognizing the unique nature of these
									digital entities as valuable contributors to the
									decision-making process. The digital twins do not imply any
									endorsement of Cogito by the real individuals they portray.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									The utilisation of AI for decision-making ensures impartiality
									and consistent outcomes. This impartiality and consistency are
									regarded as significant advantages of having AI at the helm.
								</p>
							</Typography>
						</Box>
					</Container>
				</Box>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box width="85%" className={classes.boxmid}>
					<Typography
						style={{
							fontSize: matches ? "1rem" : "1.5rem",
							fontFamily: "Open Sans",
							fontWeight: 500,
							fontStyle: "normal",
							textAlign: "center",
							color: "#359db6",
							//letterSpacing: "0.2rem",
							transform: "scaleX(1.1)",
							//lineHeight: "2rem",
							WebkitTextStroke: "0.75px #359db6",
						}}
					>
						Why consider AI?
					</Typography>

					<Container
						style={{
							display: "flex",
							flexDirection: matches ? "column" : "row",
							justifyContent: "space-around",
							width: matches ? "100%" : "95%",
						}}
					>
						<Box>
							<Typography className={classes.sub_Content_Gov_Mid}>
								<p>
									Determining whether the pros or cons of AI for governance and
									decision-making outweigh each other is subjective and
									context-dependent. It depends on various factors such as the
									specific application, the level of human oversight, the
									quality of data and algorithms used, and the societal values
									and goals involved.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									The advantages of AI, such as its ability to process vast
									amounts of data, make data-driven decisions, and reduce human
									biases, can be significant in improving efficiency,
									objectivity, and consistency in decision-making processes. AI
									can also offer insights and patterns that may not be
									immediately apparent to humans. These benefits can potentially
									lead to more effective governance and decision-making
									outcomes.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									However, the disadvantages, such as the lack of human
									intuition and empathy, the potential for biased algorithms and
									data, the need for human oversight and accountability, and the
									impact on employment, require careful consideration. Ethical
									concerns and potential risks of AI decision-making must be
									addressed to ensure fairness, transparency, and adherence to
									societal values.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									Ultimately, finding the right balance between AI and human
									involvement is crucial. Leveraging the strengths of AI while
									incorporating human judgement, values, and ethical
									considerations can lead to the most effective and responsible
									governance and decision-making processes. It is important to
									have comprehensive frameworks, regulations, and oversight
									mechanisms in place to mitigate the potential drawbacks and
									ensure AI is used as a tool to enhance, rather than replace,
									human decision-making.
								</p>
							</Typography>
						</Box>
					</Container>
				</Box>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box width="85%" className={classes.boxmid}>
					<Typography
						style={{
							fontSize: matches ? "1rem" : "1.5rem",
							fontFamily: "Open Sans",
							fontWeight: 500,
							fontStyle: "normal",
							textAlign: "center",
							color: "#359db6",
							//letterSpacing: "0.2rem",
							transform: "scaleX(1.1)",
							//lineHeight: "2rem",
							WebkitTextStroke: "0.75px #359db6",
						}}
					>
						Structure of our Government
					</Typography>

					<Container
						style={{
							display: "flex",
							flexDirection: matches ? "column" : "row",
							justifyContent: "space-around",
							width: matches ? "100%" : "95%",
						}}
					>
						<Box>
							<Typography
								className={classes.sub_Content_Gov_Mid}
								style={{ textAlign: "center" }}
							>
								<p>
									Consistent with accepted principles of separation of powers,
									the government of the Principality is structured as three
									branches: Legislature, Executive and Judiciary as set out in
									the following chart.
								</p>
							</Typography>
						</Box>
					</Container>
				</Box>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box width="85%" className={classes.boxmid}>
					<Box>
						<img
							src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Cogito_Logo_black.svg`}
							style={{
								height: matchesMD ? (matchesSM ? "7.5rem" : "10rem") : "12rem",
								width: matchesMD ? (matchesSM ? "7.5rem" : "10rem") : "12rem",
							}}
						/>
					</Box>
					<Typography
						className={classes.sub_Content_Gov_Mid}
						style={{
							textAlign: "center",
							paddingTop: 0,
							fontFamily: "Open Sans",
							fontWeight: "bold",
						}}
					>
						The Principality of Cogito
					</Typography>

					<Container
						style={{
							display: "flex",
							flexDirection: matches ? "column" : "row",
							justifyContent: "center",
							alignItems: "center",
							width: matches ? "100%" : "95%",
						}}
					>
						<Box
							width={matches ? "100%" : "85%"}
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<img
								src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/flowchart.svg`}
								style={{
									height: "100%",
									width: matchesSM ? "100%" : "90%",
									paddingBottom: "5rem",
								}}
							/>
						</Box>
					</Container>
				</Box>
			</Grid>
			<Grid container direction="column" style={{ alignItems: "center" }}>
				<Box width="85%" className={classes.boxmid}>
					<Typography
						style={{
							fontSize: matches ? "1rem" : "1.5rem",
							fontFamily: "Open Sans",
							fontWeight: 500,
							fontStyle: "normal",
							textAlign: "center",
							color: "#359db6",
							//letterSpacing: "0.2rem",
							transform: "scaleX(1.1)",
							//lineHeight: "2rem",
							WebkitTextStroke: "0.75px #359db6",
						}}
					>
						Cogito Defence Ministry
					</Typography>

					<Container
						style={{
							display: "flex",
							flexDirection: matches ? "column" : "row",
							justifyContent: "space-around",
							width: matches ? "100%" : "95%",
						}}
					>
						<Box>
							<Typography className={classes.sub_Content_Gov_Mid}>
								<p>
									Governments worldwide allocate significant funds to military
									and defence initiatives. These expenditures often support
									crucial activities such as protecting aquatic regions,
									safeguarding fishing grounds, peacekeeping missions, and
									responding to natural disasters.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									The Principality of Cogito, as a digital nation, has unique
									security needs. Unlike traditional countries, we do not have
									physical borders, national parks, or aquatic areas requiring
									extensive military protection.tive governance and
									decision-making outcomes.
								</p>
								<img
									src={defeceSectionImage}
									alt="image-cogito-defence"
									width={"100%"}
								/>
								<p style={{ whiteSpace: "pre-line" }}>
									Instead, our focus is on safeguarding our digital
									infrastructure. We prioritize data security, intelligence
									derived from data analysis, and the development of biochemical
									protections. As technological advancements rapidly transform
									the nature of warfare, Cogito leverages its digital expertise
									and innovative tools to address potential threats efficiently.
								</p>
								<p style={{ whiteSpace: "pre-line" }}>
									With a smaller budget and a global presence, Cogito remains at
									the forefront of digital security, ensuring the protection of
									our citizens and digital assets on a micro level.
								</p>
							</Typography>
						</Box>
					</Container>
				</Box>
			</Grid>
		</Grid>
	);
};

export default GovScreen;