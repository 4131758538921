import { React, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { getWithExpiry } from "../asset/utility";

function RegistrationPage() {
  const storedRef = getWithExpiry("ref") || null;
  useEffect(() => {
    if (storedRef) {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_URL_SIGNUP}`;
      }, 3000);
    }
  });
  return (
    <div>
      <Grid container></Grid>

      <Divider variant="middle" />

      <Grid
        container
        justifyContent="center"
        className="registrationcontainer-txt"
      >
        <Grid item className="colorGrey">
          <Typography variant="h5">
            You are being securely Re-directed to our app...
            <br />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default RegistrationPage;
