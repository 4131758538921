import { React, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { getWithExpiry } from "../asset/utility";

function LoginPage() {
  useEffect(() => {
    const storedRef = getWithExpiry("ref") || null;
    if (storedRef) {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_URL_LOGIN}/${storedRef}`;
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_URL_LOGIN}`;
      }, 3000);
    }
  });
  return (
    <div>
      <Grid container></Grid>

      <Divider variant="middle" />

      <Grid
        container
        justifyContent="center"
        className="registrationcontainer-txt"
      >
        <Grid item className="colorGrey">
          <Typography variant="h5">
            You are being securely Re-directed to our app...
            <br />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginPage;
