import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Styles from "../components/common/style";
import { useParams } from "react-router";
import { setWithExpiry, getWithExpiry } from "../asset/utility";
import OutlinedButton from "../components/common/outlinedButton";
import buildWealth from "../asset/buildWealth.svg";
import financial from "../asset/financial.png";
import Rectangle2 from "../asset/Rectangle (2).svg";
import Cogito_5 from "../asset/Cogito_5.svg";
import Arrow from "../asset/down_arrow.svg";
import { red } from "@material-ui/core/colors";

const AboutScreen = () => {
  const classes = Styles();
  const theme = useTheme();
  const paramRef = useParams().ref || null;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      // window.open(signupLink, "_blank");
    } else {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      // window.open(signupLink, "_blank");
    }
  };

  const redirectToAbout = () => {
    window.location.pathname = "/about";
  };

  return (
    <div className="classes.root">
      <Grid container direction="column">
        <Grid item xs={12} sm={12}>
          <Grid container direction="column">
            <Box className={classes.about}>
              {matches ? (
                <Grid item xs={12} sm={12}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item className={classes.regssmargin}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                      >
                        <Grid item>
                          <Box style={{ marginTop: "10rem" }}>
                            <Typography
                              style={{
                                fontSize: "1.4rem",
                                fontFamily: "ARIAL",
                                fontWeight: "bold",
                                textAlign: "center",
                                color: "white",
                                letterSpacing: 2,
                                paddingBottom: "6em",
                                paddingTop: "3rem",
                              }}
                            >
                              THE PRINCIPALITY
                              <br />
                              OF COGITO
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Grid item className={classes.regssmargin}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-around"
                    >
                      <Grid item>
                        <Box
                          style={{ marginTop: "17rem", marginBottom: "8.4rem" }}
                        >
                          <Typography
                            variant="h2"
                            style={{
                              fontFamily: "ARIAL",
                              fontWeight: "bold",
                              textAlign: "center",
                              color: "white",
                              letterSpacing: 3,
                            }}
                          >
                            THE PRINCIPALITY
                            <br />
                            OF COGITO
                          </Typography>

                          <Box
                            id="detail"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "9rem",
                            }}
                          >
                            <a
                              href="#detail"
                              style={{ textDecoration: "none" }}
                            >
                              <Box
                                style={{
                                  fontStyle: "Arial",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  color: "#ff7300",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                FIND OUT MORE
                                <img
                                  src={Arrow}
                                  alt="arrow"
                                  height={22}
                                  style={{
                                    paddingTop: "0.8rem",
                                  }}
                                />
                              </Box>
                            </a>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box style={{ marginTop: "3%", marginBottom: "0%" }}>
          <Grid item xs={12} sm={12}>
            {matches ? (
              <>
                <Grid container direction="column">
                  <Box className={classes.boxmid}>
                    <Box>
                      <Container>
                        <Typography className={classes.sub_Content}>
                          Cogito is revolutionary. A principality that breaks
                          traditional borders and is open to e-residents from
                          every country in the world. All human beings can
                          apply, no age restrictions and no country
                          restrictions.
                        </Typography>
                        <Typography className={classes.sub_Content}>
                          Our vision is that e-residents would support each
                          other, to provide freedom of trade, interest-free
                          finance, simple and low tax rates, education, and the
                          ability to start investing. As an e-resident, we would
                          encourage you to establish companies and asset
                          protection trusts in the Principality of Cogito to
                          protect your hard earned wealth.
                        </Typography>
                        <Typography className={classes.sub_Content}>
                          Principality of Cogito allows you to:
                        </Typography>
                      </Container>
                    </Box>

                    <Grid container direction="row">
                      <Grid item xs={5} sm={4}>
                        <Box>
                          <img
                            src={buildWealth}
                            alt="build-wealth"
                            height={80}
                            style={{ marginTop: "1.5rem" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={7}>
                        <Box>
                          <Box>
                            <Typography className={classes.midBoxtypohead}>
                              Build Wealth
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.midBoxtyposub}>
                              The Principality of Cogito brings our global
                              market together in the Cogito Metaverse, and in
                              doing so breaks down boundaries and minimises
                              local government control of finances. As a Cogito
                              e-resident, you build wealth in a way that
                              traditional banks and investment companies can not
                              provide. You will eventually have access to a
                              growing market of fractional investments, enabling
                              small to large investments with a higher level of
                              security and transparency. This will give you the
                              ability to build wealth in a new way.
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={5} sm={4}>
                        <Box style={{ alignItems: "center" }}>
                          <img
                            src={financial}
                            alt="flexibility"
                            height={100}
                            width={100}
                            style={{ marginTop: "1.4rem" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={7}>
                        <Box>
                          <Box>
                            <Typography className={classes.midBoxtypohead}>
                              Financial Flexibility
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.midBoxtyposub}>
                              Cogito is the natural evolution to global digital
                              currency where everyone is treated as equals.
                              Cogito protects you from the financial limitations
                              your government may place on citizens by not being
                              influenced by exchange rates, by being taxed
                              fairly, and by having an open and transparent
                              global marketplace.
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={5} sm={4}>
                        <Box>
                          <img
                            src={Rectangle2}
                            alt="security"
                            height={80}
                            style={{ marginTop: "1.4rem" }}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={7}>
                        <Box>
                          <Box>
                            <Typography className={classes.midBoxtypohead}>
                              Security & Privacy
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.midBoxtyposub}>
                              The Cogito Metaverse is built with the type of
                              cutting edge security that you would expect from
                              any financial institution around the world. In
                              addition to this, the design of the Cogito
                              Metaverse means that someone from outside the
                              Cogito Metaverse cannot hack in and steal Cog and
                              remove them from the Metaverse. This ensures there
                              is a digital trail for any one attempting
                              fraudulent behaviour.
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xs={5} sm={4}>
                        <Box>
                          <img
                            src={Cogito_5}
                            alt="HowToJoin"
                            height={150}
                            style={{ marginTop: "-8%" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={7}>
                        <Box>
                          <Box>
                            <Typography className={classes.midBoxtypohead}>
                              How to join
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={classes.midBoxtyposub}>
                              We have streamlined the process for you to gain
                              access to the benefits of the Cogito Metaverse.
                              From the first step of registering your details –
                              you are guided by our team of specialists to get
                              your e-residency. A Cogito e-resident is able to
                              access corporate company structure which gives
                              them security of assets and tax management
                              control. By securing your business in the Cogito
                              Metaverse, you access a safe and secure repository
                              for both private and commercial operations.
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box style={{ padding: "2rem 0 6rem 0" }}>
                      <Link
                        to="/cogito-metaverse"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <OutlinedButton
                          title="FIND OUT MORE"
                          size={10}
                          height={35}
                          // onClick={handleClickSingUp}
                          width={150}
                          border={2}
                        />
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Box direction="column" className={classes.boxmid}>
                  <Box style={{ paddingBottom: "5rem" }}>
                    <Container maxWidth={false}>
                      <Typography className={classes.sub_Content}>
                        Cogito is revolutionary. A principality that breaks
                        traditional borders and is open to e-residents from
                        every country in the world. All human beings can apply,
                        no age restrictions and no country restrictions.
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        Our vision is that e-residents would support each other,
                        to provide freedom of trade, interest-free finance,
                        simple and low tax rates, education, and the ability to
                        start investing. As an e-resident, we would encourage
                        you to establish companies and asset protection trusts
                        in the Principality of Cogito to protect your hard
                        earned wealth.
                      </Typography>
                      <Typography className={classes.sub_Content}>
                        Principality of Cogito allows you to:
                      </Typography>
                    </Container>
                  </Box>
                  <Grid container direction="row">
                    <Grid item xs={5} sm={4}>
                      <Box className={classes.midBoximg}>
                        <img
                          src={buildWealth}
                          alt="build-wealth"
                          height={150}
                          style={{ marginTop: "4%" }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={7}>
                      <Box>
                        <Box>
                          <Typography className={classes.midBoxtypohead}>
                            Build Wealth
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.midBoxtyposub}>
                            The Principality of Cogito brings our global market
                            together in the Cogito Metaverse, and in doing so
                            breaks down boundaries and minimises local
                            government control of finances. As a Cogito
                            e-resident, you build wealth in a way that
                            traditional banks and investment companies can not
                            provide. You will eventually have access to a
                            growing market of fractional investments, enabling
                            small to large investments with a higher level of
                            security and transparency. This will give you the
                            ability to build wealth in a new way.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={5} sm={4}>
                      <Box
                        className={classes.midBoximg}
                        style={{ alignItems: "center" }}
                      >
                        <img
                          src={financial}
                          alt="flexibility"
                          height={150}
                          width={150}
                          style={{ marginTop: "2%" }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={7}>
                      <Box>
                        <Box>
                          <Typography className={classes.midBoxtypohead}>
                            Financial Flexibility
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.midBoxtyposub}>
                            Cogito is the natural evolution to global digital
                            currency where everyone is treated as equals. Cogito
                            protects you from the financial limitations your
                            government may place on citizens by not being
                            influenced by exchange rates, by being taxed fairly,
                            and by having an open and transparent global
                            marketplace.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={5} sm={4}>
                      <Box className={classes.midBoximg}>
                        <img
                          src={Rectangle2}
                          alt="security"
                          height={150}
                          style={{ marginTop: "4%" }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={7}>
                      <Box>
                        <Box>
                          <Typography className={classes.midBoxtypohead}>
                            Security & Privacy
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.midBoxtyposub}>
                            The Cogito Metaverse is built with the type of
                            cutting edge security that you would expect from any
                            financial institution around the world. In addition
                            to this, the design of the Cogito Metaverse means
                            that someone from outside the Cogito Metaverse
                            cannot hack in and steal Cog and remove them from
                            the Metaverse. This ensures there is a digital trail
                            for any one attempting fraudulent behaviour.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={5} sm={4}>
                      <Box className={classes.midBoximg}>
                        <img
                          src={Cogito_5}
                          alt="HowToJoin"
                          height={240}
                          style={{ marginTop: "-8%" }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={6} sm={7}>
                      <Box>
                        <Box>
                          <Typography className={classes.midBoxtypohead}>
                            How to join
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.midBoxtyposub}>
                            We have streamlined the process for you to gain
                            access to the benefits of the Cogito Metaverse. From
                            the first step of registering your details – you are
                            guided by our team of specialists to get your
                            e-residency. A Cogito e-resident is able to access
                            corporate company structure which gives them
                            security of assets and tax management control. By
                            securing your business in the Cogito Metaverse, you
                            access a safe and secure repository for both private
                            and commercial operations.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box style={{ padding: "2rem 0 2rem 0" }}>
                    <Link
                      to="cogito-metaverse"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <OutlinedButton
                        title="FIND OUT MORE"
                        size={16}
                        // onClick={handleClickSingUp}
                        width={250}
                        border={2}
                        style={{
                          background: "#0000",
                        }}
                      />
                    </Link>
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default AboutScreen;
