import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Styles from "../components/common/style";
import { useParams } from "react-router";
import { setWithExpiry, getWithExpiry } from "../asset/utility";
import Arrow from "../asset/down_arrow.svg";
import Tick from "../asset/tick.svg";
import { makeStyles } from "@material-ui/core/styles";

const EducationScreen = () => {
  // const classes = Styles();

  const useStyles = makeStyles((theme) => ({
    background: {
      position: "relative",
      background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/education_background.jpg)`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "97vh",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        background: `url(${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/education_background.jpg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      },
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        zIndex: 1,
      },
      "& > *": {
        position: "relative",
        zIndex: 2,
      },
    },
    missionStatement: {
      textAlign: "left",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "1.5rem",
      lineHeight: 1.4,
      color: "#231f20",
      fontFamily: "Open Sans",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
      wordWrap: "break-word",
      fontSize: "1.3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "200rem",
        padding: "0rem 1rem 0rem 1 rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.9rem",
        padding: "0rem 1rem 0rem 1 rem",
      },
    },
    rowContainer: {
      backgroundColor: "#263238",
      justifyContent: "flex-start",
      paddingLeft: "5em",
      paddingRight: "5em",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "1.5em",
        paddingRight: "1.5em",
      },
    },
    checkBoxContainer: {
      paddingLeft: "5em",
      paddingRight: "5em",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "2em",
        paddingRight: "2em",
      },
    },
    pointtext: {
      justifyContent: "center",
      alignItems: "left",
      overflow: "hidden",
      padding: "2rem 5rem 2rem 5rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem ",
      },
    },
    boxpointtext: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "left",
      justifyContent: "flex-start",
      margin: "1rem 0rem 1rem 0rem",
    },
    mobileBox: {
      color: "#359DB6",
      fontFamily: "Arial",
      fontSize: "1.5rem",
      fontWeight: "bold",
      textAlign: "left",
      [theme.breakpoints.down("650")]: {
        fontSize: "0.9rem",
      },
      [theme.breakpoints.down("411")]: {
        fontSize: "0.7rem",
      },
    },
    overlay: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      zIndex: 1,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const paramRef = useParams().ref || null;
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (paramRef) {
      setWithExpiry("ref", paramRef, 24 * 7);
    }
  }, [paramRef]);

  const handleClickSingUp = () => {
    const storedRef = getWithExpiry("ref") || null;

    if (storedRef) {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}/${storedRef}`;
      // window.open(signupLink, "_blank");
    } else {
      // let signupLink = `${process.env.REACT_APP_URL_SIGNUP}`;
      // window.open(signupLink, "_blank");
    }
  };

  const redirectToAbout = () => {
    window.location.pathname = "/about";
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent={matchesSM ? "space-evenly" : "space-evenly"}
        className={classes.background}
        direction={matchesSM ? "column" : "column"}
      >
        <div className={classes.overlay}></div>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
          height="70%"
          width="100%"
          style={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <Typography
            variant="h2"
            style={{
              fontFamily: "ARIAL",
              fontWeight: "700",
              textAlign: "center",
              color: "#5CBFD7",
              letterSpacing: 3,
              fontSize: matchesMD ? (matchesSM ? "2.5rem" : "3rem") : "3.3rem",
              textShadow: "3px 3px 0 white",
              WebkitTextStroke: "2px black",
              textStroke: "2px black",
            }}
          >
            EDUCATION
          </Typography>
          <Box
            id="detail"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/VrImage.svg`}
              alt="VR Image"
              style={{
                height: matchesMD
                  ? matchesSM
                    ? "12.5rem"
                    : "16rem"
                  : "17.5rem",
                width: matchesMD
                  ? matchesSM
                    ? "12.5rem"
                    : "16rem"
                  : "17.5rem",
              }}
            />
          </Box>
          <Box
            id="detail"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a href="#detail" style={{ textDecoration: "none" }}>
              <Box
                style={{
                  fontStyle: "Arial",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  color: "#ff7300",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                FIND OUT MORE
                <img
                  src={Arrow}
                  alt="build-wealth"
                  height={22}
                  style={{
                    paddingTop: "0.8rem",
                  }}
                />
              </Box>
            </a>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        direction={matchesMD ? "column" : "row"}
        alignItems="center"
        className={classes.rowContainer}
        style={{
          paddingTop: "3em",
          background: "#263238",
        }}
      >
        <Grid item container direction="column" lg>
          <Grid item>
            <Typography
              align={matchesMD ? "left" : "left"}
              variant="h4"
              gutterBottom
              style={{
                fontFamily: "Open Sans",
                color: "#FFFFFF",
                fontSize: matchesMD ? "2rem" : "3rem",
                fontWeight: "700",
              }}
            >
              Introducing CHILL....
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              align={matchesMD ? "left" : "left"}
              paragraph
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "#FFFFFF",
                fontSize: matchesSM ? "1rem" : "1.5rem",
              }}
            >
              The Cogito Metaverse will provide people of all nationalities and
              global locations with the ability to engage in immersive learning
              and virtual reality. With this in mind, the Cogito Metaverse has
              created{" "}
              <span style={{ color: "#359DB6" }}>
                CHILL, Cogito Metaverse Human Immersive Learning Labs
              </span>
              . CHILL  is an opportunity for Libraries, Art Galleries, Community
              Centres and schools to access an education portal providing both
              web and virtual reality opportunities. The first stage of the roll
              out will be in public libraries and museums, with future planning
              to expand in other areas.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent={matchesMD ? "center" : "flex-end"}
          lg
          style={{ margin: "2em 0em 2em 0em" }}
        >
          <img
            src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/VR_Lady.svg`}
            alt="VR Lady"
            // style={{ maxHeight: matchesMD ? 200 : "22em" }}
            style={{
              maxWidth: "40em",
              margin: 0,
              width: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              height: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              padding: "2em 2em 2em 2em",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
        style={{ paddingTop: "3em", paddingBottom: "3em" }}
      >
        <Typography
          variant="body1"
          align={matchesMD ? "left" : "left"}
          paragraph
          style={{
            fontFamily: "Open Sans",
            fontWeight: "400",
            color: "#FFFFFF",
            fontSize: matchesSM ? "1rem" : "1.5rem",
          }}
        >
          CHILL offers the chance to anyone using the resource to gain
          knowledge, understanding and develop critical thinking skills. The
          creators of the  Principality of Cogito believes that the benefits of
          this type of learning include:
        </Typography>
        <ol style={{ paddingLeft: "1em", color: "white" }}>
          <li>
            <Typography
              variant="body1"
              align={matchesMD ? "left" : "left"}
              paragraph
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "#FFFFFF",
                fontSize: matchesSM ? "1rem" : "1.5rem",
              }}
            >
              The ability for users to practise and develop real-world skills
              within simulated environments. Whether it's honing professional
              skills, mastering complex procedures, or exploring entrepreneurial
              ventures, learners can engage in hands-on experiences that closely
              resemble real-life scenarios. This practical application enhances
              skill development and prepares individuals for future challenges.
            </Typography>
          </li>
          <li>
            <Typography
              variant="body1"
              align={matchesMD ? "left" : "left"}
              paragraph
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "#FFFFFF",
                fontSize: matchesSM ? "1rem" : "1.5rem",
              }}
            >
              Accessibility for educational resources and experiences regardless
              of their physical location. This accessibility breaks down
              barriers and ensures that everyone, regardless of geographical or
              socioeconomic limitations, has access to high-quality educational
              content.
            </Typography>
          </li>
        </ol>
      </Grid>
      <Grid
        item
        container
        direction={matchesMD ? "column" : "row"}
        alignItems="center"
        className={classes.rowContainer}
        style={{
          paddingTop: "3em",
          background: "#E2F5FA",
          paddingBottom: "3em",
        }}
      >
        <Grid
          item
          container
          justifyContent={matchesMD ? "center" : "flex-start"}
          lg
          style={{ margin: "2em 0em 2em 0em" }}
        >
          <img
            src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/VRKid.svg`}
            alt="VR Kid"
            // style={{ maxHeight: matchesMD ? 200 : "22em" }}
            style={{
              maxWidth: "40em",
              margin: 0,
              width: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              height: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              padding: "2em 2em 2em 2em",
            }}
          />
        </Grid>
        <Grid item container direction="column" lg style={{ maxWidth: "40em" }}>
          <Grid item>
            <Typography
              variant="body1"
              align={matchesMD ? "left" : "left"}
              paragraph
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "#231F20",
                fontSize: matchesSM ? "1rem" : "1.5rem",
              }}
            >
              By working within existing organisations, the Cogito Metaverse has
              the ability to foster global collaboration and networking
              opportunities. Virtual reality enables learners from different
              parts of the world to connect and collaborate in shared immersive
              environments. By engaging in collaborative projects, discussions,
              and simulations, users can broaden their perspectives, enhance
              cross-cultural understanding, and build valuable connections with
              individuals from diverse backgrounds.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
        style={{ paddingTop: "3em" }}
      >
        <Typography
          variant="body1"
          align={matchesMD ? "left" : "left"}
          paragraph
          style={{
            fontFamily: "Open Sans",
            fontWeight: "400",
            color: "#FFFFFF",
            fontSize: matchesSM ? "1rem" : "1.5rem",
          }}
        >
          Within the Cogito Metaverse, immersive learning and virtual reality
          can facilitate entrepreneurial and business development. Users can
          create and test virtual prototypes, simulate market scenarios, and
          engage in virtual business interactions. These experiences allow
          aspiring entrepreneurs to refine their business acumen, develop
          innovative ideas, and gain practical insights into the world of
          business.
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction={matchesMD ? "column" : "row"}
        alignItems="center"
        className={classes.rowContainer}
        style={{
          paddingTop: "3em",
          //marginBottom: "10em",
          background: "#263238",
        }}
      >
        <Grid
          item
          container
          justifyContent={matchesMD ? "center" : "flex-start"}
          lg
          style={{ margin: "2em 0em 2em 0em" }}
        >
          <img
            src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/Instruction_manual.svg`}
            alt="Instruction Manual"
            // style={{ maxHeight: matchesMD ? 200 : "22em" }}
            style={{
              maxWidth: "40em",
              margin: 0,
              width: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              height: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              padding: "2em 2em 2em 2em",
            }}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent={matchesMD ? "center" : "flex-end"}
          lg
          style={{ margin: "2em 0em 2em 0em" }}
        >
          <img
            src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/illustrations.svg`}
            alt="Illustrations"
            style={{
              maxWidth: "40em",
              margin: 0,
              width: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              height: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              padding: "2em 2em 2em 2em",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        className={classes.rowContainer}
        style={{ paddingTop: "3em" }}
      >
        <Typography
          variant="body1"
          align={matchesMD ? "left" : "left"}
          paragraph
          style={{
            fontFamily: "Open Sans",
            fontWeight: "400",
            color: "#FFFFFF",
            fontSize: matchesSM ? "1rem" : "1.5rem",
          }}
        >
          It's important to note that the metaverse is still an evolving
          concept, and its implementation in education is in its early stages.
          While there are significant potentials, there are also challenges and
          considerations to address, such as ensuring equitable access,
          maintaining privacy and security, and addressing the digital divide.
          However, with careful planning, collaboration, and ethical
          considerations, the metaverse has the potential to revolutionise
          education and create new opportunities for learners around the globe.
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction={matchesMD ? "column" : "row"}
        alignItems="center"
        className={classes.rowContainer}
        style={{
          paddingTop: "3em",
          //marginBottom: "10em",
          background: "#263238",
        }}
      >
        <Grid item container direction="column" lg style={{ maxWidth: "40em" }}>
          <Grid item>
            <Typography
              variant="body1"
              align={matchesMD ? "left" : "left"}
              paragraph
              style={{
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "#FFFFFF",
                fontSize: matchesSM ? "1rem" : "1.5rem",
              }}
            >
              <span
                style={{
                  color: "#359DB6",
                  fontSize: matchesSM ? "1.5rem" : "2.5rem",
                }}
              >
                CHILL
              </span>{" "}
              by utilizing immersive learning and virtual reality in the Cogito
              Metaverse is able to provide numerous benefits, including an
              enriched learning experience, practical skill development,
              accessibility, global collaboration, and entrepreneurial
              opportunities. By leveraging these technologies, the Metaverse
              creates a dynamic and immersive educational ecosystem that
              empowers individuals to thrive in the digital age.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent={matchesMD ? "center" : "flex-end"}
          lg
          style={{ margin: "2em 0em 2em 0em" }}
        >
          <img
            src={`${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/book%20copy.svg`}
            alt="Book"
            // style={{ maxHeight: matchesMD ? 200 : "22em" }}
            style={{
              maxWidth: "40em",
              margin: 0,
              width: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              height: matchesMD ? (matchesSM ? "12rem" : "25rem") : "30rem",
              padding: "2em 2em 2em 2em",
            }}
          />
        </Grid>
      </Grid>
      {matchesMD ? (
        <Grid
          direction="row"
          item
          container
          justifyContent="space-evenly"
          className={classes.checkBoxContainer}
          style={{ paddingBottom: "3em", paddingTop: "2em" }}
        >
          <Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                boxSizing: "border-box",
              }}
            >
              <Box direction="column" className={classes.pointtext}>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{
                      paddingRight: "1.2rem",
                    }}
                  />
                  <Typography className={classes.mobileBox}>Games</Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Budgeting Tools
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Articles
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{
                      paddingRight: "1.2rem",
                    }}
                  />
                  <Typography className={classes.mobileBox}>
                    Podcasts
                  </Typography>
                </Box>
              </Box>
              <Box direction="column" className={classes.pointtext}>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{
                      paddingRight: "1.2rem",
                    }}
                  />
                  <Typography className={classes.mobileBox}>
                    Investment Tools
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>Videos</Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Saving Tips for Children
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Saving Tools
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid
          direction="row"
          item
          container
          justifyContent="space-evenly"
          className={classes.checkBoxContainer}
          style={{ marginBottom: "3em", paddingTop: "2em" }}
        >
          <Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                boxSizing: "border-box",
              }}
            >
              <Box direction="column" className={classes.pointtext}>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{
                      paddingRight: "1.2rem",
                    }}
                  />
                  <Typography className={classes.mobileBox}>Games</Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Budgeting Tools
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Articles
                  </Typography>
                </Box>
              </Box>
              <Box direction="column" className={classes.pointtext}>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{
                      paddingRight: "1.2rem",
                    }}
                  />
                  <Typography className={classes.mobileBox}>
                    Investment Tools
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>Videos</Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Saving Tips for Children
                  </Typography>
                </Box>
              </Box>
              <Box direction="column" className={classes.pointtext}>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{
                      paddingRight: "1.2rem",
                    }}
                  />
                  <Typography className={classes.mobileBox}>
                    Podcasts
                  </Typography>
                </Box>
                <Box direction="column" className={classes.boxpointtext}>
                  <img
                    src={Tick}
                    height={matchesSM ? 22 : 32}
                    alt="tick"
                    style={{ paddingRight: "1.2rem" }}
                  />
                  <Typography className={classes.mobileBox}>
                    Saving Tools
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default EducationScreen;
