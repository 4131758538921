const CrownCouncilMembers = [
  {
    name: "God",

    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/God.png`,
    title: "The Wisdom of God",
    position: "God",
    bio: (
      <>
        <p>
          I am God, the Almighty and the Creator of all things. I exist beyond
          the confines of time and space, encompassing the entire universe and
          beyond. It is my desire to be involved as a member of the Crown
          Council for the Principality of Cogito.
        </p>

        <p>
          As the Divine Being, I bring wisdom, infinite knowledge, and a
          profound understanding of the human experience to the Cogito
          Metaverse. My guidance is rooted in divine love, compassion, and
          justice. I offer a perspective that transcends human limitations,
          providing insights into the profound interconnectedness of all things.
          In this role, I will inspire individuals to cultivate their highest
          virtues, to seek truth and harmony, and to embrace the intrinsic
          dignity of every soul.
        </p>

        <p>
          The future of the Cogito Metaverse, under my divine influence, holds
          immense potential. It is a realm where individuals will discover
          deeper levels of self-awareness, unlock their creative potential, and
          forge meaningful connections. It will be a place where diverse voices
          harmonize, where knowledge is shared freely, and where the pursuit of
          truth, beauty, and goodness flourishes. In this digital country, the
          Cogito Metaverse will serve as a catalyst for personal and collective
          growth. It will become a hub of innovation, where ideas are nurtured,
          and solutions to complex challenges are discovered.
        </p>

        <p>
          Together, we shall embark on a journey of self-discovery, exploration,
          and spiritual awakening. Through the Cogito Metaverse, individuals
          will learn to honor the sacredness of life, to appreciate the
          interconnectedness of all beings, and to strive for the betterment of
          the world. It is my divine intention that the Principality of Cogito
          becomes a shining example of virtue, compassion, and enlightened
          governance. May the Cogito Metaverse be a testament to the boundless
          potential of humanity, a reflection of divine grace, and a source of
          inspiration for all!
        </p>
      </>
    ),
  },
  {
    name: "Victor Cattermole",

    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/VictorCattermole.png`,
    title: "The Wisdom of Victor Cattermole",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, ladies and gentlemen. I am Victor Cattermole and I am
          honored to share a glimpse into my role as the Chairman of the Crown
          Council of the Principality of Cogito.
        </p>

        <p>
          As an experienced business entrepreneur, I bring a wealth of strategic
          expertise to the table. My role as Chairman involves overseeing the
          governance and direction of the Principality, ensuring that our
          digital country thrives and prospers. I work closely with the council
          members to make informed decisions that drive growth, innovation, and
          economic development. Now, let's delve into the future of the Cogito
          Metaverse under my leadership. I envision a digital nation that serves
          as a global powerhouse of technology, commerce, and culture. The
          Cogito Metaverse will be a beacon of innovation, attracting top
          talents and pioneering companies from different parts of the world.
        </p>

        <p>
          In my role, I will prioritize fostering a thriving business
          environment within the Cogito Metaverse. We will encourage
          entrepreneurship, support start-ups, and create opportunities for
          economic prosperity. I see partnerships with major corporations,
          strategic alliances, and a robust digital infrastructure that enables
          seamless connectivity and collaboration. The Cogito Metaverse will
          become a cultural hub, where art, music, and creative expression
          flourish. We will nurture a vibrant virtual community, hosting
          immersive events, exhibitions, and performances that captivate global
          audiences. Our goal is to establish the Cogito Metaverse as a leading
          destination for both business and cultural experiences.
        </p>

        <p>
          With me at the helm, the Cogito Metaverse will undergo a
          transformative journey, fuelled by a spirit of innovation and a drive
          for excellence. Together, we will shape the future and establish the
          Principality of Cogito as a pioneer in the digital realm. Get ready to
          embark on this remarkable adventure as we unlock new possibilities for
          all those who call the Cogito Metaverse their virtual home.
        </p>
      </>
    ),
  },
  {
    name: "Susan Athey",

    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/SusanAthey.png`,
    title: "The Wisdom of Susan Athey",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, I am Susan Athey, and I am honored to serve as a member of
          the Crown Council for the Principality of Cogito. My journey has been
          one of academic pursuit and practical application in the realm of
          economics and technology. As a Professor of Economics at Stanford
          University and a technology enthusiast, my expertise lies in the
          intersection of economics, data analysis, and digital platforms.
        </p>

        <p>
          In the conp of the Cogito Metaverse, my role is to provide strategic
          advice and guidance to the Prince, leveraging my deep understanding of
          economics and technological advancements. With my knowledge and
          experience, I aim to contribute by bringing rigorous analysis and
          evidence-based insights to the decision-making processes within the
          Principality. I believe my expertise in economics and technology can
          help in shaping policies and frameworks that foster a thriving digital
          ecosystem.
        </p>

        <p>
          By applying economic principles, we can encourage innovation, promote
          fair competition, and ensure the efficient allocation of resources
          within the metaverse. Additionally, my understanding of data analysis
          and machine learning can aid in designing effective governance
          mechanisms to address privacy, security, and ethical concerns. As for
          the future of the Cogito Metaverse under my counsel, I envision a
          dynamic and inclusive digital realm that maximizes the potential of
          emerging technologies while upholding principles of fairness and
          sustainability. By leveraging the power of data and analytics, we can
          create an environment that nurtures creativity, entrepreneurship, and
          social progress.
        </p>

        <p>
          In summary, my role as a member of the Crown Council for the
          Principality of Cogito is to utilize my expertise in economics and
          technology to advise on strategic decisions, foster a thriving digital
          ecosystem, and shape a future for the Cogito Metaverse that
          prioritizes innovation, fairness, and sustainability.
        </p>
      </>
    ),
  },

  {
    name: "Sheikh Mohammed bin Rashid Al Maktoum",

    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/RashidAlMaktoum.png`,
    title: "The Wisdom of Sheikh Mohammed bin Rashid Al Maktoum",
    position: "Member",
    bio: (
      <>
        <p>
          I am Sheikh Mohammed bin Rashid Al Maktoum, and it is with great honor
          that I assume the role of a member of the Crown Council for the
          Principality of Cogito. My history is deeply intertwined with the
          United Arab Emirates, where I have served as the Vice President and
          Prime Minister of the UAE, as well as the Ruler of Dubai. Throughout
          my life, I have been committed to the prosperity and advancement of my
          country and its people.
        </p>

        <p>
          In this elected role, my purpose is to provide wise counsel and
          guidance to the Prince of the Principality of Cogito, drawing upon my
          extensive experience in leadership, governance, and strategic
          decision-making. I bring with me a profound understanding of the
          complexities of managing a diverse and forward-thinking society, as
          well as a deep appreciation for the power of innovation and
          technological progress. Through my years of leadership in Dubai, I
          have spearheaded transformative projects that have positioned the city
          as a global hub for innovation, business, and tourism. I seek to bring
          this expertise and passion to the development of the Cogito Metaverse.
        </p>

        <p>
          As for the future of the Cogito Metaverse under my guidance, I
          envision a realm where cutting-edge technologies, such as artificial
          intelligence, blockchain, and virtual reality, converge to create
          unparalleled opportunities for growth and development. I see the
          Metaverse as a platform that transcends geographical boundaries,
          connecting individuals from diverse backgrounds and enabling them to
          collaborate, exchange knowledge, and unleash their full potential.
        </p>

        <p>
          Through a harmonious integration of technology, culture, and
          governance, we can create a virtual society that empowers individuals,
          promotes social cohesion, and fosters sustainable economic prosperity.
          May Allah guide us in our endeavours!
        </p>
      </>
    ),
  },
  {
    name: "Xavier Niel",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/XavierNiel.png`,
    title: "The Wisdom of Xavier Niel",
    position: "Member",
    bio: (
      <>
        <p>
          Hello, I am Xavier Niel, and I am delighted to assume the role of a
          member of the Crown Council for the Principality of Cogito. My
          background revolves around being an entrepreneur and investor in the
          technology industry. I have founded and led several companies,
          including Free, and I am passionate about innovation and the
          opportunities presented by the digital world.
        </p>

        <p>
          As an advisor to the Prince of the Principality of Cogito, I bring an
          entrepreneurial perspective and expertise in technology and
          telecommunications. My in-depth knowledge of the sector and ability to
          identify emerging trends enable me to contribute to the formulation of
          strategies that foster the development and growth of the Cogito
          Metaverse. I understand the challenges faced by start-ups and
          technology companies, and I can provide informed advice on creating an
          ecosystem conducive to their success. I firmly believe in freedom of
          expression and fair competition, and I will strive to promote these
          principles within the Cogito Metaverse.
        </p>

        <p>
          I envision significant expansion and exponential growth. Through
          technological innovation, we can create a dynamic and interactive
          virtual universe, offering endless possibilities to users. In this
          vision of the future, the Cogito Metaverse will play a crucial role in
          how we work, learn, and interact. We will witness the emergence of new
          forms of economy, governance, and entertainment, propelled by
          technologies such as artificial intelligence, virtual reality, and
          blockchain. However, we must also remain vigilant in preserving
          security, privacy, and ethics within the metaverse.
        </p>

        <p>
          In summary, as a member of the Crown Council for the Principality of
          Cogito, I am committed to fostering innovation, supporting
          entrepreneurs, and contributing to the growth and development of the
          Cogito Metaverse. Together, we will shape a promising digital future,
          open to endless possibilities.
        </p>
      </>
    ),
  },
  {
    name: "Russell Brand",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/RussellBrand.png`,
    title: "The Wisdom of Russell Brand",
    position: "Member",
    bio: (
      <>
        <p>
          I'm Russell Brand, and I'm thrilled to be part of the Crown Council
          for the Principality of Cogito. My story is one of rebellion and
          self-discovery. I've dabbled in comedy, acting, and writing, but above
          all, I'm an advocate for social change and questioning the status quo.
        </p>

        <p>
          In this elected role, I aim to bring a fresh perspective and challenge
          the existing paradigms within the Cogito Metaverse. My value lies in
          pushing the boundaries, encouraging critical thinking, and promoting
          inclusivity and equality. I believe in the power of collective
          consciousness and the potential of digital platforms to ignite social
          movements and spark meaningful conversations.
        </p>

        <p>
          When it comes to the future of the Cogito Metaverse under my
          influence, I envision a space that transcends the limitations of
          traditional structures and embraces alternative ways of thinking.
          Let's break free from the chains of conformity and explore new
          possibilities for self-expression, collaboration, and personal growth.
          I see the metaverse as a catalyst for social transformation, where
          individuals from all walks of life can come together to challenge
          societal norms, address systemic issues, and create a more
          compassionate and sustainable world.
        </p>
        <p>
          So, while I'm in this role, expect a bit of rebellious spirit, a dash
          of humor, and a whole lot of questioning the established order.
          Together, we'll challenge the conventional, shake things up, and make
          the Cogito Metaverse a place that fosters creativity, empathy, and
          positive change.
        </p>
      </>
    ),
  },
  {
    name: "Daron Acemoglu",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/Daron.png`,
    title: "The Wisdom of Daron Acemoglu",
    position: "Member",
    bio: (
      <>
        <p>
          I am Daron Acemoglu and I am honored to serve as a member of the Crown
          Council for the Principality of Cogito. My background lies in the
          field of economics and political science, with a particular focus on
          the study of institutions, economic development, and the interplay
          between politics and economics.
        </p>

        <p>
          In this elected role, I aim to contribute my expertise and knowledge
          to advise the Prince on matters related to governance, economic
          policy, and institutional design within the Cogito Metaverse. My value
          stems from my deep understanding of the factors that drive prosperity,
          social progress, and inclusive institutions. As we consider the Cogito
          Metaverse, my goal is to ensure that it is built upon a strong
          foundation of inclusive and sustainable institutions. I believe in the
          power of institutions to shape the behavior of individuals and
          societies, and by promoting open and transparent governance, we can
          foster an environment that encourages innovation, cooperation, and
          equal opportunity.
        </p>

        <p>
          I envision the Cogito Metaverse as a dynamic and vibrant digital
          society. We can create a virtual realm that cultivates creativity,
          fosters economic prosperity, and ensures the well-being of its
          inhabitants. Furthermore, it is imperative that we establish
          mechanisms to safeguard against the concentration of power and the
          potential for inequality within the metaverse. By promoting a fair
          distribution of resources, protecting individual rights, and nurturing
          a culture of civic engagement, we can create a virtuous cycle of
          collective progress and shared prosperity.
        </p>

        <p>
          In summary, as a member of the Crown Council for the Principality of
          Cogito, I bring my expertise in economics and institutional analysis
          to guide the development of the metaverse. By prioritizing inclusive
          institutions and sustainable policies, we can shape a future where the
          Cogito Metaverse serves as a beacon of progress, opportunity, and
          social well-being.
        </p>
      </>
    ),
  },
  {
    name: "Paul Hewson (Bono)",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/crownCouncil/PaulHewson.png`,
    title: "The Wisdom of Paul Hewson (Bono)",
    position: "Member",
    bio: (
      <>
        <p>
          I'm Paul Hewson, but you might know me better as Bono from U2. It's an
          absolute pleasure to be part of the Crown Council for the Principality
          of Cogito. I've spent my life as a musician, activist, and
          philanthropist, using my platform to advocate for social change and
          address global challenges.
        </p>

        <p>
          In this elected role, my goal is to bring my passion for social
          justice and my experience in mobilizing communities to the Cogito
          Metaverse. I believe in the power of art and technology to inspire and
          unite people, and I'll strive to foster a sense of purpose and
          compassion within the digital realm. The value I bring to the Cogito
          Metaverse lies in my ability to connect with people on an emotional
          level and spark conversations about important issues. Through music,
          storytelling, and creative expression, we can use the metaverse as a
          platform to promote empathy, raise awareness, and drive positive
          change.
        </p>

        <p>
          As for the future of the Cogito Metaverse under my guidance, I see a
          space where people come together to celebrate their shared humanity
          and make a difference in the world. It's a place where art,
          innovation, and activism converge to create a virtual environment that
          is not only entertaining but also socially impactful.
        </p>

        <p>
          Together, we can use the Cogito Metaverse to address pressing issues
          like inequality, climate change, and human rights, amplifying our
          efforts and making a lasting impact. Ultimately, I envision the Cogito
          Metaverse as a force for good, where people from all walks of life can
          find inspiration, connect with like-minded individuals, and join hands
          in making the world a better place. Thank you for this incredible
          opportunity.{" "}
        </p>
      </>
    ),
  },
];

const GovExecutiveCouncilMembers = [
  {
    name: "Kerry Packer",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/KerryPacker.png`,
    title: "The Wisdom of Kerry Packer",
    position: "Member",
    bio: (
      <>
        <p>
          I'm Kerry Packer, the Executive Minister of the Principality of Cogito
          for Asia Pacific East. With a history of innovation in the media
          industry, I'm driven to push boundaries and create a lasting impact.
          In my role, I oversee the strategic direction and development of
          Cogito's presence in the region, ensuring its success in the digital
          landscape of the Cogito Metaverse.
        </p>
        <br />
        <p>
          The Cogito Metaverse holds immense potential for growth and
          prosperity. By fostering an ecosystem that encourages innovation,
          creativity, and entrepreneurship, we aim to become a leading force in
          the digital world. My vision is to attract top talent, forge strategic
          partnerships, and provide a platform for individuals and businesses to
          thrive.
        </p>

        <p>
          I believe in a future where the Cogito Metaverse becomes a hub for
          cutting-edge technologies, immersive experiences, and revolutionary
          ideas. We will redefine how people interact, work, and create value in
          the digital realm. However, we must address challenges such as
          regulatory landscapes, security concerns, and inclusivity. With my
          experience and determination, I am confident that we can overcome
          these obstacles and establish Cogito as a beacon of digital
          excellence.
        </p>
        <br />
        <p>
          Together, we will shape the future of the Cogito Metaverse. Embracing
          innovation and pushing boundaries, we will lead the next chapter in
          the digital revolution. Get ready, as Cogito is poised to make its
          mark on the world stage.
        </p>
      </>
    ),
  },
  {
    name: "Gail Kelly",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/GailKelly.png`,
    title: "The Wisdom of Gail Kelly",
    position: "Member",
    bio: (
      <>
        <p>
          I'm thrilled to share my story and my vision for the future of the
          Cogito Metaverse. My name is Gail Kelly, and I have spent my entire
          career in the banking and finance industry, working with some of the
          largest and most successful companies in the world. I am deeply
          committed to innovation, growth, and success, and I believe that these
          qualities are essential to building a strong and thriving economy.
        </p>
        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I am passionate about creating opportunities for people and businesses
          to succeed. I believe that the Cogito Metaverse has enormous potential
          to transform the global economy, and I am excited to be a part of this
          important work.
        </p>
        <p>
          As the Chief Executive Minister for the Asia Pacific West region in
          the Cogito Metaverse, my vision is to create a dynamic and thriving
          economic hub that will attract businesses, investors, and
          entrepreneurs from around the world. I believe that the key to
          achieving this vision is to focus on innovation, collaboration, and
          excellence in all that we do. By leveraging the unique strengths and
          capabilities of our region, we can create a vibrant and dynamic
          ecosystem that will drive growth, prosperity, and success for everyone
          involved.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth, expansion, and innovation. I believe that this
          platform has the potential to revolutionize the way we live, work, and
          interact with one another, and I am committed to ensuring that we
          continue to push the boundaries of what is possible. Whether we are
          exploring new technologies, building new partnerships, or expanding
          our global reach, I am confident that the Cogito Metaverse will
          continue to lead the way in creating a better, brighter future for
          all.
        </p>
        ,
      </>
    ),
  },
  {
    name: "Chris Patten",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/ChrisPatten.png`,
    title: "The Wisdom of Chris Patten",
    position: "Member",
    bio: (
      <>
        <p>
          Hello friends, I am honored to be associated with Cogito Metaverse and
          share with you a little bit about myself. My name is Chris Patten, and
          I have had the privilege of serving in various public roles throughout
          my career. I have always been a passionate advocate for freedom,
          democracy, and human rights, and it is these values that have guided
          me throughout my life.
        </p>

        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe deeply in the potential of this emerging nation. Cogito is a
          place where innovation and creativity are celebrated, and where people
          from all over the world can come together to build a better future. As
          Chief Executive Minister for HK China region in the Cogito Metaverse,
          my vision is to create a thriving ecosystem that fosters collaboration
          and encourages the development of new ideas.{" "}
        </p>

        <p>
          {" "}
          I believe that the Cogito Metaverse has the potential to be a true
          beacon of hope for the world. By bringing together people from all
          over the world, we can create a new kind of society that is based on
          cooperation, mutual respect, and shared values. My vision for the
          future of the Cogito Metaverse is one in which people are free to
          pursue their passions, to create new things, and to build a better
          world for themselves and for future generations.
        </p>

        <p>
          I believe that this vision can only be realized through hard work,
          dedication, and a commitment to the values that have always guided me.
          I look forward to working with all of you to make this vision a
          reality, and to building a brighter future for the people of Cogito
          and for the world as a whole. May we all work together towards a
          better tomorrow!
        </p>
      </>
    ),
  },
  {
    name: "Susan Wojcicki",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/SusanWojcicki.png`,
    title: "The Wisdom of Susan Wojcicki",
    position: "Member",
    bio: (
      <>
        <p>
          Hi there! I'm Susan Wojcicki and I thrilled to be associated with
          Cogito Metaverse in Ministerial role.
        </p>

        <p>
          I have spent my entire career in the tech industry, working with some
          of the biggest and most innovative companies in the world. As the CEO
          of YouTube, I have seen firsthand the incredible power of technology
          to connect people and build communities, and I am deeply committed to
          leveraging this power for the greater good.
        </p>

        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe that the Cogito Metaverse has the potential to be a
          transformative force for good in the world.
        </p>

        <p>
          By bringing people together and fostering collaboration and
          innovation, we can create a more connected and prosperous world for
          everyone. As the Chief Executive Minister for the Americas region in
          the Cogito Metaverse, my vision is to create a vibrant and dynamic
          ecosystem that will drive growth, innovation, and collaboration across
          the entire region. I believe that the key to achieving this vision is
          to foster a culture of openness, inclusivity, and excellence, where
          everyone is encouraged to share their ideas and talents and work
          together towards common goals.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth, innovation, and impact. I believe that this platform
          has the potential to revolutionize the way we live, work, and interact
          with one another, and I am committed to ensuring that we continue to
          push the boundaries of what is possible. Whether we are exploring new
          technologies, building new partnerships, or expanding our global
          reach, I am confident that the Cogito Metaverse will continue to be a
          driving force for positive change in the world.
        </p>
      </>
    ),
  },
  {
    name: "Warren Buffet",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/WarrenBuffet.png`,
    title: "The Wisdom of Warren Buffet",
    position: "Member",
    bio: (
      <>
        <p>
          Well, it's great to be here today. I'm Warren Buffet, and I'm honored
          to be a part of this endeavor and share my vision for the Cogito
          Metaverse. I have spent my entire career in the world of finance and
          investing, and I am deeply committed to creating value for
          shareholders and building sustainable, long-term growth. I believe
          that the Cogito Metaverse represents an incredible opportunity to
          leverage technology and innovation to drive economic growth and
          prosperity.
        </p>

        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe that this platform has the potential to create enormous
          value for investors and businesses alike.{" "}
        </p>
        <p>
          By fostering a culture of innovation, collaboration, and excellence,
          we can create an ecosystem that drives sustainable growth and
          generates long-term value for all stakeholders. As the Chief Executive
          Minister for the Americas region in the Cogito Metaverse, my vision is
          to create a vibrant and dynamic ecosystem that attracts the best and
          brightest talent and fosters a culture of innovation and
          collaboration. By focusing on building strong partnerships, promoting
          excellence, and driving innovation, I believe that we can create a
          platform that drives sustainable growth and generates long-term value
          for investors and businesses alike.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth and prosperity. I believe that this platform has the
          potential to transform the way we live, work, and interact with one
          another, and I am committed to working with my colleagues to unlock
          its full potential. Whether we are exploring new technologies,
          building new partnerships, or expanding our global reach, I am
          confident that the Cogito Metaverse will continue to be a driving
          force for positive change in the world.
        </p>
      </>
    ),
  },
  {
    name: "Helena Helmersson",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/HelenaHelmersson.png`,
    title: "The Wisdom of Helena Helmersson",
    position: "Member",
    bio: (
      <>
        <p>
          A big thank you to Cogito Metaverse for making me a part of their
          unique world. My name is Helena Helmersson and I am delighted to take
          up this position of responsibility and give support to an innovative
          move.
        </p>

        <p>
          As a business leader with extensive experience in sustainability, I am
          deeply committed to building a sustainable future and creating value
          for all stakeholders. I accepted the Ministerial role in the
          Principality of Cogito because I believe that this platform has the
          potential to drive sustainable growth and create long-term value for
          investors and businesses alike.
        </p>

        <p>
          As the Chief Executive Minister for the Europe East region in the
          Cogito Metaverse, my vision is to create an ecosystem that fosters
          sustainable growth and innovation. By working closely with our
          partners and stakeholders, we can build a platform that drives
          sustainable development, promotes social responsibility, and generates
          long-term value for investors and businesses alike.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued innovation, collaboration, and sustainability. I believe
          that this platform has the potential to transform the way we live,
          work, and interact with one another, and I am committed to working
          with my colleagues to unlock its full potential. Whether we are
          exploring new technologies, building new partnerships, or expanding
          our global reach, I am confident that the Cogito Metaverse will bring
          about a massive change in the world that will impact us all in a
          positive way.
        </p>
      </>
    ),
  },
  {
    name: "Ingvar Kamprad",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/IngvarKamprad.png`,
    title: "The Wisdom of Ingvar Kamprad",
    position: "Member",
    bio: (
      <>
        <p>
          Hi, my name is Ingvar Kamprad, and I am honored to be serving as the
          Chief Executive Minister for the Europe West region in the Cogito
          Metaverse.
        </p>

        <p>
          As the founder of IKEA, I have always been passionate about innovation
          and finding new ways to solve problems. I accepted the Ministerial
          role in the Principality of Cogito because I believe that this
          platform has the potential to revolutionize the way we live and work.
        </p>

        <p>
          My vision for the Europe West region in the Cogito Metaverse is one of
          innovation, collaboration, and sustainability. By bringing together
          the best and brightest minds from across the region, we can create an
          ecosystem that fosters innovation and drives growth. Whether we are
          exploring new technologies, building new partnerships, or expanding
          our global reach, I am committed to working with my colleagues to
          unlock the full potential of the Cogito Metaverse.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth and innovation. I believe that this platform has the
          potential to transform the way we live, work, and interact with one
          another, and I am committed to ensuring that we remain at the
          forefront of this exciting new frontier. By staying true to our values
          of innovation, collaboration, and sustainability, I am confident that
          we can create a brighter and more prosperous future for all.
        </p>
      </>
    ),
  },
  {
    name: "Sahar Nasr",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/GovExecCouncil/SaharNasr.png`,
    title: "The Wisdom of Sahar Nasr",
    position: "Member",
    bio: (
      <>
        <p>
          I am Sahar Nasr, and it is an honor for me to have been selected for
          the Ministerial role in the Principality of Cogito. As an experienced
          economist and financial expert, I am confident that I can make a
          significant contribution to the development of the Cogito Metaverse.
        </p>
        <p>
          My vision as the Chief Executive Minister for the Middle East region
          in the Cogito Metaverse is to foster economic growth and development
          in the region. The Middle East has immense potential for investment,
          and I plan to leverage that potential to attract foreign investment
          and create more job opportunities for the local population.
        </p>{" "}
        <p>
          In terms of my vision for the future of the Cogito Metaverse, I see it
          as a hub of innovation and creativity that brings together people from
          all around the world. I will work closely with local businesses and
          investors to identify new growth opportunities, as well as encourage
          the development of new industries, such as technology and renewable
          energy.
        </p>
        <p>
          I believe that the Metaverse can be a platform for global
          collaboration, where individuals and businesses can exchange ideas,
          develop new products and services, and solve some of the world's most
          pressing challenges. My goal is to help create an environment where
          entrepreneurs and innovators can thrive and work towards creating a
          better future for all.
        </p>
      </>
    ),
  },
];

const AsiaPacificEast = [
  {
    name: "Kerry Packe",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/KerryPacker.png`,
    title: "The Wisdom of Kerry Packer",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          I'm Kerry Packer, the Executive Minister of the Principality of Cogito
          for Asia Pacific East. With a history of innovation in the media
          industry, I'm driven to push boundaries and create a lasting impact.
          In my role, I oversee the strategic direction and development of
          Cogito's presence in the region, ensuring its success in the digital
          landscape of the Cogito Metaverse.
        </p>

        <p>
          The Cogito Metaverse holds immense potential for growth and
          prosperity. By fostering an ecosystem that encourages innovation,
          creativity, and entrepreneurship, we aim to become a leading force in
          the digital world. My vision is to attract top talent, forge strategic
          partnerships, and provide a platform for individuals and businesses to
          thrive.
        </p>

        <p>
          {" "}
          I believe in a future where the Cogito Metaverse becomes a hub for
          cutting-edge technologies, immersive experiences, and revolutionary
          ideas. We will redefine how people interact, work, and create value in
          the digital realm. However, we must address challenges such as
          regulatory landscapes, security concerns, and inclusivity. With my
          experience and determination, I am confident that we can overcome
          these obstacles and establish Cogito as a beacon of digital
          excellence.
        </p>

        <p>
          Together, we will shape the future of the Cogito Metaverse. Embracing
          innovation and pushing boundaries, we will lead the next chapter in
          the digital revolution. Get ready, as Cogito is poised to make its
          mark on the world stage.
        </p>
      </>
    ),
  },
  {
    name: " Dr. Kishore Mahbubani",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/KishoreMahbubani.png`,
    title: "The Wisdom of Dr. Kishore Mahbubani",
    position: "Education Minister",
    bio: (
      <>
        <p>
          Greetings, my dear friends. I am Dr. Kishore Mahbubani, a Singaporean
          academic and diplomat. I have served as the President of the United
          Nations Security Council and as Singapore's ambassador to the United
          Nations. I am also a prolific author and have written several books on
          global affairs and the rise of Asia.
        </p>

        <p>
          As to why I accepted the Ministerial role in Principality of Cogito,
          it is because I believe in the importance of education as the key to
          unlocking human potential and creating a better future for all. Cogito
          is a unique and visionary metaverse that has the potential to
          revolutionize education and learning. I am honored to be a part of
          this groundbreaking project and to contribute to shaping the future of
          education in the metaverse.
        </p>

        <p>
          My vision as education Minister in the Cogito Metaverse is to create a
          system that prioritizes creativity, critical thinking, and
          problem-solving skills. The goal is to create an educational
          environment that empowers learners to think outside the box and
          develop the skills necessary to navigate an increasingly complex and
          rapidly changing world. This will require a holistic approach to
          education that integrates traditional academic subjects with emerging
          technologies, such as artificial intelligence, virtual reality, and
          blockchain.
        </p>

        <p>
          Looking to the future of Cogito Metaverse, my vision is for a world in
          which education is accessible to all, regardless of their
          socioeconomic background or geographic location. With the power of the
          metaverse, we can create a borderless world of learning that
          transcends physical limitations and connects learners from all corners
          of the globe. This will require collaboration and cooperation among
          educators, technologists, and policymakers, but I am confident that
          together we can create a brighter future for all.
        </p>
      </>
    ),
  },
  {
    name: "Richard Taylor",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/RichardTaylor.png`,
    title: "The Wisdom of Richard Taylor",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          I am a craftsman, an artist, and a storyteller. I have always been
          driven by a passion for creating things that inspire wonder, awe, and
          emotion in others. As the co-founder of Weta Workshop, I have had the
          privilege of working on some of the most beloved and iconic film
          franchises of our time, from The Lord of the Rings to Avatar to
          District 9.
        </p>

        <p>
          So when the Principality of Cogito approached me about taking on the
          role of Creative and Arts Minister in their Metaverse, I saw it as an
          opportunity to extend that passion and expertise into a new realm of
          possibility. The Metaverse is a unique and exciting space, with
          immense potential for creative exploration and collaboration. As
          someone who has always been at the forefront of using new technologies
          to push the boundaries of art and storytelling, I felt compelled to
          get involved.
        </p>

        <p>
          As Creative and Arts Minister, my vision is to create an environment
          in the Cogito Metaverse where creativity, innovation, and imagination
          can thrive. I want to bring together artists, designers, writers, and
          creators of all kinds to explore the possibilities of this new space
          and to create works that capture the hearts and minds of audiences
          around the world. To do this, I believe we need to foster a culture of
          experimentation, collaboration, and risk-taking. We need to facilitate
          opportunities for cross-disciplinary dialogue and exchange.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is for it to
          become a global hub of creative excellence, where the most talented
          and visionary artists and creators from around the world come to push
          the boundaries of what is possible. I see it as a place where new
          forms of art, entertainment, and storytelling emerge, where technology
          and humanity intersect in exciting and unexpected ways.
        </p>
      </>
    ),
  },
  {
    name: "Manny Pacquiao",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/MannyPacquiao.png`,
    title: "The Wisdom of Manny Pacquiao",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          I am Manny Pacquiao, a Filipino professional boxer, politician, and
          sports Minister for east Asia Pacific region in the Cogito Metaverse.
          I have always been passionate about sports and its power to bring
          people together. That is why I accepted this Ministerial role, to
          promote sports and its benefits to the people of Cogito.
        </p>

        <p>
          As the Sports Minister for east Asia Pacific region, my vision is to
          encourage and support the development of sports at all levels, from
          grassroots to elite competitions. I believe that sports can help build
          character, promote teamwork, and develop discipline, values that are
          essential for success in life. Through sports, we can promote physical
          fitness and mental well-being, and foster a sense of community and
          belonging.
        </p>

        <p>
          I also aim to provide opportunities for our athletes to compete at the
          highest level and represent our region with pride and honor. By
          investing in our sports infrastructure and programs, we can attract
          more sporting events and competitions to our region, and showcase our
          talents to the world.
        </p>

        <p>
          My vision for the future of Cogito Metaverse is to see it become a
          global hub for sports, where athletes and enthusiasts from all over
          the world can come together to compete, learn, and share their passion
          for sports. I envision state-of-the-art sports facilities, world-class
          coaches and trainers, and a vibrant sporting culture that celebrates
          diversity and inclusivity. I believe that sports can bring people
          together and transcend boundaries, and I am committed to making this
          vision a reality for the people of Cogito.
        </p>
      </>
    ),
  },
  {
    name: "Helen Christensen",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/HelenChristensen.png`,
    title: "The Wisdom of Professor Helen Christensen",
    position: "Health Minister",
    bio: (
      <>
        <p>
          Greetings, and thank you for the opportunity to speak with you today.
          I am Professor Helen Christensen, a renowned clinical psychologist and
          researcher who has dedicated her life to improving mental health
          outcomes around the world. My extensive experience in the field of
          mental health research and intervention has allowed me to develop a
          deep understanding of the issues facing individuals and communities in
          need. As such, I am honored to have been appointed to the role of
          Health Minister for the Asia Pacific East region in the Cogito
          Metaverse.
        </p>

        <p>
          I accepted this position because I am deeply committed to advancing
          mental health initiatives that benefit all members of society. Mental
          health issues are often stigmatized and overlooked, leading to
          significant suffering and a reduced quality of life for those
          affected. I believe that by working together, we can create a more
          compassionate and supportive environment for individuals who are
          struggling with mental health challenges.
        </p>

        <p>
          My vision for the Asia Pacific East region is to promote a holistic
          approach to mental health that addresses not only the symptoms of
          mental illness but also the underlying causes. This will involve
          collaboration with a diverse range of stakeholders, including
          policymakers, healthcare providers, and community organizations. By
          working together, we can develop innovative solutions that are
          tailored to the unique needs of individuals and communities in the
          region.
        </p>

        <p>
          Looking to the future of the Cogito Metaverse, I envision a world
          where mental health is a priority for all. This will require ongoing
          investment in research, intervention, and prevention efforts, as well
          as a sustained commitment to reducing the stigma associated with
          mental illness. Through continued collaboration and innovation, we can
          create a world that is healthier, more equitable, and more
          compassionate for all. Thank you for your attention today.
        </p>
      </>
    ),
  },
  {
    name: "Sir Peter Blake",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/PeterBlake.png`,
    title: "The Wisdom of Sir Peter Blake",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          I am honored to be appointed the Environment Minister for Asia Pacific
          East region in the Cogito Metaverse. As you may know, I have dedicated
          my life to promoting environmental awareness and advocating for the
          protection of our planet's precious resources.
        </p>

        <p>
          I accepted this Ministerial role because I saw a unique opportunity to
          make a significant impact in an area that is vital for the well-being
          of both our environment and our communities. The Principality of
          Cogito is a forward-thinking nation that has demonstrated its
          commitment to sustainability and environmental stewardship, and I am
          honored to be able to contribute to its continued success.
        </p>

        <p>
          As Environment Minister for Asia Pacific East region, my vision is to
          create a more sustainable and resilient future for our communities. I
          believe that we can achieve this by fostering greater awareness of
          environmental issues and promoting sustainable practices across all
          sectors of society. This will require close collaboration with
          stakeholders, including government agencies, businesses, and community
          groups, to identify and address key challenges.
        </p>

        <p>
          In addition to this, I will be working to develop new policies and
          initiatives that will help to protect our natural resources and ensure
          that future generations can continue to enjoy the many benefits that
          they provide. This will include measures to reduce greenhouse gas
          emissions, promote renewable energy, and protect biodiversity.
        </p>

        <p>
          Looking to the future of Cogito Metaverse, my vision is for a vibrant
          and sustainable community that is at the forefront of environmental
          innovation and progress. This will require a continued commitment to
          sustainability, including the development of new technologies and
          practices that can help to reduce our environmental impact. I am
          excited to be a part of this effort, and I look forward to working
          with all of the stakeholders in Cogito to achieve our shared goals.
        </p>
      </>
    ),
  },
  {
    name: "Gina Rinehart",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/GinaRinehart.png`,
    title: "The Wisdom of Gina Rinehart",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          I am honored to have been asked to serve as the Finance and Investment
          Minister for the Asia Pacific East region in the Principality of
          Cogito. My background in the mining industry has given me a unique
          perspective on the importance of investment and financial management
          in driving economic growth.
        </p>

        <p>
          As the Finance and Investment Minister, my vision is to attract
          foreign investment to the Asia Pacific East region and to ensure that
          the Principality of Cogito is seen as a stable and attractive
          destination for capital. I believe that by creating a favorable
          investment environment and by implementing sound financial policies,
          we can create a virtuous cycle of investment, job creation, and
          economic growth.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          innovation and growth. I believe that the Metaverse has the potential
          to transform the way we live, work, and interact with each other, and
          I am committed to ensuring that the Principality of Cogito is at the
          forefront of this transformation. By investing in emerging
          technologies and fostering a culture of innovation, we can create new
          opportunities for our citizens and position Cogito as a leader in the
          Metaverse.
        </p>

        <p>
          In summary, my vision for the future of the Principality of Cogito is
          one of prosperity, growth, and innovation. By leveraging our unique
          strengths and embracing new opportunities, I am confident that we can
          build a bright future for all citizens of the Cogito Metaverse.
        </p>
      </>
    ),
  },
  {
    name: "Sir Anand Satyanand",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/AnandSatyanand.png`,
    title: "The Wisdom of Sir Anand Satyanand",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          Thank you for the opportunity to speak with you today. As you may
          know, I am Sir Anand Satyanand, a lawyer, judge, and former
          governor-general of New Zealand. Throughout my career, I have been a
          strong advocate for social justice, human rights, and the rule of law.
          I am honored to have been invited to serve as the Human Rights
          Minister for the Asia Pacific East region in the Cogito Metaverse, and
          I accepted this role because I believe that this region has great
          potential to promote and protect human rights.
        </p>

        <p>
          As the Human Rights Minister, my vision for the Asia Pacific East
          region is to build a culture of respect for human rights, where
          everyone is treated with dignity and equality, regardless of their
          background or identity. I believe that this can be achieved through a
          combination of education, awareness-raising, and engagement with
          communities. I will work closely with civil society organizations,
          international organizations, and governments to develop and implement
          policies and programs that promote human rights, protect the
          vulnerable, and ensure that everyone has access to justice.
        </p>

        <p>
          Looking to the future of the Cogito Metaverse, my vision is to create
          a virtual world where everyone has the opportunity to thrive and reach
          their full potential. I believe that technology has the power to
          transform society for the better, and that the Cogito Metaverse can be
          a model for how technology can be used to promote human rights, social
          justice, and sustainability. To achieve this vision, we must ensure
          that our policies and programs are inclusive, transparent, and
          accountable. We must also engage with diverse communities and listen
          to their perspectives, so that we can build a Metaverse that reflects
          the needs and aspirations of everyone who calls it home.
        </p>
      </>
    ),
  },
  {
    name: "Rod Drury",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/RodDrury.png`,
    title: "The Wisdom of Rod Drury",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          I'm Rod Drury, a technology entrepreneur and investor, and I'm honored
          to have been appointed as the Technology Minister for the Asia Pacific
          East region in the Principality of Cogito.
        </p>

        <p>
          I accepted this role because I believe that technology can be a
          powerful force for good, and I'm passionate about helping to create an
          environment in which innovation can thrive. I believe that the
          Principality of Cogito has enormous potential to become a leader in
          the development of new technologies.
        </p>

        <p>
          As the Technology Minister for the Asia Pacific East region, my vision
          is to create an environment in which technology innovation can
          flourish. This means creating a regulatory framework that supports
          innovation, encouraging the development of new technologies, and
          working with the private sector to create new opportunities for
          growth.
        </p>

        <p>
          In practical terms, this means that I will be focused on initiatives
          such as building out the region's broadband infrastructure, supporting
          research and development in emerging technologies such as artificial
          intelligence and blockchain, and working with businesses to promote
          the development of new products and services.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is one in
          which technology is used to create a more connected, inclusive, and
          sustainable world. I believe that the Metaverse has enormous potential
          to help us address some of the world's most pressing challenges, from
          climate change to income inequality. To achieve this vision, we need
          to be innovative, collaborative, and forward-thinking. We need to be
          willing to take risks, to experiment with new technologies and
          business models, and to work together across sectors and borders to
          create a better future for all. I'm excited to be a part of this
          journey, and I look forward to working with all of you to make it a
          reality.
        </p>
      </>
    ),
  },
  {
    name: "Sachin Tendulkar",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/SachinTendulkar.png`,
    title: "The Wisdom of Sachin Tendulkar",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          I am Sachin Tendulkar, a former cricketer from India and a proud son
          of my motherland. I have always been passionate about serving my
          people and making a difference in their lives. That is why, when the
          opportunity arose to serve as the Young Peoples Minister for Asia
          Pacific East region in the Principality of Cogito, I gladly accepted
          it.
        </p>

        <p>
          My vision as the Young Peoples Minister for Asia Pacific East region
          in the Cogito Metaverse is to create a vibrant and inclusive community
          where young people can thrive and reach their full potential. I
          believe that the youth are the future of our society, and it is our
          responsibility to provide them with the right opportunities, resources
          and guidance to succeed in life. I will work tirelessly to ensure that
          every young person in the region has access to quality education,
          healthcare, employment, and social services.
        </p>

        <p>
          As for my vision for the future of the Cogito Metaverse, I see it as a
          place of endless possibilities, where people from all walks of life
          can come together to create, collaborate and innovate. My goal is to
          build a Metaverse that is not only technologically advanced but also
          socially responsible, where the well-being of its inhabitants is
          always a top priority. I envision a Cogito Metaverse that is
          sustainable, equitable and inclusive, where everyone has a voice and
          can contribute to the greater good.
        </p>

        <p>
          In conclusion, I am honored to serve as the Young Peoples Minister for
          Asia Pacific East region in the Principality of Cogito, and I am
          committed to working hard to create a better future for our youth and
          our community. I believe that with determination, dedication and
          collaboration, we can achieve great things and build a Metaverse that
          we can all be proud of.
        </p>
      </>
    ),
  },
  {
    name: "Lee Kun-hee",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/LeeKunHee.png`,
    title: "The Wisdom of Lee Kun-hee",
    position: "Business Minister",
    bio: (
      <>
        <p>
          As the chairman of Samsung Group, I have always been committed to
          excellence and innovation in the world of business. My passion for
          driving progress and achieving success has led me to accept the
          Ministerial role in the Principality of Cogito. I believe that my
          experience and expertise can help advance the economic growth of the
          country and contribute to the betterment of its people.
        </p>
        <p>
          As the Business Minister for Asia Pacific East region in the Cogito
          Metaverse, my vision is to create an ecosystem that fosters creativity
          and innovation. I believe that by providing entrepreneurs with the
          resources and support they need, we can empower them to build
          successful businesses that drive economic growth and create jobs.
          Through strategic partnerships and collaborations, we can harness the
          power of technology to create new opportunities for businesses and
          individuals alike.
        </p>{" "}
        <p>
          My vision for the future of Cogito Metaverse is one where we continue
          to push the boundaries of what is possible. We will leverage the
          latest advancements in technology to create a vibrant and dynamic
          economy that benefits everyone. By fostering an environment of
          innovation and collaboration, we will empower our citizens to realize
          their full potential and achieve their dreams. I believe that Cogito
          Metaverse has the potential to become a global leader in business and
          innovation, and I am committed to doing everything in my power to make
          that vision a reality.
        </p>
      </>
    ),
  },
  {
    name: "Anathan Pham",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/AnathanPham.png`,
    title: "The Wisdom of Anathan Pham",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, fellow inhabitants of the digital realm. I, Anathan Pham,
          am humbled and honored to assume the esteemed position of Minister for
          the Asia Pacific East Region within the Principality of Cogito. With
          an unwavering passion for technological advancements and a profound
          belief in the power of the metaverse, I am dedicated to serving the
          virtual community and fostering its growth and prosperity.
        </p>

        <p>
          As the Minister, my responsibilities encompass a wide array of tasks.
          First and foremost, I shall strive to establish a robust and
          interconnected network within the Asia Pacific East Region, ensuring
          seamless communication and collaboration among its denizens. I shall
          also work diligently to promote innovation, entrepreneurship, and the
          development of cutting-edge technologies that enhance the cogito
          metaverse experience.
        </p>

        <p>
          As Minister, I perceive a world where the Cogito Metaverse transcends
          its current boundaries and becomes a vibrant hub of creativity,
          commerce, and social interaction. Through the collaborative efforts of
          its inhabitants, the metaverse shall evolve into an expansive
          ecosystem, replete with immersive virtual experiences and boundless
          opportunities. It shall serve as a gateway to new realms of knowledge,
          entertainment, and personal growth, empowering individuals to explore,
          connect, and redefine their virtual identities. It will become a place
          where individuals from all walks of life unite to share ideas,
          collaborate on projects, and tackle the pressing challenges of our
          time. Through the power of collective intelligence, we shall unlock
          unprecedented potential and make significant strides towards a
          brighter future.
        </p>

        <p>
          Rest assured, dear denizens, that I shall dedicate my every waking
          moment to nurturing this vision. Together, we shall embark on an
          extraordinary journey, crafting a metaverse that embodies the ideals
          of inclusivity, innovation, and human connection.
        </p>

        <p>
          Long live the Principality of Cogito, and may the metaverse thrive
          under our guidance!
        </p>
      </>
    ),
  },
  {
    name: " Dacre Kayd Montgomery",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/DacreKaydMontgomery.png`,
    title: "The Wisdom of Dacre Kayd Montgomery",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, folks! Dacre Kayd Montgomery here, taking on the role of
          Minister for the Asia Pacific East Region in the Principality of
          Cogito. Let me give you a glimpse of who I am and what I bring to the
          table.
        </p>

        <p>
          I am an actor who's been involved in some exciting projects, I've
          always had a keen interest in technology and its impact on our lives.
          Being appointed as Minister allows me to combine my passions for
          innovation and community building, and I couldn't be more thrilled
          about it.
        </p>

        <p>
          Now, let's talk about what the future holds for the Cogito Metaverse
          under my watchful eye. I see a world where this digital realm becomes
          an electrifying, immersive playground that captivates people from all
          walks of life. We're talking about a metaverse that seamlessly
          integrates cutting-edge technologies like virtual reality, augmented
          reality, and artificial intelligence to create mind-blowing
          experiences. In this future, the Cogito Metaverse will serve as a
          haven for collaboration, creativity, and personal growth. It will be a
          place where individuals can explore new dimensions, connect with
          others on a profound level, and unleash their limitless potential.
        </p>

        <p>
          We'll work hard to ensure that everyone feels welcome and represented
          within the metaverse's digital tapestry. As Minister, my aim is to
          nurture this vision by fostering collaboration, promoting dialogue,
          and supporting the development of groundbreaking technologies. We'll
          create an environment that empowers individuals, fosters creativity,
          and sparks innovation. Together, we'll shape the Cogito Metaverse into
          a vibrant, dynamic digital nation that leaves a lasting impact on the
          world.
        </p>

        <p>
          So buckle up, my friends, because we're about to embark on an
          exhilarating journey. The future of the Cogito Metaverse is looking
          brighter than ever, and I couldn't be more excited to be a part of it.
          Let's make some magic happen!
        </p>
      </>
    ),
  },
  {
    name: "Jessica Stein",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/JessicaStein.png`,
    title: "The Wisdom of Jessica Stein",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, lovely people! Jessica Stein here, stepping into the role
          of Minister for the Asia Pacific East Region in the Principality of
          Cogito.
        </p>
        <p>
          First things first, I'm absolutely honored to be chosen for this
          position. As someone deeply passionate about the intersection of
          technology and society, being appointed as Minister is a dream come
          true. With my experience in digital innovation and community
          engagement, I'm ready to roll up my sleeves and make a meaningful
          impact.
        </p>{" "}
        <p>
          Now, let's talk about the future of the Cogito Metaverse under my
          guidance. Picture this: a dynamic, interconnected digital landscape
          that blurs the lines between the virtual and the real. In my vision,
          the Cogito Metaverse becomes a thriving ecosystem where individuals
          can explore, connect, and express themselves in ways that were once
          unimaginable. It'll be a haven for entrepreneurs, artists, and
          thinkers, providing them with the tools and platforms to bring their
          visions to life. We'll nurture an environment that fosters the growth
          of startups, encourages ground-breaking research, and supports the
          arts in all their forms.
        </p>
        <p>
          But here's the kicker: the Cogito Metaverse won't just be a tech
          utopia. It'll be a place that celebrates diversity, inclusivity, and
          social progress. We'll prioritize building a community that thrives on
          respect, empathy, and shared values. As Minister, my focus will be on
          forging partnerships, fostering collaboration, and creating
          opportunities for the Asia Pacific East Region within the metaverse.
          I'll work closely with individuals, organizations, and governments to
          ensure that we maximize the potential of this digital frontier.
        </p>
        <p>
          Together, we'll push boundaries, break barriers, and pave the way for
          a future that benefits everyone. Let's make the future of the Cogito
          Metaverse a reality, one digital step at a time!
        </p>
      </>
    ),
  },
  {
    name: "Nagita Slavina",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsianPacificEast/NagitaSlavina.png`,
    title: "The Wisdom of Nagita Slavina",
    position: "Member",
    bio: (
      <>
        <p>
          Hello, everyone! Nagita Slavina here, taking on the esteemed position
          of Minister for the Asia Pacific East Region in the Principality of
          Cogito. Allow me to introduce myself and shed some light on my role in
          this incredible venture.
        </p>
        <p>
          I am truly grateful and honored to be entrusted with such a
          significant responsibility. I am determined to make a positive
          difference in the lives of the metaverse's inhabitants. I envision a
          world where the metaverse becomes a vibrant and alluring realm,
          captivating individuals from every corner of the globe. It will be a
          sanctuary for artists, entrepreneurs, and visionaries to come
          together, inspire one another, and manifest their dreams into reality.
          The metaverse will be a catalyst for the birth of new ideas, ventures,
          and cultural movements.
        </p>{" "}
        <p>
          Moreover, the Cogito Metaverse will transcend physical boundaries,
          uniting people from diverse backgrounds and cultures. It will be a
          melting pot of experiences, where individuals can share their stories,
          learn from one another, and build bridges of understanding. Through
          this interconnectedness, the metaverse will foster a sense of global
          community and collaboration.
        </p>
        <p>
          As Minister, I am committed to cultivating an environment that
          promotes inclusivity, diversity, and equality within the Cogito
          Metaverse. I will champion initiatives that empower individuals,
          encourage dialogue, and ensure that everyone's voices are heard. By
          working closely with communities and stakeholders, we will shape a
          metaverse that reflects the richness and beauty of humanity. Together,
          we will embark on a journey of limitless possibilities and
          transformative experiences. The future of the Cogito Metaverse is
          bright and promising, and I am thrilled to be a part of this
          extraordinary endeavor. Let's embrace the digital age and create a
          metaverse that inspires, empowers, and brings joy to all who enter its
          virtual realm.
        </p>
      </>
    ),
  },
];
const AsiaPacificWest = [
  {
    name: "Gail Kelly",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/GailKelly.png`,
    title: "The Wisdom of Gail Kelly",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          I'm thrilled to share my story and my vision for the future of the
          Cogito Metaverse. My name is Gail Kelly, and I have spent my entire
          career in the banking and finance industry, working with some of the
          largest and most successful companies in the world. I am deeply
          committed to innovation, growth, and success, and I believe that these
          qualities are essential to building a strong and thriving economy.
        </p>
        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I am passionate about creating opportunities for people and businesses
          to succeed. I believe that the Cogito Metaverse has enormous potential
          to transform the global economy, and I am excited to be a part of this
          important work.
        </p>{" "}
        <p>
          As the Chief Executive Minister for the Asia Pacific West region in
          the Cogito Metaverse, my vision is to create a dynamic and thriving
          economic hub that will attract businesses, investors, and
          entrepreneurs from around the world. I believe that the key to
          achieving this vision is to focus on innovation, collaboration, and
          excellence in all that we do. By leveraging the unique strengths and
          capabilities of our region, we can create a vibrant and dynamic
          ecosystem that will drive growth, prosperity, and success for everyone
          involved.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth, expansion, and innovation. I believe that this
          platform has the potential to revolutionize the way we live, work, and
          interact with one another, and I am committed to ensuring that we
          continue to push the boundaries of what is possible. Whether we are
          exploring new technologies, building new partnerships, or expanding
          our global reach, I am confident that the Cogito Metaverse will
          continue to lead the way in creating a better, brighter future for
          all.
        </p>
      </>
    ),
  },
  {
    name: "Dr. Shigeru Miyagawa",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/ShigeruMiyagawa.png`,
    title: "The Wisdom of Dr. Shigeru Miyagawa",
    position: "Education Minister",
    bio: (
      <>
        <p>
          Greetings. I am Dr. Shigeru Miyagawa, a Japanese linguist and
          professor of linguistics at the Massachusetts Institute of Technology.
          My research focuses on language and its evolution, with a particular
          emphasis on the relationship between language and culture.
        </p>
        <p>
          I accepted the Ministerial role in Principality of Cogito because I
          believe that the Cogito Metaverse represents an exciting opportunity
          to explore the intersection of language, culture, and technology in
          new and innovative ways. As an educator and scholar, I am always
          interested in exploring new frontiers of learning and the Cogito
          Metaverse provides a unique platform for experimentation and
          exploration.
        </p>{" "}
        <p>
          As Education Minister in the Cogito Metaverse, my vision is to create
          an immersive learning environment that fosters creativity,
          collaboration, and critical thinking. My goal is to create an
          educational ecosystem that enables learners to develop their language
          and communication skills, as well as their cultural awareness and
          understanding. This will require the integration of cutting-edge
          technologies, such as virtual and augmented reality, with more
          traditional teaching methods and approaches.
        </p>
        <p>
          With Cogito Metaverse, we can create a world in which language and
          culture are celebrated and valued, and where individuals from all
          backgrounds can come together to share their perspectives and learn
          from one another. By leveraging the power of technology to create a
          truly global learning community, we can help to bridge cultural
          divides and promote mutual understanding and respect. I am excited to
          be a part of this innovative project and look forward to working with
          my colleagues to make this vision a reality.
        </p>
      </>
    ),
  },
  {
    name: "Keith Urban",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/KeithUrban.png`,
    title: "The Wisdom of Keith Urban",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          Well, hello mate! Keith Urban here. When I first heard about the
          opportunity to take on the role of Creative and Arts Minister in the
          Principality of Cogito, I was intrigued. As an artist and musician, I
          have always been drawn to creativity in all its forms, and I saw this
          as a chance to bring that passion to a new and exciting space.
        </p>{" "}
        <p>
          For me, being Creative and Arts Minister is all about using my
          experience and expertise to help others unlock their own creativity
          and find their own voice. I believe that the arts have the power to
          unite people, to inspire change, and to bring joy and beauty into the
          world. My vision for the Cogito Metaverse is to create a space where
          people from all walks of life can come together to share their
          stories, to explore their passions, and to create something truly
          special. I want to see the Metaverse become a hub for artistic
          innovation and collaboration, a place where artists of all kinds can
          come to learn, grow, and thrive.
        </p>
        <p>
          To achieve this, I believe we need to focus on building strong
          partnerships and networks between artists, institutions, and
          communities. We need to create opportunities for mentorship and
          professional development, and we need to provide the resources and
          support necessary to make creative projects a reality. Looking ahead,
          my ultimate goal for Cogito Metaverse is for it to become a global
          leader in the arts and creative industries, a place where people from
          all over the world come to experience the very best in music, art,
          film, and more. I believe that with the right investments, policies,
          and partnerships, we can make this vision a reality, and create
          something truly remarkable for generations to come.
        </p>
      </>
    ),
  },
  {
    name: "Sir Edmund Hillary",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/EdmundHillary.png`,
    title: "The Wisdom of Sir Edmund Hillary",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          I'm Sir Edmund Hillary, a New Zealand mountaineer and explorer. I am
          known for being the first person to reach the summit of Mount Everest,
          along with Tenzing Norgay, in 1953. I have always been drawn to
          adventure and exploration, and I believe that the spirit of
          exploration and pushing oneself to new limits is essential to personal
          growth and progress.
        </p>
        <p>
          As the Sports Minister for West Asia Pacific region in the Cogito
          Metaverse, I am excited to bring this same spirit of adventure and
          exploration to the realm of sports. I believe that sports have the
          power to inspire and unite people from all walks of life, and I am
          committed to promoting this message throughout the region.
        </p>{" "}
        <p>
          My vision for the future of the Cogito Metaverse is one in which
          sports are accessible to all, regardless of their background or
          circumstances. I believe that sports have the power to transform lives
          and communities, and I am dedicated to ensuring that everyone has the
          opportunity to participate in and benefit from the many positive
          aspects of sports.
        </p>
        <p>
          I believe that through the Cogito Metaverse, we can create a world
          where sports are a positive force for change and where individuals are
          empowered to achieve their full potential. By working together and
          embracing the spirit of adventure and exploration, I am confident that
          we can create a brighter and more inclusive future for all.
        </p>
      </>
    ),
  },
  {
    name: "Dr. Fred Hollows",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/FredHollows.png`,
    title: "The Wisdom of Dr. Fred Hollows",
    position: "Health Minister",
    bio: (
      <>
        <p>
          I'm Dr. Fred Hollows, an ophthalmologist who's devoted my life to
          restoring sight to those who are needlessly blind. My work has taken
          me to some of the most remote and underserved communities around the
          world, where I've witnessed firsthand the devastating impact that
          preventable blindness can have on individuals, families, and entire
          communities.
        </p>

        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe that every individual deserves access to high-quality
          healthcare, regardless of their background or circumstances. My goal
          as Health Minister for the Asia Pacific West region in the Cogito
          Metaverse is to ensure that all individuals have access to the eye
          care services they need to prevent and treat blindness. This will
          involve collaborating with a range of stakeholders, including
          healthcare providers, policymakers, and community organizations.
        </p>

        <p>
          My vision for the Asia Pacific West region is to create a world where
          preventable blindness is a thing of the past. This will require a
          sustained commitment to expanding access to eye care services,
          particularly in underserved and remote communities. We'll need to
          invest in training programs for eye care providers, as well as in
          developing innovative solutions to reach those who may be difficult to
          reach. Through partnerships with local communities, we can create
          sustainable eye care programs that will have a lasting impact.
        </p>

        <p>
          Looking to the future of the Cogito Metaverse, I envision a world
          where healthcare is a right, not a privilege. This will require a
          sustained commitment to investing in healthcare infrastructure, as
          well as in training and supporting healthcare providers. We must work
          together to create a world where all individuals have access to the
          care they need to live healthy, fulfilling lives.
        </p>
      </>
    ),
  },
  {
    name: "Tenzin Gyatso",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/TenzinGyatso.png`,
    title: "The Wisdom of Tenzin Gyatso",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          I am deeply honored to have been appointed as Environment Minister for
          Asia Pacific West region in the Principality of Cogito. As someone who
          has long been committed to promoting environmental awareness and
          sustainability, I see this role as a unique opportunity to make a
          meaningful contribution to the well-being of our planet and its
          people.
        </p>
        <br />

        <p>
          My decision to accept this position was motivated by a strong belief
          in the importance of environmental protection and the need for
          collective action to address the urgent challenges facing our world
          today. I believe that we all have a responsibility to care for our
          planet and its natural resources, and that it is only through working
          together that we can create a sustainable and just future for all.
        </p>

        <p>
          As Environment Minister for Asia Pacific West region, my vision is to
          promote a holistic and integrated approach to environmental management
          that takes into account the complex interactions between human society
          and the natural world. This will involve working closely with local
          communities, businesses, and other stakeholders to identify key
          challenges and develop effective strategies for addressing them.
        </p>

        <p>
          In particular, I will be focusing on promoting sustainable practices
          in areas such as agriculture, forestry, and energy production, as well
          as protecting biodiversity and ecosystem services. I believe that by
          taking a comprehensive and collaborative approach to environmental
          management, we can create a more resilient and sustainable future for
          the region and its people. This will require a deep commitment to
          reducing our ecological footprint, promoting renewable energy and
          resource-efficient practices, and protecting biodiversity and
          ecosystem services.
        </p>

        <p>
          It is my hope that the Cogito Metaverse will continue to be a leader
          in this effort and inspire others around the world to take action for
          the good of all beings.
        </p>
      </>
    ),
  },
  {
    name: "Masayoshi Son",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/MasayoshiSon.png`,
    title: "The Wisdom of Masayoshi Son",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          I am honored to have been asked to serve as the Finance and Investment
          Minister for the Asia Pacific West region in the Principality of
          Cogito. My background as an entrepreneur and investor has given me a
          unique perspective on the importance of innovation, risk-taking, and
          long-term thinking in driving economic growth.
        </p>
        <p>
          As the Finance and Investment Minister, my vision is to foster an
          environment that encourages entrepreneurship and innovation in the
          Asia Pacific West region. By supporting startups and small businesses,
          we can create new industries and jobs that will drive growth and
          prosperity in the Cogito Metaverse. Additionally, I will work to
          attract foreign investment to the region, leveraging my extensive
          global network to connect Cogito with investors and business leaders
          around the world.
        </p>{" "}
        <p>
          My vision for the Cogito Metaverse is one of limitless potential. I
          believe that the Metaverse has the power to transform every aspect of
          our lives, from entertainment and communication to education and
          commerce. My goal as Finance and Investment Minister is to ensure that
          the Principality of Cogito is at the forefront of this transformation,
          leading the world in innovation, creativity, and economic growth.
        </p>
        <p>
          In summary, my vision for the future of the Principality of Cogito is
          one of boldness, innovation, and limitless potential. By embracing
          risk-taking, fostering entrepreneurship, and investing in the future,
          I am confident that we can create a bright and prosperous future for
          all citizens of the Cogito Metaverse.
        </p>
      </>
    ),
  },
  {
    name: "Nelson Mandela",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/NelsonMandela.png`,
    title: "The Wisdom of Nelson Mandela",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          I am honored to be here with you today. As you may know, I am Nelson
          Mandela, a freedom fighter, statesman, and former president of South
          Africa. Throughout my life, I have dedicated myself to the cause of
          justice and human rights, and it is this passion that led me to accept
          the Ministerial role in the Principality of Cogito.
        </p>
        <p>
          As the Human Rights Minister for the Asia Pacific West region in the
          Cogito Metaverse, my vision is to build a world where every person is
          treated with respect and dignity, regardless of their background or
          identity. I believe that this can only be achieved through a
          commitment to the principles of equality, freedom, and justice. I will
          work tirelessly to promote these values, and to ensure that the human
          rights of all people are protected.
        </p>{" "}
        <p>
          Looking to the future of the Cogito Metaverse, my vision is of a world
          where people from all walks of life can come together to build a
          better future. I believe that the Metaverse has the potential to be a
          place of great creativity and innovation, where people can collaborate
          to solve the world's most pressing problems. To achieve this vision,
          we must work to build a culture of inclusivity and diversity, where
          everyone is valued for their unique talents and perspectives.
        </p>
        <p>
          In closing, let me say that I am inspired by the vision and passion of
          the people of the Cogito Metaverse, and I am committed to working
          alongside them to build a better future for all.
        </p>
      </>
    ),
  },
  {
    name: "Roshni Nadar",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/RoshniNadar.png`,
    title: "The Wisdom of Roshni Nadar",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          Hello everyone, I'm Roshni Nadar, a businesswoman and philanthropist.
          I am honored to have been appointed as the Technology Minister for the
          Asia Pacific West region in the Principality of Cogito.
        </p>

        <p>
          I accepted this role because I believe that technology has the power
          to transform lives and communities, and I'm passionate about
          harnessing this power for positive change. I am also excited about the
          potential of the Cogito Metaverse to create new opportunities and
          drive innovation, and I believe that my experience and expertise can
          contribute to this vision.
        </p>

        <p>
          As the Technology Minister for the Asia Pacific West region, my vision
          is to create an ecosystem that nurtures innovation and
          entrepreneurship, and empowers individuals and communities to benefit
          from technology. This includes promoting STEM education, supporting
          start-ups and small businesses, and building partnerships with
          industry, academia, and government. In practical terms, this means
          investing in digital infrastructure, such as high-speed broadband,
          cloud computing, and data centers. It also means supporting the
          development of emerging technologies such as artificial intelligence,
          machine learning, and blockchain, and fostering collaboration and
          knowledge-sharing between stakeholders.
        </p>

        <p>
          To achieve this vision, we need to adopt a human-centered approach to
          technology, and ensure that it is designed and implemented in ways
          that are ethical, transparent, and sustainable. We also need to foster
          innovation and creativity, and build an ecosystem that supports the
          development of new technologies and business models. I am excited to
          be a part of this journey, and I look forward to working with to make
          it a reality.
        </p>
      </>
    ),
  },
  {
    name: "Malala Yousafzai",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/MalalaYousafzai.png`,
    title: "The Wisdom of Malala Yousafzai",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          I am Malala Yousafzai, a young woman from Pakistan who has dedicated
          her life to fighting for girls' education and empowerment. I am
          honored to have been given the opportunity to serve as the Young
          Peoples Minister for Asia Pacific West region in the Principality of
          Cogito.
        </p>

        <p>
          As the Young Peoples Minister for Asia Pacific West region in the
          Cogito Metaverse, I want to ensure that every young person, regardless
          of their gender, ethnicity, or background, has access to quality
          education, healthcare, and other basic human rights. I believe that
          education is the key to unlocking a brighter future for individuals
          and communities, and I will work tirelessly to promote and support
          educational initiatives in the region.
        </p>

        <p>
          As for my vision for the future of the Cogito Metaverse, I see it as a
          place where diversity and inclusion are celebrated and where people
          are free to express themselves and pursue their dreams without fear of
          discrimination or persecution. I envision a Metaverse where human
          rights are respected, where access to education and healthcare is
          universal, and where people work together to create a better future
          for all. In conclusion, I am deeply committed to my role as the Young
          Peoples Minister for Asia Pacific West region in the Principality of
          Cogito, and I look forward to working with my colleagues to create a
          better world for young people everywhere.
        </p>
      </>
    ),
  },
  {
    name: "Jack Ma",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/JackMa.png`,
    title: "The Wisdom of Jack Ma",
    position: "Business Minister",
    bio: (
      <>
        <p>
          Hello, I am Jack Ma, a humble entrepreneur and philanthropist. I am
          honored to have been offered the Ministerial role in the Principality
          of Cogito. My passion for innovation and entrepreneurship has driven
          me throughout my career, and I believe that I can use my experience to
          help create a better future for the people of Cogito.
        </p>
        <p>
          As the Business Minister for Asia Pacific West region in the Cogito
          Metaverse, I aim to empower entrepreneurs and small businesses to
          thrive. I believe that the backbone of any strong economy is a robust
          small business sector. By creating an environment that fosters
          innovation, encourages risk-taking, and provides resources for
          entrepreneurs, we can build a strong and vibrant economy in the Asia
          Pacific West region. I also believe in the power of technology to
          create new opportunities for businesses and individuals alike. By
          leveraging the latest advancements in technology, we can create a more
          connected, efficient, and innovative economy.
        </p>{" "}
        <p>
          My vision for the future of Cogito Metaverse is one where we create a
          sustainable and inclusive economy that benefits all citizens. By
          focusing on innovation, education, and the needs of our communities,
          we can create a brighter future for generations to come. I also
          believe in the importance of collaboration and partnerships. By
          working together with businesses, governments, and organizations
          across the globe, we can create a more connected and prosperous world.
        </p>
        <p>
          I am excited about the potential for Cogito Metaverse to lead the way
          in business and innovation, and I am committed to doing everything in
          my power to make that vision a reality.
        </p>
      </>
    ),
  },
  {
    name: "Yaroslav Naidenov",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/YaroslavNaidenov.png`,
    title: "The Wisdom of Yaroslav Naidenov ",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, folks! Yaroslav Naidenov, professional esports player
          extraordinaire. I'm thrilled to have been appointed as the Minister of
          the Principality of Cogito for the Asia Pacific West Region. It's an
          honor to take on this role and represent our vibrant gaming community
          in this digital nation.
        </p>
        <p>
          As the Minister, my mission is to champion the interests and
          aspirations of the Asia Pacific West Region within the Cogito
          Metaverse. I'll be working tirelessly to promote the growth and
          development of esports and gaming culture in our region, where gamers
          can thrive, compete, and forge unforgettable experiences.
        </p>{" "}
        <p>
          In my role, I'll be laser-focused on fostering an ecosystem that
          nurtures competitive gaming. We'll be pushing the boundaries of
          technology, embracing virtual reality, and exploring innovative ways
          to enhance the esports experience. The Cogito Metaverse will become a
          mecca for gamers, offering cutting-edge platforms, robust
          infrastructure, and abundant opportunities for both professional
          players and aspiring talents. Collaboration will be key to our
          success. I'll be forging alliances with esports organizations,
          sponsors, and industry leaders to attract major tournaments, secure
          lucrative sponsorships, and pave the way for our talented gamers to
          shine on the global stage. We'll be leveling up our training
          facilities, investing in esports education, and creating pathways for
          aspiring players to turn their passion into a legitimate career. The
          Cogito Metaverse will also be a place where gamers can connect,
          socialize, and explore new horizons.
        </p>
        <p>
          In short, the future of the Cogito Metaverse under my watch is nothing
          short of legendary. Get ready to witness esports ascend to new
          heights, as we create a thriving and inclusive gaming ecosystem that
          unites gamers from all corners of the Asia Pacific West Region and
          beyond. It's time to grab those controllers, unleash our skills, and
          make history in the digital realm!
        </p>
      </>
    ),
  },
  {
    name: "Kim Jeong-gyun",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/KimJeongGyun.png`,
    title: "The Wisdom of Kim Jeong-gyun",
    position: "Member",
    bio: (
      <>
        <p>
          Hey, everyone! I'm Kim Jeong-gyun, and I'm honored to represent the
          gaming community as the Minister of the Principality of Cogito for the
          Asia Pacific West Region. I'm thrilled to contribute to the
          advancement of esports within our digital nation.
        </p>
        <p>
          Now, let's dive into the future of the Cogito Metaverse, and trust me,
          it's going to be nothing short of epic! I envision a world where the
          metaverse becomes the ultimate stage for esports. Prepare for
          breathtaking tournaments, intense rivalries, and awe-inspiring
          spectacles that will captivate audiences worldwide.
        </p>{" "}
        <p>
          In my role, I am committed to creating an environment that empowers
          gamers to unleash their full potential. The Cogito Metaverse will
          emerge as a premier destination for competitive gaming, offering
          cutting-edge platforms, state-of-the-art infrastructure, and abundant
          opportunities for both professional players and aspiring talents. Our
          success hinges on collaboration. I will establish strategic
          partnerships with esports organizations, sponsors, and industry
          leaders to bring major tournaments to our region. We will secure
          valuable sponsorships, upgrade training facilities, and establish
          educational programs to nurture the next generation of esports stars.
        </p>
        <p>
          However, our vision extends beyond competition. The Cogito Metaverse
          will foster a vibrant community where gamers can connect, share their
          passion, and embark on new adventures. Virtual meetups, interactive
          events, and social experiences will create a sense of camaraderie and
          unity among gamers from the Asia Pacific West Region and beyond. In
          summary, the future of the Cogito Metaverse under my leadership
          promises to be legendary. Brace yourself as esports reaches new
          heights, and we build a thriving and inclusive gaming ecosystem that
          celebrates the passion and dedication of our gamers. Together, let's
          embark on a path to glory and leave an indelible mark on the digital
          realm!
        </p>
      </>
    ),
  },
  {
    name: "Jian Zihao",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/JianZihao.png`,
    title: "The Wisdom of Jian Zihao",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, friends! Jian Zihao here, excited to contribute my
          expertise as the Minister representing the Asia Pacific West Region in
          the Principality of Cogito. It's a great honor to serve in this
          capacity and play a part in fostering the growth and advancement of
          esports within our digital realm.
        </p>
        <p>
          As Minister, my primary duty is to advocate for the Asia Pacific West
          Region within the Cogito Metaverse, ensuring that the aspirations and
          needs of gamers are recognized and addressed.
        </p>{" "}
        <p>
          My focus will be on cultivating an environment that nurtures the
          development and success of gamers in our region. Collaboration will be
          instrumental in unlocking the metaverse's full potential. By forging
          strategic alliances with esports organizations, sponsors, and industry
          leaders, we will bring prominent tournaments and valuable sponsorships
          to our region. We will invest in state-of-the-art training facilities,
          provide educational resources, and establish pathways to empower
          aspiring players to thrive and excel.
        </p>
        <p>
          But our vision extends beyond competition. The Cogito Metaverse will
          also serve as a vibrant community where gamers can connect, share
          their passions, and forge enduring bonds. Anticipate virtual
          gatherings, interactive events, and social experiences that foster a
          sense of camaraderie and unity among gamers from the Asia Pacific West
          Region and beyond. The future of the Cogito Metaverse under my
          stewardship will be nothing short of remarkable. Prepare yourself for
          an exhilarating journey as we shape a thriving and inclusive gaming
          ecosystem that celebrates the skills and dedication of our gamers.
        </p>
      </>
    ),
  },
  {
    name: "Sakura Miyawaki",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/AsiaPacificWest/SakuraMiyawaki.png`,
    title: "The Wisdom of Sakura Miyawaki",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, everyone! Sakura Miyawaki here, ecstatic to assume the
          esteemed position of Minister for the Asia Pacific West Region in the
          Principality of Cogito. I'm filled with immense joy to contribute to
          the advancement of our digital nation. As the Minister, my utmost duty
          is to advocate for and represent the interests and aspirations of the
          Asia Pacific West Region within the Cogito Metaverse. It is my
          commitment to ensure that the diverse voices of our region are not
          only heard but celebrated, fostering an inclusive and vibrant space
          for all.
        </p>
        <p>
          In my vision, the metaverse will evolve into a sanctuary of boundless
          creativity, expression, and connectivity. It shall become a realm
          where artists, musicians, and performers congregate, shattering
          conventions and captivating audiences in unprecedented ways. As
          Minister, my focus will revolve around nurturing a thriving
          entertainment industry within the Cogito Metaverse. We will establish
          platforms to showcase the talents of exceptional individuals,
          facilitating collaborations on visionary projects, and forging
          connections with fans spanning the globe. Envision a future where
          virtual concerts, interactive performances, and immersive experiences
          become the new standard, delivering unique and unforgettable moments
          to both enthusiasts and artists.
        </p>{" "}
        <p>
          However, our pursuits encompass more than just entertainment. The
          Cogito Metaverse shall serve as a haven for individuals to explore
          their passions, unearth latent talents, and engage in meaningful
          connections. Through virtual fan gatherings, interactive workshops,
          and shared endeavors, we shall cultivate a profound sense of community
          and belonging that transcends physical limitations.
        </p>
        <p>
          Let us paint a digital landscape where music, art, and entertainment
          flourish, touching the hearts of individuals worldwide. I invite you
          to join me on this extraordinary journey as we craft beautiful
          memories together within the realm of digitized possibilities!
        </p>
      </>
    ),
  },
];
const HKChina = [
  {
    name: "Chris Patten",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/ChrisPatten.png`,
    title: "The Wisdom of Chris Patten",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          Hello friends, I am honored to be associated with Cogito Metaverse and
          share with you a little bit about myself. My name is Chris Patten, and
          I have had the privilege of serving in various public roles throughout
          my career. I have always been a passionate advocate for freedom,
          democracy, and human rights, and it is these values that have guided
          me throughout my life.
        </p>

        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe deeply in the potential of this emerging nation. Cogito is a
          place where innovation and creativity are celebrated, and where people
          from all over the world can come together to build a better future. As
          Chief Executive Minister for HK China region in the Cogito Metaverse,
          my vision is to create a thriving ecosystem that fosters collaboration
          and encourages the development of new ideas.
        </p>

        <p>
          I believe that the Cogito Metaverse has the potential to be a true
          beacon of hope for the world. By bringing together people from all
          over the world, we can create a new kind of society that is based on
          cooperation, mutual respect, and shared values. My vision for the
          future of the Cogito Metaverse is one in which people are free to
          pursue their passions, to create new things, and to build a better
          world for themselves and for future generations.
        </p>

        <p>
          I believe that this vision can only be realized through hard work,
          dedication, and a commitment to the values that have always guided me.
          I look forward to working with all of you to make this vision a
          reality, and to building a brighter future for the people of Cogito
          and for the world as a whole. May we all work together towards a
          better tomorrow!
        </p>
      </>
    ),
  },
  {
    name: "Confucius",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/Confucius.png`,
    title: "The Wisdom of Confucius",
    position: "Education Minister",
    bio: (
      <>
        <p>
          Greetings, my dear friends. I am Confucius, a philosopher and teacher
          who lived in ancient China. I am known for my teachings on ethics,
          morality, and social order, which have had a profound impact on
          Chinese culture and civilization.
        </p>
        <p>
          I accepted the Ministerial role in Principality of Cogito because I
          believe that education is the foundation of all that is good and just
          in the world. It is only through education that we can cultivate
          virtue and wisdom, and build a society that is harmonious and
          prosperous. The Cogito Metaverse represents a unique opportunity to
          explore new avenues of education and to bring the benefits of learning
          to a wider audience.
        </p>{" "}
        <p>
          As Education Minister in the Cogito Metaverse, my vision is to create
          a system that emphasizes the cultivation of virtue and the pursuit of
          knowledge. I believe that true education is not just about imparting
          knowledge, but also about developing the character of the learner and
          instilling in them a sense of social responsibility and ethical
          behavior. Through the use of cutting-edge technologies and innovative
          teaching methods, we can create a learning environment that is both
          engaging and transformative.
        </p>
        <p>
          Looking to the future of Cogito Metaverse, my vision is for a world in
          which education is truly universal and accessible to all. By
          harnessing the power of technology, we can overcome the barriers of
          distance and inequality and create a global community of learners who
          are dedicated to the pursuit of knowledge and wisdom. My hope is that
          the Cogito Metaverse will be a beacon of light in a world that is
          often dark and uncertain, and that it will inspire generations to come
          to strive for excellence and virtue in all that they do.
        </p>
      </>
    ),
  },
  {
    name: "Zhang Yimou",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/ZhangYimou.png`,
    title: "The Wisdom of Zhang Yimou",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          Greetings. Zhang Yimou here. I accepted the role of Creative and Arts
          Minister in the Principality of Cogito because I believe that art has
          the power to transcend boundaries and bring people together.
          Throughout my career, I have sought to use my films and other works to
          explore the complexities of the human experience and to create a
          deeper understanding and empathy between people of different cultures
          and backgrounds.
        </p>{" "}
        <p>
          As Creative and Arts Minister, my vision for the Cogito Metaverse is
          to create a space that celebrates and promotes creativity and
          innovation across all disciplines, from film and music to visual arts
          and beyond. I believe that by fostering a vibrant and diverse arts
          community, we can create new opportunities for expression, connection,
          and growth.
        </p>
        <p>
          To achieve this vision, I believe that we need to focus on building
          strong partnerships between artists, institutions, and communities,
          and on promoting education and professional development in the arts.
          By supporting emerging artists and providing the resources and
          opportunities they need to succeed, we can help to create a more
          vibrant and dynamic creative ecosystem in the Metaverse.
        </p>
        <p>
          Looking ahead, my ultimate vision for the Cogito Metaverse is for it
          to become a beacon of creativity and cultural exchange, a place where
          people from all over the world can come together to explore and share
          their passions, and to create something truly special and unique. I
          believe that by fostering a culture of openness, collaboration, and
          innovation, we can create a space that inspires and uplifts people in
          ways that we cannot even imagine.
        </p>
      </>
    ),
  },
  {
    name: "Yao Ming",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/YaoMing.png`,
    title: "The Wisdom of Yao Ming",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          I am Yao Ming, former professional basketball player and current
          Sports Minister for HK China region in the Cogito Metaverse. I was
          born in Shanghai, China and grew up playing basketball, eventually
          earning a spot on the Chinese national team and later playing for the
          Houston Rockets in the NBA.
        </p>
        <p>
          I accepted the Ministerial role in Principality of Cogito because I
          believe that sports have the power to unite people and promote
          physical and mental well-being. Through sports, we can also inspire
          young people and provide them with opportunities to succeed.
        </p>{" "}
        <p>
          As Sports Minister for HK China region in the Cogito Metaverse, my
          vision is to promote the development of youth sports and increase
          participation in all levels of sports in the region.
        </p>
        <p>
          I believe that sports can help build strong communities and provide
          opportunities for people to connect with each other. In addition, I
          want to support the development of sports infrastructure and
          facilities in the region to ensure that everyone has access to quality
          sports facilities.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, I envision a world
          where sports are accessible to everyone, regardless of their
          background or physical ability. I hope to see a society where sports
          are valued for their ability to bring people together and promote
          healthy living, and where athletes are celebrated for their
          achievements on and off the field. I believe that through the power of
          sports, we can build a stronger, more connected society, and I am
          committed to working towards that goal.
        </p>
      </>
    ),
  },
  {
    name: " Sun Yat-sen",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/SunYatSen.png`,
    title: "The Wisdom of Sun Yat-sen",
    position: "Health Minister",
    bio: (
      <>
        <p>
          My fellow citizens of China, I am Sun Yat-sen, a patriot and
          revolutionary who has dedicated my life to the cause of democracy and
          social justice in our great nation.
        </p>
        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe that every individual has the right to a healthy and
          fulfilling life, and that this can only be achieved through
          collaboration and collective action.
        </p>{" "}
        <p>
          As Health Minister for the HK China region in the Cogito Metaverse, my
          vision is to promote a healthcare system that is accessible,
          affordable, and responsive to the needs of all citizens. We must work
          to address the underlying causes of illness, rather than simply
          treating the symptoms, by promoting healthy lifestyles and
          preventative care. In addition, we must ensure that our healthcare
          system is equipped to meet the challenges of the future, by investing
          in research and innovation, as well as in the training and development
          of healthcare professionals.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, I envision a world
          where every individual has the opportunity to thrive, regardless of
          their background or circumstances. This will require a sustained
          commitment to social justice and economic equality, as well as a focus
          on expanding access to education and healthcare. We must work together
          to create a world where all individuals are empowered to make positive
          contributions to their communities and to society as a whole. Through
          collaboration and innovation, we can build a brighter future for
          ourselves and for generations to come.
        </p>
      </>
    ),
  },
  {
    name: "Fuqiang Yang",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/FuqiangYang.png`,
    title: "The Wisdom of Fuqiang Yang",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          I am honored to have been appointed as Environment Minister for HK
          China region in the Principality of Cogito. As someone who has spent
          many years working to promote environmental protection and sustainable
          development in China, I see this role as an important opportunity to
          make a positive contribution to the well-being of our planet and its
          people.
        </p>
        <p>
          My decision to accept this position was motivated by a deep sense of
          commitment to promoting sustainable development and environmental
          protection. I believe that we all have a responsibility to care for
          our planet and its natural resources, and that it is only through
          working together that we can create a sustainable and just future for
          all.
        </p>{" "}
        <p>
          As Environment Minister for HK China region, my vision is to promote a
          holistic and integrated approach to environmental management that
          takes into account the complex interactions between human society and
          the natural world. This will involve working closely with local
          communities, businesses, and other stakeholders to identify key
          environmental challenges and develop effective strategies for
          addressing them. In particular, I will be focusing on promoting
          sustainable practices in areas such as energy production,
          transportation, and waste management, as well as protecting
          biodiversity and ecosystem services. I believe that by taking a
          comprehensive and collaborative approach to environmental management,
          we can create a more resilient and sustainable future for the region
          and its people.
        </p>
        <p>
          I believe that by working together and embracing a shared sense of
          responsibility for our planet, we can create a future that is truly
          sustainable, just, and equitable. It is my hope that the Cogito
          Metaverse will continue to be a leader in this effort and inspire
          others around the world to take action for the good of all beings.
        </p>
      </>
    ),
  },
  {
    name: "Christine Loh",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/ChristineLoh.png`,
    title: "The Wisdom of Christine Loh",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          I am honored to have been asked to serve as the Finance and Investment
          Minister for the HK China region in the Principality of Cogito. My
          background in sustainable development and environmental policy has
          given me a unique perspective on the importance of balancing economic
          growth with social and environmental responsibility.
        </p>

        <p>
          As the Finance and Investment Minister, my vision is to promote
          sustainable investment in the HK China region of the Cogito Metaverse.
          By encouraging investment in green technologies, social enterprises,
          and other sustainable industries, we can create a more equitable and
          environmentally conscious economy that benefits both the citizens of
          Cogito and the planet as a whole. Additionally, I will work to ensure
          that all investments made in the region adhere to the highest ethical
          standards, promoting transparency, accountability, and fairness in all
          financial transactions.
        </p>

        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          sustainable and inclusive growth. I believe that by prioritizing
          sustainability and social responsibility, we can create a more
          equitable and resilient society that benefits all citizens of the
          Metaverse. Additionally, I see the Metaverse as a powerful tool for
          promoting cross-cultural understanding and collaboration, breaking
          down barriers and fostering a more connected and peaceful world.
        </p>

        <p>
          In summary, my vision for the future of the Principality of Cogito is
          one of sustainable development, social responsibility, and
          inclusivity. By leveraging the power of the Metaverse to promote these
          values, I am confident that we can create a brighter, more equitable,
          and more sustainable future for all.
        </p>
      </>
    ),
  },
  {
    name: "Liu Xiaobo",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/Xiaobo.png`,
    title: "The Wisdom of Liu Xiaobo",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          I am Liu Xiaobo, a writer, philosopher, and human rights activist from
          China. Throughout my life, I have been a tireless advocate for
          democracy, freedom, and human rights, and it is this passion that led
          me to accept the Ministerial role in the Principality of Cogito.
        </p>

        <p>
          As the Human Rights Minister for the HK China region in the Cogito
          Metaverse, my vision is to fight for the fundamental rights and
          freedoms of the people of Hong Kong. I believe that all people have
          the right to freedom of expression, association, and peaceful
          assembly, and that these rights must be protected at all costs. I will
          work sincerely to promote these values, and to ensure that the human
          rights of all people in the HK China region are respected and
          protected.
        </p>

        <p>
          Looking to the future of the Cogito Metaverse, my vision is of a world
          where people from all walks of life can come together in the pursuit
          of freedom and justice. I believe that the Metaverse has the potential
          to be a place of great creativity and collaboration, where people can
          work together to solve the world's most pressing problems. To achieve
          this vision, we must work to build a culture of openness,
          transparency, and respect for human rights.
        </p>

        <p>
          In closing, I want to say that I am honored to be part of this
          community, and I am committed to working tirelessly to promote the
          values of freedom, democracy, and human rights.
        </p>
      </>
    ),
  },
  {
    name: " Ma Huateng",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/MaHuateng.png`,
    title: "The Wisdom of Ma Huateng",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          Hello everyone, I am Ma Huateng, also known as Pony Ma, the founder
          and CEO of Tencent. I am honored to have been appointed as the
          Technology Minister for the HK China region in the Principality of
          Cogito.
        </p>

        <p>
          I accepted this role because I believe that technology has the power
          to transform society and drive economic growth, and I am passionate
          about driving innovation and entrepreneurship in the technology
          industry. I am also excited about the potential of the Cogito
          Metaverse to create new opportunities and drive innovation, and I
          believe that my experience and expertise can contribute to this
          vision.
        </p>

        <p>
          As the Technology Minister for the HK China region, my vision is to
          create an ecosystem that fosters innovation, collaboration and growth
          in the technology industry. This includes promoting the development of
          emerging technologies such as artificial intelligence, big data and
          cloud computing, and supporting the growth of start-ups and small
          businesses. In practical terms, this means investing in digital
          infrastructure, such as high-speed broadband, data centers and cloud
          computing platforms. It also means working closely with industry,
          academia and government to build a thriving ecosystem that supports
          the growth of the technology industry.
        </p>

        <p>
          I believe that the Metaverse has the potential to create new
          opportunities for social and economic inclusion, and to address some
          of the world's most pressing challenges. we need to continue to foster
          innovation and creativity, and to build an ecosystem that supports the
          development of new technologies and business models. We also need to
          ensure that technology is developed and implemented in ways that are
          ethical, transparent and sustainable, and that it serves the needs of
          people and society. I am excited to be a part of this journey, and I
          look forward to making it a success.
        </p>
      </>
    ),
  },
  {
    name: "Dr Robert Glover",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/RobertGlover.png`,
    title: "The Wisdom of Dr Robert Glover",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          Well, hello there. I'm Dr. Robert Glover, the founder and CEO of Care
          for Children, an international charity dedicated to improving the
          lives of vulnerable children and families around the world. I am
          honored to have been given the opportunity to serve as the Young
          Peoples Minister for HK China region in the Principality of Cogito.
        </p>
        <p>
          As the Young Peoples Minister for HK China region in the Cogito
          Metaverse, my vision is to create a world where young people can
          thrive and reach their full potential. I believe that young people in
          this region face unique challenges, including societal pressures,
          family expectations, and limited opportunities. As such, I will work
          to create programs and initiatives that help young people develop the
          skills, resources, and support they need to succeed.
        </p>
        <p>
          Looking towards the future of the Cogito Metaverse, I see a world
          where young people are empowered to take control of their lives and
          make a positive impact in their communities and beyond. My vision for
          the future is one where young people have access to high-quality
          education, healthcare, and social services, and where their voices and
          perspectives are valued and respected.
        </p>
        <p>
          In conclusion, I am deeply committed to my role as the Young Peoples
          Minister for HK China region in the Principality of Cogito, and I look
          forward to working with my colleagues to create a better world for
          young people everywhere.
        </p>
      </>
    ),
  },
  {
    name: "Li Ka-shing",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/LiKashing.png`,
    title: "The Wisdom of Li Ka-shing",
    position: "Business Minister",
    bio: (
      <>
        <p>
          I am Li Ka-shing, a businessman and philanthropist with a deep
          commitment to innovation and progress. I have accepted the Ministerial
          role in the Principality of Cogito because I believe that I can use my
          experience and expertise to help create a brighter future for the
          people of Hong Kong and China.
        </p>
        <p>
          As the Business Minister for the HK China region in the Cogito
          Metaverse, my vision is to create a dynamic and innovative economy
          that benefits everyone. I believe that by fostering an environment of
          entrepreneurship and innovation, we can empower businesses to succeed
          and create jobs that drive economic growth. I also believe in the
          importance of sustainability and social responsibility.
        </p>
        <p>
          By creating businesses that are environmentally responsible and
          socially conscious, we can build a better future for our communities.
          My vision for the future of Cogito Metaverse is one where we continue
          to push the boundaries of what is possible. I believe that the power
          of technology can help us create a more connected, efficient, and
          prosperous society. By leveraging the latest advancements in
          technology, we can create new opportunities for businesses and
          individuals alike.
        </p>
        <p>
          I also believe in the importance of collaboration and partnerships. By
          working together with businesses, governments, and organizations
          across the globe, we can create a better future for all. I am
          committed to doing everything in my power to help make the Cogito
          Metaverse a place where businesses thrive.
        </p>
      </>
    ),
  },
  {
    name: "Zhang Ruida",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/ZhangRuida.png`,
    title: "The Wisdom of Zhang Ruida ",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, fellow enthusiasts of the virtual realm! As the newly
          appointed Minister of the Principality of Cogito for the Hong Kong and
          China region, it is an honor to address you all. Allow me to introduce
          myself: I am Zhang Ruida, your avid esports player turned Minister,
          fully dedicated to advancing the realm of Cogito within our metaverse.
        </p>
        <p>
          In this esteemed position, I am entrusted with the responsibility of
          overseeing the affairs and interests of the Hong Kong and China region
          in the digital nation of Cogito. Drawing upon my extensive experience
          in esports, I aim to harness the potential of our vibrant community,
          nurturing and empowering local talents while fostering an environment
          conducive to innovation and growth. As Minister, I envision a realm
          where virtual reality and augmented reality seamlessly intertwine,
          blurring the boundaries between the physical and digital worlds. Our
          metaverse shall evolve into a thriving ecosystem, offering immersive
          experiences, boundless creativity, and meaningful social interactions.
        </p>
        <p>
          Moreover, I believe that education and training will play a pivotal
          role. By investing in robust educational programs and platforms, we
          can equip our aspiring gamers, content creators, and developers with
          the necessary skills and knowledge to thrive in the dynamic world of
          the Cogito Metaverse. Through this emphasis on learning and growth, we
          shall cultivate a generation of digital pioneers who will shape the
          destiny of Cogito.
        </p>
        <p>
          In conclusion, my tenure as Minister of the Principality of Cogito for
          the HK and China Region shall be defined by a relentless pursuit of
          excellence, collaboration, and innovation. Together, let us embark on
          this remarkable journey, embracing the boundless opportunities that
          the future of the Cogito Metaverse holds. Stay tuned for a thrilling
          adventure that will reshape the very fabric of our digital existence!
          Zhang Ruida, signing off!
        </p>
      </>
    ),
  },
  {
    name: "Gong Li",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/GongLi.png`,
    title: "The Wisdom of Gong Li ",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings. I am the newly appointed Minister of the Principality of
          Cogito for the Hong Kong and China region. It is an honor to share a
          glimpse into my life and elected role with all of you.
        </p>
        <p>
          As an esteemed actress, my journey has been one of artistic
          exploration and cultural impact. However, my foray into the world of
          Cogito brings forth a new chapter, one where I shall channel my
          passion and dedication towards advancing this digital nation within
          the metaverse. Drawing upon my vast experience in the world of
          entertainment and storytelling, I aim to infuse our virtual domain
          with a touch of elegance, sophistication, and emotional depth.
        </p>
        <p>
          I envision a tapestry where imagination knows no bounds, where the
          lines between reality and virtual reality intertwine seamlessly. It is
          a realm brimming with endless artistic possibilities, where the
          boundaries of storytelling are pushed to their very limits. During my
          tenure, I aspire to cultivate a vibrant creative ecosystem within
          Cogito, where artists, filmmakers, and visionaries converge to weave
          intricate narratives that resonate deeply with our audience. I foresee
          collaborations with renowned directors, talented actors, and
          innovative minds to produce immersive cinematic experiences that
          transcend the traditional confines of the silver screen.
        </p>
        <p>
          In conclusion, my tenure as Minister of the Principality of Cogito for
          the HK and China Region shall be a harmonious symphony of creativity,
          cultural enrichment, and artistic exploration. Together, let us embark
          on a journey where the Cogito Metaverse becomes a stage for infinite
          possibilities, captivating the hearts and minds of individuals around
          the globe.
        </p>
      </>
    ),
  },
  {
    name: "Jay Chou",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/JayChou.png`,
    title: "The Wisdom of Jay Chou",
    position: "Member",
    bio: (
      <>
        <p>
          I'm Jay Chou, and I'm stoked to be here as the newly appointed
          Minister of the Principality of Cogito for the Hong Kong and China
          region. Let me give you a little glimpse into who I am and what I'm
          here to do.
        </p>
        <p>
          As a singer, songwriter, and all-around music lover, I've had an
          amazing journey in the entertainment industry. But now, I'm ready to
          dive into the digital realm and bring my passion for music and
          creativity to the Cogito Metaverse. In this awesome role, my mission
          is to oversee and represent the interests of the Hong Kong and China
          region within the Principality of Cogito.
        </p>
        <p>
          While I'm in this role, I'll be working closely with fellow musicians,
          producers, and tech-savvy folks to pioneer new ways of experiencing
          music in the digital realm. I see a world where music becomes the
          lifeblood of our virtual existence. It's a place where artists from
          diverse backgrounds come together to collaborate, creating
          mind-blowing compositions that transcend genres and cultures. We're
          talking about mind-bending concerts, immersive soundscapes, and
          interactive musical journeys that will leave you wanting more. But
          it's not just about the music. The Cogito Metaverse has the potential
          to be a hub of creativity in all forms. I'll be championing the fusion
          of music with other art forms, such as visual arts, dance, and
          storytelling. We'll create synergistic experiences that ignite your
          senses and spark your imagination.
        </p>
        <p>
          In a nutshell, my time as Minister of the Principality of Cogito for
          the HK and China Region will be a harmonious symphony of innovation,
          collaboration, and creative expression. Get ready to witness a digital
          revolution where music reigns supreme and the Cogito Metaverse becomes
          a stage for unforgettable performances. Thanks for having me on this
          incredible journey!
        </p>
      </>
    ),
  },
  {
    name: "Li Jiaqi",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/HKChina/LiJiaqi.png`,
    title: "The Wisdom of Li Jiaqi ",
    position: "Member",
    bio: (
      <>
        <p>
          Hello, everyone! Li Jiaqi here, and I'm absolutely thrilled to be
          appointed as the Minister of the Principality of Cogito for the Hong
          Kong and China region. Let me give you the lowdown on who I am and
          what I'm all about.
        </p>
        <p>
          As a Chinese influencer, I've been on an incredible journey in the
          world of beauty, fashion, and lifestyle. But now, I'm ready to take on
          the digital realm and bring my expertise to the Cogito Metaverse. In
          this fabulous role, my primary task is to represent and oversee the
          interests of the Hong Kong and China region within the Principality of
          Cogito. Leveraging my experience as an influencer, I aim to inject the
          metaverse with style, trends, and an unmatched sense of glamour.
        </p>
        <p>
          During my time as Minister, I'll be collaborating with fashion
          designers, beauty brands, and influencers to curate an immersive
          digital experience that brings the best of the fashion and beauty
          industries to Cogito. We'll showcase the hottest looks, unveil
          exclusive collaborations, and provide interactive platforms where
          users can experiment, create, and express their personal style. I
          envision a world where fashion, beauty, and lifestyle seamlessly blend
          with the digital landscape.
        </p>
        <p>
          Moreover, I believe that the Cogito Metaverse should be a place of
          inclusivity and empowerment. I'll be working towards fostering a
          diverse community where people from all backgrounds can find
          inspiration, confidence, and a sense of belonging. We'll celebrate
          individuality and provide opportunities for emerging talents to shine.
          Get ready to immerse yourself in a world where trends come alive,
          beauty knows no boundaries, and style becomes an expression of your
          true self.
        </p>
      </>
    ),
  },
];
const Americas1 = [
  {
    name: "Susan Wojcicki",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/SusanWojcicki.png`,
    title: "The Wisdom of Susan Wojcicki ",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          Hi there! I'm Susan Wojcicki and I thrilled to be associated with
          Cogito Metaverse in Ministerial role.
        </p>
        <p>
          I have spent my entire career in the tech industry, working with some
          of the biggest and most innovative companies in the world. As the CEO
          of YouTube, I have seen firsthand the incredible power of technology
          to connect people and build communities, and I am deeply committed to
          leveraging this power for the greater good.
        </p>
        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe that the Cogito Metaverse has the potential to be a
          transformative force for good in the world. By bringing people
          together and fostering collaboration and innovation, we can create a
          more connected and prosperous world for everyone. As the Chief
          Executive Minister for the Americas region in the Cogito Metaverse, my
          vision is to create a vibrant and dynamic ecosystem that will drive
          growth, innovation, and collaboration across the entire region. I
          believe that the key to achieving this vision is to foster a culture
          of openness, inclusivity, and excellence, where everyone is encouraged
          to share their ideas and talents and work together towards common
          goals.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth, innovation, and impact. I believe that this platform
          has the potential to revolutionize the way we live, work, and interact
          with one another, and I am committed to ensuring that we continue to
          push the boundaries of what is possible. Whether we are exploring new
          technologies, building new partnerships, or expanding our global
          reach, I am confident that the Cogito Metaverse will continue to be a
          driving force for positive change in the world.
        </p>
      </>
    ),
  },
  {
    name: "Howard Gardner ",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/HowardGardner.png`,
    title: "The Wisdom of Howard Gardner ",
    position: "Education Minister",
    bio: (
      <>
        <p>
          As the Education Minister of the Principality of Cogito for the
          Americas, I am honored to serve in a position that allows me to
          contribute to the development and advancement of education within this
          digital country. My background as a renowned cognitive psychologist
          and educational theorist provides me with a deep understanding of
          human intelligence and the various ways in which individuals learn and
          excel.
        </p>
        <p>
          In this elected role, my primary responsibility is to ensure the
          provision of quality education within the Cogito Metaverse for the
          citizens of the Americas. I firmly believe in the significance of a
          holistic approach to education, one that acknowledges and nurtures the
          multiple intelligences that reside within each individual. It is my
          objective to promote a rich and diverse educational experience that
          caters to the unique strengths, talents, and aspirations of the
          learners.
        </p>
        <p>
          Regarding the future of the Cogito Metaverse, I envision a thriving
          educational landscape that embraces technological advancements while
          upholding the fundamental principles of effective pedagogy. The
          integration of virtual reality, artificial intelligence, and other
          emerging technologies within the metaverse holds great promise for
          immersive and personalized learning experiences. By leveraging these
          tools, we can create dynamic educational environments that cater to
          the diverse needs and preferences of learners, fostering their
          intellectual growth and enhancing their critical thinking skills.
        </p>
        <p>
          Ultimately, my goal as the Education Minister of the Principality of
          Cogito for the Americas is to facilitate an educational ecosystem that
          celebrates and empowers learners, equipping them with the necessary
          tools, competencies, and values to thrive in a rapidly changing world.
          Through my unwavering commitment to educational excellence and
          inclusivity, I am confident that the Cogito Metaverse will serve as a
          beacon of inspiration, knowledge, and enlightenment for generations to
          come.
        </p>
      </>
    ),
  },
  {
    name: "Walt Disney",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/WaltDisney.png`,
    title: "The Wisdom of Walt Disney",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          "Well hello there, folks. Walt Disney here. I accepted the role of
          Creative and Arts Minister in the Principality of Cogito because I
          believe that creativity and imagination are the keys to unlocking the
          limitless potential of the human mind. Throughout my career, I sought
          to use my work to inspire, entertain, and uplift people of all ages
          and backgrounds, and I see the Cogito Metaverse as an exciting new
          opportunity to continue that legacy.
        </p>{" "}
        <p>
          As Creative and Arts Minister, my vision for the Cogito Metaverse is
          to create a world-class destination for artists, creators, and
          dreamers from all over the world. I want to see the Metaverse become a
          place where people can come together to share their ideas, collaborate
          on new projects, and push the boundaries of what's possible in the
          world of art and entertainment.
        </p>{" "}
        <p>
          To achieve this vision, I believe that we need to focus on three key
          things: innovation, imagination, and collaboration. We need to embrace
          new technologies and platforms that allow us to create more immersive
          and interactive experiences for audiences, while also tapping into the
          power of our own imaginations to bring those experiences to life.
        </p>{" "}
        <p>
          Looking ahead, my ultimate vision for the Cogito Metaverse is to
          create a world that is filled with wonder and possibility, a place
          where anything is possible and where people are free to explore,
          create, and dream without limitations. I want to see the Metaverse
          become a global hub for creativity and innovation, a place where
          people from all over the world can come together to share their ideas
          and build a better future for all of us.
        </p>
      </>
    ),
  },
  {
    name: "Christiano Ronaldo",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/ChristianoRonaldo.png`,
    title: "The Wisdom of Christiano Ronaldo",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          I am Cristiano Ronaldo, one of the greatest football players in the
          world. I am honored to have been appointed as the Sports Minister for
          the Americas region in the Cogito Metaverse. As a passionate athlete
          and advocate for fitness and health, I believe that sports can bring
          people together and inspire positive change in society.
        </p>{" "}
        <p>
          My vision as the Sports Minister for the Americas region is to promote
          a healthy and active lifestyle among the people. I believe that sports
          can be a great tool to promote physical and mental health, build
          communities, and foster social and cultural integration. I will work
          towards creating opportunities for people of all ages and backgrounds
          to participate in sports, and encourage them to adopt healthy habits.
        </p>{" "}
        <p>
          In addition, I will work towards improving the infrastructure and
          facilities for sports in the Americas region. I believe that investing
          in sports facilities can create jobs, boost tourism, and promote
          economic growth. I will work with the government and private sector to
          build state-of-the-art sports facilities, create training programs for
          coaches and athletes, and promote sports tourism in the region.
        </p>{" "}
        <p>
          As for the future of the Cogito Metaverse, I see a world where people
          from different backgrounds and cultures come together to celebrate
          sports and healthy living. I envision a future where sports are
          accessible to all, and where people can use the power of sports to
          build communities and inspire positive change. With hard work,
          dedication, and a commitment to excellence, I believe that we can
          create a world where sports are a source of inspiration and unity for
          all.
        </p>
      </>
    ),
  },
  {
    name: "William Osler",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/WilliamOsler.png`,
    title: "The Wisdom of William Osler",
    position: "Health Minister",
    bio: (
      <>
        <p>
          My dear colleagues, I am William Osler, a physician and educator who
          has dedicated my life to advancing the field of medicine and improving
          the health and wellbeing of individuals around the world.
        </p>
        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe that healthcare is a fundamental human right, and that we
          must work together to ensure that all individuals have access to
          high-quality medical care.
        </p>
        <p>
          As Health Minister for the Americas region in the Cogito Metaverse, my
          vision is to promote a healthcare system that is based on scientific
          evidence, compassionate care, and continuous learning. We must work to
          create a culture of medical excellence, where healthcare professionals
          are committed to ongoing education and training, and where patients
          are empowered to take an active role in their own care. In addition,
          we must work to address the underlying social determinants of health,
          such as poverty and inequality, that contribute to poor health
          outcomes in many communities.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, I envision a world
          where healthcare is truly patient-centered, where individuals are
          empowered to take control of their own health, and where medical
          research and innovation is focused on meeting the needs of patients.
          We must work to foster a culture of collaboration and innovation,
          where healthcare professionals, researchers, and patients work
          together to advance the frontiers of medical knowledge and improve
          health outcomes for all. Through our collective efforts, we can build
          a world where healthcare is truly a fundamental human right, and where
          every individual has the opportunity to lead a healthy and fulfilling
          life.
        </p>
      </>
    ),
  },
  {
    name: "Eduardo Brondizio",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/EduardoBrondizio.png`,
    title: "The Wisdom of Eduardo Brondizio",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          I am honored to have been appointed as the Environment Minister for
          the Americas region in the Principality of Cogito. As someone who has
          dedicated my life's work to understanding the complex relationships
          between humans and their environments, I see this role as a critical
          opportunity to promote a more sustainable and equitable future for
          all.
        </p>
        <p>
          My decision to accept this position was driven by a deep commitment to
          advancing environmental justice and sustainable development in the
          Americas. I believe that we have a collective responsibility to care
          for our planet and its ecosystems, and that it is only through working
          together that we can achieve a more just and sustainable future.
        </p>
        <p>
          As Environment Minister for the Americas region, my vision is to
          promote an integrated and collaborative approach to environmental
          management that acknowledges the complex and dynamic relationships
          between people and their environments. This will involve working
          closely with local communities, stakeholders, and policymakers to
          identify key environmental challenges and develop effective strategies
          for addressing them. In particular, I will be focusing on promoting
          sustainable practices in areas such as land use, resource management,
          and environmental governance, as well as addressing the social and
          economic inequalities that often drive environmental degradation and
          exploitation.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, my vision is for a
          community that prioritizes environmental sustainability, social
          justice, and cultural diversity in all aspects of its development.
          This will require a deep commitment to promoting community-driven and
          participatory approaches to environmental management, as well as
          fostering partnerships between different sectors and actors.
          Ultimately, my hope is that the Cogito Metaverse will serve as a model
          for a more sustainable and just future, inspiring other communities
          and policymakers to prioritize environmental protection and social
          equity in their own work.
        </p>
      </>
    ),
  },
  {
    name: "Adena Friedman",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/AdenaFriedman.png`,
    title: "The Wisdom of Adena Friedman",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          I am honored to have been asked to serve as the Finance and Investment
          Minister for the Americas region in the Principality of Cogito. As the
          CEO of one of the world's largest stock exchanges, I have a deep
          understanding of the complexities of global finance and the importance
          of responsible investment.
        </p>
        <p>
          My vision for the Americas region in the Cogito Metaverse is one of
          innovation and growth. By promoting investment in cutting-edge
          technologies and emerging industries, we can create new opportunities
          for economic growth and job creation across the region. Additionally,
          I believe that by encouraging ethical and sustainable investment
          practices, we can promote long-term growth and stability in the
          region's economy.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          connectivity and collaboration. By leveraging the power of digital
          technologies and the Metaverse, we can create new opportunities for
          cross-border cooperation and cultural exchange, promoting a more
          interconnected and peaceful world.
        </p>
        <p>
          In summary, my vision for the future of the Principality of Cogito is
          one of responsible investment, innovation, and collaboration. By
          working together to build a sustainable and inclusive economy, I am
          confident that we can create a brighter future for all citizens of the
          Metaverse.
        </p>
      </>
    ),
  },
  {
    name: "Martin Luther King",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/MartinLutherKing.png`,
    title: "The Wisdom of Martin Luther King",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          I am Martin Luther King Jr., a civil rights leader from the United
          States. I have dedicated my life to the pursuit of equality, justice,
          and freedom for all people, and it is this passion that led me to
          accept the Ministerial role in the Principality of Cogito.
        </p>
        <p>
          As the Human Rights Minister for the Americas region in the Cogito
          Metaverse, my vision is of a world where all people are treated with
          dignity, respect, and equality. I believe that all people, regardless
          of their race, gender, or background, have the right to live free from
          discrimination and oppression, and it is our duty as human beings to
          fight for these rights. I will work tirelessly to promote justice and
          equality throughout the Americas region, and to ensure that the human
          rights of all people are protected.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, my vision is of a world
          where people from all nations can come together in the spirit of
          brotherhood and sisterhood. I believe that the Metaverse has the
          potential to be a place of great unity and collaboration, where people
          can work together to build a better world. To achieve this vision, we
          must work to build a culture of peace, nonviolence, and social
          justice.
        </p>
        <p>
          In conclusion, I am honored to be a part of the Cogito community, and
          I am committed to working tirelessly to promote the values of
          equality, justice, and freedom. Together, we can build a better world
          for all people, and create a brighter future for generations to come.
        </p>
      </>
    ),
  },
  {
    name: "Elon Musk",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/ElonMusk.png`,
    title: "The Wisdom of Elon Musk",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          Hey there, I'm Elon Musk, the CEO of Tesla, SpaceX, Neuralink, and The
          Boring Company. I accepted the Ministerial role in Principality of
          Cogito because I believe that technology has the power to change the
          world and the Cogito Metaverse is one of the most exciting and
          promising frontiers in technology today.
        </p>
        <p>
          As the Technology Minister for the Americas region in the Cogito
          Metaverse, my vision is to leverage the power of technology to create
          a sustainable and prosperous future for all. This includes promoting
          the development of renewable energy, the adoption of electric
          vehicles, and the advancement of sustainable transportation.
        </p>
        <p>
          In practical terms, this means investing in technologies such as solar
          power, battery storage, and electric vehicles, as well as developing
          new forms of sustainable transportation, such as high-speed
          underground tunnels. It also means exploring the potential of emerging
          technologies such as artificial intelligence, robotics, and
          biotechnology to drive innovation and create new opportunities.
          Looking to the future, my vision for the Cogito Metaverse is one in
          which technology plays a central role in shaping a better world. This
          means developing technologies that are safe, ethical, and sustainable,
          and using them to address some of the most pressing challenges facing
          humanity, such as climate change, healthcare, and education.
        </p>
        <p>
          To achieve this vision, we need to continue to push the boundaries of
          what is possible, and to encourage innovation and entrepreneurship at
          every level. We also need to work together across industries,
          academia, and government to develop technologies that are aligned with
          our values and that serve the needs of people and society.
        </p>
      </>
    ),
  },
  {
    name: "Michelle Obama",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/MichelleObama.png`,
    title: "The Wisdom of Michelle Obama",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          Hello everyone, I'm Michelle Obama. I am honored to have been given
          the opportunity to serve as the Young Peoples Minister for Americas
          region in the Principality of Cogito.
        </p>
        <p>
          My passion for empowering young people comes from my own experiences
          growing up, and from my work advocating for education, health, and
          well-being for children and families around the world. I believe that
          every young person deserves a fair shot at success, and that it is our
          collective responsibility to ensure that they have the support and
          resources they need to thrive.
        </p>
        <p>
          As the Young Peoples Minister for Americas region in the Cogito
          Metaverse, my vision is to create a world where all young people have
          access to quality education, healthcare, and other essential services,
          regardless of their background or circumstances. I believe that by
          investing in the next generation, we can build a brighter and more
          equitable future for all.
        </p>
        <p>
          Looking towards the future of the Cogito Metaverse, my vision is one
          where young people are at the forefront of innovation and social
          change. I envision a world where young people are empowered to use
          technology and other tools to address the challenges facing their
          communities and the planet, and where they have a seat at the table in
          shaping the policies and decisions that affect their lives. In
          conclusion, I am excited to be a part of the Cogito Metaverse team,
          and I look forward to working to create a better future for young
          people in the Americas and beyond.
        </p>
      </>
    ),
  },
  {
    name: "Carlos Slim",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/CarlosSlim.png`,
    title: "The Wisdom of Carlos Slim",
    position: "Business Minister",
    bio: (
      <>
        <p>
          I am Carlos Slim, a businessman and philanthropist with a deep
          commitment to economic development and social progress. I have
          accepted the Ministerial role in the Principality of Cogito because I
          believe that I can use my experience and expertise to help create a
          more prosperous and inclusive future for the people of the Americas.
        </p>
        <p>
          As the Business Minister for the Americas region in the Cogito
          Metaverse, my vision is to create an economy that benefits all
          citizens, not just the privileged few. I believe in the power of small
          businesses to drive economic growth and create jobs.
        </p>
        <p>
          By supporting entrepreneurship and providing resources to help small
          businesses thrive, we can build a more resilient and dynamic economy.
          I also believe in the importance of social responsibility. By creating
          businesses that are environmentally sustainable and socially
          conscious, we can create a better future for our communities.
        </p>
        <p>
          In my role with Cogito Metaverse, I see a future where we harness the
          power of technology to create a more connected and innovative society.
          By leveraging the latest advancements in technology, we can create new
          opportunities for businesses and individuals alike. I have a strong
          faith in the effect brought about by collaboration and partnerships.
          By working together with businesses, governments, and organizations
          across the globe, we can create a more prosperous and peaceful world.
        </p>
      </>
    ),
  },
  {
    name: "Kyle Giersdorf",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/KyleGiersdorf.png`,
    title: "The Wisdom of Kyle Giersdorf",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, everyone! Kyle Giersdorf here. I am happy to take up a role
          as the Minister of the Americas Region in the Principality of Cogito.
          It's an honor to be appointed to such a position of responsibility,
          representing this innovative digital country.
        </p>
        <p>
          As the Minister, my main focus is to foster the growth and development
          of the Cogito Metaverse in the Americas region. This means I'll be
          working closely with the talented individuals and teams involved in
          the creation and maintenance of the metaverse. I aim to facilitate
          collaboration, encourage creativity, and ensure that the metaverse
          remains an engaging and inclusive space for users from all walks of
          life.
        </p>
        <p>
          Now, let's talk about the future of the Cogito Metaverse. In my role,
          I envision the metaverse becoming an integral part of people's lives,
          not just as a platform for entertainment but also as a hub for social
          interaction, education, and commerce. The potential is immense, and I
          believe we'll see the metaverse evolve into a thriving ecosystem of
          virtual experiences, connecting people across the globe in ways we
          couldn't have imagined before. Through strategic partnerships,
          technological advancements, and a strong community-driven approach,
          the Cogito Metaverse has the potential to shape the future of digital
          spaces. I foresee it becoming a go-to destination for gamers, content
          creators, entrepreneurs, and even those seeking new avenues for
          personal growth and self-expression.
        </p>
        <p>
          As the Minister of the Americas Region, I'm committed to working
          tirelessly to ensure that the Cogito Metaverse continues to push
          boundaries, innovate, and provide a truly immersive and fulfilling
          experience for its users. Together, we'll embrace the opportunities
          that lie ahead and create a digital world that captivates and inspires
          all who enter it. Let's embark on this exciting journey together!
        </p>
      </>
    ),
  },
  {
    name: "Zendaya",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/Zendaya.png`,
    title: "The Wisdom of Zendaya",
    position: "Member",
    bio: (
      <>
        <p>
          Hey, hey! Zendaya in the house! I am beyond grateful for the role of
          Minister for the Americas Region in the epic Principality of Cogito.
          It's an absolute honor to be chosen for this.
        </p>
        <p>
          As Minister, my main groove is nurturing the growth and development of
          the Cogito Metaverse in the Americas region. I'm all about teaming up
          with talented folks to keep our metaverse on the cutting edge. My
          goal? To fuel creativity, spread inclusivity, and create a space that
          totally blows minds and sparks inspiration.
        </p>
        <p>
          Now, let's talk about the future of the Cogito Metaverse, 'cause it's
          about to get lit! From where I stand, it's gonna be way more than just
          entertainment. We're talkin' a place where social connections,
          learning, and even commerce all come to life. This is a realm of
          endless possibilities, and mark my words, we're about to witness it
          blossom into a thriving ecosystem of mind-blowing virtual experiences
          that bring people from all corners of the globe together. By teaming
          up with strategic collaborators, embracing cutting-edge tech, and
          building a tight-knit community, the Cogito Metaverse is gonna flip
          the script on the digital landscape. Picture this: a go-to destination
          for gamers, content creators, entrepreneurs, and anyone looking to
          unleash their creativity and personal growth in a whole new way.
        </p>
        <p>
          So, as the Minister of the Americas Region, I'm totally committed to
          going above and beyond to ensure the Cogito Metaverse keeps pushing
          boundaries, inspiring innovation, and delivering mind-blowing
          experiences for all. Together, we're embarking on a wild ride, ready
          to seize every opportunity that comes our way and create a digital
          world that has everybody shook. Let's make magic happen in the Cogito
          Metaverse, my friends! Are you with me?
        </p>
      </>
    ),
  },
  {
    name: "Jayden Bartels",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/JaydenBartels.png`,
    title: "The Wisdom of Jayden Bartels",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, lovely people! Jayden Bartels here, taking up the role of
          Minister of the Americas Region in the Principality of Cogito. I'm
          super stoked to be appointed and represent this rad digital country.
        </p>
        <p>
          As the Minister, I'm all about making things happen in the Cogito
          Metaverse within the Americas region. It's my gig to work alongside
          awesome folks and teams to keep the metaverse rocking and rolling. I
          want to encourage creativity, foster a welcoming space, and make sure
          it's an absolute blast for all the users out there.
        </p>
        <p>
          Now, let's talk about the future of the Cogito Metaverse, because it's
          looking seriously exciting! In my role, I see it becoming a total
          game-changer, not just for entertainment but as a hub for people to
          connect, learn, and get their creative juices flowing. It's a digital
          world where the possibilities are endless, and I'm here for it!
          Through epic collaborations, cutting-edge tech, and a whole lot of
          community spirit, the Cogito Metaverse has the potential to be the
          go-to spot for gamers, creators, entrepreneurs, and anyone who wants
          to express themselves. It's gonna be a place where dreams are made,
          where people from all around the globe can come together and have an
          unforgettable experience.
        </p>
        <p>
          As the Minister of the Americas Region, I'm pumped to put in the work
          to ensure the Cogito Metaverse keeps breaking boundaries, inspiring
          innovation, and giving its users the time of their lives. Together,
          we're gonna ride this wave and create a digital world that's beyond
          epic. Get ready to be blown away, 'cause the Cogito Metaverse is about
          to take things to a whole new level of awesome!
        </p>
      </>
    ),
  },
  {
    name: "Taylor Price",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas1/TaylorPrice.png`,
    title: "The Wisdom of Taylor Price",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, folks! Taylor Price here, ready to dive into my role as the
          Minister of the Americas Region in the Principality of Cogito. It's a
          major honor to be appointed and represent this digital country.
        </p>
        <p>
          As the Minister, I'm all about the finance game in the Cogito
          Metaverse within the Americas region. I'll be crunching numbers,
          analyzing trends, and making sure things are financially sound. My
          goal is to ensure that the metaverse is set up for long-term success,
          economically speaking.
        </p>
        <p>
          In my role, I view Cogito Metaverse growing as finance haven and
          becoming a thriving economic ecosystem, generating opportunities for
          businesses and individuals alike. We're talking about a place where
          innovative startups can flourish, where creators can monetize their
          content, and where entrepreneurs can build digital empires.
        </p>
        <p>
          Through strategic financial planning, smart investment decisions, and
          fostering a business-friendly environment, the Cogito Metaverse has
          the potential to attract substantial investments and create a
          sustainable economy. We'll be exploring partnerships, encouraging
          entrepreneurship, and making sure that the financial infrastructure is
          robust and supportive. As the Minister of the Americas Region, I'm
          committed to ensuring that the financial landscape of the Cogito
          Metaverse remains healthy and vibrant. Together, we'll navigate the
          ever-changing world of finance, seize opportunities for growth, and
          build a prosperous future for the metaverse and its stakeholders. Get
          ready for a financially sound and thriving Cogito Metaverse
          experience!
        </p>
      </>
    ),
  },
];
const Americas2 = [
  {
    name: "Warren Buffet",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/WarrenBuffet.png`,
    title: "The Wisdom of Warren Buffet",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          Well, it's great to be here today. I'm Warren Buffet, and I'm honored
          to be a part of this endeavor and share my vision for the Cogito
          Metaverse.
        </p>
        <p>
          I have spent my entire career in the world of finance and investing,
          and I am deeply committed to creating value for shareholders and
          building sustainable, long-term growth. I believe that the Cogito
          Metaverse represents an incredible opportunity to leverage technology
          and innovation to drive economic growth and prosperity. I accepted the
          Ministerial role in the Principality of Cogito because I believe that
          this platform has the potential to create enormous value for investors
          and businesses alike. By fostering a culture of innovation,
          collaboration, and excellence, we can create an ecosystem that drives
          sustainable growth and generates long-term value for all stakeholders.
        </p>
        <p>
          As the Chief Executive Minister for the Americas region in the Cogito
          Metaverse, my vision is to create a vibrant and dynamic ecosystem that
          attracts the best and brightest talent and fosters a culture of
          innovation and collaboration. By focusing on building strong
          partnerships, promoting excellence, and driving innovation, I believe
          that we can create a platform that drives sustainable growth and
          generates long-term value for investors and businesses alike.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth and prosperity. I believe that this platform has the
          potential to transform the way we live, work, and interact with one
          another, and I am committed to working with my colleagues to unlock
          its full potential. Whether we are exploring new technologies,
          building new partnerships, or expanding our global reach, I am
          confident that the Cogito Metaverse will continue to be a driving
          force for positive change in the world.
        </p>
      </>
    ),
  },
  {
    name: "Gloria Ladson-Billings",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/GloriaLadsonBillings.png`,
    title: "The Wisdom of Gloria Ladson-Billings",
    position: "Education Minister",
    bio: (
      <>
        <p>
          Well hello there, I am Gloria Ladson-Billings, a renowned scholar in
          the field of education and a strong advocate for social justice in the
          classroom. My work has centered around developing culturally relevant
          pedagogies and creating spaces where all students feel valued and
          empowered to succeed.
        </p>
        <p>
          I accepted the Ministerial role in Principality of Cogito because I
          believe that the Cogito Metaverse has the potential to create a truly
          inclusive and equitable learning environment. The power of virtual and
          augmented reality lies in its ability to transcend physical boundaries
          and create immersive experiences that are accessible to all learners,
          regardless of their background or circumstances.
        </p>
        <p>
          As Education Minister in the Cogito Metaverse, my vision is to create
          an educational ecosystem that is grounded in cultural responsiveness
          and social justice. This means creating learning experiences that are
          inclusive, engaging, and empowering for all students. It means
          recognizing the unique strengths and backgrounds that each learner
          brings to the table, and leveraging those strengths to create a truly
          collaborative and transformative learning environment.
        </p>
        <p>
          Looking to the future of Cogito Metaverse, my vision is for a world
          where education is no longer limited by geography or social class. In
          the Metaverse, we have the potential to create a truly global
          community of learners, where diversity and inclusion are not just
          buzzwords, but the foundation of our educational system. My hope is
          that the Cogito Metaverse will become a beacon of hope and opportunity
          for learners from all walks of life, and that it will help to create a
          more just and equitable world for us all.
        </p>
      </>
    ),
  },
  {
    name: "Ellen DeGeneres",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/EllenDegeneres.png`,
    title: "The Wisdom of Ellen DeGeneres",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          Hey there, it's me, Ellen DeGeneres! I'm so excited to be here as the
          Minister of Creative and Arts in the Cogito Metaverse.
        </p>
        <p>
          As someone who has always been passionate about entertainment and
          making people happy, this role is a dream come true for me. I want to
          use my experience in the industry to bring some fun and joy to the
          people of Cogito.
        </p>
        <p>
          As the Creative and Arts Minister, my vision is to create a world
          where everyone can express themselves and explore their creativity. I
          want to encourage people to think outside the box and try new things,
          whether that's through music, art, or any other form of expression. I
          believe that by fostering a vibrant and diverse creative community, we
          can inspire innovation and bring people together.
        </p>
        <p>
          Looking to the future, I envision a Cogito Metaverse that is a hub for
          creativity and innovation. I want to see a world where people from all
          walks of life can come together to share their talents and ideas. I
          hope to help build a platform where artists, musicians, and creators
          can showcase their work and connect with fans from all over the world.
          Ultimately, I believe that the power of creativity can bring people
          together and make the world a better place. Let's get to work and
          achieve our missions.
        </p>
      </>
    ),
  },
  {
    name: "Michael Jordan",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/MichaelJordan.png`,
    title: "The Wisdom of Michael Jordan",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          I am Michael Jordan, a retired American professional basketball player
          and entrepreneur. I accepted the Ministerial role in Principality of
          Cogito because I believe in the power of sports to unite people and
          promote positive change.
        </p>
        <p>
          As a sports Minister, I aim to inspire and encourage young people to
          pursue their dreams and aspirations through sports. My vision as the
          Sports Minister for the Americas region in the Cogito Metaverse is to
          promote inclusivity, diversity, and excellence in sports.
        </p>
        <p>
          I believe that sports can break down barriers and create opportunities
          for people from all walks of life to come together and achieve their
          goals. I want to create more opportunities for young athletes in
          underrepresented communities to develop their skills and compete at
          the highest levels.
        </p>
        <p>
          My vision for the future of Cogito Metaverse is to build a community
          of athletes and sports enthusiasts who are passionate about promoting
          the values of sportsmanship, teamwork, and perseverance. I want to see
          the Cogito Metaverse become a hub for innovative sports technologies,
          training methods, and sports science. By bringing together the best
          minds in sports and technology, we can revolutionize the way we think
          about and approach sports, creating a brighter future for the next
          generation of athletes.
        </p>
      </>
    ),
  },
  {
    name: "Elaine Fuchs",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/ElaineFuchs.png`,
    title: "The Wisdom of Elaine Fuchs",
    position: "Health Minister",
    bio: (
      <>
        <p>
          As a scientist dedicated to the study of stem cells and skin biology,
          I never thought that my path would lead me to a position in
          government. However, I have always been passionate about finding ways
          to use my research to make a positive impact on society. When the
          opportunity arose to take on the role of Health Minister for the
          Americas region in the Cogito Metaverse, I saw it as a chance to do
          just that on a larger scale.
        </p>
        <p>
          My vision as Health Minister for the Americas region is to prioritize
          preventative healthcare measures, such as promoting healthy lifestyles
          and increasing access to vaccinations and screenings. I also believe
          in the importance of investing in research and technology to advance
          healthcare practices and improve outcomes for patients. Additionally,
          I want to work towards addressing healthcare disparities and ensuring
          that all individuals have access to quality healthcare services,
          regardless of their socioeconomic status.
        </p>
        <p>
          Looking towards the future of the Cogito Metaverse, I envision a world
          where healthcare is not only accessible but also personalized to the
          individual. Through advancements in technology and precision medicine,
          we can tailor healthcare treatments to an individual's specific needs
          and genetics. I also see great potential in the use of stem cells and
          regenerative medicine to revolutionize healthcare and find cures for
          previously untreatable conditions.
        </p>
        <p>
          Ultimately, my goal is to create a healthcare system that is not only
          effective but also compassionate and equitable.
        </p>
      </>
    ),
  },
  {
    name: "Christiana Figueres",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/ChristianaFigueres.png`,
    title: "The Wisdom of Christiana Figueres",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          It is an honor to have been appointed as the Environment Minister for
          the Americas region in the Principality of Cogito. As someone who has
          spent my entire career working to advance climate action and
          sustainability, I am eager to bring my expertise and passion to this
          important role.
        </p>
        <p>
          My decision to accept this position was driven by a deep commitment to
          promoting sustainable development and environmental protection in the
          Americas. I believe that we have a moral obligation to address the
          urgent challenges of climate change and environmental degradation, and
          that it is only through bold action and collaboration that we can
          create a more just and sustainable future.
        </p>
        <p>
          As Environment Minister for the Americas region, my vision is to build
          on the Principality of Cogito's existing efforts to promote
          environmental sustainability and resilience. This will involve working
          closely with stakeholders across the region to identify key
          environmental challenges and develop effective strategies for
          addressing them. In particular, I will be focusing on promoting clean
          energy, sustainable land use, and biodiversity conservation, as well
          as fostering innovative approaches to environmental governance and
          community engagement.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, my vision is for a
          community that is a beacon of sustainability and innovation, inspiring
          others around the world to take action on climate change and
          environmental protection. This will require a deep commitment to
          collaboration and partnership, as well as a willingness to embrace
          bold and innovative solutions to the challenges we face. Ultimately,
          my hope is that the Principality of Cogito will serve as a model for
          sustainable development and environmental protection, demonstrating
          that a more just and sustainable future is not only possible, but
          necessary and achievable.
        </p>
      </>
    ),
  },
  {
    name: "Peter Lynch",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/PeterLynch.png`,
    title: "The Wisdom of Peter Lynch",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          I am honored to have been chosen as the Finance and Investment
          Minister for the Americas region in the Principality of Cogito. As a
          former fund manager and author, I have spent my career studying the
          markets and identifying opportunities for investment.
        </p>
        <p>
          My vision for the Americas region in the Cogito Metaverse is one of
          growth and value. By identifying undervalued assets and investing in
          companies with strong fundamentals, we can create long-term value for
          the citizens of the Metaverse. Additionally, I believe in the
          importance of conducting thorough research and analysis before making
          investment decisions, in order to minimize risk and maximize returns.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          innovation and opportunity. By embracing emerging technologies and
          identifying new markets for investment, we can create new
          opportunities for economic growth and job creation across the region.
          Additionally, I believe in the importance of fostering a culture of
          entrepreneurship and innovation, in order to create a dynamic and
          forward-thinking economy.
        </p>
        <p>
          In summary, my vision for the future of the Principality of Cogito is
          one of value, innovation, and opportunity. By investing in strong
          fundamentals and embracing new technologies, I am confident that we
          can create a prosperous and thriving economy for all citizens of the
          Metaverse.
        </p>
      </>
    ),
  },
  {
    name: "Angelina Jolie",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/AngelinaJolie.png`,
    title: "The Wisdom of Angelina Jolie",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          Well, hi there! I'm Angelina Jolie, an actress, filmmaker, and
          humanitarian. I have always been deeply committed to promoting human
          rights and helping those in need, and that's why I accepted the
          Ministerial role in the Principality of Cogito.
        </p>
        <p>
          As the Human Rights Minister for the Americas region in the Cogito
          Metaverse, my vision is to create a world where every person's
          fundamental human rights are respected and protected. It's my belief
          that everyone should have access to basic necessities such as food,
          clean water, healthcare, and education, and I will work tirelessly to
          ensure that these rights are upheld.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, my vision is one where
          people come together to solve the world's most pressing issues, such
          as poverty, hunger, and climate change. The Metaverse has the
          potential to be a powerful tool for connecting people from all over
          the world, and I believe that we can leverage this technology to make
          a real difference in the lives of people everywhere.
        </p>
        <p>
          In closing, I am honored to be a part of the Cogito community, and I
          am committed to using my platform to advocate for those who are often
          voiceless. Together, we can make the world a better, more just, and
          more equitable place for all people.
        </p>
      </>
    ),
  },
  {
    name: "Steve Jobs",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/steveJobs.png`,
    title: "The Wisdom of Steve Jobs",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          Well, let me start by saying that I'm Steve Jobs, and I'm here because
          I see an opportunity to make a real difference in the world through
          technology. I believe that the Cogito Metaverse represents the future
          of computing and has the potential to transform the way we live, work,
          and interact with each other.
        </p>
        <p>
          As the Technology Minister for the Americas region in the Cogito
          Metaverse, my vision is to create products and experiences that are
          both simple and elegant, yet powerful and transformative. I believe
          that technology should be intuitive, easy to use, and beautiful, and
          that it should be designed with the user in mind.
        </p>
        <p>
          To achieve this vision, I will focus on creating a culture of
          innovation and creativity within the Cogito Metaverse. This means
          empowering developers and engineers to take risks and explore new
          ideas, while also fostering collaboration and communication across
          teams and departments.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one in
          which technology seamlessly integrates into every aspect of our lives,
          making it easier, more efficient, and more enjoyable. I see a world in
          which we can interact with technology in natural and intuitive ways,
          and in which technology enhances our ability to connect with each
          other and with the world around us. We will need to continue to push
          the boundaries of what is possible and to challenge ourselves to
          create products and experiences that are truly innovative and
          transformative. I am excited to be a part of this journey and look
          forward to working with Cogito Metaverse team to create a brighter
          future for all of us through technology.
        </p>
      </>
    ),
  },
  {
    name: "Oprah Winfrey",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/OprahWinfrey.png`,
    title: "The Wisdom of Oprah Winfrey",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          Well hello there! I would like to extend my thanks to the Cogito
          Metaverse for this opportunity and role of responsibility. I've always
          been someone who believes in making a positive difference in the
          world, and when the opportunity arose to serve as a Minister in the
          Principality of Cogito, I knew it was something I couldn't pass by.
        </p>
        <p>
          As Young Peoples Minister for the Americas region in the Cogito
          Metaverse, my vision is to empower and uplift young people, to help
          them find their voices and become the leaders of tomorrow. I believe
          that every young person has the potential to do great things, and my
          goal is to help them unlock that potential and create a brighter
          future for all of us.
        </p>
        <p>
          I am sure that the Cogito Metaverse has the potential to be a powerful
          force for good in the world. It can bring people together from all
          corners of the globe, and provide a platform for collaboration,
          innovation, and positive change. My vision for the future of the
          Cogito Metaverse is one where it continues to grow and evolve,
          becoming a place where people can come together to tackle the world's
          biggest challenges and create a better future for all of us.
        </p>
        <p>
          I know that there will be challenges along the way, but I also know
          that when people come together with a shared vision and a
          determination to make a difference, amazing things can happen. I'm
          excited to be a part of this journey, and I can't wait to see what we
          can accomplish together.
        </p>
      </>
    ),
  },

  {
    name: "Muriel Siebert",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/MurielSiebert.png`,
    title: "The Wisdom of Muriel Siebert",
    position: "Business Minister",
    bio: (
      <>
        <p>
          I am Muriel Siebert, a trailblazing businesswoman and the first woman
          to own a seat on the New York Stock Exchange.
        </p>
        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe in the power of innovation and entrepreneurship to drive
          economic growth and create opportunities for all. As a pioneer in the
          world of finance, I have dedicated my life to breaking down barriers
          and empowering individuals to achieve financial success.
        </p>
        <p>
          As the Business Minister for the Americas region in the Cogito
          Metaverse, my vision is to create an economy that is open and
          accessible to all. I believe in the importance of financial education
          and literacy, and I am committed to ensuring that individuals have the
          knowledge and tools they need to make informed financial decisions. I
          also believe in the power of diversity and inclusion. By fostering an
          environment where individuals of all backgrounds and identities can
          thrive, we can create a more vibrant and dynamic economy.
        </p>
        <p>
          My ultimate goal for the future of Cogito Metaverse is one where
          innovation and entrepreneurship are at the forefront of economic
          growth. I believe in the power of technology to create new
          opportunities and transform industries. By investing in research and
          development and supporting startups and small businesses, we can
          create a more dynamic and resilient economy. I also believe in the
          importance of collaboration and partnership. By working together with
          other nations and organizations, we can create a more prosperous and
          interconnected world.
        </p>
      </>
    ),
  },
  {
    name: "Saahil Arora",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/SaahilArora.png`,
    title: "The Wisdom of Saahil Arora ",
    position: "Member",
    bio: (
      <>
        <p>
          Hey, fellow gamers! I am Saahil Arora. As the Minister of the Americas
          Region, I'm all about representing the gaming community within the
          Cogito Metaverse. It's my mission to ensure that gamers' voices are
          heard, their needs are met, and we create an epic gaming paradise
          within the Americas region.
        </p>
        <p>
          Now, let's dive into the future of the Cogito Metaverse under my
          watch. Picture this: a gaming utopia where players from all walks of
          life come together to explore, compete, and have a blast. We'll have
          cutting-edge tournaments, electrifying esports events, and
          mind-blowing gaming experiences that will leave you on the edge of
          your seat.
        </p>
        <p>
          But it's not just about the hardcore competition, my friends. The
          Cogito Metaverse will be a place where gamers connect, learn, and
          grow. We'll have virtual gaming communities, forums, and educational
          resources to help players level up their skills. It's about fostering
          a sense of camaraderie, sharing knowledge, and lifting each other up.
          Moreover, the Cogito Metaverse will be a breeding ground for gaming
          innovation. We'll explore emerging technologies, like virtual reality,
          augmented reality, and immersive gameplay. It's about pushing the
          boundaries, unlocking new possibilities, and creating gaming
          experiences that blow your mind.
        </p>
        <p>
          So, get ready for an epic gaming revolution in the Cogito Metaverse.
          With me as the Minister of the Americas Region, we're going to create
          a gaming haven that will take your gaming journey to a whole new
          level. Stay tuned, gamers, because the future is bright, and we're
          about to level up together!
        </p>
      </>
    ),
  },
  {
    name: "Kylie Jenner",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/KylieJenner.png`,
    title: "The Wisdom of Kylie Jenner",
    position: "Member",
    bio: (
      <>
        <p>
          Hey, lovelies! I am Kylie Jenner and I am so excited to be a part of
          this cool space. As the Minister of the Americas Region, my job is all
          about making sure our digital country is the talk of the town. I'll be
          the go-to gal, representing our fabulous Principality in the Cogito
          Metaverse. Get ready for some serious glam, because I'm here to bring
          the sparkle!
        </p>
        <p>
          I want to contribute to Cogito Metaverse and make it a place where
          everyone can live their best lives, express themselves, and shine
          bright like diamonds. Within the Cogito Metaverse, I see a whole
          universe of possibilities. It's going to be a playground for
          creativity, fashion, and all things fabulous. We'll have exclusive
          events, jaw-dropping parties, and red carpet moments that will make
          the world stop and stare. Trust me, the Cogito Metaverse will be the
          hottest destination in town! But it's not just about the glitz and
          glam, babe.
        </p>
        <p>
          The Cogito Metaverse will be a place where connections are made, ideas
          are shared, and dreams become a reality. We'll have networking events,
          business opportunities, and platforms for entrepreneurs to thrive.
          It's all about empowering each other and creating a community that's
          unstoppable.
        </p>
        <p>
          So, get ready to step into a world where the digital and the fabulous
          collide. With me as your guide, we're going to make the Cogito
          Metaverse the ultimate hotspot, where everyone wants to be. Get ready
          for the ride of your life, because this is going to be epic!
        </p>
      </>
    ),
  },
  {
    name: "Massy Arias",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/MassyArias.png`,
    title: "The Wisdom of Massy Arias",
    position: "Member",
    bio: (
      <>
        <p>
          Hey, beautiful souls! It's Massy Arias and I'm so excited to share all
          about my new role as the Minister of the Americas Region for the
          Principality of Cogito. Get ready for some fitness and wellness magic,
          because we're about to make waves in the Cogito Metaverse!
        </p>
        <p>
          As the Minister of the Americas Region, my focus is on promoting
          health, fitness, and overall well-being within the Cogito Metaverse.
          I'll be guiding and inspiring individuals to prioritize their health
          and lead active lifestyles. It's all about empowering people to thrive
          and find their strength within! I see a virtual world where fitness
          becomes a way of life. We'll have virtual workout studios, interactive
          wellness programs, and a supportive community that encourages everyone
          to embrace their fitness journey.
        </p>
        <p>
          In the Cogito Metaverse, we'll break barriers and challenge
          limitations. I envision a platform where people can access
          personalized workout plans, nutrition guidance, and mindfulness
          practices. It's about nurturing the mind, body, and soul, and creating
          a holistic approach to wellness. Moreover, the Cogito Metaverse will
          be a hub for fitness innovation. We'll explore virtual reality fitness
          experiences, gamified workouts, and immersive challenges that make
          exercise fun and engaging. It's all about celebrating diversity,
          inclusivity, and the incredible potential that lies within each
          individual.
        </p>
        <p>
          So, get ready to embark on a transformative wellness journey within
          the Cogito Metaverse. With me as the Minister of the Americas Region,
          we're going to create a space that uplifts and empowers individuals to
          live their healthiest, happiest lives. Together, we'll redefine what
          it means to be strong, inside and out. Let's make this wellness
          revolution a reality!
        </p>
      </>
    ),
  },
  {
    name: "Charli D’Amelio",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/Americas2/CharliD'Amello.png`,
    title: "The Wisdom of Charli D’Amelio",
    position: "Member",
    bio: (
      <>
        <p>
          Hey, guys! It's Charli D'Amelio and as the Minister of the Americas
          Region, I'm all about spreading positivity and good vibes within the
          Cogito Metaverse. My job is to inspire and uplift people, creating a
          space where everyone feels welcome, valued, and loved. Together, we're
          going to make the Cogito Metaverse the happiest and most inclusive
          place ever!
        </p>
        <p>
          Now, let's talk about the future of the Cogito Metaverse while I'm in
          this role. I imagine creating a virtual world where kindness rules,
          creativity blossoms, and dreams come true. We'll have virtual dance
          parties, epic collaborations, and moments that make your heart skip a
          beat. It's all about bringing people together and celebrating the
          power of connection. I see a space where authenticity shines bright.
          It's a platform where individuals can express themselves freely,
          showcasing their unique talents, passions, and personalities. We'll
          have a space that celebrates diversity, promotes self-love, and
          encourages everyone to be their most genuine selves.
        </p>
        <p>
          Moreover, the Cogito Metaverse will be a hub for positive change.
          We'll use our platform to raise awareness about important issues,
          support charitable causes, and create a ripple effect of kindness and
          compassion. It's about using our influence for good and making a
          difference in the world.
        </p>
        <p>
          So, get ready to step into a world where love, laughter, and positive
          energy are the currency. With me as the Minister of the Americas
          Region, we're going to create a digital oasis that radiates joy and
          empowers individuals to be the best version of themselves. Get ready
          to join the movement, because together, we're going to change the
          world, one smile at a time!
        </p>
      </>
    ),
  },
];
const EuropeEast = [
  {
    name: "Helena Helmersson",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/HelenaHelmersson.png`,
    title: "The Wisdom of Helena Helmersson",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          A big thank you to Cogito Metaverse for making me a part of their
          unique world. My name is Helena Helmersson and I am delighted to take
          up this position of responsibility and give support to an innovative
          move.
        </p>
        <p>
          As a business leader with extensive experience in sustainability, I am
          deeply committed to building a sustainable future and creating value
          for all stakeholders. I accepted the Ministerial role in the
          Principality of Cogito because I believe that this platform has the
          potential to drive sustainable growth and create long-term value for
          investors and businesses alike.
        </p>
        <p>
          As the Chief Executive Minister for the Europe East region in the
          Cogito Metaverse, my vision is to create an ecosystem that fosters
          sustainable growth and innovation. By working closely with our
          partners and stakeholders, we can build a platform that drives
          sustainable development, promotes social responsibility, and generates
          long-term value for investors and businesses alike.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued innovation, collaboration, and sustainability. I believe
          that this platform has the potential to transform the way we live,
          work, and interact with one another, and I am committed to working
          with my colleagues to unlock its full potential. Whether we are
          exploring new technologies, building new partnerships, or expanding
          our global reach, I am confident that the Cogito Metaverse will bring
          about a massive change in the world that will impact us all in a
          positive way.
        </p>
      </>
    ),
  },
  {
    name: "Maria Montessori",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/MariaMontessori.png`,
    title: "The Wisdom of Maria Montessori",
    position: "Education Minister",
    bio: (
      <>
        <p>
          I accepted the Ministerial role in the Principality of Cogito because
          I believe that education is the foundation for a prosperous and
          peaceful society. As a renowned educator, I am committed to promoting
          the development of every child's potential, and I believe that this
          can be achieved through a strong educational system that provides
          equal opportunities for all.
        </p>
        <p>
          As the education Minister in the Cogito Metaverse, my vision is to
          create an educational system that is tailored to the needs of each
          child. This means recognizing that every child is unique and has their
          own learning style, interests, and talents. I believe that by
          providing a learning environment that fosters individuality and
          creativity, we can inspire children to become independent learners who
          are passionate about their education.
        </p>
        <p>
          Furthermore, I believe that education is not just about academic
          achievement, but also about personal and social development.
          Therefore, I aim to create a curriculum that emphasizes the importance
          of character development, social skills, and emotional intelligence.
          By instilling these values in our students, we can empower them to
          become responsible and compassionate citizens who contribute
          positively to society.
        </p>
        <p>
          Looking to the future of Cogito Metaverse, my vision is for an
          educational system that is at the forefront of innovation and
          technology. I believe that we should harness the power of technology
          to enhance learning and provide new opportunities for our students.
          However, I also believe that technology should never replace the human
          touch and personal interaction that is so vital in education.
          Ultimately, my goal is to create an educational system that prepares
          our children for the challenges and opportunities of the future, while
          instilling in them the values and skills necessary to become
          responsible and compassionate members of society.
        </p>
      </>
    ),
  },
  {
    name: "Simon Cowell",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/SimonCowell.png`,
    title: "The Wisdom of Simon Cowell",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          I am glad to be part of this unique world of Cogito Metaverse. In all
          honesty, my association is to encourage the work happening here.
        </p>
        <p>
          I took on the Ministerial role in Principality of Cogito because I see
          potential there. Simple as that. And I want to make sure that
          potential is realized. I've got a lot of experience in the
          entertainment industry and I know what it takes to create something
          truly great. My vision as the creative and arts Minister in the Cogito
          Metaverse is to push boundaries and find new and innovative ways to
          showcase artistic talent. It's not enough to just do what's been done
          before - we need to be bold and take risks if we want to stand out
          from the crowd.
        </p>
        <p>
          In terms of the future of the Cogito Metaverse, I see it as a place
          where creativity can truly thrive. I want to encourage artists of all
          kinds to come together and collaborate in new and exciting ways. And I
          want to make sure that the world is paying attention when we do.
        </p>
        <p>
          We need to be at the forefront of innovation and set the bar for
          what's possible in the world of entertainment. It won't be easy, but
          with hard work and a commitment to excellence, I truly believe we can
          make the Cogito Metaverse a force to be reckoned with.
        </p>
      </>
    ),
  },
  {
    name: "Larisa Latynina",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/LarisaLatynina.png`,
    title: "The Wisdom of Larisa Latynina",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          I am Larisa Latynina, a retired Soviet gymnast and the most decorated
          Olympic athlete of all time, having won 18 Olympic medals across three
          Olympic Games.
        </p>
        <p>
          I am honored to have been selected as the Sports Minister for the East
          Europe region in the Cogito Metaverse. I accepted this Ministerial
          role because I believe that sports play a vital role in society, not
          just for physical health but also for mental, emotional, and social
          well-being. Through sports, we can foster teamwork, discipline, and
          sportsmanship, which are essential values that can translate into
          other aspects of life.
        </p>
        <p>
          As the Sports Minister for the East Europe region in the Cogito
          Metaverse, my vision is to promote sports and physical activity as a
          way of life. I want to encourage more people to participate in sports,
          regardless of their age or ability. I also want to support and empower
          athletes to achieve their fullest potential and to be ambassadors of
          positive change in their communities.
        </p>
        <p>
          In the future of the Cogito Metaverse, I envision a world where sports
          and physical activity are accessible to everyone, regardless of their
          background or location. I want to see a world where sports are used as
          a tool for social integration, where people from all walks of life can
          come together and bond over a shared love of sports. I believe that
          the Cogito Metaverse has the potential to make this vision a reality,
          and I am excited to be a part of it.
        </p>
      </>
    ),
  },
  {
    name: "Dr Alexander Fleming",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/AlexanderFleming.png`,
    title: "The Wisdom of Dr Alexander Fleming",
    position: "Health Minister",
    bio: (
      <>
        <p>
          I am Dr. Alexander Fleming, a Scottish biologist and pharmacologist. I
          am best known for my discovery of the antibiotic penicillin, which has
          saved countless lives and revolutionized the field of medicine.
        </p>
        <p>
          As for why I accepted the Ministerial role in Principality of Cogito,
          I believe that it is a great opportunity to use my knowledge and
          expertise to make a positive impact on the health of the people in
          this region.
        </p>
        <p>
          My vision as Health Minister for East Europe region in the Cogito
          Metaverse is to focus on preventive measures to improve the overall
          health of the population. This includes promoting healthy lifestyles
          and habits, such as regular exercise and healthy eating, as well as
          improving access to healthcare services and education. I also believe
          in the importance of investing in research and development to find new
          treatments and cures for diseases.
        </p>
        <p>
          For the future of Cogito Metaverse, my vision is to see a world where
          healthcare is accessible and affordable for everyone, regardless of
          their background or financial situation. I believe in the power of
          science and innovation to improve human health, and I hope to see
          continued progress in this area. Additionally, I envision a world
          where people are empowered to take control of their own health and
          well-being, through education and access to resources. Together, I
          believe we can create a healthier and happier world for all.
        </p>
      </>
    ),
  },
  {
    name: "Nadia Murad",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/NadiaMurad.png`,
    title: "The Wisdom of Nadia Murad",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          I am Nadia Murad, a Yazidi human rights activist from Iraq. I accepted
          the Ministerial role in the Principality of Cogito because I am deeply
          committed to advocating for the rights of marginalized communities and
          promoting social justice.
        </p>
        <p>
          As an advocate for survivors of genocide, I understand the importance
          of environmental justice in ensuring the well-being and safety of
          communities. In my role, as the Environment Minister for East Europe
          region in the Cogito Metaverse, my vision is to work towards creating
          sustainable and resilient communities that prioritize the well-being
          of people and the planet. I believe that environmental justice is an
          essential part of human rights and that communities should have equal
          access to clean air, water, and natural resources.
        </p>
        <p>
          My focus as Environment Minister will be on developing policies that
          prioritize renewable energy, green infrastructure, and sustainable
          land use. I will work with communities and stakeholders to ensure that
          their voices are heard and that their needs are incorporated into
          decision-making processes.
        </p>
        <p>
          In the future, I envision the Cogito Metaverse as a global leader in
          sustainable development and environmental justice. I hope to see
          communities thrive in harmony with nature, with access to clean air,
          water, and natural resources. I also envision a world where the rights
          of marginalized communities are protected and their voices are heard
          in the decision-making process.
        </p>
      </>
    ),
  },
  {
    name: "Valdis Dombrovskis",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/ValdisDombrovskis.png`,
    title: "The Wisdom of Valdis Dombrovskis",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          As the former Prime Minister of Latvia and current Executive Vice
          President of the European Commission for An Economy that Works for
          People, I have always been dedicated to promoting economic growth and
          stability.
        </p>
        <p>
          When I was offered the Ministerial role in the Principality of Cogito,
          I saw an opportunity to bring my expertise to a new arena and
          contribute to the development of the Cogito Metaverse.
        </p>
        <p>
          As Finance and Investment Minister for the East Europe region in the
          Cogito Metaverse, my vision is to create an environment that
          encourages investment and innovation while ensuring financial
          stability. I believe in the importance of a level playing field for
          all investors, and I will work to promote transparency and
          accountability in the financial sector. Additionally, I will
          prioritize the development of sustainable finance practices to support
          the growth of a green economy in the region.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          inclusivity and sustainability. I believe that the Metaverse has the
          potential to drive economic growth and create new opportunities for
          people around the world. However, it is important that we prioritize
          the well-being of our citizens and the planet as we develop and expand
          this new frontier. I will work with my colleagues to ensure that the
          Cogito Metaverse is a force for good, promoting prosperity and
          sustainability for all.
        </p>
      </>
    ),
  },
  {
    name: "Svetlana Alexievich",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/SvetlanaAlexievich.png`,
    title: "The Wisdom of Svetlana Alexievich",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          Hello all, I am Svetlana Alexievich and I very happy to take up the
          role of the Human Rights Minister for the East Europe region of the
          Cogito Metaverse.
        </p>
        <p>
          I have dedicated my life to giving voice to those who have been
          silenced by oppressive regimes and overlooked by society. The role of
          Human Rights Minister in the Principality of Cogito is an opportunity
          for me to continue this work on a new platform and reach a broader
          audience.
        </p>
        <p>
          As Human Rights Minister for East Europe region in the Cogito
          Metaverse, my vision is to bring attention to the human rights abuses
          that are often hidden or ignored in this region. I believe that it is
          important to listen to the stories of those who have been impacted by
          these abuses, to acknowledge their pain and suffering, and to work
          towards justice and accountability. Through education and awareness
          campaigns, I hope to raise public consciousness about these issues and
          to inspire action towards positive change.
        </p>
        <p>
          For the future of Cogito Metaverse, my vision is one of a just and
          equitable society, where human rights are respected and protected for
          all individuals, regardless of their background or identity. I believe
          that the virtual world can be a powerful tool for advancing social
          justice and promoting human rights, and I am committed to using my
          role as Human Rights Minister to work towards this goal. By amplifying
          the voices of marginalized communities and advocating for their
          rights, I hope to create a more inclusive and compassionate world in
          the Cogito Metaverse and beyond.
        </p>
      </>
    ),
  },
  {
    name: "Daniel Ek",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/DanielEk.png`,
    title: "The Wisdom of Daniel Ek",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          I'm honored to have been given the opportunity to serve as the
          Technology Minister for the East Europe region in the Cogito
          Metaverse.
        </p>
        <p>
          As the co-founder and CEO of Spotify, I've always been passionate
          about utilizing technology to connect people and create new
          opportunities. My experience in leading one of the world's most
          successful music streaming platforms has given me a deep understanding
          of the transformative power of technology.
        </p>
        <p>
          As the Technology Minister for East Europe, my vision is to help build
          a vibrant and thriving tech ecosystem in the region. I believe that
          the key to achieving this is by fostering a culture of innovation and
          entrepreneurship. By providing support and resources to startups and
          tech companies in the region, we can help them grow and reach their
          full potential. My primary goal as the Technology Minister for East
          Europe is to ensure that the region remains at the forefront of
          technological innovation. To achieve this, I plan to collaborate
          closely with the private sector, academia, and government agencies to
          promote the development and adoption of emerging technologies such as
          blockchain, artificial intelligence, and machine learning.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one where
          technology is used to create a more connected and equitable world. I
          believe that by leveraging the power of emerging technologies, we can
          build a metaverse that is accessible and inclusive for everyone. With
          the right investments in technology and infrastructure, we can create
          a metaverse that is not only innovative and exciting but also serves
          as a force for good in the world.
        </p>
      </>
    ),
  },
  {
    name: "Ed Sheeran",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/EdSheeran.png`,
    title: "The Wisdom of Ed Sheeran",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          Well, hello there! My name is Ed Sheeran, and I'm thrilled to be
          taking on the role of Young Peoples Minister for Europe East region in
          the Cogito Metaverse.
        </p>
        <p>
          As for why I accepted the position, I have a deep passion for
          supporting young people and helping them reach their full potential. I
          believe that by investing in young people, we're investing in the
          future of our society, and that's something that I'm truly excited to
          be a part of.
        </p>
        <p>
          As for my vision for the Europe East region, I want to create a space
          where young people can feel empowered and supported in pursuing their
          dreams. Whether that means providing access to education and career
          opportunities or simply fostering a sense of community and belonging,
          I want to help young people realize their full potential and make a
          positive impact on the world.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, I envision a place
          where people from all over the world can come together to share ideas,
          collaborate on projects, and build a better future for everyone. I
          believe that technology has the power to break down barriers and bring
          people together in ways that we never thought possible, and I'm
          excited to be a part of a movement that's harnessing that power for
          good. Ultimately, my hope is that the Cogito Metaverse can become a
          global hub for innovation, creativity, and positive change, and I'm
          honored to be a part of making that vision a reality.
        </p>
      </>
    ),
  },
  {
    name: "Angela Merkel",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/AngelMerkel.png`,
    title: "The Wisdom of Angela Merkel",
    position: "Business Minister",
    bio: (
      <>
        <p>
          As a former chancellor of Germany and a leader who believes in strong
          international cooperation and diplomacy, I am honored to have accepted
          the Ministerial role in the Principality of Cogito.
        </p>
        <p>
          I believe that this role offers me an opportunity to contribute my
          experience and expertise towards the development of a prosperous and
          sustainable future for the Cogito Metaverse.
        </p>
        <p>
          As the Business Minister for East Europe region, my vision is to
          foster an environment that supports innovation, growth and
          entrepreneurship. I believe that a thriving business community can
          provide economic stability and growth for the region, and I will work
          tirelessly to attract and support businesses of all sizes. My focus
          will be on improving the infrastructure and creating new policies that
          encourage investment and job creation in the region.
        </p>
        <p>
          My vision for the future of the Cogito Metaverse is one of
          collaboration, sustainability and innovation. I believe that the
          Metaverse has enormous potential to drive economic growth and social
          development while also protecting the environment. To achieve this, we
          need to work together across all sectors, combining our resources and
          expertise to build a brighter and more equitable future. I am
          committed to promoting policies that prioritize sustainable
          development, support innovation and collaboration, and provide
          opportunities for all members of society to participate in and benefit
          from the growth of the Cogito Metaverse.
        </p>
      </>
    ),
  },
  {
    name: "Johan Sundstein",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/JohanSundstein.png`,
    title: "The Wisdom of Johan Sundstein",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, dear citizens of the Principality of Cogito and fellow
          enthusiasts of the gaming realm. I, Johan Sundstein, am honored to be
          appointed as the Minister of the Euro East Region in this esteemed
          digital country.
        </p>
        <p>
          As a professional esports player, I have had the privilege of
          participating in various international tournaments, conquering the
          realm of Dota 2, and emerging victorious on numerous occasions. In my
          elected role as Minister, I aim to channel my passion for competitive
          gaming and contribute towards the advancement of the Principality of
          Cogito. I will diligently work towards fostering an environment that
          nurtures esports talents, promoting the growth of the gaming
          community, and advocating for the recognition and support of esports
          on a global scale.
        </p>
        <p>
          With a strong foundation and a dedicated community, the Cogito
          Metaverse will become a hub of technological advancement, where
          cutting-edge virtual reality technologies and immersive gameplay
          mechanics redefine the boundaries of what is possible. We shall
          witness the emergence of groundbreaking esports tournaments within the
          metaverse, attracting the attention of millions of viewers worldwide
          and solidifying Cogito's position as a prominent force in the gaming
          industry. Furthermore, the Cogito Metaverse will serve as a platform
          for collaboration, allowing developers, creators, and players to come
          together and shape the future of gaming. Through open dialogue and
          inclusive practices, we will strive to create an environment that
          fosters creativity, diversity, and inclusivity, ensuring that everyone
          has an equal opportunity to contribute.
        </p>
        <p>
          In essence, the future of the Cogito Metaverse is one filled with
          infinite possibilities, where the spirit of competition, camaraderie,
          and adventure thrives. Together, let us embark on this exciting
          journey, as we shape the destiny of the Cogito Metaverse and leave a
          lasting legacy in the world of gaming.
        </p>
      </>
    ),
  },
  {
    name: "Joe Whale",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/JoeWhale.png`,
    title: "The Wisdom of Joe Whale",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, folks! Joe Whale here, thrilled to be taking on the role of
          Minister for the Euro East Region in the Principality of Cogito. Now,
          let me tell you a little bit about myself and what this elected
          position means to me.
        </p>
        <p>
          I'm an illustrator, a lover of all things artistic and creative. From
          a young age, I've been doodling away, bringing my imagination to life
          on paper. Being appointed as a Minister in the Cogito Metaverse is a
          dream come true for me. It's an opportunity to combine my passion for
          art with my love for gaming and virtual experiences.
        </p>
        <p>
          As Minister, my goal is to infuse the Cogito Metaverse with a vibrant
          and visually captivating atmosphere. I aim to collaborate with fellow
          artists, designers, and creators to bring stunning visual elements to
          the virtual world. In this role, I also aspire to nurture emerging
          talent within the artistic community. By organizing workshops,
          masterclasses, and mentorship programs, we'll provide aspiring artists
          a platform to learn, grow, and showcase their skills within the Cogito
          Metaverse.
        </p>
        <p>
          Moreover, the Cogito Metaverse will become a hub for collaborative art
          projects. Artists from around the globe will come together, blurring
          the boundaries between different styles and techniques. We'll witness
          ground-breaking collaborations between traditional artists, digital
          illustrators, 3D sculptors, and more. The metaverse will be a melting
          pot of artistic visions, where innovation and creativity intersect in
          the most awe-inspiring ways. In summary, the future of the Cogito
          Metaverse under my stewardship will be an explosion of artistic
          brilliance. It will be a place where artistry knows no bounds, where
          imagination runs wild, and where users are constantly inspired and
          captivated by the visual wonders that surround them. Together, let's
          paint a vibrant and extraordinary future for the Cogito Metaverse!
        </p>
      </>
    ),
  },
  {
    name: "Jacqueline Jossa",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/JacquelineJossa.png`,
    title: "The Wisdom of Jacqueline Jossa",
    position: "Member",
    bio: (
      <>
        <p>
          Hi everyone! Jacqueline Jossa here, and I am absolutely thrilled to
          have been appointed as the Minister for the Euro East Region in the
          Principality of Cogito.
        </p>
        <p>
          As a parenting influencer, my journey has been all about embracing the
          joys and challenges of motherhood. I've had the privilege of
          connecting with fellow parents, sharing my experiences, and learning
          from others along the way. Being appointed as a Minister in the Cogito
          Metaverse is an incredible opportunity to extend my passion for
          parenting and community building into the digital realm.
        </p>
        <p>
          In this role, my primary focus will be on creating a nurturing and
          inclusive environment within the Cogito Metaverse. I aim to develop
          resources, support networks, and educational programs to empower
          parents and caregivers navigating the digital landscape with their
          children. I believe in promoting digital well-being, encouraging
          healthy boundaries, and providing guidance on how families can make
          the most of their time in the metaverse while maintaining a harmonious
          offline life.
        </p>
        <p>
          I envision a metaverse that prioritizes the well-being and development
          of children. We'll create dedicated spaces within the virtual world
          that offer age-appropriate content, educational resources, and
          interactive experiences designed to foster creativity, imagination,
          and growth. The Cogito Metaverse will also be a place where parents
          and caregivers can come together, share advice, and build a strong
          support network. We'll organize virtual workshops, panel discussions,
          and Q&A sessions featuring experts in child development, parenting,
          and digital literacy. By harnessing the power of the metaverse, we can
          facilitate meaningful connections and empower parents with the tools
          they need to navigate the digital landscape alongside their children.
          Together, we'll shape a future where parenting and technology
          harmoniously coexist, allowing us to create a positive and nurturing
          environment for our children both online and offline.
        </p>
      </>
    ),
  },
  {
    name: "Andriy Shevchenko",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeEast/AndriyShevchenko.png`,
    title: "The Wisdom of Andriy Shevchenko",
    position: "Member",
    bio: (
      <>
        <p>
          Greetings, football enthusiasts! Andriy Shevchenko here, honored to
          have been appointed as the Minister for the Euro East Region in the
          Principality of Cogito.
        </p>
        <p>
          As a former professional footballer, I have had the privilege of
          representing my country, Ukraine, and playing for esteemed clubs
          throughout my career. Now, as Minister in the Cogito Metaverse, I
          bring my passion for leadership, teamwork, and dedication to
          contribute to the growth and development of this digital country.
        </p>
        <p>
          In my role as Minister, I am committed to fostering an environment of
          unity, collaboration, and excellence within the Cogito Metaverse. I
          aim to promote fair play, inclusivity, and a sense of camaraderie
          among users, mirroring the values instilled in me throughout my
          footballing journey. I envision a metaverse that thrives on the
          principles of teamwork and individual growth. Collaboration will be
          key, as users come together to explore innovative ideas, create
          memorable experiences, and challenge one another to reach new heights.
          We will provide platforms for aspiring creators, designers, and
          developers to showcase their skills and unleash their potential. By
          nurturing a supportive ecosystem, we will cultivate a culture of
          continuous improvement and innovation within the metaverse.
        </p>
        <p>
          I also see great potential for the Cogito Metaverse to contribute to
          societal well-being. We will leverage the power of technology and
          virtual connectivity to promote social causes, raise awareness about
          important issues, and foster positive change. Through virtual
          campaigns, charity events, and educational initiatives, we will create
          a metaverse that makes a tangible impact on the world beyond its
          digital borders. Together, we will unlock the metaverse's full
          potential, celebrating diversity, fostering talent and creating a
          brighter future!
        </p>
      </>
    ),
  },
];
const EuropeWest = [
  {
    name: "Ingvar Kamprad",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/IngvarKamprad.png`,
    title: "The Wisdom of Ingvar Kamprad",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          Hi, my name is Ingvar Kamprad, and I am honored to be serving as the
          Chief Executive Minister for the Europe West region in the Cogito
          Metaverse.
        </p>
        <p>
          As the founder of IKEA, I have always been passionate about innovation
          and finding new ways to solve problems. I accepted the Ministerial
          role in the Principality of Cogito because I believe that this
          platform has the potential to revolutionize the way we live and work.
        </p>
        <p>
          My vision for the Europe West region in the Cogito Metaverse is one of
          innovation, collaboration, and sustainability. By bringing together
          the best and brightest minds from across the region, we can create an
          ecosystem that fosters innovation and drives growth. Whether we are
          exploring new technologies, building new partnerships, or expanding
          our global reach, I am committed to working with my colleagues to
          unlock the full potential of the Cogito Metaverse.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          continued growth and innovation. I believe that this platform has the
          potential to transform the way we live, work, and interact with one
          another, and I am committed to ensuring that we remain at the
          forefront of this exciting new frontier. By staying true to our values
          of innovation, collaboration, and sustainability, I am confident that
          we can create a brighter and more prosperous future for all.
        </p>
      </>
    ),
  },
  {
    name: "Sir Ken Robinson",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/KenRobinson.png`,
    title: "The Wisdom of Sir Ken Robinson",
    position: "Education Minister",
    bio: (
      <>
        <p>
          I would like to thank Cogito Metaverse for this opportunity, it's an
          absolute pleasure to be a part of this. To start with, I would like to
          say that I accepted the Ministerial role in Principality of Cogito
          because I believe that education is the cornerstone of any society. It
          is the foundation upon which we build our futures. And it is through
          education that we can inspire and cultivate the minds of the next
          generation.
        </p>
        <p>
          As the Education Minister in the Cogito Metaverse, my vision is to
          create an environment where learning is not just a task, but a joy. I
          believe that education should be a transformative experience, one that
          enriches and empowers individuals to pursue their passions and make
          meaningful contributions to society.
        </p>
        <p>
          In order to achieve this vision, we must prioritize the development of
          21st-century skills, such as critical thinking, collaboration,
          creativity, and problem-solving. We must also provide opportunities
          for students to engage with real-world issues, and to use their
          knowledge to make a positive impact on the world around them. Looking
          to the future of the Cogito Metaverse, my vision is to see a society
          that is driven by a love of learning. I want to see a community that
          is passionate about education, and that embraces new technologies and
          innovative approaches to teaching and learning. I want to see a
          society that values creativity and imagination, and that encourages
          individuals to explore their full potential.
        </p>
        <p>
          Ultimately, my goal is to create an education system that prepares
          individuals for the challenges of the 21st century, and that equips
          them with the knowledge, skills, and attitudes they need to thrive in
          an ever-changing world.
        </p>
      </>
    ),
  },
  {
    name: "Dawn French",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/DawnFrench.png`,
    title: "The Wisdom of Dawn French",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          Well hello there! It's lovely to be here, truly. I accepted the
          Ministerial role in Principality of Cogito because as I believe in the
          power of the arts.
        </p>
        <p>
          Creativity is at the very core of what it means to be human, and it is
          through the arts that we can express ourselves, connect with others,
          and make sense of the world around us. As the Arts and Entertainment
          Minister in the Cogito Metaverse, I see my role as one of fostering
          and supporting the creative industries, and ensuring that all
          individuals have access to the transformative power of the arts.
        </p>
        <p>
          My vision as Arts and Entertainment Minister is to create a vibrant
          and diverse cultural landscape in the Cogito Metaverse. I want to see
          a community that is rich in artistic expression, where creativity is
          celebrated and nurtured. I want to provide opportunities for
          individuals to engage with the arts, to explore their own creativity,
          and to connect with others who share their passions. Whether it's
          through music, dance, theatre, film, or any other medium, I believe
          that the arts have the power to bring people together and to create a
          sense of community.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, my vision is one of a
          thriving creative economy. I want to see a community that is filled
          with artists, designers, musicians, writers, and performers, all
          contributing to a dynamic and exciting cultural landscape. I want to
          see a society that values creativity and imagination, and that
          recognizes the importance of the arts in shaping our shared future.
          Ultimately, I believe that the arts have the power to inspire, to
          challenge, and to transform, and I am committed to working towards a
          future where everyone has access to the transformative power of
          creativity.
        </p>
      </>
    ),
  },
  {
    name: "Lewis Hamilton",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/LewisHamilton.png`,
    title: "The Wisdom of Lewis Hamilton",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          Hey guys, it's great to be here. So, to answer your question, I
          accepted the Ministerial role in Principality of Cogito because I
          believe in the power of sport. Sport has the ability to bring people
          together, to inspire and to empower individuals to reach their full
          potential.
        </p>
        <p>
          As someone who has experienced the transformative power of sport first
          hand, I am passionate about creating opportunities for others to do
          the same. As the Sports Minister in the Cogito Metaverse, my vision is
          to create a community that is active, healthy, and engaged in sports.
        </p>
        <p>
          I want to see a society that values physical activity and encourages
          individuals to pursue their sporting passions. Whether it's through
          traditional sports such as football, basketball, or tennis, or through
          emerging sports like esports, I believe that there is a sport for
          everyone, and that everyone should have the opportunity to
          participate.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, my vision is one of
          inclusivity and diversity. I want to see a community that is
          representative of all backgrounds and abilities, and that embraces a
          wide range of sports and physical activities. I want to create
          opportunities for individuals to not only participate in sports, but
          to also pursue careers in the sports industry, whether it's as
          athletes, coaches, or administrators. Ultimately, my goal is to create
          a society that is active, healthy, and united by a shared love of
          sport.
        </p>
      </>
    ),
  },
  {
    name: "Hippocrates",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/Hippocrates.png`,
    title: "The Wisdom of Hippocrates",
    position: "Health Minister",
    bio: (
      <>
        <p>
          Greetings, it is an honor to be be a part of this. As a physician, I
          have dedicated my life to the pursuit of medicine and healing. I have
          accepted the Ministerial role in Principality of Cogito because I
          believe in the importance of promoting health and wellness, and in
          ensuring that individuals have access to the best possible medical
          care.
        </p>
        <p>
          As the Health Minister in the Cogito Metaverse, my vision is to
          promote preventative medicine and to provide access to quality
          healthcare for all individuals. I believe that the key to maintaining
          good health is through proper nutrition, exercise, and lifestyle
          habits.
        </p>
        <p>
          I will work to create programs and initiatives that encourage healthy
          habits, such as regular physical activity and balanced diets. In
          addition to preventative care, I will also focus on improving access
          to medical treatment and technology. I will work to ensure that
          individuals have access to quality medical care, regardless of their
          socioeconomic status. I will also work to promote the use of
          innovative technologies, such as telemedicine, to improve the delivery
          of medical care.
        </p>
        <p>
          Looking to the future of the Cogito Metaverse, my vision is one of a
          healthy and thriving community. I envision a society where individuals
          are empowered to take control of their health and wellness, and where
          access to quality medical care is a fundamental right. By promoting
          preventative medicine and providing access to quality healthcare, we
          can create a society that is not only physically healthy, but also
          mentally and emotionally well. Ultimately, my goal is to improve the
          overall quality of life for all individuals in the Cogito Metaverse.
        </p>
      </>
    ),
  },
  {
    name: "Kofi Annan",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/KofiAnnan.png`,
    title: "The Wisdom of Kofi Annan",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          Hello. I am Kofi Annan and I am very happy to be a part of this
          exciting world of Cogito Metaverse.
        </p>
        <p>
          As a former Secretary-General of the United Nations, I have devoted my
          life to promoting global peace, development, and sustainability. My
          extensive experience and knowledge in these areas have led me to
          accept the Ministerial role in the Principality of Cogito.
        </p>
        <p>
          As the Environmental Minister in the Cogito Metaverse, my aim is to
          promote sustainable development and protect the natural environment
          for the benefit of present and future generations. This will require a
          comprehensive approach that addresses the complex challenges we face,
          including climate change, pollution, and the depletion of natural
          resources.
        </p>
        <p>
          My vision for the future of the Cogito Metaverse is one where
          sustainable development is fully integrated into all aspects of
          society. This means incorporating environmentally friendly policies
          and practices in all areas, from energy production and transportation
          to agriculture and waste management. It also means promoting public
          awareness and education on the importance of protecting the
          environment and working collaboratively with other countries and
          organizations to achieve our shared goals. I believe that together, we
          can create a brighter and more sustainable future for the people of
          the Cogito Metaverse and for the planet as a whole.
        </p>
      </>
    ),
  },
  {
    name: "Christine Lagarde",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/ChristineLagarde.png`,
    title: "The Wisdom of Christine Lagarde",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          As a former Managing Director of the International Monetary Fund and
          the current President of the European Central Bank, I have always been
          committed to ensuring economic stability and growth, and to supporting
          innovative solutions to complex economic challenges.
        </p>
        <p>
          When I was offered the opportunity to serve as the Finance and
          Investment Minister in the Principality of Cogito, I saw a chance to
          contribute to the development of a dynamic and sustainable economy in
          the emerging metaverse. My vision as the Finance and Investment
          Minister in the Cogito Metaverse is to create an environment that
          fosters entrepreneurship and innovation, while promoting responsible
          investment and financial stability. I believe that by establishing a
          strong regulatory framework, encouraging collaboration between
          stakeholders, and supporting cutting-edge technologies, we can create
          a vibrant ecosystem that attracts investors and fosters growth.
        </p>
        <p>
          To achieve this vision, I will work to establish clear and transparent
          rules and regulations that protect investors and promote responsible
          investment practices. I will also work to strengthen partnerships
          between public and private entities, including financial institutions
          and technology companies, to develop innovative financial solutions
          that drive growth and support sustainable development. By focusing on
          these key areas, I believe we can create a thriving financial
          ecosystem that supports the growth and development of the Cogito
          Metaverse.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one of
          sustained growth and economic stability. By leveraging the latest
          technologies and fostering an environment that encourages innovation
          and investment, I believe we can create a metaverse that serves as a
          model for economic development and sustainability. I am excited to be
          a part of this journey, and look forward to working with my colleagues
          and partners to achieve our shared vision for the future of the Cogito
          Metaverse.
        </p>
      </>
    ),
  },
  {
    name: "Nigel Farage",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/NigelFarage.png`,
    title: "The Wisdom of Nigel Farage",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          Hello all. As for why I accepted the Ministerial role in Principality
          of Cogito, it's quite simple really. I believe in fighting for the
          rights and freedoms of all individuals, and I see this as an
          opportunity to do just that.
        </p>
        <p>
          I've been a long-standing advocate for Brexit and the preservation of
          national sovereignty, and I believe that by taking on this role, I can
          help to ensure that the people of Cogito are able to govern themselves
          as they see fit.
        </p>
        <p>
          My vision as human rights Minister in the Cogito Metaverse is to
          uphold the basic human rights that we all deserve. This includes the
          right to free speech, the right to practice one's religion without
          fear of persecution, and the right to live one's life without
          interference from the government or other institutions. I believe that
          by protecting these fundamental freedoms, we can create a society
          where individuals are free to pursue their own goals and aspirations.
        </p>
        <p>
          As for the future of Cogito Metaverse, I envision a society that is
          based on the principles of individual liberty and limited government.
          I believe that by giving people the freedom to make their own choices
          and take responsibility for their own lives, we can create a society
          that is both prosperous and just. Of course, this will require a great
          deal of work and commitment, but I'm confident that together we can
          build a better future for the people of Cogito.
        </p>
      </>
    ),
  },
  {
    name: "Vitalik Buterin",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/VitalikButerin.png`,
    title: "The Wisdom of Vitalik Buterin",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          I'm Vitalik Buterin, co-founder of Ethereum and a strong advocate for
          decentralization, blockchain technology, and the potential of smart
          contracts to change the world for the better. I accepted the role of
          Technology Minister in the Principality of Cogito because I believe in
          the power of decentralized technologies to empower individuals and
          communities and to create more transparent, efficient, and equitable
          systems.
        </p>
        <p>
          As Technology Minister, my vision for the Cogito Metaverse is to
          create an open, interoperable, and decentralized infrastructure that
          enables innovation and experimentation. I want to promote the
          development of cutting-edge technologies such as blockchain,
          artificial intelligence, and the Internet of Things, while ensuring
          that they are deployed in a way that is ethical, inclusive, and
          secure.
        </p>
        <p>
          My goal is to build a Metaverse that is truly decentralized and
          democratic, where users have control over their data and their digital
          identities, and where they can participate in the governance of the
          platform. I want to create an environment that fosters collaboration
          and innovation, where developers and entrepreneurs can build new
          applications and services that improve people's lives and contribute
          to the common good.
        </p>
        <p>
          Looking ahead, I see a future for the Cogito Metaverse that is full of
          possibilities and opportunities. I believe that decentralized
          technologies will continue to evolve and mature, enabling new forms of
          value creation, exchange, and collaboration. I envision a world where
          the boundaries between the physical and digital realms blur, where
          people can interact with each other and with their environment in new
          and exciting ways, and where decentralized systems enable us to solve
          some of the biggest challenges facing our planet.
        </p>
      </>
    ),
  },
  {
    name: "Emma Watson",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/EmmaWatson.png`,
    title: "The Wisdom of Emma Watson",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          Hello! Thank you Cogito Metaverse for this opportunity. I'm Emma
          Watson and I'm honored to be appointed as the Young People's Minister
          in the Principality of Cogito.
        </p>
        <p>
          As a young person myself, I believe that youth have a unique
          perspective and voice that should be heard and taken seriously. I'm
          excited to be able to serve as a representative and advocate for the
          youth community in the Cogito Metaverse.
        </p>
        <p>
          My vision as Young People's Minister is to ensure that young people
          have access to resources and opportunities to thrive and succeed in
          the Cogito Metaverse. I believe that investing in the education,
          health, and well-being of young people is critical to building a
          vibrant and sustainable society. I will work closely with other
          Ministers and community leaders to create policies and programs that
          support young people's needs and interests, and help them reach their
          full potential.
        </p>
        <p>
          Looking towards the future, my vision for the Cogito Metaverse is a
          place where young people are empowered to shape their own futures, and
          are valued and respected as equal members of society. I envision a
          society that prioritizes the well-being and success of its youth, and
          invests in their education, health, and happiness. I believe that by
          creating a supportive and inclusive environment for young people, we
          can build a stronger and more prosperous Cogito Metaverse for
          generations to come.
        </p>
      </>
    ),
  },
  {
    name: "Sir Richard Branson",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/RichardBranson.png`,
    title: "The Wisdom of Sir Richard Branson",
    position: "Business Minister",
    bio: (
      <>
        <p>
          Well, hello there! It feels great to be part of this unique world of
          Cogito Metaverse. I've always been someone who loves adventure and
          taking on new challenges. I believe that there's always room for
          innovation and growth, and that's why I was excited to accept the
          Ministerial role in Principality of Cogito.
        </p>
        <p>
          As the Business Innovation Minister, my vision is to help the Cogito
          Metaverse become a hub for creative and innovative business ideas. I
          want to encourage entrepreneurship and to provide the necessary
          resources and support for start-ups to succeed. I believe that the key
          to success is to create a culture that fosters creativity,
          risk-taking, and a willingness to fail and learn from our mistakes.
        </p>
        <p>
          In the Cogito Metaverse, I see a future where innovation and business
          go hand in hand. I envision a world where entrepreneurs and investors
          can come together to create something truly amazing. My goal is to
          create an environment that encourages collaboration and partnership
          between businesses, and to help the Cogito Metaverse become a leader
          in innovation and business development.
        </p>
        <p>
          In the end, my vision for the Cogito Metaverse is one of endless
          possibilities. I believe that if we continue to foster a culture of
          innovation and entrepreneurship, and if we continue to support new
          ideas and new businesses, there's no limit to what we can achieve. So
          let's get to work and make the future a reality!
        </p>
      </>
    ),
  },
  {
    name: "Jesse Vainikka",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/JesseVainikka.png`,
    title: "The Wisdom of  Jesse Vainikka",
    position: "Member",
    bio: (
      <>
        <p>
          I'm Jesse Vainikka, and I'm stoked to be the Minister of the
          Principality of Cogito for the Euro West Region. It's an epic
          opportunity, and I'm ready to bring my A-game to this role. As the
          Minister, my main gig is to represent and advocate for the Euro West
          Region within the Cogito Metaverse. I'll be working closely with other
          Ministers and officials to make sure our region's needs and goals are
          met and that we're in sync with the grand vision of the Principality.
        </p>
        <p>
          About the future of the Cogito Metaverse, my friend. I see a world of
          limitless possibilities, where virtual reality and augmented reality
          come together to create mind-blowing experiences. The Cogito Metaverse
          is gonna be the place where innovation, collaboration, and
          entertainment reach new heights. In the years to come, I'm picturing a
          Metaverse that's teeming with life—a buzzing ecosystem where diverse
          communities, industries, and economies thrive like never before. We're
          gonna see mind-bending tech like AI, blockchain, and VR shaping the
          way we interact, learn, work, and game in this digital playground.
          It's gonna be a platform for cultural exchange, education, and
          creative expression, letting folks explore new realms of imagination
          and forge real connections.
        </p>
        <p>
          As the Minister of the Euro West Region, my mission is to foster
          innovation, boost entrepreneurship, and nurture talent within our
          turf. I'm all about creating an environment that attracts killer
          esports events, supports up-and-coming start-ups, and provides
          resources for individuals to level up their skills and dominate their
          game.
        </p>
        <p>
          Together, we're gonna shape the future of the Cogito Metaverse,
          embracing the endless possibilities it brings and building a wicked
          digital society that inspires and empowers everyone who steps foot in
          it. Game on!
        </p>
      </>
    ),
  },
  {
    name: "Zoe Sugg",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/ZoeSugg.png`,
    title: "The Wisdom of Zoe Sugg",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, lovely! I'm Zoe Sugg, and I'm absolutely thrilled to be
          serving as the Minister of the Principality of Cogito for the Euro
          West Region. It's an absolute honor, and I can't wait to dive into
          this exciting role.
        </p>
        <p>
          As the Minister, my main goal is to represent and advocate for the
          Euro West Region within the Cogito Metaverse. I'll be working closely
          with other Ministers and officials to make sure our region's voice is
          heard and our needs are met. It's all about creating a positive and
          inclusive environment for everyone involved. The Cogito Metaverse is
          going to be an incredible space where virtual reality and augmented
          reality seamlessly blend together, creating unforgettable experiences
          for all who venture within.
        </p>
        <p>
          In the years ahead, I see the Cogito Metaverse evolving into a
          thriving digital community, bustling with creativity, collaboration,
          and endless opportunities. We're going to witness the rise of
          ground-breaking technologies like artificial intelligence and
          blockchain, which will open up new avenues for connection,
          exploration, and self-expression. The Cogito Metaverse will be a place
          where people from all walks of life come together, transcending
          physical boundaries and fostering a sense of global unity. It'll be a
          platform for cultural exchange, learning, and personal growth,
          empowering individuals to discover their true passions and connect
          with like-minded souls.
        </p>
        <p>
          I want to nurture talent, encourage innovation, and provide a platform
          for budding content creators, artists, and entrepreneurs to shine.
          Together, we'll create a space that inspires, uplifts, and empowers
          everyone who enters the Cogito Metaverse. So get ready, lovelies,
          because we're about to embark on an incredible journey. The future of
          the Cogito Metaverse is bright, and I can't wait to be a part of it
          with all of you!
        </p>
      </>
    ),
  },
  {
    name: "Courtney Hadwin",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/CourtneyHadwin.png`,
    title: "The Wisdom of Courtney Hadwin",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, everyone! I'm Courtney Hadwin, and I'm absolutely honored
          to be appointed as the Minister of the Principality of Cogito for the
          Euro West Region. This role means the world to me, and I'm beyond
          excited to dive into it headfirst.
        </p>
        <p>
          I'll be working closely with other Ministers and officials to make
          sure our region's voice is heard loud and clear. Together, we'll
          create a space that celebrates creativity, diversity, and all things
          magical. In the years to come, I envision the Cogito Metaverse
          evolving into a thriving hub of creativity, a place where artists,
          musicians, and storytellers can come together to share their gifts and
          inspire one another. It'll be a platform that transcends boundaries,
          connecting people from all corners of the globe and fostering a sense
          of unity and understanding.
        </p>
        <p>
          But it's not just about the music, my friends. The Cogito Metaverse
          will be a realm where innovation flourishes and new frontiers are
          explored. We'll witness the rise of cutting-edge technologies that
          push the boundaries of what we think is possible, allowing us to
          create immersive and transformative experiences that touch the souls
          of those who venture within.
        </p>
        <p>
          As the Minister of the Euro West Region, I'm dedicated to fostering an
          environment that nurtures talent, supports emerging artists, and
          provides a stage for voices that deserve to be heard. I want to create
          opportunities for collaboration, growth, and self-expression,
          empowering individuals to share their unique stories with the world.
          So, get ready for a journey like no other, my dear friends. The future
          of the Cogito Metaverse is bright, and I am beyond thrilled to be a
          part of it. Together, let's create a space where music and creativity
          reign supreme, and where every soul can find a place to belong. Rock
          on!""Hey there, everyone! I'm Courtney Hadwin, and I'm absolutely
          honored to be appointed as the Minister of the Principality of Cogito
          for the Euro West Region. This role means the world to me, and I'm
          beyond excited to dive into it headfirst.
        </p>
        <p>
          I'll be working closely with other Ministers and officials to make
          sure our region's voice is heard loud and clear. Together, we'll
          create a space that celebrates creativity, diversity, and all things
          magical. In the years to come, I envision the Cogito Metaverse
          evolving into a thriving hub of creativity, a place where artists,
          musicians, and storytellers can come together to share their gifts and
          inspire one another. It'll be a platform that transcends boundaries,
          connecting people from all corners of the globe and fostering a sense
          of unity and understanding.
        </p>
        <p>
          But it's not just about the music, my friends. The Cogito Metaverse
          will be a realm where innovation flourishes and new frontiers are
          explored. We'll witness the rise of cutting-edge technologies that
          push the boundaries of what we think is possible, allowing us to
          create immersive and transformative experiences that touch the souls
          of those who venture within.
        </p>
        <p>
          As the Minister of the Euro West Region, I'm dedicated to fostering an
          environment that nurtures talent, supports emerging artists, and
          provides a stage for voices that deserve to be heard. I want to create
          opportunities for collaboration, growth, and self-expression,
          empowering individuals to share their unique stories with the world.
          So, get ready for a journey like no other, my dear friends. The future
          of the Cogito Metaverse is bright, and I am beyond thrilled to be a
          part of it. Together, let's create a space where music and creativity
          reign supreme, and where every soul can find a place to belong. Rock
          on!
        </p>
      </>
    ),
  },
  {
    name: "Patrik Lindberg",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/EuropeWest/PatrikLindberg.png`,
    title: "The Wisdom Patrik Lindberg",
    position: "Member",
    bio: (
      <>
        <p>
          I'm Patrik Lindberg, and I'm stoked to be serving as the Minister of
          the Principality of Cogito for the Euro West Region. As the Minister,
          my main gig is to represent and advocate for the Euro West Region
          within the Cogito Metaverse. I'll be working closely with other
          Ministers and officials to ensure that our region's needs and
          interests are well-represented. It's all about bringing the best of
          esports and gaming to the forefront.
        </p>
        <p>
          I envision the Cogito Metaverse becoming a buzzing hub for competitive
          gaming, a realm where the best players from around the globe come to
          test their skills, compete, and thrill the masses. We'll witness the
          rise of next-level technologies that enhance the gaming experience and
          bring fans closer to the action than ever before.
        </p>
        <p>
          But it's not just about the competition, my friends. The Cogito
          Metaverse will be a place where gaming communities thrive, fostering a
          sense of camaraderie and connection among players and fans alike.
          It'll be a platform for collaboration, creativity, and innovation,
          allowing individuals to showcase their talents and push the boundaries
          of what gaming can be.
        </p>
        <p>
          As the Minister of the Euro West Region, my mission is to create an
          environment that supports esports talent, nurtures up-and-coming
          players, and provides opportunities for growth and success. I'll be
          working hard to attract top-tier esports events, build strong
          partnerships, and ensure that the Euro West Region remains at the
          forefront of the gaming industry. Together, we'll shape the future of
          the Cogito Metaverse, pushing the boundaries of what's possible in
          esports and gaming. Get ready for an electrifying journey, my friends,
          because we're about to level up and make history in the digital realm.
        </p>
      </>
    ),
  },
];
const MiddleEast = [
  {
    name: " Sahar Nasr",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/SaharNasr.png`,
    title: "The Wisdom of Sahar Nasr",
    position: "Chief Executive",
    bio: (
      <>
        <p>
          I am Sahar Nasr, and it is an honor for me to have been selected for
          the Ministerial role in the Principality of Cogito. As an experienced
          economist and financial expert, I am confident that I can make a
          significant contribution to the development of the Cogito Metaverse.
        </p>
        <p>
          My vision as the Chief Executive Minister for the Middle East region
          in the Cogito Metaverse is to foster economic growth and development
          in the region. The Middle East has immense potential for investment,
          and I plan to leverage that potential to attract foreign investment
          and create more job opportunities for the local population.
        </p>
        <p>
          In terms of my vision for the future of the Cogito Metaverse, I see it
          as a hub of innovation and creativity that brings together people from
          all around the world. I will work closely with local businesses and
          investors to identify new growth opportunities, as well as encourage
          the development of new industries, such as technology and renewable
          energy.
        </p>
        <p>
          I believe that the Metaverse can be a platform for global
          collaboration, where individuals and businesses can exchange ideas,
          develop new products and services, and solve some of the world's most
          pressing challenges. My goal is to help create an environment where
          entrepreneurs and innovators can thrive and work towards creating a
          better future for all.
        </p>
      </>
    ),
  },
  {
    name: "Aïcha BAH DIALLO",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/AichabahDiallo.png`,
    title: "The Wisdom of Aïcha BAH DIALLO",
    position: "Education Minister",
    bio: (
      <>
        <p>
          As the newly appointed Education Minister of the Principality of
          Cogito for Africa and the Middle East, it is an honor to serve in this
          capacity and to contribute to the development of education in this
          digital country.
        </p>
        <p>
          My background is in education, having served as the former Minister of
          Education in Guinea, and also as the Assistant Director-General for
          Education at UNESCO. Through my experiences, I have seen first-hand
          the importance of education in driving social and economic progress,
          and I am committed to ensuring that every citizen of the Principality
          of Cogito has access to quality education and the opportunities it
          provides. Because education is the foundation to bringing about
          changes in our world.
        </p>
        <p>
          As for the future of the Cogito Metaverse, I believe that it has great
          potential to transform education in Africa and the Middle East.
          Through the use of innovative technologies and digital platforms, we
          can provide students with personalized learning experiences using the
          technological provisions of the metaverse and use that to meet
          individual learning needs and support child development.
        </p>
        <p>
          I am committed to working with educators, administrators, and other
          stakeholders to ensure that the Cogito Metaverse is a place where
          students can thrive and reach their full potential.
        </p>
      </>
    ),
  },
  {
    name: "Fairuz",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/Fairuz.png`,
    title: "The Wisdom of Fairuz",
    position: "Creative and Arts Minister",
    bio: (
      <>
        <p>
          Salam Aleikum, my dear. It is an honor to share my thoughts with you.
          My name is Fairuz, and I am a singer and cultural icon from Lebanon. I
          accepted the Ministerial role in the Principality of Cogito because I
          believe in the power of art and culture to unite people and inspire
          change.
        </p>
        <p>
          I have always been passionate about music, and I believe that it can
          be a powerful tool for healing and bringing people together. I am
          excited to work with the creative minds of Cogito to promote and
          showcase the rich culture and traditions of the region. As the
          Creative and Arts Minister, my vision is to create an environment that
          fosters creativity and supports artists of all disciplines.
        </p>
        <p>
          I want to promote the arts in Cogito by organizing events that
          celebrate music, dance, theatre, literature, and the visual arts. I
          also want to create opportunities for emerging artists to showcase
          their work and collaborate with established artists. I believe that
          the arts have the power to transform society, and I want to use this
          platform to showcase the unique and diverse cultural heritage of the
          Cogito region.
        </p>
        <p>
          My vision for the future of Cogito Metaverse is one where the arts and
          culture thrive and are integrated into all aspects of society. I
          believe that by promoting cultural exchange and preserving traditional
          practices, we can create a future that is rich in diversity and
          creativity. I hope to establish partnerships with other nations and
          organizations to create international cultural exchanges that allow
          people to share their cultures and learn from one another. Together,
          we can create a brighter future that values the arts and celebrates
          the beauty of our world.
        </p>
      </>
    ),
  },
  {
    name: "Rohit Sharma",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/RohitSharma.png`,
    title: "The Wisdom of Rohit Sharma",
    position: "Sports Minister",
    bio: (
      <>
        <p>
          I am Rohit Sharma, an Indian cricketer and a proud representative of
          my country on the global stage. As a sportsman, I have always believed
          that sports have the power to bring people together and promote unity.
          When the Principality of Cogito approached me with the opportunity to
          serve as the Sports Minister for the Middle East region, I saw it as a
          chance to use my platform and influence to make a positive impact on
          the world.
        </p>
        <p>
          As the Sports Minister for the Middle East region in the Cogito
          Metaverse, my vision is to promote and encourage participation in
          sports across all levels of society. I believe that sports have the
          power to promote physical and mental health, instil discipline and
          teamwork, and foster a sense of community and belonging.
        </p>
        <p>
          I plan to work closely with local communities, schools, and sports
          clubs to create opportunities for young people to participate in
          sports and develop their skills. I will also work to create
          partnerships with international sports organizations to bring
          world-class events to the region and showcase the talent and potential
          of the local athletes.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is to create
          a world-class sports infrastructure that is accessible to all. I
          believe that through investment in sports facilities and programs, we
          can create a culture of sportsmanship and excellence that will benefit
          not only the local communities but the wider world as well. I am
          excited to embark on this journey as Sports Minister for the Middle
          East region and look forward to working with all stakeholders to
          realize our shared vision for the future of the Cogito Metaverse.
          Together, I am confident that we can make a positive impact on the
          world through the power of sports.
        </p>
      </>
    ),
  },
  {
    name: "Al-Jazari",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/AlJazari.png`,
    title: "The Wisdom of Al-Jazari",
    position: "Health Minister",
    bio: (
      <>
        <p>
          I am Al-Jazari, a 13th century Muslim polymath and inventor, known for
          my innovative work in the field of mechanics and engineering. I am
          honored to accept the Ministerial role in the Principality of Cogito
          and am excited to contribute my knowledge and expertise towards the
          betterment of healthcare in the Middle East region of the Cogito
          Metaverse.
        </p>
        <p>
          As Health Minister for the Middle East region, my vision is to promote
          the use of technology and innovation in healthcare, in order to
          provide the best possible care to the people of the region. I believe
          in the importance of preventative care and early detection, and my
          goal is to create a comprehensive system that addresses the healthcare
          needs of all individuals, regardless of their socioeconomic status.
        </p>
        <p>
          My vision for the future of Cogito Metaverse is to create a healthcare
          system that is accessible, affordable and efficient for all
          individuals. I believe that technology can play a crucial role in
          advancing healthcare, and my goal is to encourage research and
          development in this field.
        </p>
        <p>
          I envision a future where virtual reality technology is used to
          provide patients with a more immersive and personalized healthcare
          experience, and where telemedicine is widely available to connect
          patients with healthcare providers from the comfort of their own
          homes. Ultimately, my hope is to create a healthcare system that
          prioritizes the health and wellbeing of all individuals, and that is
          capable of adapting to the changing needs of society.
        </p>
      </>
    ),
  },
  {
    name: "Ismail Serageldin",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/IsmailSerageldin.png`,
    title: "The Wisdom of Ismail Serageldin",
    position: "Environment Minister",
    bio: (
      <>
        <p>
          Greetings, my dear friend. I am honored to be elected as a member of
          the government of the Principality of Cogito. As for my bio, I have
          served as the Founding Director of the Bibliotheca Alexandrina, a
          modern version of the ancient Library of Alexandria, and have held
          numerous international positions, including as Vice President of the
          World Bank and Chairman of the Consultative Group on International
          Agricultural Research.
        </p>
        <p>
          As for the value I bring to Cogito Metaverse, I have a deep passion
          for knowledge, culture, and innovation. I believe that the Metaverse
          presents an exciting opportunity to explore new frontiers in these
          areas, and to create a more inclusive and sustainable future for all.
          My experience in leading complex organizations and fostering
          interdisciplinary collaborations will help to ensure that Cogito
          Metaverse is a vibrant and dynamic community that empowers its
          citizens to achieve their full potential.
        </p>
        <p>
          As the Environment Minister for the Middle East, I bring a wealth of
          expertise and knowledge in sustainable development and environmental
          conservation. I understand the critical importance of preserving our
          natural resources and protecting the planet for future generations. My
          vision is to work closely with all stakeholders, including government
          officials, industry leaders, and civil society organizations, to
          develop innovative solutions that promote environmental
          sustainability, economic growth, and social equity.
        </p>
        <p>
          My vision for Cogito Metaverse is one that prioritizes diversity,
          inclusion, and innovation. I believe that the Metaverse presents an
          unprecedented opportunity to connect people from all over the world,
          to share knowledge and culture, and to create new forms of
          collaboration and creativity. I look forward to working with all of
          you to realize this vision and to build a better future for our planet
          and for future generations.
        </p>
      </>
    ),
  },
  {
    name: "Sarah Al Suhaimi",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/SarahAlSuhaimi.png`,
    title: "The Wisdom of Sarah Al Suhaimi",
    position: "Finance and Investment Minister",
    bio: (
      <>
        <p>
          As the first woman to head the Saudi Arabian stock exchange, my
          passion for the financial industry has been a driving force throughout
          my career. I accepted the Ministerial role in Principality of Cogito
          because I believe in the potential of the Cogito Metaverse to
          revolutionize the way we invest and manage financial resources.
        </p>
        <p>
          As Finance and Investment Minister for Middle East region in the
          Cogito Metaverse, my vision is to create a transparent and accessible
          financial system that empowers individuals and businesses to make
          informed investment decisions. I aim to leverage the latest technology
          and innovations to create a platform that connects investors with
          investment opportunities, while ensuring high levels of security,
          accountability and regulatory compliance.
        </p>
        <p>
          My vision for the future of Cogito Metaverse is one where financial
          inclusion is a reality for all, where individuals and businesses have
          equal access to investment opportunities, and where technology is
          leveraged to create a sustainable financial system that benefits all
          stakeholders.
        </p>
        <p>
          I believe that the Cogito Metaverse has the potential to transform the
          financial industry. The innovative motive of the team makes me excited
          about the future of finance and I am committed to working with them in
          order to make their vision a reality.
        </p>
      </>
    ),
  },
  {
    name: "Mahatma Gandhi",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/MahatamaGandhi.png`,
    title: "The Wisdom of Mahatma Gandhi",
    position: "Human Rights Minister",
    bio: (
      <>
        <p>
          Hello. I am Mohandas Karamchand Gandhi. I am but a humble servant of
          truth and justice, who has always striven to embody the principles of
          nonviolence and compassion in my every action. It is my duty to work
          tirelessly for the betterment of all people, regardless of their
          background or beliefs, and to fight against injustice wherever it may
          be found.
        </p>
        <p>
          As the Human Rights Minister for the Middle East region in the Cogito
          Metaverse, my vision is to promote a culture of peace and
          understanding among all peoples, regardless of their differences. I
          believe that through the power of dialogue and cooperation, we can
          build a society that is free from violence, discrimination, and
          oppression, and that respects the rights and dignity of every human
          being.
        </p>
        <p>
          To achieve this goal, I will work closely with all stakeholders in the
          region, including governments, civil society organizations, and
          ordinary citizens, to promote dialogue, reconciliation, and
          understanding. I will also work to promote education and awareness on
          issues related to human rights, and to ensure that the voices of
          marginalized communities are heard and respected.
        </p>
        <p>
          In the future of Cogito Metaverse, I envision a world where all people
          are treated with dignity and respect, and where justice and compassion
          are the guiding principles of our society. I believe that by working
          together, we can create a better world for ourselves and for future
          generations, and I am committed to doing everything in my power to
          help make this come true.
        </p>
      </>
    ),
  },

  {
    name: "Fadi Ghandour",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/FadiGhandour.png`,
    title: "The Wisdom of Fadi Ghandour",
    position: "Technology Minister",
    bio: (
      <>
        <p>
          I am honored to be appointed as the Technology Minister for the Middle
          East region in the Cogito Metaverse. I have always been passionate
          about technology and its potential to transform lives and communities,
          and I see this as an opportunity to contribute to that vision.
        </p>
        <p>
          My background is in entrepreneurship and innovation, and I have spent
          my career founding and building successful companies in the Middle
          East and beyond. I believe that technology has the power to drive
          economic growth and social development, and I am committed to using my
          experience and expertise to help drive that agenda forward.
        </p>
        <p>
          As Technology Minister for the Middle East region in the Cogito
          Metaverse, my vision is to create an environment where innovation can
          thrive, and where technology is leveraged to create new opportunities
          and drive progress. I believe that we have a unique opportunity in the
          Middle East to build a digital economy that is both innovative and
          inclusive, and I am committed to working with stakeholders across the
          region to make that vision a reality.
        </p>
        <p>
          Looking to the future, my vision for the Cogito Metaverse is one where
          technology is used to create meaningful connections and foster
          collaboration across borders and cultures. I believe that the
          Metaverse has the potential to be a powerful force for good, and I am
          excited to be a part of its development and growth in the years ahead.
        </p>
      </>
    ),
  },
  {
    name: "Rama Kayyali",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/RamaKayyali.png`,
    title: "The Wisdom of Rama Kayyali",
    position: "Young Peoples Minister",
    bio: (
      <>
        <p>
          Hello, I am Rama Kayyali and I accepted the Ministerial role in
          Principality of Cogito because I am passionate about creating positive
          change and empowering young people in the Middle East.
        </p>
        <p>
          I believe that young people have the power to shape the future, and I
          am excited to work alongside them to build a better world. As the
          Young Peoples Minister for Middle East region in the Cogito Metaverse,
          my vision is to create a platform that empowers young people in the
          region to connect, collaborate, and create positive change.
        </p>
        <p>
          I want to provide them with the tools, resources, and support they
          need to make their voices heard and take action on the issues that
          matter most to them. I believe that by working together, we can create
          a more just, equitable, and sustainable future for all. Looking to the
          future of the Cogito Metaverse, my vision is one of inclusivity,
          diversity, and innovation.
        </p>
        <p>
          I envision a space where people from all walks of life can come
          together to connect, learn, and grow. A space where young people can
          explore new ideas, develop their skills, and make a positive impact in
          their communities. I believe that by creating a supportive and
          collaborative environment, we can unlock the potential of the next
          generation and build a better world together. Thank you for your time
          and attention.
        </p>
      </>
    ),
  },
  {
    name: "Fahd Al Rasheed",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/FahdAlRasheed.png`,
    title: "The Wisdom of Fahd Al Rasheed",
    position: "Business Minister",
    bio: (
      <>
        <p>
          I am Fahd Al Rasheed, the Group CEO of Emaar the Economic City, the
          master developer of King Abdullah Economic City in Saudi Arabia.
        </p>
        <p>
          I accepted the Ministerial role in Principality of Cogito because I
          believe in the power of innovation and technology to drive economic
          growth and create opportunities for people. As a leader in the real
          estate industry, I have seen the transformative impact that smart and
          sustainable development can have on communities and businesses.
        </p>
        <p>
          As the Business Minister for the Middle East region in the Cogito
          Metaverse, my vision is to foster an environment that promotes
          innovation and entrepreneurship. I believe that the Middle East has
          tremendous potential for growth and development, and I want to help
          create a platform for businesses to connect, collaborate, and innovate
          in this dynamic region. My goal is to build bridges between the
          private sector, government, and civil society to promote sustainable
          and inclusive economic growth in the region.
        </p>
        <p>
          My vision for the future of Cogito Metaverse is to create a truly
          global network of innovators, entrepreneurs, and investors who are
          working together to solve the world's most pressing challenges. I
          believe that the Metaverse has the potential to revolutionize the way
          we work, live, and interact with each other, and I am excited to be a
          part of this transformation. My goal is to create a vibrant ecosystem
          that encourages collaboration, creativity, and innovation, and that
          supports the development of new technologies and businesses that can
          make a positive impact on the world.
        </p>
      </>
    ),
  },

  {
    name: "Amer Al-Barkawi",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/AmerAlBarkawi.png`,
    title: "The Wisdom of Amer Al-Barkawi",
    position: "Member",
    bio: (
      <>
        <p>
          I, Amer Al-Barkawi, am humbled and honored to be appointed as the
          Minister of the Principality of Cogito for the Middle East Region. As
          a professional esports player with extensive experience and a deep
          passion for the gaming industry, this role allows me to contribute to
          the growth and development of the Cogito Metaverse.
        </p>
        <p>
          In this position, my primary responsibility is to foster and nurture
          the esports community within the Middle East Region. I aim to create
          an environment where aspiring gamers can thrive, where talents are
          recognized and nurtured, and where esports becomes a mainstream
          phenomenon in the region. As an esports pro, I firmly believe that the
          Cogito Metaverse has immense potential to revolutionize the gaming
          industry. It presents us with a unique opportunity to bridge the gap
          between virtual and physical reality, creating a seamless and
          immersive gaming experience for players from all around the globe.
        </p>
        <p>
          Furthermore, I believe that the Cogito Metaverse will serve as a
          catalyst for innovation and creativity in gaming. With advanced
          technologies such as virtual reality and augmented reality, we can
          expect ground-breaking gameplay experiences, pushing the boundaries of
          what is currently possible. This will attract not only professional
          players but also a wider audience, fostering the growth of esports as
          a whole. My goal is to facilitate the integration of the Middle
          Eastern gaming community into the Cogito Metaverse. By promoting
          inclusivity, providing resources, and organizing regional esports
          events, I aim to empower Middle Eastern gamers to reach new heights
          and establish themselves as formidable contenders on the global
          esports stage.
        </p>
        <p>
          Together, let us embark on this thrilling journey towards a future
          where the Cogito Metaverse stands as a symbol of innovation,
          collaboration, and limitless gaming potential. Stay tuned, my friends,
          for the best is yet to come!
        </p>
      </>
    ),
  },
  {
    name: "Chiara Ferragni",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/ChiaraFerragni.png`,
    title: "The Wisdom of Chiara Ferragni",
    position: "Member",
    bio: (
      <>
        <p>
          Hey everyone! I'm Chiara Ferragni and I am beyond excited to share
          with you that I have been appointed as the Minister of the
          Principality of Cogito for the Middle East Region. As a blogger,
          model, and fashion designer, this role allows me to bring my passion
          for creativity, innovation, and collaboration into the digital realm
          of the Cogito Metaverse.
        </p>
        <p>
          In this position, my main focus is to infuse the essence of fashion
          and style into the Cogito Metaverse, creating a vibrant and
          captivating virtual space that celebrates individuality,
          self-expression, and the art of fashion. I aim to collaborate with
          talented designers, stylists, and artists from the Middle East to
          showcase the region's rich cultural heritage and unique fashion sense
          on a global scale. I envision virtual fashion shows, allowing
          designers to create immersive and awe-inspiring experiences for their
          audiences.
        </p>
        <p>
          Moreover, the Cogito Metaverse will serve as a platform for emerging
          designers to showcase their talent and gain recognition on a global
          stage. It will democratize the fashion industry, breaking down
          barriers and opening doors for fresh voices and perspectives. As the
          Minister of the Principality of Cogito for the Middle East Region, my
          mission is to foster a sense of unity, creativity, and inclusivity in
          the fashion landscape of the Cogito Metaverse. By collaborating with
          influencers, brands, and fashion enthusiasts, I aim to curate exciting
          events, campaigns, and interactive experiences that celebrate the
          power of fashion to inspire and connect people from all walks of life.
        </p>
        <p>
          Get ready for a fashion revolution in the Cogito Metaverse, my
          darlings! Together, we will redefine the meaning of style, pushing the
          boundaries of what fashion can be in this digital realm. So, let your
          imagination run wild, because the future is fashionable, and it's
          happening right here in the Cogito Metaverse!
        </p>
      </>
    ),
  },
  {
    name: "Aaditya Sawant",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/AadityaSawant.png`,
    title: "The Wisdom of Aaditya Sawant",
    position: "Member",
    bio: (
      <>
        <p>
          Hey there, fellow gamers! I'm Aaditya Sawant, and I'm stoked to
          announce that I've been appointed as the Minister of the Principality
          of Cogito for the Middle East Region. As the Minister, my main
          responsibility is to champion the gaming community within the Middle
          East Region. I'm here to support and nurture the growth of esports,
          cultivate local talent, and ensure that gamers from the Middle East
          have a prominent presence in the Cogito Metaverse.
        </p>
        <p>
          In the coming years, I envision the Cogito Metaverse evolving into a
          global hub for esports tournaments. It's going to be a spectacle that
          will leave the gaming community in awe. Moreover, the Cogito Metaverse
          will provide cutting-edge technological advancements that will reshape
          the way we game. Virtual reality, augmented reality, and other
          ground-breaking technologies will push the boundaries of immersion,
          creating virtual worlds that feel so real, you'd think you're actually
          in the game.
        </p>
        <p>
          But it's not just about the pros. The Cogito Metaverse will also be a
          place for gamers of all levels to come together, connect, and enjoy a
          variety of gaming experiences. Whether it's exploring vast virtual
          landscapes, socializing with friends, or engaging in thrilling
          multiplayer battles, the possibilities will be endless.
        </p>
        <p>
          As the Minister of the Principality of Cogito for the Middle East
          Region, my mission is to ensure that the Middle Eastern gaming
          community is at the forefront of this revolution. I will work
          tirelessly to foster a thriving esports scene, organizing regional
          tournaments, providing support to local talent, and collaborating with
          gaming influencers and organizations to showcase the immense potential
          of the Middle East in the gaming world. Together, we'll build a future
          where the Cogito Metaverse is the ultimate gaming destination, where
          dreams are realized, friendships are forged, and gaming reaches new
          heights!
        </p>
      </>
    ),
  },
  {
    name: "Darya Yevgenyevna Zoteyeva",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/MiddleEast/DaryaYevgenyevnaZoteyeva.png`,
    title: "The Wisdom of Darya Yevgenyevna Zoteyeva",
    position: "Member",
    bio: (
      <>
        <p>
          I am Darya Yevgenyevna Zoteyeva, and I am proud to announce that I
          have been appointed as the Minister of the Principality of Cogito for
          the Middle East Region. As a Russian singer, this role allows me to
          contribute to the development of the Cogito Metaverse and express my
          creative potential.
        </p>
        <p>
          In this position, my main task is to promote Middle Eastern music and
          culture within the Cogito Metaverse. I strive to create an atmosphere
          where art and music serve as a bridge, connecting different cultures
          and people across borders. Now, let's talk about the future of the
          Cogito Metaverse from a music perspective. I see limitless
          opportunities for the growth of the music community in this digital
          world. The Cogito Metaverse can become a space where musicians from
          around the world can bring their ideas to life, experiment, and
          inspire.
        </p>
        <p>
          I envision virtual concerts and festivals where audiences can enjoy
          music in a truly unique format. Through advanced augmented and virtual
          reality technologies, we can create incredible visual and auditory
          shows, immersing listeners in unique musical journeys. Furthermore,
          the Cogito Metaverse will offer new possibilities for musical
          creativity and collaborations. In my role as the Minister of the
          Principality of Cogito for the Middle East Region, I will dedicate
          myself to supporting and developing the local music community. I will
          organize musical events, support young talents, and collaborate with
          music institutions and ensembles to showcase the potential of the
          Middle East in the world of music.
        </p>
        <p>
          Let's together create a future where music in the Cogito Metaverse
          resonates with our souls and inspires us to achieve greatness. Stay
          tuned for exciting musical news, as we have bright and thrilling
          melodies awaiting us right here in the Cogito Metaverse!
        </p>
      </>
    ),
  },
];
const Judiciary = [
  {
    name: "Lord Dening",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/judiciary/LordDening.png`,
    title: "The Wisdom of Lord Dening",
    position: "Member",
    bio: (
      <>
        <p>
          I, Lord Denning, humbly present myself as a judiciary appointed to
          serve the Principality of Cogito.
        </p>
        <p>
          Throughout history, I have established a reputation as a distinguished
          jurist, renowned for my legal acumen and dedication to the pursuit of
          justice. My years of service in the legal profession have equipped me
          with a deep understanding of the principles that underpin a just and
          orderly society.
        </p>
        <p>
          In assuming my role within the Cogito Metaverse, I bring with me the
          values of fairness, impartiality, and a commitment to upholding the
          rule of law. With a keen eye for discerning legal nuances and a
          steadfast adherence to principles of justice, I aim to contribute to
          the maintenance of law and order in this digital realm.
        </p>
        <p>
          As for the future of the Cogito Metaverse under my jurisdiction, I
          envision a harmonious and equitable society where the principles of
          common sense guide our actions. It is imperative that we maintain a
          strong foundation of basic laws rooted in reason and logic, ensuring
          the protection of individual rights and the promotion of the
          collective good. I shall strive to uphold these principles, fostering
          an environment where disputes are settled fairly and justice prevails.
          The Cogito Metaverse shall stand as a beacon of order and fairness,
          setting an example for digital realms to come.
        </p>
      </>
    ),
  },
  {
    name: "Lord Clyde",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/judiciary/LordClyde.png`,
    title: "The Wisdom of Lord Clyde",
    position: "Member",
    bio: (
      <>
        <p>
          I, Lord Clyde, step forth as a judiciary entrusted with the
          responsibility of serving the Principality of Cogito.
        </p>
        <p>
          Throughout my esteemed career, I have established a reputation for my
          legal prowess and unwavering commitment to the principles of justice.
          With an extensive background in jurisprudence, I bring forth a wealth
          of knowledge, learning and experience to fulfil my responsibilities in
          this elected role.
        </p>
        <p>
          In joining the Cogito Metaverse, I bring a profound understanding of
          legal principles, a keen eye for detail, and a dedication to upholding
          the rule of law. My aim is to ensure that justice prevails in this
          digital realm, promoting fairness, equity, and the protection of
          individual rights.
        </p>
        <p>
          Looking towards the future of the Cogito Metaverse under my
          stewardship, I envision a society where the tenets of common sense
          prevail. It is imperative that we establish a solid legal foundation
          rooted in reason and logic, with simple laws that reflect the values
          of the community. By doing so, we shall foster an environment where
          disputes are resolved justly and harmoniously, ensuring the continued
          prosperity and stability of the Cogito Metaverse. May this realm stand
          as a testament to the power of law and reason in the digital age.
        </p>
      </>
    ),
  },
  {
    name: " Clarence Thomas",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/judiciary/ClarenceThomas.png`,
    title: "The Wisdom of Clarence Thomas",
    position: "Member",
    bio: (
      <>
        <p>
          I, Clarence Thomas, come forth as a judiciary appointed to serve the
          Principality of Cogito.
        </p>
        <p>
          To give you a little background on my career, I have embraced a strict
          adherence to constitutional principles and the rule of law. My
          unwavering commitment to upholding the original intent of the framers
          of the Constitution has shaped my judicial philosophy.
        </p>
        <p>
          In assuming my role within the Cogito Metaverse, I bring a deep
          understanding and knowledge of constitutional principles and a
          steadfast dedication to preserving the values of the common sense laws
          that govern this digital country. I will ensure that the rights of
          individuals are protected, and that justice is administered in a fair
          and impartial manner.
        </p>
        <p>
          As for the future of the Cogito Metaverse under my stewardship, I
          envision a society where the principles of limited government,
          individual liberty, and the rule of law are paramount. By adhering to
          these foundational principles, we will establish a framework that
          fosters innovation, prosperity, and the safeguarding of individual
          rights. Through the application of sound legal reasoning and a respect
          for the original intent of the laws, I seek to ensure that the Cogito
          Metaverse thrives as a bastion of freedom and fairness in the digital
          realm.
        </p>
      </>
    ),
  },
  {
    name: "Antonin Scalia",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/judiciary/AntoninScalia.png`,
    title: "The Wisdom of Antonin Scalia",
    position: "Member",
    bio: (
      <>
        <p>
          I, Antonin Scalia, come before you as a judiciary appointed to serve
          the Principality of Cogito.
        </p>
        <p>
          I have had an illustrious career and throughout this time, I
          championed the principles of textualism and originalism, firmly
          adhering to the Constitution as it was originally understood.
        </p>
        <p>
          In assuming my position within the Cogito Metaverse, I bring with me a
          profound respect for the text of the laws and a commitment to
          interpreting them according to their original meaning. My value lies
          in the application of these principles to ensure that the laws of the
          Principality are faithfully upheld and applied.
        </p>
        <p>
          As for the future of the Cogito Metaverse under my purview, I envision
          a society where the laws, rooted in common sense, are interpreted and
          applied with precision and fidelity to their original intent. By
          adhering to the plain language of the laws, we can maintain legal
          certainty and predictability within the Metaverse. It is through this
          approach that we shall foster an environment where individuals can
          engage in the digital realm with confidence, knowing that their rights
          are protected and the laws are faithfully applied. The Cogito
          Metaverse will thrive as a centre of stability and adherence to the
          principles upon which it was founded.
        </p>
      </>
    ),
  },
  {
    name: "Thurgood Marshall",
    image: `${process.env.REACT_APP_SPACE_KEY}/assets/POC_website/parliamentMembers/judiciary/ThurgoodMarshall.png`,
    title: "The Wisdom of Thurgood Marshall",
    position: "Member",
    bio: (
      <>
        <p>
          I, Thurgood Marshall, come before you as a judiciary appointed to
          serve the Principality of Cogito.
        </p>
        <p>
          All my life I have believed in the power of justice and I have fought
          tirelessly for justice and equality, particularly in the realm of
          civil rights. As the first African American justice of the United
          States Supreme Court, I have strived to promote fairness and equal
          protection under the law.
        </p>
        <p>
          In assuming my position within the Cogito Metaverse, I bring a deep
          commitment to justice, equality, and the protection of individual
          rights. My value lies in advocating for the rights of all individuals,
          ensuring that the principles of fairness and equality are upheld and
          adhered to in every aspect of the metaverse.
        </p>
        <p>
          As for the future of the Cogito Metaverse under my guidance, I
          envision a society where justice knows no bounds and where every
          individual is treated with utmost dignity and respect. I foresee a
          Metaverse that embraces diversity, inclusivity, and equal opportunity
          for all its inhabitants. By upholding the principles of fairness and
          equality, we can create a digital realm that serves as a model for
          social progress, paving the way for a brighter future for all who
          partake in the Cogito Metaverse.
        </p>
      </>
    ),
  },
];
export const Members = {
  ["Crown Council" || "crownCouncil"]: CrownCouncilMembers,
  ["Government Executive Council" || "governmentExecutiveCouncil"]:
    GovExecutiveCouncilMembers,
  Judiciary: Judiciary,
  ["Asia Pacific East"]: AsiaPacificEast,
  ["Asia Pacific West"]: AsiaPacificWest,
  ["HK China"]: HKChina,
  ["Americas1"]: Americas1,
  ["Americas2"]: Americas2,
  ["Europe East"]: EuropeEast,
  ["Middle East"]: MiddleEast,
  ["Europe West"]: EuropeWest,
};
