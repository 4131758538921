import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "360px",
    background: "#FFF3D9",
    height: "140px",
    color: "#000",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      width: "340px",
    },
  },
  title: {
    padding: 0,
    color: "#000",
    fontSize: "1rem",
    fontWeight: "bolder",
  },
  cardSubTitleRoot: {
    color: "#000",
    padding: "2%",
  },
  cardHeaderTitleRoot: {
    padding: "2%",
  },
  image: {
    width: "100px",
  },
}));

const MembersCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Card className={classes.root} onClick={props.clicked}>
      <CardMedia
        component="img"
        className={classes.image}
        image={props.image}
        alt={props.name}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" className={classes.title}>
            {props.title}
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            className={classes.cardSubTitleRoot}
          >
            {props.position}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default MembersCard;
