import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

function TermsConditions() {
  return (
    <div className="insightsContainer">
      <Grid container className="insightSections" justifyContent="center">
        <Grid item>
          <Typography variant="h5" className="insightSection2-txt colorGrey">
            COGITO BANK ACCOUNT OPENING AND OPERATION TERMS & CONDITIONS
          </Typography>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Grid container className="insightSections">
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">SCOPE OF INTERPRETATION</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Terms & Conditions apply to all present and future relations
              between the bank and the Customer, subject to Clause 1.2(a). The
              Terms & Conditions include these Terms and Conditions and
              product/services specific Terms and Conditions , which are between
              the Customer and the Bank.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">1.Definitions </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              In this General Part:
              <br /> COGITO BANK (hereinafter referred as “the Bank” or “Bank”
              or “COGITO BANK”), acting through its head office and / or any
              branch : or <br />
              Any affiliate of COGITO BANK, acting through the affiliate’s head
              office and/or any branch including any change in it’s constitution
              consequent to conversion to wholly owned subsidiary or otherwise.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Account” means, each account of the Customer held at the Bank in
              the jurisdiction of the Principality of Cogito where the Customer
              and the Bank have agreed to be bound by the Terms &Conditions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Affiliate” means, in relation to any person, any entity
              controlled, directly or indirectly, by that person, any entity
              that controls, directly or indirectly, that person or any entity
              directly or indirectly under common control with that person. For
              this purpose, “control” of any entity or person means ownership of
              a majority of the voting power of the entity or person.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Bank” means that office or branch of COGITO BANK with which
              customer has agreed to be bound by the applicable Terms
              &Conditions.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Communication” means, any notice, order, instruction or other
              communication between the Customer and the Bank.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Customer” means,
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              A person or entity that maintains an account and/or has a business
              relationship with the bank.
              <br /> One on whose behalf the account is maintained (i.e. the
              beneficial owner). ‘Beneficial Owner' means the natural person who
              ultimately owns or controls a firm or a commercial entity and or
              the person on whose behalf a transaction is being conducted, and
              includes a person who exercises ultimate effective control over a
              juridical person or firm or a commercial entity].
              <br /> Beneficiaries of transactions conducted by professional
              intermediaries, such as Stock Brokers, Chartered Accountants,
              Solicitors etc. as permitted under the law, and any person or
              entity connected with a financial transaction which can pose
              significant reputational or other risks to the bank, say, a wire
              transfer or issue of a high value demand draft as a single
              transaction. “ Account Opening Terms & Conditions Version V12 1
              February 2020 “Terms & Conditions”, means, these Terms and
              Conditions, any applicable product/services specific Terms and
              Conditions ( as may be amended from time to time).
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Liabilities” means all present and future obligations and
              liabilities (whether actual or contingent) of the Customer to the
              Bank and/or its Related Entities.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Related Entity” means, any Branch, Office, Regional Office, Head
              Office, Affiliates, Partners etc of COGITO BANK.
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              “Statement” means, any statement, confirmation or advice (whether
              in written or electronic form) supplied by the Bank to the
              Customer.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">1.2 Interpretation </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) In the event of conflict between the Terms & Conditions and
              provisions applying to specific products or services provided by
              the Bank, the terms, conditions or provisions of the latter shall
              prevail.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              b) Subject to paragraph (a) above, the Terms & Conditions shall
              supersede any term, provision or condition previously agreed with
              or entered into by the Customer in connection with its
              relationship with the Bank in the jurisdiction where the Account
              is located. Without prejudice to any security or right granted by
              the Customer in favor of the Bank and/or any of its Related
              Entities, any existing account opened by the Customer with the
              Bank shall be considered an Account and shall be subject to the
              Terms & Conditions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              c) Nothing in the Terms & Conditions shall constitute a commitment
              by the Bank to provide the Customer with any overdraft or other
              credit facilities or any other product, service or relationship.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              d) Words importing the singular shall include the plural and
              vice-versa.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              e) In the Terms & Conditions, unless the context requires
              otherwise, a reference to a person shall be construed so as to
              include its successors and transferees.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              f) If the Terms & Conditions are translated into a language other
              than English, the English language version shall prevail.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <h3 className="aboutsection3-h1">2. OPERATION OF AN ACCOUNT </h3>
          </Grid>
          <Grid item xs={12}>
            <h3 className="aboutsection3-h1">
              2.1 Generals Duties of the Bank and the Customer{" "}
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) The Bank and the Customer shall exercise reasonable skill and
              care in all their present and future dealings with each other. In
              ascertaining whether or not a party has exercised reasonable skill
              and care, parties shall have regard to the then current applicable
              laws and regulations and established commercial practices: i) In
              the case of the Bank, practiced by a bank comparable to the Bank
              in the banking industry in the relevant jurisdiction; and ii) In
              the case of the Customer practiced by a commercial entity
              comparable to the Customer in the relevant sector. Account Opening
              Terms & Conditions Version V12 1 February 2020.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              b) The Customer will not act or omit to act in any way in a manner
              which may facilitate fraud, forgery or similar misconduct.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              c) The Customer agrees that the Bank, before opening any deposit
              account, will carry out a due diligence as required under Know
              Your Customer (KYC) guidelines of the Bank. The Customer is
              required to submit necessary documents or proofs, such as
              identity, address, photograph and any such information to meet
              with KYC, AML or other statutory/regulatory requirements. Further,
              in compliance with the extant regulatory guidelines, the Customer
              agrees to submit the above required KYC documents again at
              periodic intervals, as may be required by the Bank.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              d) The Customer agrees and undertakes to ensure that there would
              be sufficient funds/ clear balance/ pre-arranged credit facilities
              in its bank account for effecting transactions. The Customer
              agrees that the Bank shall not be liable for any consequences
              arising out of noncompliance by the Bank of Customer instructions
              due to inadequacy of funds and the Bank can at its sole discretion
              decide to carry out the instructions notwithstanding the
              inadequacy of funds without prior approval from or notice to the
              Customer and Customer will be liable to repay the resulting
              advance, overdraft or credit thereby created and all related
              charges arising thereby.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              e) The Customer agrees and undertakes to that we are not dealing
              with or shall have any transaction with the entities, individuals
              or organization that are banned/restricted by
              UNO/OFAC/MOFA/European Union Sanctions\Government of India\RBI or
              any other authorities.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.2 Opening and Maintaining an Account{" "}
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Customer will provide the Bank with any information,
              authorization or other document, which the Bank considers
              necessary in connection with opening and maintaining an account
              including, without limitation, provision of specimen of the
              signatures of each authorized Signatory.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">2.3 Notification of Change</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Customer will promptly notify the Bank in writing of any
              change in:
              <br /> its name or address;
              <br /> change in the beneficial owner/s, if any.
              <br /> the authority of any authorized signatory(ies) even if such
              a change has been recorded in a public register ; and/ or
              <br /> any information, authorization and/ or documents provided
              by the Customer under clause 2.2. Until such time as the Bank has
              received notice of any change, it is entitled to rely on any
              information, authorization or document previously provided to it
              according to Account Opening Terms & Conditions Version V12 1
              February 2020
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.4 Statements and Bank Records
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) The Customer shall:
              <br /> promptly examine all statements; and
              <br /> check whether the instructions given by or on behalf of the
              Customer have been executed correctly and in full by the Bank and
              shall notify the Bank of any error or omission, as the case may
              be, as soon as possible. If the Customer has not informed the Bank
              in writing of any error or omission contained in any Statement, or
              in the execution of any instructions, within 45 days from the date
              on which the Customer is deemed to have received the relevant
              Statement in accordance with the provisions of these terms and
              conditions, the Customer shall be deemed to have:
              <br /> confirmed the accuracy of the contents of such Statement ;
              and
              <br /> waived its right to bring an action against the Bank in
              respect of any error or omission which could have been brought to
              the attention of the Bank within the said time limit.
              <br /> b) The Bank’s records and statements as to date, amount or
              rates shall, in absence of manifest error, be conclusive evidence
              of the facts reflected in those records or statements.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.5 Dealings with itself and third parties
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Bank is authorized to deal with and use the services of third
              parties and/or deal with itself in executing orders, performing
              agreements and depositing goods and/or documents of title in
              custody and for any other purpose in connection with its relations
              with the Customer. The Bank shall exercise reasonable skill and
              care in the choice of such third party provided that the Bank
              shall at no time liable for any loss, damage or liability arising
              from the act or omission of any such third party.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.6 Reverse Entries and Correction of Errors
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) If at any time the Bank credits any account in anticipation of
              the receipt of funds, securities or other such items of value at a
              later time, the Bank may debit that Account to the extent that
              such funds, securities or other such items of value:
              <br /> are not actually received for value in the relevant
              currency at such later time; or are received but, as a result of
              error , omission, negligence or fraud on the part of the
              transferor or other relevant third party in relation to their
              transfer.
              <br /> The Bank is required to refund or return such funds,
              securities or other items of value to the transferor or other
              relevant third party.
              <br /> b) The Bank or the Customer shall notify the other party of
              any error or omission it discovers in any Communication from the
              other party and the relevant party shall correct that error or
              omission as soon as possible.
              <br /> c) Any adjustment made to give effect to paragraphs (a) or
              (b) above shall, so far as possible , be made for value on the
              date on which the credit was made by the Bank or (as appropriate)
              to which the error or omission related. Where such an adjustment
              is not possible, the adjustment actually made shall have the same
              economic effects as if made on the date. If any exchange or
              currency is required, the rate used by the Bank shall be the one
              available to it at the time of the exchange.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.7 Payments and Clearing Systems
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              For the purpose of giving effect to any Communication, the Bank or
              any Related Entity is authorized to use any payment or clearing
              system or intermediary bank it reasonably selects. The Bank or any
              Related Entity shall act in accordance with the rules and
              regulations from time to time of such payment or clearing system
              and (in respect of reimbursement, documentary collection and
              letter of credit related services) with guidelines and procedures
              of the International Chamber Of Commerce in effect at the time of
              performance unless otherwise agreed.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.8 Recordings of Telephone Conversations
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Bank may record any telephone calls with the Customer, made or
              received by the Bank, for quality and monitoring purpose(s). The
              Bank may use these recordings to verify dealings with the Customer
              and make sure that the Bank is meeting the service quality and
              standards.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.9 Performance Without prejudice to Clause 7(b):
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Only the Bank (and not any of its Related Entities ) shall be
              obliged to perform the Bank’s obligations in respect of each
              Account or other product or service to which the Terms &
              Conditions apply; and
              <br /> The Bank shall only be obliged to perform those obligations
              in the currency in which they are denominated although, where any
              restrictions or controls are imposed on the availability,
              convertibility to transfer of any funds of the Customer or any
              other person Account Opening Terms & Conditions Version V12 1
              February 2020 (whether before , on or after maturity and in
              whatever jurisdictions those controls or restrictions are
              imposed), the Bank may (but shall not be obliged to ) perform
              those obligations in any currency, at any rate and in any manner
              which in each case it reasonably determines and such performance
              shall constitute a good and valid discharge of those obligations.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              2.10 Dormant Accounts Dormant Accounts
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Accounts where no transactions (either debit or credit) have been
              made for last 24 months which are induced at the instance of
              Customers as well as third party. The service charges levied by
              the bank or interest credited by the bank will not be considered
              as transaction for this purpose.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">3. CLOSURE OF ACCOUNTS</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) In addition to the Banks’ rights in these terms and conditions
              and subject to any other provisions agreed in writing between the
              Customer and the Bank, the Bank may, at any time, close any
              Account on giving one month’s (or such other period required or
              permitted by any applicable law) notice in writing to the
              Customer, such period starting from the date that the Customer
              receives or is deemed to receive such notice. The Terms &
              Conditions shall continue to apply even after the closure of any
              Account until all Liabilities have been paid in full.
              <br /> b) The Customer may, at any time close any Account by
              giving notice in writing to the Bank along with required mandate,
              which notice shall take effect immediately upon its receipt or
              deemed receipt by the Bank unless such later time is specified in
              the notice.
              <br /> c) Upon notification to the Customer by the Bank under
              paragraph (a) above or by the Customer to the Bank under paragraph
              (b) above, all of the liabilities in relation to the relevant
              Account shall become due and payable when the Closure of the
              Account becomes effective.
              <br /> d) Any closure of an Account shall be without prejudice to:
              i)any liabilities arising prior to the closure, or ii) the right
              of the Bank to utilize the Account number for the purpose of
              administering the liabilities
              <br /> e) Repayment of all amounts owe must be made in euro and to
              an account as required by the bank from time to time.
              <br /> f) The Customer liable under these terms is the applicant
              in the real world and not the digital world, and all liabilities
              are personally guaranteed by them.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              4. Rights and obligations of Customer{" "}
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) The Customer will be entitled, subject to other terms and
              conditions in the Regulations and in addition to these Terms and
              Conditions to issue Payment Orders for execution by the Bank.
              <br /> b) Payment Order shall be issued by the Customer in the
              prescribed format, complete in all respect and with required
              particulars. The Customer shall be responsible for the accuracy of
              the particulars given in the Payment Order issued by self/ it’s
              signatories and shall be liable to compensate the Bank for any
              loss arising on account of any error in the Payment Order.
              <br /> c) The Customer will be bound by any Payment Order executed
              by the Bank if the Bank had executed the Payment Order in good
              faith and in compliance with the security procedure. Account
              Opening Terms & Conditions Version V12 1 February 2020
              <br /> d) The Customer will ensure availability of funds in its
              account properly applicable to the Payment Order before the
              execution of the Payment Order by the Bank. Where the Bank
              executes the Payment Order without properly applicable funds being
              available in the Customer's account the Customer shall be bound to
              pay to the Bank the amount debited to its account for which an
              RTGS / NEFT was executed by the Bank pursuant to the Payment
              Order, together with the charges including interest payable to the
              Bank.
              <br /> e) The Customer authorizes the Bank to debit its account
              any for liability incurred by the Customer to the Bank for
              execution by the Bank of any Payment Order issued by the Customer.
              <br /> f) The Customer agrees that the Payment Order shall become
              irrevocable when it is executed by the Bank.
              <br /> g) The Customer agrees that the Bank is not bound by any
              notice of revocation unless it is in compliance with the security
              procedure.
              <br />
              h) The Customer agrees that it shall not be entitled to make any
              claim against any party in the RBI RTGS / NEFT System except the
              Bank.
              <br /> i) The Customer agrees that in the event of any delay in
              the completion of the Funds Transfer or any loss on account of
              error in the execution of the Funds Transfer pursuant to a Payment
              Order, the Bank's liability shall not be held liable in the event
              of loss on account of error, negligence or fraud on the part of
              any employee of the Bank.
              <br /> j) The Customer agrees that no special circumstances shall
              attach to any Payment Order executed under the RTGS / NEFT
              Facility and under no circumstances Customer shall be entitled to
              claim any compensation in excess of that which is provided in
              clause (i) above, for any breach of contract or otherwise.
              <br /> k) The Customer is responsible for the correctness of the
              information supplied to the Bank for the use of RTGS / NEFT
              service. The Bank accepts no liability for the consequences
              arising out of erroneous information supplied to the Bank.
              <br /> l) The Bank shall not be liable for any unauthorized
              transaction occurring through the use of RTGS / NEFT service and
              the Customer hereby fully indemnifies and holds the Bank harmless
              against any action, suits, proceeding initiated against it or any
              loss, cost or damage caused by it as a result thereof.
              <br /> m) In consideration of the Bank providing this service, the
              Customer will indemnify and hold the Bank, including its
              officials, employees and agents, indemnified against all losses
              and expenses on full indemnity basis which the Bank may incur,
              suffer or likely to suffer in connection with the Bank executing
              the Customer RTGS / NEFT instruction.
              <br /> n) The Bank shall not be liable for any omission in
              carrying out of all or any of the Customer’s instruction for
              effecting an RTGS / NEFT payment or for late payments due to
              circumstances beyond the control of Bank (act of God, power
              failure, natural calamity, computer break down or malfunction,
              interruption or malfunction of communication facilities, labour
              issues, difficulties, or any other causes etc.) Account Opening
              Terms & Conditions Version V12 1 February 2020
              <br /> o) The Customer agrees that if its Funds Transfer request
              bears a Value Date that falls on a banking holiday/ suddenly
              declared banking holiday the Bank will process the request on
              following business day.
              <br /> p) The Customer is aware of the operating procedures
              relating to RTGS / NEFT.
              <br /> q) All other existing terms and conditions for operating an
              account hold good.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              5. Rights and obligations of the Bank
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) The Bank shall execute a Payment Order issued by the Customer
              duly authenticated by Self/ authorized signatory(ies) as verified
              by the security procedure, unless: The funds available in the
              account of the Customer are not adequate or properly applicable to
              comply with the Payment Order and the Customer has not made any
              other arrangement to meet the payment obligation. The Payment
              Order is incomplete or it is not issued in the agreed form. The
              Payment Order is attached with notice of any special
              circumstances. The Bank has reason to believe that the Payment
              Order is issued to carry out an unlawful transaction. The Payment
              Order cannot be executed under the RBI RTGS / NEFT System.
              <br /> b) No Payment Order issued by the Customer shall be binding
              on the Bank until the Bank has accepted.
              <br /> c) The Bank shall, upon execution of every Payment Order
              executed by it, be entitled to debit the designated account of the
              Customer, the amount of the funds transferred together with taxes
              payable thereon, whether or not be account has sufficient balance.
              <br /> d) If the Funds Transfer is not complete before the closure
              of business hours of the Bank the following RTGS / NEFT business
              day the Bank shall advise to the Customer on execution of the
              transaction.
              <br /> e) The Bank shall issue to the Customer a duly
              authenticated record of the transaction periodically. The Customer
              shall, within a period of two days from the date of receipt of the
              record of transaction(s) or as the case may be, report to the Bank
              any discrepancy in the execution of the Payment Order.
              <br /> f) The Customer agrees that it shall not be entitled to
              dispute the correctness of the execution of the Payment Order or
              the amount debited to his account if he fails to report the
              discrepancy within the said period.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">6. Disclosure of Information</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Customer hereby expressly agrees that:
              <br /> The Bank or its Related Entities may from time to time
              provide or obtain information about the Customer , its accounts
              and transactions to or from the Bank or its Related Entities and
              its or their contractors, data carriers, agents or other third
              parties around the world for the purpose of transaction and
              payment processing and for other purposes directly related to the
              services which the Bank or its Related Entities may provide
              (including other financial products and services) to the Customer.
              <br /> The Bank or its related Entities shall also be entitled to
              disclose information as permitted or required by applicable law,
              legal process, regulation or by an order, judgment or decree of a
              court of Principality of Cogito or for the purposes of any legal
              process which concerns the Bank or its Related Entities.
              Disclosure may also be made to Principality of Cogito government
              and regulatory agencies and authorities and to credit rating
              agencies ; and
              <br /> This permission to transfer and disclose information shall
              continue notwithstanding any termination of any agreement or
              cessation of any service with or to the Customer.
              <br /> As a pre-condition, relating to grant of the loans/
              advances/ other fund-based and non-fund based credit facilities by
              the Bank to the Customer, the Bank, requires the Customer’s
              consent for the disclosure of information and data relating to the
              Customer, of the credit facility availed of/ to be availed, by the
              Customer, obligations assumed/ to be assumed, by the Customer, in
              relation thereto and default, if any, committed by the Customer,
              in discharge thereof. Accordingly, The Customer agrees and gives
              consent for the disclosure by the Bank, of all or any such
              information and data relating to the Customer the information or
              data relating to any credit facility availed of/ to be availed, by
              the Customer, and default, if any, committed by the Customer in
              discharge of its such obligation, as the Bank may deem appropriate
              and necessary, to disclose and furnish to any other agency
              authorized in this behalf by Cogito Bank. The Customer declares
              and agrees that the information and data furnished by the Customer
              to the Bank are true and correct.
              <br /> The Customer agrees and undertakes that:
              <br /> the Credit Information Companies and any other agency so
              authorized may use, process the said information and data
              disclosed by the Bank in the manner as deemed fit by them; and
              <br /> the Credit Information Companies and any other agency so
              authorized may furnish for consideration, the processed
              information and data or products thereof prepared by them, to
              banks/ financial institutions and other credit grantors or
              registered users, as may be specified by the Cogito Bank in this
              behalf.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">7. Taxes and Fees</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Bank may impose such costs and charges in respect of its
              services in connection with the Accounts and such other services
              or products to which the Terms & Conditions are applicable as are
              agreed with the Customer from time to time. Agreed taxes and
              charges shall be confirmed in writing by the Bank. In the absence
              of any such agreement, the Bank may impose such taxes and charges
              as may be usual for the Bank to impose and may vary the amount and
              the means of calculating those taxes and charges upon prior notice
              to the Customer. The Customer shall be deemed to agree to such
              costs, charges or variation 15 days after receipt or deemed
              receipt by the Customer of the notice. The Bank may (without
              notice) debit the amount of all taxes and charges to any Account.
              All payments by the Customer under this Clause shall be made free
              of withholding or deduction on account of tax or other charges.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">8. Liability </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) The Bank shall be liable only for its negligence or in case of
              any willful misconduct. <br /> b) The Bank shall not be liable for
              any consequential loss, damages or loss of profit to the Customer
              notwithstanding that the Customer may have advised the Bank of the
              possibility of such or damage. <br /> c) Neither the Bank nor any
              of its Related Entities nor the Customer shall be liable for any
              loss, damage, delay or failure to perform any obligation resulting
              from any of the following circumstances: <br /> A legal enactment,
              decree or moratorium or any regulation, rule, practice or
              guidelines of a public authority (de jure or de facto) (including,
              without limitation, exchange control or currency restrictions and
              taxes, levies or imposts applicable to any Account balance (or
              part of it) attributable to the Customer and neither the Bank nor
              its related Entities need perform any obligation which might be in
              breach of any of the foregoing; or <br /> An intervention of a
              public authority (de jure or de facto) an act of nationalization,
              confiscation or expropriation, an act of war, a violent or armed
              action or inaction; or <br /> A failure of a payment or
              communications system, a power failure, a breakdown of equipment,
              a software malfunction or a deficiency in a software program; or{" "}
              <br /> A strike, a lock-out, a boycott, a blockade by or amongst
              the staff of the Bank, any of its Related Entities or any other
              person ; or <br /> A disaster (whether natural or manmade) Without
              in any way limiting, reducing or otherwise qualifying the
              obligations of the Customer under the Terms & Conditions, the Bank
              or the Customer shall take such reasonable steps which it
              considers in its sole opinion to be available to it to mitigate
              any of the adverse effects on the other party of any of those
              circumstances listed in sub-paragraph (i) to (v) (inclusive)
              above.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">9. Costs and Expenses</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) The Customer shall pay to the Bank all reasonable costs and
              expenses (including legal fees on a full indemnity basis) incurred
              by the Bank in connection with:
              <br /> its relationship with the Customer under the Terms &
              Conditions:
              <br /> the exercise and preservation of the Banks’ rights under
              the Terms & Conditions; or
              <br /> any legal proceedings or disputes between the Customer and
              a third party where the Bank becomes involved.
              <br /> b) The Customer shall indemnify the Bank against any
              liability or expense it incurs in respect of any payment on
              account of tax or any other liability to tax (not being tax on the
              Bank’s overall net income) or in respect of the stamp,
              registration and similar tax incurred by the Bank in connection
              with the relationship between the Bank and the Customer.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <h3 className="aboutsection3-h1">10. Communications </h3>
          </Grid>
          <Grid item xs={12}>
            <h3 className="aboutsection3-h1">
              10.1 Communications by the Customer{" "}
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) Each Communication given by the Customer to the Bank shall be
              clear and un ambiguous, state any relevant Account number and
              shall be addressed to each part of the Bank for which the
              Communication is intended. The Bank is not obliged to verify the
              accuracy of the information contained in any Communication.
              <br /> b) The Bank may rely upon a Communication where the Bank
              reasonably considers it is given by or emanating from the Customer
              by electronic banking arrangement or electronic mail (E-mail).
              <br /> c) Each communication given by the Customer shall be in a
              form acceptable to the Bank. The Bank is authorized, at its
              absolute discretion, to act or not to act on those Communications
              which are not in an agreed form.
              <br /> d) The Bank and the Customer may, subject to applicable
              legal or regulatory provision, agree methods or procedures to
              authenticate or validate any Communication issued by the Customer
              in electronic form.
              <br /> e) The Customer shall indemnify the Bank against all
              losses, costs and liabilities incurred by the Bank as a result of:
              <br /> An error in or a misunderstanding arising from a
              Communication by the Customer; and/or
              <br /> Acting on a communication given by or behalf of the
              Customer.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">10.2 Making of Communications</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) Any communication made by the Bank in connection with the Terms
              & Conditions may be made by any method referred to in Clause 9.2b)
              below.
              <br /> b) Any communication will be deemed to be given if by
              electronic banking arrangement or electronic mail (E-mail), when
              received in comprehensible form by the receiver of the
              Communication in its electronic mail box. However a notice given
              in accordance with the above but received on a non-working day or
              after applicable business hours is the place of receipt will only
              be deemed to be given on the next working day in that place.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">11. Severability </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              If any provision of the Terms & Conditions is or become illegal,
              invalid or unenforceable in any jurisdiction, that shall not
              effect:
              <br /> a) The validity or enforceability in that jurisdiction of
              any other provision of the Terms & conditions; or
              <br /> b) The validity or enforceability in other jurisdictions of
              that or any other provision of the Terms & Conditions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <h3 className="aboutsection3-h1">12 Amendments and Waivers </h3>
          </Grid>
          <Grid item xs={12}>
            <h3 className="aboutsection3-h1">12.1 Procedure </h3>
          </Grid>
        </Grid>

        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Bank may from time to time change the Terms & Conditions and
              such change shall take effect as between the Customer and the Bank
              60 days after receipt or deemed receipt by the Customer of a
              notice in writing setting out the details of the change. Where a
              change is a result of a legal or regulatory requirement applicable
              to the Bank, such change shall come into effect as and when
              required by the applicable law or regulation.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">
              12.2 Waivers and Remedies Cumulative{" "}
            </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The rights of the Bank and the Customer under the Terms
              &Conditions:
              <br /> May be exercised as often as necessary,
              <br /> Are cumulative and not exclusive of their rights under any
              applicable law, and
              <br /> May be waived only in writing and specifically. Delay in
              exercising or non-exercise of any such right is not a waiver of
              that right.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">13. Default</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) Without prejudice to any provision agreed upon in writing
              between the Bank and the Customer, if the Customer has given a
              notice in writing by the Bank that:
              <br /> The Customer is in breach of any of its payment obligations
              to the Bank and the Bank and the breach in continuing; or
              <br /> The Customer is in breach of any of its obligations other
              than its payment obligations to the Bank and the Bank has notified
              the Customer in writing that, in the Bank’s reasonable opinion ,
              the breach is likely to have a material adverse effect on the
              ability of the Customer to perform its obligations to the Bank
              hereunder ; or
              <br /> Any of the Customer’s assets have been (or the Bank
              considers any of them to be in danger of being ) seized or sold
              under any form of distress, attachment, execution or other legal
              process or to be otherwise in jeopardy and in the reasonable
              opinion of the Bank , such seizure, sale or jeopardy is likely to
              have a material or adverse effect on ability of the Customer to
              perform its obligations to the Bank hereunder ; or
              <br /> Any proceedings are commenced against the Customer in
              relation to its insolvency , dissolution or winding up or in
              respect of legislation directed at the staying of creditors’
              claims,
              <br />
              <br /> b) All liabilities shall there upon become due and payable
              and the Bank shall be immediately entitled to close any Account.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">14. Set Off</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              a) The Bank may set off any Liabilities against any obligation
              owed to the Customer by the Bank and/or any Related Entity,
              whether such obligation is matured and whether actual or
              contingent, regardless of the place of payment, booking branch or
              currency of any such obligation.
              <br /> b) Unless the law otherwise requires, the Bank is not
              obliged to give prior notice to the Customer of any exercise of
              its right of set off.
              <br /> c) If the relevant obligation owed by the Customer to the
              Bank or any of its Related Entities has not matured, the Bank
              shall not exercise its right of set off unless : Any asset of the
              Customer has been attached or seized, its recovery has been sought
              or a security interest has been created over it; or The Customer
              transfers any rights it has against the Bank to a third party
              without the Bank’s consent.
              <br /> d) If the obligations are in different currencies, the Bank
              may convert either obligation at a market rate of exchange in its
              usual course of business for the purpose of the set off. If any
              obligation is un-liquidated or unascertained, the Bank may set off
              in any currency.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">15. Governing Law </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              All claims, matters and disputes are subject to the exclusive
              jurisdiction of the competent courts in Principality of Cogito
              only. These Terms and Conditions and/or the operations in the
              accounts of the Customer maintained by the Bank and/or the use of
              the services provided by the Bank shall be governed by the laws of
              the Principality of Cogito and no other nation. The Customer and
              the Bank agree to submit to the exclusive Jurisdiction of the
              Courts located in the Principality of Cogito as regards any claims
              or matters arising under these Terms and Conditions. The Bank
              accepts no liability whatsoever, direct or indirect, for
              non-compliance with the laws of any country other than the
              Principality of Cogito.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">16. Customer Indemnity </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Customer agrees to indemnify and hold the Bank harmless against
              all actions, claims, demands, proceedings, losses, damages, costs,
              charges and expenses whatsoever which the Bank may at any time
              incur, sustain, suffer or be put to as a consequence of or by
              reason of or arising out of providing any of the services or due
              to any negligence/mistake/misconduct on Customer’s part or breach
              or non-compliance by the Customer of any of the Terms and
              Conditions relating to any of the services or by reason of the
              Bank in good faith taking or refusing to take action on any
              instruction given by me.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">17. Customer Declarations </h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The Customer is considered to have read and understood following
              declarations and hereby agrees to abide by the same:
              <br /> a) The Customer understands and agrees that (where
              applicable) all its Accounts held under the same 'Customer
              Number'/ ‘Customer ID’ with the Bank will adhere to the Account
              Type selected in the Application Form of the Bank. Accordingly all
              other accounts which the Customer currently maintains with the
              Bank using the same Customer Number/ Customer ID will be adjusted
              automatically by reference to the Account Type that Customer had
              opted as set out in the Account Opening Form of the Bank. The
              Customer is considered to be aware on the Account Features
              depending on the Account Type selected, accordingly different
              fees, charges and minimum / average account balance requirements
              (as determined by the Account Type that the Customer had opted)
              will henceforth apply to all Customer accounts held using the same
              Customer Number/ Customer ID.
              <br />
              <br /> b) The Customer acknowledges that all deposits and lending
              against such deposit(s) shall be subject to the laws of the
              Principality of Cogito, as amended from time to time.
              <br />
              <br /> c) The Customer understands and acknowledges that deposits
              placed with COGITO BANK's branch(es) have no right of recourse
              against the Head Office of COGITO BANK.
              <br />
              <br /> d) The Customer understands and acknowledges that changes
              in laws and rules in the Principality of Cogito may affect the
              liability of COGITO BANK to repay any depositor.
              <br />
              <br /> e) The Customer is required to declare that only the
              individuals declared as principal shareholders / beneficial owners
              in the account opening form, and as and when there is any change
              in the shareholding pattern of the entity, Customer will provide
              the Bank with the latest details of individuals holding 25% or
              above voting rights in the Entity.
              <br />
              <br /> f) The Customer is considered of having read and understood
              the Tariff Card and accepts the Average Monthly Balance /Average
              Quarterly Balance (AQB) requirement as applicable Account Opening
              Terms & Conditions. The Customer is considered of having read and
              understands the Account Rules, Tariff Card and hereby agrees to be
              bound by the Terms and Conditions governing the relevant
              Account(s). The Bank shall publish the guidelines, Account Rules,
              Tariff card and Terms and Conditions (and the
              amendments/supplements thereto) which are applicable to the
              relevant Accounts on its website. All the details mentioned
              herein, the declarations, the relevant Annexures mentioned in the
              Account Opening Form, along with all the guidelines, Tariff Card
              and Terms and Conditions that may be formulated and
              established/specified by COGITO BANK from time to time (including
              amendments/supplements), shall together govern all Accounts.
              <br />
              <br /> g) Customer confirms that as on the date of application,
              there are/ were no litigations, initiated by other Banks/
              financial institutions, being faced by the Customer.
              <br />
              <br /> h) The Customer authorizes COGITO BANK:
              <br /> in the course of managing the relationship with COGITO BANK
              and wherever necessary to comply with Money Laundering Prevention
              obligations, to record, obtain and to give or make available to
              any member(s) of the COGITO BANK other branches & subsidiaries, or
              their agents, information about the directors and employees of the
              Entity.
              <br /> unless and until the Customer directs otherwise, to update
              information about itself as provided in the account opening form
              and / or in any such application(s) or related documentation, or
              obtained in the course of, or for the purpose of, making any
              application for financial products and services (including but not
              limited to any credit information), onto a database which may be
              accessed by the member(s) of the COGITO BANK other branches &
              subsidiaries.
              <br /> COGITO BANK branch to convert to any other form of
              organization due to regulatory changes with regard to Wholly Owned
              Subsidiaries (WOS) guidelines or otherwise.
              <br /> The Customer is aware that full details of the use to which
              the said information can be put to by the Bank and other members
              of the Bank other branches & subsidiaries are set out in the Bank
              and / or such other branches & subsidiaries member's Terms and
              Conditions and / or privacy policy and that nothing contained in
              this consent shall absolve any member of the Bank other branches &
              subsidiaries of their obligation to comply with their Terms and
              Conditions and / or privacy policy.
              <br /> The Customer understands that standards of data/privacy
              protection laws in the country(ies) in which the Customer is
              applying/ has applied for the requested financial product(s) and
              service(s) may vary but that its information will be protected by
              a strict code of secrecy and security which all members of the
              COGITO BANK other branches & subsidiaries, their staff and any
              third parties with whom such information is shared, are subject
              to.
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Divider variant="middle" />

      <Grid conatiner className="insightsection3">
        <Grid item className=" colorGrey">
          <Typography variant="subtitle1">
            You can also learn more through our{" "}
            <Link to="/faq" className="blueLink">
              FAQ{" "}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default TermsConditions;
