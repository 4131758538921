import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

function RegistryPage() {
  return (
    <div className="insightsContainer">
      <Grid container>
        <Grid item xs={12}>
          <img
            src="/images/registrybanner-1.jpg"
            className="registrybanner"
            alt=""
          />
        </Grid>
      </Grid>

      <Divider variant="middle" />

      {/* <Grid
        container
        className="registrySection2"
        justifyContent="center"
      >
        <Grid item className="colorGrey" xs={12} md={12}>
          <Typography variant="h5" style={{ paddingTop: "6%" }}>
            You don’t have to own a yacht in the Bahamas to secure your assets
          </Typography>
        </Grid>
        <Grid item className="colorGrey " xs={12} md={3}>
          <Typography style={{ paddingTop: "6%" }} variant="h6">
            Take control of your tax
          </Typography>
          <br />
          <Typography variant="subtitle1">
            Regardless of your location take control of your assets and tax
          </Typography>
        </Grid>

        <Grid item className="colorGrey  " xs={12} md={3}>
          <Typography style={{ paddingTop: "6%" }} variant="h6">
            Asset Security
          </Typography>
          <br />
          <Typography variant="subtitle1">
            Security and privacy is simple process in the Principality of Cogito
          </Typography>
        </Grid>
      </Grid> */}
      <Grid container className="insightSections">
        <Grid container>
          <Grid item className="colorGrey" xs={12} md={12}>
            <Typography variant="h5" style={{ paddingTop: "6%" }}>
              You don’t have to own a yacht in the Bahamas to secure your assets
            </Typography>
          </Grid>
          <Grid item>
            <h3 className="aboutsection3-h1">Take control of your tax</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Regardless of your location take control of your assets and tax
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <h3 className="aboutsection3-h1">Asset Security</h3>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Security and privacy is simple process in the Principality of
              Cogito <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Divider variant="middle" />
      <Grid container className="insightSections">
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">Registry</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              Register using the Cogito portal to access all the benefits of the
              Principality of Cogito. These benefits include the safe haven for
              anyone who registers a trust and business and the ability to
              manage and control the timing of your tax liability at home.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item className="colorGrey">
            <Typography variant="subtitle1">
              Cogito is more than a place for safety and security, by being part
              of Cogito you will have the ability to buy and sell everyday goods
              and services using the currency of Cogito, the Cog.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid conatiner className="spacing">
          <Grid item className="colorGrey">
            <Typography variant="subtitle1">
              The Cog is not a cryptocoin, it is a government issued legal
              tender, issued by the Principality of Cogito, only available in a
              digital format. Cogito provides the most secure banking system in
              the world with a single centralised banking system and no ability
              to transact to other foreign banks. The benefit of this is that
              every transaction can be traced and tracked eliminating fraudulent
              transactions.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">Registration Process</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The registration process includes complying with the KYC and AML
              process. KYC stands for ‘Know Your Customer’ and AML stands for
              ‘Anti-Money Laundering’. It is the process of identifying and
              verifying the identity of a business’s clients. These processes
              are part of your registration to verify your identification and
              provides security for both you and Cogito. Your details are kept
              completely confidential.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <img
              className="aboutsection4-img"
              src="/images/Process.png"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              The registration process includes complying with the KYC and AML
              process. KYC stands for ‘Know Your Customer’ and AML stands for
              ‘Anti-Money Laundering’. It is the process of identifying and
              verifying the identity of a business’s clients. These processes
              are part of your registration to verify your identification and
              provides security for both you and Cogito. Your details are kept
              completely confidential..
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">Information for Trusts</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              By establishing a discretionary trust, you will be protector of
              the trust, (protector means you have veto rights over important
              decisions of the trustee). Cogito Corporate Trustee Ltd will act
              as trustee. You can select the beneficiaries of the trust. Do not
              include yourself. The reason you are not included as a beneficiary
              is that it compromises the independence of the structure from you.
              This helps protect you against a claim of tax liability at home.
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <h3 className="aboutsection3-h1">Information for Companies</h3>
          </Grid>
        </Grid>
        <Grid container className="spacing">
          <Grid item>
            <Typography variant="subtitle1" className="colorGrey">
              There is no obligation to set up a company in Cogito. The reason
              that you would register a business in Cogito is that without a
              Cogito registered company, earnings in Cogito would most likely be
              taxable in your country of residence. Citizens that register an
              investment company will have access to the Cogito stock exchange
              which will list all of the investments available through Cogito.
              Contact us to keep informed about the date of stock exchange
              opening.
              <br />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="middle" />

      <Grid container justifyContent="center">
        <Grid item className="colorGrey" xs={12}>
          <Typography variant="h5" className="section2-txt1">
            Enquire to discover how the Principality of Cogito can empower you
          </Typography>
        </Grid>

        <Grid item className="colorGrey " xs={12} md={5}>
          <Typography variant="h6" className="section2-txt2">
            We offer the opportunity to gain control of your assets.
          </Typography>
          <br />
          <Typography variant="subtitle1">
            <Link to="/registration" className="blueLink">
              Register with Cogito
            </Link>{" "}
            or learn more at our{" "}
            <Link to="/insights" className="blueLink">
              Insights
            </Link>{" "}
            and{" "}
            <Link to="/faq" className="blueLink">
              FAQ
            </Link>{" "}
            pages.
          </Typography>
        </Grid>
      </Grid>
      <Divider variant="middle" />
    </div>
  );
}

export default RegistryPage;
