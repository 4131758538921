import {
  makeStyles,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import ThemeButton from "../components/common/themebutton";
import logodark from "../asset/logoDark.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  img: {
    width: "105%",

    [theme.breakpoints.down(1280)]: {
      width: "90%",
    },
    [theme.breakpoints.down(960)]: {
      width: "85%",
    },
  },
}));

const Page_not_found = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item container justify="flex-start">
            <Box>
              <img
                className={classes.img}
                src={logodark}
                height={70}
                width={70}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="space-around" alignItems="center">
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={4}
            justify={matches ? "center" : null}
          >
            <Box py={{ xs: 2, sm: 2, md: 16, lg: 12 }}>
              <img
                className={classes.img}
                src="https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/404.png"
              />
            </Box>
          </Grid>

          <Grid item container xs={12} sm={12} md={6} lg={6} direction="column">
            <Box
              py={{ xs: 2, sm: 2, md: 16, lg: 12 }}
              px={{ xs: 5, sm: 2 }}
              pl={{ lg: 6 }}
            >
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                OOPS!
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  marginTop: "0.5rem",
                  textAlign: "left",
                }}
              >
                404-Page Not Found
              </Typography>
              <Typography
                variant="h6"
                style={{ marginTop: "1.5rem", textAlign: "left" }}
              >
                The page you are looking for might have been removed, had its
                name changed, or is temporarily unavailable. For now, relax grab
                a cup of coffee and relax you deserve it.
              </Typography>
              <ThemeButton
                disabled={false}
                background="#359DB6"
                title="Home"
                size={14}
                height={38}
                width={100}
                radius={11}
                onClick={() => {
                  window.location.pathname = "/";
                }}
                margin={"1.5rem 0rem 0rem 0rem"}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Page_not_found;
