import {
  Box,
  Container,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

const MemberInfo = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <Container
        style={{
          background: "white",
          padding: "4%",
          height: "70vh",
          overflowY: "scroll",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              float: matches ? "none" : "left",
              width: matches ? "100%" : "165px",
              alignItems: matches ? "center" : "left",
            }}
          >
            <Box>
              <img
                src={props.image}
                alt={props.name}
                style={{ width: "150px" }}
              />
            </Box>
            <Box
              style={{
                paddingBottm: "2%",
                width: "150px",
                textAlign: "center",
              }}
            >
              <Typography>{props.position}</Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {props.title}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              style={{
                color: "#231F20",
                fontWeight: 400,
                fontSize: "15px",
                lineHeight: "27px",
              }}
            >
              {props.bio}
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default MemberInfo;
